import React from 'react';
import SelfUpdatedAsyncSelectField from "../Shared/SelfUpdatedAsyncSelectField";
import $ from "jquery";

export default class ChooseUserOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            current_user: null,
            new_order: null
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        let me = this;
        let url = me.props.redirect_url;
        if ( url.indexOf("?") == -1 ) url += `?user_order_id=${e}`;
        if ( url.indexOf("?") >= 0  ) url += `&user_order_id=${e}`;
        // alert(url);
        document.location.href = url;
    }

    render() {
        let me = this;
        let options_url = "/orders/search";
        if (me.props.current_user) {
            options_url += "?user_id="+me.props.current_user.id;
        }
        if (me.props.custom_params) {
            options_url += me.props.custom_params;
        }
        let xxx = (
            <>
                <SelfUpdatedAsyncSelectField updateCallback={me.handleChange} placeholder="Укажите название заказа" label="" fieldName="user_order_id" options_url={options_url} updateUrl={null} />
            </>
        );
        return xxx;
    }
}