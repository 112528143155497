import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import "./table.scss";
import UserOrderKanbanView from "./UserOrderKanbanView";

export default class KanbanView extends React.Component {
  
	constructor(props) {
		super(props);
		this.state = {
			order_stages: [],
			orders: [],
			intervalId: null,
			ordersIntervalId: null,
			amountPerPage: 20,
			activeShift: 0,
			shownOrders: []

		};
		this.getOrderStages = this.getOrderStages.bind(this);
		this.getOrders = this.getOrders.bind(this);
		this.paginateOrders = this.paginateOrders.bind(this);
	} 

	paginateOrders(pagination) {
		let me = this;
		let step = parseInt(me.state.orders.length / me.state.amountPerPage);
		let paginatedData = me.state.orders.slice((pagination - 1) * me.state.amountPerPage, (pagination) * me.state.amountPerPage);
		let prevState = {... me.state};
		prevState.activeShift = pagination;
		prevState.shownOrders = paginatedData;
		me.setState(prevState);
	}

	componentDidMount() {
		let me = this;
		// let intervalId = setInterval(this.getOrderStages, 5000);
		this.getOrders().then((e) => {
				me.paginateOrders(1);
			}
		);
		// this.setState({intervalId: intervalId, ordersIntervalId: ordersIntervalId});
		// this.getOrderStages();
		// this.setState(this.props.content);
	}


	componentWillUnmount() {
	   // use intervalId from the state to clear the interval
	   // clearInterval(this.state.intervalId);
	   // clearInterval(this.state.ordersIntervalId);
	}


	getOrderStages() {
	  	// let me = this;
	  	// fetch("/orders/all_stages.json").then((e) => {return e.json()}).then(
	  	// 	(e) => {
	  	// 		let prevState = {... this.state};
	  	// 		prevState.order_stages = e;
	  	// 		me.setState(prevState);
	  	// 	}
	  	// );
	}


	async getOrders() {

		let me = this;
		return new Promise((resolve, reject) => {
			fetch("/orders").then((e) => {return e.json()}).then(
				 (e) => {
					 let prevState = {... this.state};
					 prevState.orders = e;
					 me.setState(prevState, (e) => {
					 	resolve(true);
					 });
					 // me.getOrders();
				 }
			);
		});
	}


	startTime(order) {
	  	for (let toothIndex in order.processed_tooths) {
	  		let tooth = order.processed_tooths[toothIndex];
	  		let dateStart = Date.parse(tooth.updated_at);
	  		let dateNow = Date.now();
	  		// alert(dateStart);
	  		if (dateStart) {
	  			let d = dateNow - dateStart;
	  	// 		alert(d);
	  	// 		// alert(date);
	  			return d;
	  		}
	  	}
	  	return null;
	}


  anyToothOnStage(stage, order) {
  	for (let toothIndex in order.processed_tooths) {
  		if (order.processed_tooths[toothIndex].tech_stage_id == stage.id) {
  			return true;
  		}
  	}

  	try {
	  	if (stage.id == "in_review") {
	  		if (order.tz.indexOf("уточне") >= 0) {
	  			return true;
	  		}
	  	}
	} catch(e) {
	}

  	return false;
  }


  render() {
  	let me = this;
  	let paginationButtons = [1];
  	for(let i = 2; i < me.state.orders.length / me.state.amountPerPage + 1; i++) {
  		paginationButtons.push(i);
	}
  	// alert(paginationButtons.length);
    let xxx = <div key={JSON.stringify(me.state.shownOrders)} style={{width: "90%", overflowX: "scroll"}}>
	      	{0 == me.state.orders.length &&
	      		<table>
					<tbody>
						<tr><td>
						<div style={{display: "flex", flexDirection: "row", alignContent: "center", alignItems: "center"}} className="messages-container">
						  <CircularProgress />
						  <p style={{fontSize: "20px", color: "#858585", margin: "0", marginLeft: "14px"}}>Загрузка заказов</p>
						</div>
						</td></tr>
					</tbody>
				</table>
	      	}
		  <table>
			  <tbody>
				<tr>
					 <td>
						  <div style={{display: "flex", flexDirection: "row", alignContent: "center", alignItems: "center"}}>
							  <span style={{marginRight: "14px"}}>Показывается: {me.state.shownOrders.length}/{me.state.orders.length}</span>
							  {paginationButtons.map( (e) => {
									let style = {fontWeight: 300, margin: "7px"};
									if (me.state.activeShift == e) {
										style = {fontWeight: 900, color: "#00abff", margin: "7px"};
									}
									return <span key={`e-${e}`} onClick={(x) => {me.paginateOrders(e);}} style={style}>{e}</span>
								  })
							  }
						  </div>
					 </td>
				</tr>
			  </tbody>
		  </table>
	      	{me.state.shownOrders.map( (e) =>
				<UserOrderKanbanView key={`order-${e.id}`} order={e} currentUser={me.props.currentUser}/>
	      	)}
    </div>;
    return (xxx);
  }
}