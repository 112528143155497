import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';

const fieldName = "is_blocked";
const label = "Заблокирован";

export default class IsBlocked extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      user: null
    }

    this.handleChange = this.handleChange.bind(this);
  } 

  componentDidMount() {
    let me = this;
    fetch('/users/me.json')
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      me.setState({user: data})
    });
  }

  handleChange() {
    let me = this;

    fetch('/users/update_field', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify({
        new_value: !this.state.user[fieldName],
        changed_field: fieldName
      })
    }).then((response) => {
      return response.json();
    })
    .then((data) => {
      me.setState({user: data})
    });
  }

  goToHref(href) {
    document.location.href = href;
  }

  render() {
    let xxx = <div>
      {!this.state.user && 
        <Skeleton variant="rect" width={40} height={14} />
      }

      {!!this.state.user && 
        <FormControlLabel
          control={<Switch checked={this.state.user[fieldName]} onChange={this.handleChange} name="checkedA" />}
          label={label}
          style={{marginRight: "30px"}}
        />
      }
    </div>;
    return (xxx);
  }
}



