import React from 'react';
import Button from '@material-ui/core/Button';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import QrCodeScaner from "../QrCodeScaner";


const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
  label: {
    textTransform: 'capitalize',
  },
});


export default class SideMenuButton extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      qrScanned: false
    };
    this.goToHref = this.goToHref.bind(this);
  }


  componentDidMount() {
  }


  goToHref(href) {
    // alert(href);
    if ("/users/qr_code" == href) {
      let prevState = {... this.state};
      prevState.qrScanned = true;
      this.setState(prevState);
      return;
    }
    document.location.href = href;
  }


  render() {
    let me = this;

    let xxx = 
      <div id={this.props.id}>
        <Button onClick={(e) => {this.goToHref(this.props.href)}} style={{width: "100%", background: "#DCE2E9", fontSize: "14px", marginBottom: "7px", textShadow: "none"}} key={this.props.title} >
          {this.props.title}
        </Button>
        <QrCodeScaner qrScanning={me.state.qrScanned}/>
      </div>
    return (xxx);
  }

}