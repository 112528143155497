import React from "react";
import SelfUpdatedTextAreaField from "../../Shared/SelfUpdatedTextAreaField";

export default class CommentsStage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let me = this;
        let orderUpdateUrl = `${document.SERVER_URL}/orders/${me.props.order.id}/update_field`;
        let orderGetUrl = `${document.SERVER_URL}/orders/${me.props.order.id}/json`;
        return <div>
            <SelfUpdatedTextAreaField updateCallback={null} variant="outlined" className={this.props.className} updateUrl={orderUpdateUrl} getUrl={orderGetUrl} fieldName="comment" label="Комментарий"/>
        </div>
    }
}