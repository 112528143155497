import React from 'react'
import Menu from "./tooth_menu/Menu";
import Jaw from "./jaw/Jaw";
import MessageInList from '../Message/MessageInList';

import ToothTaskShow from "./ToothTaskShow";
import TextTz from "./TextTz";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import EditableMenu from "./tooth_menu/EditableMenu";
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import "../Shared/a-color-system.scss";
import "./toothtask.scss";
import "./tooth_menu/menus.scss";

var toothTaskHistory = [];
var handlingContextMenu = false;
export default class ToothTask extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      key: 0,
      jaws: ["","2"],
      activeJaw: "",
      menus: [],
      successOpen: false,
      order: null,
      task: { color: null},
      jawDisabled: true,
      mode: "none",
      hint: "Левый клик на номер зуба, чтобы выбрать конструкцию",
      currentWorkName: "",
      first_menu: null,
      narrative_image_src: null,
      hovered_image_src: null,
      blocked_by_menu: false,
      teeth_prices: {},
      about: {
        display: "none",
        title: null,
        color: null,
        content: null,
        modelSvg: null
      },
      chosenTeeth: [],
      resettingTooth: false
    };
    toothTaskHistory.push(this.state);
    this.openMenu = this.openMenu.bind(this);
    this.levelUp = this.levelUp.bind(this);
    this.returnToFirstMenu = this.returnToFirstMenu.bind(this);
    this.setWorkFromTooth = this.setWorkFromTooth.bind(this);
    this.getOrderData = this.getOrderData.bind(this);
    this.updateData = this.updateData.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleContextMenu = this.handleContextMenu.bind(this);
    this.disableJaw = this.disableJaw.bind(this);
    this.goToMenuRaw = this.goToMenuRaw.bind(this);
    this.triggerHoveredPic = this.triggerHoveredPic.bind(this);
    this.unTriggerHoveredPic = this.unTriggerHoveredPic.bind(this);
    this.setColor = this.setColor.bind(this);
    this.updatePrice = this.updatePrice.bind(this);
    this.setVariable = this.setVariable.bind(this);
    this.addSelected = this.addSelected.bind(this);
    this.enableToothReset = this.enableToothReset.bind(this);
    this.stopResetting = this.stopResetting.bind(this);
    // this.textTzRef = React.createRef();
    this.jawRef = React.createRef();
  }

  async enableToothReset() {
    let me = this;
    // alert(4444);
    return new Promise((resolve, reject) => {
      let prevState = {... this.state};
      prevState.resettingTooth = true;
      me.setState(prevState, (e) => {
        resolve(true);
      });
    })
  }

  async stopResetting() {
    let me = this;
    // alert(4444);
    return new Promise((resolve, reject) => {
      let prevState = {... this.state};
      prevState.resettingTooth = false;
      me.setState(prevState, (e) => {
        resolve(true);
      });
    })
  }

  async addSelected(toothNumber) {
    let prevState = {... this.state};
    if (!prevState.order) return;
    let me = this;
    
    return new Promise( (resolve, reject) => {
      if (prevState.chosenTeeth.indexOf(toothNumber) == -1) {
        prevState.chosenTeeth.push(toothNumber);
      } else {
        let index = prevState.chosenTeeth.indexOf(toothNumber);
        prevState.chosenTeeth.splice(index, 1);
      }
      me.setState(prevState, (x) => {
        resolve(true);
      });
    } );
    // console.log("ToothTask.jsx"+ prevState.chosenTeeth);
  }

  handleClose() {
    let prevState = {... this.state};
    prevState.successOpen = false
    this.setState(prevState);
  }

  async openMenu (id, val, color, finish_and_start_tz) {
    let me = this;
    
    let url = `/menus/${id}.json`;
    let prevState = {... this.state};
    if (!prevState.order) return;
    let lastMenu = this.state.menus[this.state.menus.length - 1];
    prevState.needsClarification = null;
    let task = { ...me.state["task" + me.state.activeJaw] };
    if (lastMenu.reset_previous_task) {
      task = {};
    }
    console.log("ToothTask.jsx"+ 2);
    if (!lastMenu.finish_and_start_tz && !finish_and_start_tz) {
      if (!id) {
        console.log("ToothTask.jsx"+ 1.1);
        // me.returnToFirstMenu();
        return;
      }
      console.log("ToothTask.jsx"+ 2.1);
      // console.log("ToothTask.jsx"+ url);
      if (url.indexOf("https://") == -1) {
        url = document.SERVER_URL + url;
      }
      if (!id) return false;
      fetch(url)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Network response was not ok.");
        })
        .then(response => {
          console.log("ToothTask.jsx"+ "2.1.1");
          // task[lastMenu.sets_variable] = {
          //   value: val,
          //   menu: lastMenu.id 
          // };

          if (lastMenu.sets_variable) {
            console.log("ToothTask.jsx"+ "2.1.1.1");
            let menuItemOk = false;
            // console.log("ToothTask.jsx"+ "Setting var and checking variants " +lastMenu.menu_variants.length );
            for(let menuVariantIndex in lastMenu.menu_variants) {
              let m_i = lastMenu.menu_variants[menuVariantIndex];
              //console.log("ToothTask.jsx"+ m_i.variable_name + " == " + lastMenu.sets_variable);
              if (m_i.variable_name == lastMenu.sets_variable) {
                // console.log("ToothTask.jsx"+ "FOUND");
                if (task[lastMenu.sets_variable]) menuItemOk = true;
                break
              }
            }
            if (!menuItemOk) {
              task[lastMenu.sets_variable] = {
                value: val,
                menu: lastMenu.id 
              };    
            }
          }

          if (!!color) {
            if ("#000000" != color)
              task["color"] = color;
          }
          prevState.menus = this.state.menus.concat(response);
          prevState["task"+me.state.activeJaw] = task;
          prevState.blocked_by_menu = true;

          prevState.takes_color = response.takes_color;
          prevState.takes_color_areas_schema = response.takes_color_areas_schema;

          if (!!response.narrative_image_src) {
            // console.log("ToothTask.jsx"+ response.narrative_image_src);
            prevState.narrative_image_src = response.narrative_image_src;

          } else {
            prevState.narrative_image_src = null;
            prevState.hovered_image_src = null;
          }
          me.setState(prevState);
        });
    } else {
      console.log("ToothTask.jsx"+ "2.1.2");
      // console.log("ToothTask.jsx"+ "Here 1");
      // try {
      if (lastMenu.sets_variable) {
        console.log("ToothTask.jsx"+ "2.1.2.1");
        let menuItemOk = false;
        // console.log("ToothTask.jsx"+ "Setting var and checking variants " +lastMenu.menu_variants.length );
        for(let menuVariantIndex in lastMenu.menu_variants) {
          let m_i = lastMenu.menu_variants[menuVariantIndex];
          //console.log("ToothTask.jsx"+ m_i.variable_name + " == " + lastMenu.sets_variable);
          if (m_i.variable_name == lastMenu.sets_variable) {
            // console.log("ToothTask.jsx"+ "FOUND");
            if (task[lastMenu.sets_variable]) menuItemOk = true;
            break
          }
        }
        if (!menuItemOk) {
          task[lastMenu.sets_variable] = {
            value: val,
            menu: lastMenu.id
          };
        }
      }
      // console.log("ToothTask.jsx"+ JSON.stringify(task));
      if (!!color) {
        if ("#000000" != color)
          task["color"] = color;
      }
      // console.log("ToothTask.jsx"+ "Here 2");
      console.log("ToothTask.jsx"+ 3);
      prevState["task" + me.state.activeJaw] = task;
      if (lastMenu.adds_value) {
        console.log("ToothTask.jsx"+ 3.1);
        // console.log("ToothTask.jsx"+ 21231221);

        task["adds_value"] = true;
        // console.log("ToothTask.jsx"+ JSON.stringify(task));
        prevState["task" + me.state.activeJaw] = task;
        // return;
        // console.log("ToothTask.jsx"+ "adds_value")
      }
      // console.log("ToothTask.jsx"+ "Here 3");

      if (!!task.toothNumberTouched) {
        console.log("ToothTask.jsx"+ 4);
        // console.log("ToothTask.jsx"+ "and here");
        me.jawRef.current.setColorDirect(task.toothNumberTouched, task).then( function() {
          me.setState(prevState, function() {
            me.handleContextMenu();
          });
        });
        return;
        // me.props.updateCallback();
      }
      // console.log("ToothTask.jsx"+ 2222222);
      for(let chosenToothIndex in prevState.chosenTeeth) {
        let toothNumberToTouch = prevState.chosenTeeth[chosenToothIndex];
        // console.log("ToothTask.jsx"+ toothNumberToTouch);
        await me.jawRef.current.setColorDirect(toothNumberToTouch, task);
      }
      await me.jawRef.current.updateData();
      // console.log("ToothTask.jsx"+ "Here 4");
      
      
      // } catch(e) {}

      prevState.jawDisabled = false;
      prevState.narrative_image_src = null;
      prevState.hovered_image_src = null;
      prevState.hint = "Левый клик на номер зуба, чтобы выбрать конструкцию";
      // prevState.currentWorkName = this.generateWorkName(prevState.task);
      // prevState.currentWorkName = this.textTzRef.current.generateWorkName(prevState["task"+me.state.activeJaw]);
      prevState.blocked_by_menu = false;
      // console.log("ToothTask.jsx"+ "Here 5");
      this.setState(prevState, function() {
        if (prevState.chosenTeeth.length > 0 ) {
          me.handleContextMenu().then((e) => {
            me.returnToFirstMenu();
          })
          // console.log("ToothTask.jsx"+ "Here 6");
          return;
        }
      });
    }
  }

  setVariable(variableName, value) {
    let me = this;
    let prevState = {... this.state };
    if (!prevState.order) return;
    let lastMenu = this.state.menus[this.state.menus.length - 1];
    prevState["task" + me.state.activeJaw][variableName] = {
      value: value,
      menu: lastMenu.id
    };
    this.setState(prevState);
  }

  async setColor(e, menuItem) {
    let prevState = {... this.state};
    return new Promise((resolve, reject) => {
      prevState.key += 1;
      // console.log("ToothTask.jsx"+ menuItem.variable_name + " " + e);
      if (!!menuItem.variable_name) {
        prevState["task"+me.state.activeJaw][menuItem.variable_name] = {value: e};
        this.setState(prevState, function() {
          resolve(true);
        });
      } else {
        resolve(true);
      }
    });
  }

  generateWorkName(task) {
    let text = "";
    for (let i in task) {
      if ("color" == i) continue;
      text += i + ": " + task[i] + "\n"
    }
    // console.log("ToothTask.jsx"+ text);
    return text;
  }

  levelUp () {
    let me = this;
    // console.log("ToothTask.jsx"+ "UP");
    let prevState = {... this.state};
    prevState.key += 1;
    let arr = [...this.state.menus];
    arr.splice(this.state.menus.length - 1,1);
    let task = { ...this.state["task" + me.state.activeJaw] };
    let lastMenu = arr[arr.length - 1];
    
    delete task[lastMenu.sets_variable];
    prevState.narrative_image_src = null;
    prevState.hovered_image_src = null;

    if (!!lastMenu.narrative_image_src) {
      // console.log("ToothTask.jsx"+ response.narrative_image_src);
      prevState.narrative_image_src = lastMenu.narrative_image_src;
    }
    prevState.takes_color = lastMenu.takes_color;
    prevState.takes_color_areas_schema = lastMenu.takes_color_areas_schema;
    prevState.menus = arr;
    prevState["task" + me.state.activeJaw] = task;
    if (prevState.menus.length <= 1) {
      prevState.blocked_by_menu = false;
    }

    // this.setState({menus: arr, task: task});
    this.setState(prevState);
  }

  newJob() {

  }

  async returnToFirstMenu() {
    let me = this;
    return new Promise(function(resolve, reject) {
      let prevState = {... me.state};
      prevState.chosenTeeth = [];
      // prevState.task.color = "null1";
      prevState["task" + me.state.activeJaw] = {};
      prevState.resettingTooth = false;
      prevState.narrative_image_src = null;
      prevState.hovered_image_src = null;
      prevState.takes_color = null;
      prevState.key += 1;
      me.setState(prevState, function() {
        let url = `/menus/first_menu?order_id=${me.props.order.id}`;
        
        if (url.indexOf("https://") == -1) {
          url = document.SERVER_URL + url;
        }

        fetch(url)
          .then(response => {
            if (response.ok) {
              return response.json();
            }
            throw new Error("Network response was not ok.");
            resolve(false);
          })
          .then(response => {
            prevState["task" + me.state.activeJaw] = {};
            prevState.hint = "Левый клик на номер зуба, чтобы выбрать конструкцию";
            prevState.menus = [response[0]];
            prevState.key += 1;
            prevState.blocked_by_menu = false;
            prevState.jawDisabled = true;
            // prevState.order.tz["random_Suff"] = Math.random();
            me.setState(prevState, function() {
              me.updatePrice().then((eee) => {
                resolve(true);
              });
            });
          });
      });
    });
  }

  goToMenuRaw(id, currentTask) {
    let me = this;
    let prevState = {... this.state};
    prevState["task"+me.state.activeJaw] = currentTask;
  // console.log("ToothTask.jsx"+ JSON.stringify(currentTask));
    // prevState.task.toothNumber = toothNumber;
    // prevState.task.color = color;
    prevState.narrative_image_src = null;
    // prevState.blocked_by_menu = true;
    prevState
    prevState.hovered_image_src = null;
    prevState.key += 1;
    if (!id) me.returnToFirstMenu();
    let url = `/menus/${id}.json`;
    if (url.indexOf("https://") == -1) {
      url = document.SERVER_URL + url;
    }
    fetch(url)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response was not ok.");
    })
    .then(response => {
      prevState.hint = "Левый клик на номер зуба, чтобы выбрать конструкцию";
      prevState.menus = [response];
      prevState.key += 1;
      prevState.blocked_by_menu = true;
      prevState.jawDisabled = true;
      me.setState(prevState);
    });
  }

  async getOrderData() {
    let me = this;
    return new Promise(function(resolve, decline) {
      // console.log("ToothTask.jsx"+ this.props.order.id);
      let url = `/orders/${me.props.order.id}.json`;
      if (url.indexOf("https://") == -1) {
        url = document.SERVER_URL + url;
      }
      fetch(url).then(
        (e) => {
          return e.json()
        }
      ).then(
        (e) => {
          // console.log("ToothTask.jsx"+ JSON.parse(e.tz).teeth);
          let prevState = {... me.state };
          prevState.key += 1;
          prevState.order = e;
          me.setState(prevState, function() {
            me.updatePrice();
            resolve(true);  
          });
          
        }
      );
    });
    
  }

  disableJaw() {
    let prevState = {... this.state};
    if (!prevState.order) return;
    prevState.jawDisabled = true;
    prevState.blocked_by_menu = true;
    this.setState(prevState);
  }

  componentDidMount() {
    document.addEventListener('contextmenu', this.handleContextMenu);
    let me = this;
    this.getOrderData().then((e) => {
      me.returnToFirstMenu();

    });
  }


  componentWillUnmount() {
    document.removeEventListener('contextmenu', this.handleContextMenu);
  }


  async handleContextMenu(event) {
    let me = this;
    if (event) event.preventDefault();

    return new Promise((resolve, reject) => {
      if (handlingContextMenu) resolve(false);
      handlingContextMenu = true;
      setTimeout(function() {
        handlingContextMenu = false;
      }, 500);
      me.returnToFirstMenu().then((e) => {
        // console.log("ToothTask.jsx"+ 123123);

        if (me.props.updateCallback)
          me.props.updateCallback().then( (e1) => {
            resolve(true);
          });
        else {
          // me.returnToFirstMenu();
        }
      });
    });
  }


  setWorkFromTooth(toothTask, toothNumberTouched) {
    let me = this;
    let prevState = {... this.state};
    prevState.jawDisabled = false;
    prevState.key += 1;
    prevState.hint = "Нажмите на зуб, чтобы назначить на него работу, нажмите ПКМ, чтобы завершить назначение";
    // console.log("ToothTask.jsx"+ toothTask);
    prevState["task" + me.state.activeJaw] = toothTask;
    prevState["task" + me.state.activeJaw].toothNumberTouched = toothNumberTouched;
    // prevState.currentWorkName = this.textTzRef.current.generateWorkName(prevState["task"+me.state.activeJaw]);

    this.setState(prevState);
  }


  updateData(taskAsString) {
    let me = this;
    console.log("update_data");
    // console.log("ToothTask.jsx"+ taskAsString);
    // console.log("ToothTask.jsx"+ JSON.stringify(this.state.order));
    if (!!this.state.order) {

        let url = `/orders/${this.state.order.id}/update_field`;
        
        if (me.props.current_user) {
          url = `/orders/${this.state.order.id}/update_field?user_id=${me.props.current_user.id}`;
        }

        if (url.indexOf("https://") == -1) {
          url = document.SERVER_URL + url;
        }

        // console.log("Sending data to "+ url);
        if (url) {
          fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify({
              new_value: taskAsString,
              changed_field: "tz" + me.state.activeJaw
            })
          }).then((response) => {
            return response.json();
          }).then((data) => {
            let prevState = {... me.state};
            prevState.order[ "tz" + me.state.activeJaw] = taskAsString;
            prevState.successOpen = true;
            prevState.key += 1;
            me.setState(prevState, function() {
              // setTimeout(function() {
                let prevState = {... me.state };
                prevState.successOpen = false;
                prevState.key += 1;
                me.setState(prevState, function() {
                  me.updatePrice();  
                });
              // }, 100);  
            });
          });
        }
    } else {
      // console.log("ToothTask.jsx"+ "yo");
      me.getOrderData().then(function() {
        me.updateData(taskAsString);
      });
      // console.log("ToothTask.jsx"+ "Заказ не задан")
    }
  }

  async updatePrice() {
    let prevState = {... this.state};
    let me = this;

    let url = `/orders/${me.props.order.id}/evaluate_price`;
    if (url.indexOf("https://") == -1) {
      url = document.SERVER_URL + url;
    }

    return new Promise((resolve, reject) => {
      fetch(url).then( 
        (e) => {
          return e.json()
      }).then((e) => {
        prevState.price = e.price;
        prevState.teeth_prices = e.teeth_prices;
        prevState.key += 1;
        me.setState(prevState, function() {
          resolve(true);
        });
      });
    });
  }

  triggerHoveredPic(item) {
    // console.log("ToothTask.jsx"+ item.narrative_image_src);
    if (item.narrative_image_src) {
      let prevState = {... this.state};
      prevState.hovered_image_src = item.narrative_image_src;
      this.setState(prevState);
    }
  }

  unTriggerHoveredPic(item) {
    let prevState = {... this.state};
    prevState.hovered_image_src = null;
    this.setState(prevState);
  }

  setActiveJaw(e) {
    let me = this;
    if (me.state.activeJaw != e) {
      let prevState = {... me.state};
      if (!prevState["task" + e]) {
        prevState["task" + e] = {color: null};
      }
      prevState.activeJaw = e;
      me.setState(prevState);
    }
  }

  render() {
    let me = this;
    let toothStateKey = `key-${this.state.key}`;
    try {
      toothStateKey += `-${this.state.order.tz}`;
    } catch(e) {}
    // console.log("ToothTask.jsx"+ me.state.takes_color_areas_schema);
    // console.log("ToothTask.jsx"+ JSON.stringify(this.state.menus));
    // console.log("ToothTask.jsx"+ toothStateKey);
    
    let jawKey = me.state.resettingTooth + "" + JSON.stringify(me.state["task"+me.state.activeJaw]) + JSON.stringify(me.state.chosenTeeth) + me.state.key;
    // let hasSomethingToClarify = JSON.stringify(me.state.task).indexOf("уточн") >= 0;
    // console.log("ToothTask.jsx"+ jawKey)
    let menuBlocked = !(me.state.chosenTeeth.length > 0 || this.state.blocked_by_menu);
    let xxx = <>
      <div className="tooth-task" style={{display: "flex"}}>
        {!!me.state.order &&
            <div  style={{position: "relative"}}  key={`active-jaw-${me.state.activeJaw}`}>

              { me.state.jaws.map( (jaw, jawIndex) => {
                  if (jaw != me.state.activeJaw) {
                    return <></>
                  }
                  return <Jaw
                      resettingTooth={me.state.resettingTooth}
                      teeth_prices={me.state.teeth_prices}
                      handleContextMenu={me.handleContextMenu}
                      chosenTeeth={me.state.chosenTeeth}
                      addSelected={me.addSelected}
                      key={`${jawKey}-${jawIndex}-${me.state.activeJaw}`}
                      ref={me.jawRef}
                      activeJaw={me.state.activeJaw}
                      setVariable={me.setVariable}
                      takes_color_areas_schema={me.state.takes_color_areas_schema} takes_color={me.state.takes_color} current_user={this.props.current_user} blocked_by_menu={this.state.blocked_by_menu} narrative_image_src={!this.state.hovered_image_src ? this.state.narrative_image_src : this.state.hovered_image_src} order={me.state.order} updateData={this.updateData} setWorkFromTooth={this.setWorkFromTooth} setWork={this.state["task"+me.state.activeJaw]} hint={this.state.hint} currentWorkName={this.state.currentWorkName} disabled={this.state.jawDisabled}
                      setColor={me.state["task"+me.state.activeJaw].color}
                      updateCallback={me.props.updateCallback}
                      getOrderData={me.getOrderData}
                      stopResetting={me.stopResetting}
                  />
              }) }
            </div>
        }
        {!me.state.order &&
          <CircularProgress />
        }
        { !!this.state.jawDisabled && this.state.menus.length > 0 &&
          <div  style={{marginLeft: "49px", display: "flex", flexDirection: "column"}}>
          <div style={{opacity: !menuBlocked ? 0.4 : 1, pointerEvents: !menuBlocked ? "none" : "all", display: "flex", flexDirection: "row", alignContent: "center", alignItems: "center", maxWidth: "30px", marginBottom: "20px", marginLeft: "21px"}}>
            <p>Отображаемый слой ТЗ:</p>
            {me.state.jaws.map( (e, eIndex) => {
                return <Button style={{backgroundColor: e == me.state.activeJaw ? "#ededed" : "#ffffff", fontSize: "12px", maxHeight: "30px", border: "1px solid #00abff", margin: "7px", borderRadius: "5px"}} onClick={(ev) => {me.setActiveJaw(e); }}>Слой-{eIndex}</Button>
            })
            }
          </div>
          <div  className="menu-horizontal-container">
            <br/>
            {this.state.menus.map( (item, index) => <>
                { !!item && <Menu enableToothReset={me.enableToothReset} jawRef={me.jawRef} takes_color={me.state.takes_color} blocked={menuBlocked} handleContextMenu={me.handleContextMenu} task={me.state["task" + me.state.activeJaw]} setVariable={me.setVariable} setColor={me.setColor} triggerHoveredPic={me.triggerHoveredPic} unTriggerHoveredPic={me.unTriggerHoveredPic}  key={`menu-${index}-${item.id}`} levelUp={this.levelUp} content={item} openMenu={this.openMenu} menu_level={index} current_menu_index={this.state.menus.length - 1} hidden={(index != this.state.menus.length - 1)} /> }
              </>
            )}
          </div>
          </div>
        }

        { !this.state.jawDisabled &&
          <div className="menu-horizontal-container" >
            <div className="menu">
              <br/><br/>
              <br/>
              <p>Назначаемая работа:</p>
              <span style={!menuBlocked ? {border: "1px solid #858585", pointerEvents: "none", opacity: 0.2} : {border: "1px solid #858585"}}>
                <Button onClick={me.handleContextMenu}>Завершить настройку конструкции</Button>
              </span>
              <br/>
              {/*{hasSomethingToClarify && 
                <Button onClick={me.props.handleContextMenu} style={{border: "1px solid #f00", color: "#f00"}}>Уточнить конструкцию</Button>
              }*/}
              <br/>
              {/*<p style={{color: this.state.task.color}}>{this.state.currentWorkName}</p>*/}
              <EditableMenu disableJaw={me.disableJaw} key={`${me.state["task"+me.state.activeJaw]}`} task={me.state["task"+me.state.activeJaw]} goToMenuRaw={me.goToMenuRaw} />
            </div>
          </div>
        }
        {/*<p>{JSON.stringify(this.state.order)}</p>*/}
        
        <Snackbar open={me.state.successOpen} autoHideDuration={6000} onClose={me.handleClose}>
          <Alert onClose={me.handleClose} severity="success">
            Данные обновлены
          </Alert>
        </Snackbar>
      </div>

      { me.props.order.tz_ready &&
        <MessageInList key={22} itself={{title: "Стоимость", content: `Примерная стоимость работ: ${me.state.price}&nbsp;₽`}}/>
      }
      {/*<ToothTaskShow order={this.state.order} title={this.state.about.title} content={this.state.about.content} />*/}
      {/*<TextTz ref={me.textTzRef} key={toothStateKey}  order={this.state.order} />*/}

    </>;
    return (xxx);
  }
}
