import React from "react";
import SelfUpdatedFileField from "../../Shared/SelfUpdatedFileField";

export default class ImagesUploadStage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let me = this;
        let orderUpdateUrl = `orders/${me.props.order.id}/upload_image_field`;
        let orderGetUrl = `/orders/${me.props.order.id}/message_attachements`;
        return (<div>
            <SelfUpdatedFileField updateCallback={null}
                                  parentId={me.props.order.id}
                                  fileUrlFieldName={"file_url"}
                                  parentModelFieldName={"user_order_id"}
                                  updateUrl={orderUpdateUrl}
                                  getUrl={orderGetUrl}
                                  label="Файлы"
                                  current_user={me.props.current_user}
            />
        </div>);
    }
}