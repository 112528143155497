import React from 'react';
import ProjectIdStage from "./order_stages/ProjectIdStage";
import ChooseUserEmployeeStage from "./order_stages/ChooseUserEmployeeStage";
import ChooseUserPatientStage from "./order_stages/ChooseUserPatientStage";
import TzStage from "./order_stages/TzStage";
import PackagingListStage from "./order_stages/PackagingListStage";
import ChooseSpecialtyStage from "./order_stages/ChooseSpecialtyStage";
import ActivateOrderStage from "./order_stages/ActivateOrderStage";
import ClarificationStage from "./order_stages/ClarificationStage";
import DeliveryFormForUser from "../Delivery/forms/DeliveryFormForUser";
import FilesUploadStage from "./order_stages/FilesUploadStage";
import DatesStage from "./order_stages/DatesStage";
import CommentsStage from "./order_stages/CommentsStage";
import ChooseSecondEmployeeStage from "./order_stages/ChooseSecondUserEmployeeStage";
import UserActivationStage from "./order_stages/UserActivationStage";
import ImagesUploadStage from "./order_stages/ImagesUploadStage";

async function postData(url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST', 
    mode: 'cors', 
    cache: 'no-cache', 
    credentials: 'same-origin', 
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', 
    referrerPolicy: 'no-referrer', 
    body: JSON.stringify(data) 
  });
  return await response.json(); 
}


export default class OrderStage extends React.Component {

  // static ventillateSituation(situation, zakaz_stage) {
    
  //   return Math.hypot(dx, dy);
  // }


  constructor(props) {
    super(props);
    this.state = {
      blocked: false
    };

    this.handleCallback = this.handleCallback.bind(this);
    this.amIBlocked = this.amIBlocked.bind(this);
    this.updateAvailability = this.updateAvailability.bind(this);
    this.handleCallbackWithOrderReload = this.handleCallbackWithOrderReload.bind(this);

    this.chooseSpecialtyStageRef = React.createRef();
    this.chooseSpecialtyStageRef = React.createRef();
    this.projectIdStageRef = React.createRef();
    this.chooseUserEmployeeStageRef = React.createRef();
    this.chooseUserPatientStageRef = React.createRef();
    this.tzStageRef = React.createRef();
    this.packagingListStageRef = React.createRef();
    this.deliveryFormForUserRef = React.createRef();
    this.activateOrderStageRef = React.createRef();
  }


  async updateAvailability() {
    let me = this;
    return new Promise( (resolve, reject) => {
      me.amIBlocked().then((e) => {
        resolve(true);
      });
    });
    
  }


  async amIBlocked() {
    let me = this;
    let url = `/zakaz_stages/${this.props.content.id}/get_status?order_id=${this.props.order.id}`;
    if (this.props.current_user) {
      url = `/zakaz_stages/${this.props.content.id}/get_status?order_id=${this.props.order.id}&user_id=${this.props.current_user.id}`;
    }
    if (url.indexOf("https://") == -1) {
      url = document.SERVER_URL + url;
    }

    return new Promise((resolve, reject) => {
      fetch(url).then((res) => {
        return res.json()
      }).then((e) => {
        let prevState = {... me.state};
        prevState.blocked = !e.res;
        me.setState(prevState, function (xxx) {
          if (me.state.blocked)
            if ("Уточнение ТЗ" == me.props.content.action_type_name) {
              try {
                me.tzStageRef.current.returnToFirstMenu();
              } catch(e) {}
            }

          resolve(true);
        });
        // }
        // alert(JSON.stringify(e));
      });
    });
  }


  componentDidMount() {
    this.amIBlocked();
    // alert(JSON.stringify(this.props.content));
  }


  componentWillUnmount() {
  }


  handleCallback(parsel) {
    if (!!this.props.updateCallback) {
      this.props.updateCallback(parsel);
    }
    // this.amIBlocked();
  }


  handleCallbackWithOrderReload(parsel) {
    if (!!this.props.updateCallbackWithOrderReload) {
      this.props.updateCallbackWithOrderReload(parsel);
    }
    // this.amIBlocked();
  }


  render() {
    let me = this;
    // alert(me.props.current_user);
    let xxx = <div className="order-element" >
      {/*<p>{JSON.stringify(me.props.order)}</p>*/}
      { ( ("Комментарий" == this.props.content.action_type_name) ) &&
      <div key={`o-${JSON.stringify(me.props.order)}-1`} style={(this.state.blocked) ? {opacity: 0.4, pointerEvents: "none"} : {} }>
        <CommentsStage current_user={me.props.current_user} stage={this.props.content} updateCallback={null} order={this.props.order} />
      </div>
      }
      { ( ("Выбор специальности" == this.props.content.action_type_name) ) &&
        <div key={`o-${JSON.stringify(me.props.order)}-1`} style={(this.state.blocked) ? {opacity: 0.4, pointerEvents: "none"} : {} }>
          <ChooseSpecialtyStage current_user={me.props.current_user} ref={me.chooseSpecialtyStageRef} stage={this.props.content} updateCallback={me.handleCallbackWithOrderReload} order={this.props.order} />
        </div>
      }
      { ( ("Ввод номера заказа" == this.props.content.action_type_name) ) &&
        <div key={`o-${JSON.stringify(me.props.order)}-2`} style={(this.state.blocked) ? {opacity: 0.4, pointerEvents: "none"} : {} }>
          <ProjectIdStage current_user={me.props.current_user} ref={me.projectIdStageRef} stage={this.props.content} updateCallback={me.handleCallback} order={this.props.order} />
        </div>
      }
      { ( ("Выбор второго врача" == this.props.content.action_type_name) ) &&
      <div key={`o-${JSON.stringify(me.props.order)}-3`} style={(this.state.blocked) ? {opacity: 0.4, pointerEvents: "none"} : {} }>
        <ChooseSecondEmployeeStage current_user={me.props.current_user} ref={me.chooseUserEmployeeStageRef} stage={this.props.content} updateCallback={me.handleCallback} order={this.props.order} />
      </div>
      }
      { ( ("Выбор врача" == this.props.content.action_type_name) ) &&
        <div key={`o-${JSON.stringify(me.props.order)}-3`} style={(this.state.blocked) ? {opacity: 0.4, pointerEvents: "none"} : {} }>
          <ChooseUserEmployeeStage current_user={me.props.current_user} ref={me.chooseUserEmployeeStageRef} stage={this.props.content} updateCallback={me.handleCallback} order={this.props.order} />
        </div>
      }
      { ( ("Выбор пациента" == this.props.content.action_type_name) ) &&
        <div key={`o-${JSON.stringify(me.props.order)}-4`} style={(this.state.blocked) ? {opacity: 0.4, pointerEvents: "none"} : {} }>
          <ChooseUserPatientStage current_user={me.props.current_user} ref={me.chooseUserPatientStageRef} stage={this.props.content} updateCallback={me.handleCallback} order={this.props.order} />
        </div>
      }
      { ( ("Уточнение ТЗ" == this.props.content.action_type_name) ) &&
        <div key={`o-${JSON.stringify(me.props.order)}-5`} style={(this.state.blocked) ? {opacity: 0.4, pointerEvents: "none"} : {} }>
          <TzStage current_user={me.props.current_user} ref={me.tzStageRef} stage={this.props.content} updateCallback={me.handleCallback} order={this.props.order} />
        </div>
      }
      { ( ("Заполнение упаковочного листа" == this.props.content.action_type_name) ) &&
        <div key={`o-${JSON.stringify(me.props.order)}-6`} style={(this.state.blocked) ? {opacity: 0.4, pointerEvents: "none"} : {} }>
          <PackagingListStage current_user={me.props.current_user} ref={me.packagingListStageRef} stage={this.props.content} updateCallback={me.handleCallback} order={this.props.order} />
        </div>
      }
      { ( ("Вызов курьера" == this.props.content.action_type_name) ) &&
        <div key={`o-${JSON.stringify(me.props.order)}-7`} style={(this.state.blocked) ? {opacity: 0.4, pointerEvents: "none"} : {} }>
          <DeliveryFormForUser current_user={me.props.current_user} ref={me.deliveryFormForUserRef} stage={this.props.content} updateCallback={me.handleCallback} delivery={this.props.order.deliveries[0]} />
        </div>
      }
      { ( ("Активация заказа" == this.props.content.action_type_name) ) &&
        <div key={`o-${JSON.stringify(me.props.order)}-8`} style={(this.state.blocked) ? {opacity: 0.4, pointerEvents: "none"} : {} }>
          <ActivateOrderStage current_user={me.props.current_user} ref={me.activateOrderStageRef} stage={this.props.content} updateCallback={me.handleCallback} order={this.props.order} />
        </div>
      }
      { ( ("Уточнение заказа" == this.props.content.action_type_name) ) &&
        <div key={`o-${JSON.stringify(me.props.order)}-9`} style={(this.state.blocked) ? {opacity: 0.4, pointerEvents: "none"} : {} }>
          <ClarificationStage current_user={me.props.current_user} ref={me.activateOrderStageRef} stage={this.props.content} updateCallback={me.handleCallback} order={this.props.order} />
        </div>
      }
      { ( ("Ожидание стадии" == this.props.content.action_type_name) ) &&
        <div key={`o-${JSON.stringify(me.props.order)}-10`} style={(this.state.blocked) ? {opacity: 0.4, pointerEvents: "none"} : {} }>
          <AwaitingStage current_user={me.props.current_user} ref={me.activateOrderStageRef} stage={this.props.content} updateCallback={me.handleCallback} order={this.props.order} />
        </div>
      }
      { ( ("Загрузка STL" == this.props.content.action_type_name) ) &&
        <div key={`o-${JSON.stringify(me.props.order)}-11`} style={(this.state.blocked) ? {opacity: 0.4, pointerEvents: "none"} : {} }>
          <FilesUploadStage current_user={me.props.current_user} ref={me.activateOrderStageRef} stage={this.props.content} updateCallback={me.handleCallback} order={this.props.order} />
        </div>
        
      }
      { ( ("Указание дат заказа" == this.props.content.action_type_name) ) &&
        <div key={`o-${JSON.stringify(me.props.order)}-12`} style={(this.state.blocked) ? {opacity: 0.4, pointerEvents: "none"} : {} }>
          <DatesStage current_user={me.props.current_user}
                      ref={me.activateOrderStageRef}
                      stage={this.props.content}
                      updateCallback={me.handleCallback}
                      order={this.props.order} />
        </div>
      }
      { ( ("Активация заказа для клиента" == this.props.content.action_type_name) ) &&
      <div key={`o-${JSON.stringify(me.props.order)}-13`} style={(this.state.blocked) ? {opacity: 0.4, pointerEvents: "none"} : {} }>
        <UserActivationStage current_user={me.props.current_user} ref={me.activateOrderStageRef} stage={this.props.content} updateCallback={me.handleCallback} order={this.props.order} />
      </div>
      }
      { ( ("Прикрепление фото" == this.props.content.action_type_name) ) &&
      <div key={`o-${JSON.stringify(me.props.order)}-14`} style={(this.state.blocked) ? {opacity: 0.4, pointerEvents: "none"} : {} }>
        <ImagesUploadStage current_user={me.props.current_user} ref={me.activateOrderStageRef} stage={this.props.content} updateCallback={me.handleCallback} order={this.props.order} />
      </div>
      }
    </div>;
    return xxx;
  }
}