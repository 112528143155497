import React from 'react'
import SideMenu from "./SideMenu";

export default class UserRightMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: [
        {
          title: "Заказы",
          action: {
            href: "/users/orders"
          }
        },
        {
          title: "Сотрудники",
          action: {
            href: "/users/user_employees"
          }
        },
        {
          title: "Пациенты",
          action: {
            href: "/users/user_patients"
          }
        },
        {
          title: "Точки забора",
          action: {
            href: "/users/pick_points"
          }
        },
        // {
        //   title: "Статистика",
        //   action: {
        //     href: "/users/stats"
        //   }
        // },
        {
          title: "История/архив",
          action: {
            href: "#"
          }
        },
        // {
        //   title: "Документы",
        //   action: {
        //     href: "/users/documents"
        //   }
        // },
        {
          title: "Настройки",
          action: {
            href: "/users/settings"
          }
        }
      ]    
    };

  } 


  componentDidMount() {
  }
  

  render() {
    let xxx = <SideMenu menu={this.state.menu}/>;
    return (xxx);
  }
}