import React from "react";
import $ from "jquery";

function getOffsetLeft(elem) {
    var offsetLeft = 0;
    do {
        if (!isNaN(elem.offsetLeft)) {
            offsetLeft += elem.offsetLeft;
        }
    } while ((elem = elem.offsetParent));
    return offsetLeft;
}

function getOffsetTop(elem) {
    var offsetTop = 0;
    do {
        if (!isNaN(elem.offsetTop)) {
            offsetTop += elem.offsetTop;
        }
    } while ((elem = elem.offsetParent));
    return offsetTop;
}

export default class Lined extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.line = React.createRef();
        this.positionLines = this.positionLines.bind(this);

    }

    handleChange(event) {}

    positionLines() {
        if (!this.props.ref1) {
            // console.log("Ref 1 trouble");
            return;
        }
        if (!this.props.ref1.current) {
            // console.log("Ref 1 trouble current");
            return;
        }
        if (!this.props.ref2.current) {
            return;
        }
        // console.log("All ok");
        let line = $(this.line.current);
        let div1 = $(this.props.ref1.current);
        let div2 = $(this.props.ref2.current);
        let x1 = getOffsetLeft(this.props.ref1.current) + (div1.width() + 7);
        let y1 = getOffsetTop(this.props.ref1.current) + div1.height() / 2;
        let x2 = getOffsetLeft(this.props.ref2.current); // + (div2.width()/2);
        let y2 = getOffsetTop(this.props.ref2.current) + div2.height() / 2;
        line.attr("x1", x1).attr("y1", y1).attr("x2", x2).attr("y2", y2);
    }

    componentDidMount() {
        this.positionLines();
        setInterval(this.positionLines, 3000);
    }

    render() {
        return (
            <>
                <svg className="svg-line">
                    <line ref={this.line} id="line" />
                </svg>
            </>
        );
    }
}