import React from "react";
import "./delivery-card.scss";
import SelfUpdatedAsyncSelectField from "../Shared/SelfUpdatedAsyncSelectField";

export default class DeliveryCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            changingCourier: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.changeCourier = this.changeCourier.bind(this);
    }

    handleChange(event) {
    }

    componentWillMount() {
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    componentDidUpdate() {
    }

    changeCourier() {
        let prevState = {... this.state};
        prevState.changingCourier = !prevState.changingCourier;
        this.setState(prevState);
    }

    render() {
        let me = this;
        let deliveryGetUrl = `${document.SERVER_URL}/deliveries/${me.props.delivery.id}.json`;
        return <div className="delivery-card">
            { me.props.delivery.route_lists.length > 0 &&
                <div style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    background: "#ababab"
                }}>
                    <p>Заявка находится в <a href={`/admin/route_lists/${me.props.delivery.route_lists[0].id}`}>маршрутном
                        листе №{me.props.delivery.route_lists[0].id}</a></p>
                </div>
            }
            <div style={{width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "space-between", alignContent: "space-between"}}>

                <h3>Заявка №{me.props.delivery.id} на <b>{me.props.delivery.delivery_type}</b> для заказа <a href={`/admin/user_orders/${me.props.delivery.user_order_id}`}>АРТ-{me.props.delivery.user_order_id}</a></h3>
                <h4>Заказчик: <a href={`/admin/users/${me.props.delivery.user_id}`}>{me.props.delivery.user.name}</a></h4>
                <div style={{display: "flex", flexDirection: "row", alignContent: "center", alignItems: "center"}}>
                    <h4 style={{margin: 0, marginRight: "14px"}}>Тип заказа: {me.props.delivery.by_myself ? "Самостоятельно" : "Курьером"}</h4>
                    {!me.props.delivery.by_myself && (!me.props.delivery.courier || me.state.changingCourier) &&
                        <SelfUpdatedAsyncSelectField getUrl={deliveryGetUrl} updateCallback={me.handleChange} placeholder="Выберите курьера" label="" fieldName="courier_id" options_url={document.SERVER_URL+"/couriers/search"} updateUrl={`/deliveries/${me.props.delivery.id}/update_field`} />
                    }
                    {!me.props.delivery.by_myself && !!me.props.delivery.courier &&
                        <p>Курьер: <a href={`/admin/couriers/${me.props.delivery.courier.id}`}>{me.props.delivery.courier.name}</a> <span style={{color: "#858585"}} onClick={me.changeCourier}>Сменить</span></p>
                    }
                </div>
            </div>
            <br/>
            <hr/>
            <br/>
            <div style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", alignContent: "flex-start", marginLeft: "35px"}}>
                <h2>Адрес: {me.props.delivery.address}</h2>
            </div>
        </div>;
    }
}


