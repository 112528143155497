import React from "react";
import "./color-picker-v2.scss";
import CircularProgress from '@material-ui/core/CircularProgress';



// try {
//   import toothBg from "./tooth_background.svg";  
// } catch(e) {
//   import toothBg from "url:./tooth_background.svg";  
// }

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

var default_areas = ["Прешеечная область", "Экватор", "Режущий край"];

export default class ToothColorPickerV2 extends React.Component {
  constructor(props) {
    super(props);
    let activeShemaName = "VITA";
    let filesPresend = false;
    let areas = this.getAreas();
    for (let i in areas) {
      let taskPart = areas[i];
      if (this.props.task[taskPart]) {
        // alert(this.props.task[taskPart].value);
        // alert(this.props.task[taskPart].value + " " + this.props.task[taskPart].value.indexOf("/"));
        if (this.props.task[taskPart].value.toString().indexOf("/") >= 0){
          activeShemaName = "По фото";
          break;
        }
      }
    }
    // alert(activeShemaName);
    this.state = {
      activeSchema: activeShemaName,
      files: []
    };
    this.activateSchema = this.activateSchema.bind(this);
    this.continueEnabled = this.continueEnabled.bind(this);
    this.getAreas = this.getAreas.bind(this);
    this.addAttachment = this.addAttachment.bind(this);
    this.upload = this.upload.bind(this);
    this.addFiles = this.addFiles.bind(this);

    this.inputRef = React.createRef();
    
  }


  addAttachment(x) {
    let me = this;
    let prevState = this.state;
    prevState.files.push(x);
    this.setState(prevState, (e) => {
      me.upload(x);
    });
  }


  addFiles () {
    this.inputRef.current.click();
  }


  upload(x) {
    let me = this;
    let formData = new FormData();
    formData.append("order_id", this.props.order.id);
    formData.append("file", x.blob, x.name);
    // alert(formData);
    // alert(JSON.stringify(x));
    let url = `${document.SERVER_URL}/orders/${this.props.order.id}/upload_color_photo`;
    
    $.ajax({
        url: url,
        data: formData,
        dataType: "json",
        type: 'POST',
        contentType: false, // NEEDED, DON'T OMIT THIS (requires jQuery 1.6+)
        processData: false, // NEEDED, DON'T OMIT THIS
        success: function(res) {
          let prevState = {... me.state};
          let indexOf = prevState.files.indexOf(x);
          prevState.files[indexOf].loading = false;
          prevState.files[indexOf].file_url = res.file_url;
          let areas = me.getAreas();
          for(let i in areas) {
            let area = areas[i];
            me.props.setVariable(area, prevState.files.map((e) => {return e.file_url;}));
          }
          me.setState(prevState);
        },
        error: function(x) {
        }
    });
  }


  continueEnabled() {
    let me = this;
    let areas = me.getAreas();
    let values = {};

    areas = me.getAreas();

    for(let areaIndex in areas) {
      let areaName = areas[areaIndex];
      try {values[areaName] = me.props.task[areaName].value} catch(e) {}
    }

    return Object.keys(values).length == areas.length;
  }
  

  colorSchemes = {
    "VITA": [
      "A1", "A2", "A3", "A3.5", "A4", "B1", "B2", "B3", "B4","C1", "C2", "C3", "C4","D2", "D3", "D4","BL1", "BL2","BL3", "BL4", "OM3", "OM2", "OM1"],
    "3Dmaster": ["1M1:1M2 (1:1)", "2M2", "3M2", "2R2,5","3R2,5", "4L2,5","4R2.5",
    "1М1", "2L1,5 : 2М2 (1:1)", "2M1", "2L1,5 : 3L1,5 (2:1)", "3L2,5","3M3","3M1 : 2L1,5 (1:1)", "3L1,5", "4L1,5 : EC11 (2:1)", "5M1 : EC10 (2:1)", "4M1 : EC10 (2:1)","2M1 : 3M1 (1:1)", "3M2 : 4R1,5 (1:1)", "3L1,5 : EC11 (2:1)"],
    "По фото": []

  };
  

  activateSchema(schemaName) {
    let prevState = {... this.state};
    // alert(schemaName);
    prevState.activeSchema = schemaName;
    this.setState(prevState);
  }

  getAreas() {
    let areas = default_areas;
    let me = this;
    try {
      if (me.props.takes_color_areas_schema.length > 0)
        areas = me.props.takes_color_areas_schema.split(",");
    } catch(e) {
      // alert(e);
    }

    return areas;
  }


  componentWillUnmount() {
    if (!this.inputRef.current) return;
    this.inputRef.current.removeAttribute("onchange");
  }


  componentDidMount() {
    let me = this;
    if (!this.inputRef.current) return;
    
    this.inputRef.current.onchange = function(e) {
      // alert(2);
      if (0 == me.inputRef.current.files) return;
      let files = me.inputRef.current.files;
      // let fileReaders = [];

      for (let i = 0; i < files.length; i++) {
        let f = files[i];
        // me.add_attachment([{name: f.name, blob: f}]);
        me.addAttachment({name: f.name, blob: f, loading: true});
        // let fileReader = new FileReader();
        // fileReader.onloadend = function (e) {
        //   var blob = e.target.result;
        //   // me.props.add_attachment([{name: f.name, blob: blob}]);
        //   me.addAttachment({name: f.name, blob: blob, loading: true});
        // };
        // fileReader.readAsArrayBuffer(f);
      }
    }
  }


  componentDidUpdate() {
    let me = this;
    if (!this.inputRef.current) return;
    
    this.inputRef.current.onchange = function(e) {
      // alert(2);
      if (0 == me.inputRef.current.files) return;
      let files = me.inputRef.current.files;
      // let fileReaders = [];

      for (let i = 0; i < files.length; i++) {
        let f = files[i];
        // me.add_attachment([{name: f.name, blob: f}]);
        me.addAttachment({name: f.name, blob: f, loading: true});
        // let fileReader = new FileReader();
        // fileReader.onloadend = function (e) {
        //   var blob = e.target.result;
        //   // me.props.add_attachment([{name: f.name, blob: blob}]);
        //   me.addAttachment({name: f.name, blob: blob, loading: true});
        // };
        // fileReader.readAsArrayBuffer(f);
      }
    }
  }
 

  render() {
    let me = this;
    let activeScheme = this.colorSchemes[me.state.activeSchema];
    let presheechnayaOblast = "-1";
    let equator = "-1";
    let rezhushiiKray = "-1";
    let values = {};

    let areas = me.getAreas();

    for(let areaIndex in areas) {
      let areaName = areas[areaIndex];
      try {values[areaName] = me.props.task[areaName].value} catch(e) {}
    }


    if (me.state.activeSchema == "По фото") {
      // alert(3456);
      areas = [];
    }


    let toothBg = document.SERVER_URL + "/uploads/drivers/20sixe4joddke0zrasc.svg";
    if (areas.indexOf("Цвет культи") >= 0) {
      toothBg = document.SERVER_URL + "/uploads/drivers/1tqxdnmhgu5bik6cult.svg";
    }
    
    let files = [];
    if (Object.keys(values).length > 0) {
      for(let jj in Object.keys(values)) {
        let fieldName = Object.keys(values)[jj];
        // alert(fieldName);
        files.push({file_url: values[fieldName][0]})
      }
      // alert(JSON.stringify(Object.keys(values)));
      // alert(JSON.stringify(values));

      // if (!Object.keys(values)[0]) continue;
      // if (Object.keys(values)) continue;
      // let filesUrls = values[Object.keys(values)[0]].split(",");
      // alert(filesUrls.length);
      // for (let j in filesUrls) {
      //   let url = filesUrls[j];
      //   try {
      //     if (url.length > 0) {
      //       files.push({file_url: url});
      //     }
      //   } catch(e) {}
      // }
      // alert(filesUrl);
    }
    files = files.map((x) => x.file_url );
    files = files.filter(onlyUnique);
    // alert(files.length);
    return (
    	<div className="tooth-color-picker">
          <p>{toothBg}</p>
          <img src={toothBg} style={{zIndex: 1, background: "#fff", display: "block", position: "absolute", "top": 0, "left": 0, width: "100%", height: "100%", objectFit: "contain"}} />
          <div style={{display: "flex", flexDirection: "row", alignContent: "center", alignItems: "center"}}>
            <p style={{margin: 0, marginRight: "21px"}} >Модель цвета</p>
             {Object.keys(me.colorSchemes).map( (e) => 
               <p onClick={(ev) => {me.activateSchema(e);}} className="color-button" style={{background: me.state.activeSchema == e ? '#ababab' : '#fff', margin: 0, marginRight: "7px"}} key={`key-${e}`}>{e}</p>
             )}
          </div>
          <br />
          <div className="tooth-pic">
            { areas.map( (area) => {
              if (me.state.activeSchema != "По фото") {
                return <div style={{marginBottom: "auto"}}>
                  <p style={{margin: 0, color: "#FF9800", fontSize: "26px"}}>{area}</p>
                  <div className="color-line">
                    {activeScheme.map((e) =>
                        <p key={`color-${e}`} style={{background: values[area] == e ? "#ababab" : "#fff"}}
                           className="color-button" onClick={(ev) => {
                          me.props.setVariable(area, e);
                        }}>{e}</p>
                    )}
                  </div>
                </div>;
              } else {
                return <></>
              }
            })}
            { me.state.activeSchema == "По фото" &&
              <div style={{background: "rgba(255,255,255,0.6)"}}>
                <input multiple={true} ref={this.inputRef} type="file" style={{display: "none"}} />
                <button onClick={me.addFiles}>Загрузить файлы</button>
                { me.state.files.map( (e, eId) => 
                  <div key={`uploadedfile-${eId}`} style={{display: "flex"}}>
                    {/*<p onClick={(ev) => { me.openFile(e.file_url); }}>{e.name} {e.file_url}</p>*/}
                    <div style={{fontSize: "18px", display: "flex", flexDirection: "row", alignItems: "center", alignContent: "center"}}>
                      {/*<p>{e.file_url}</p>*/}
                      <a style={{marginLeft: "14px"}} href={e.file_url} target={"_blank"}><i class="fal fa-cloud-download fa-3x"></i></a>
                      {e.loading && 
                        <CircularProgress size={40}/>
                      }
                    </div>
                  </div>
                )}

                { files.filter(onlyUnique).map( (e, eId) =>
                  <div key={`uploadedfile--${eId}`} style={{display: "flex"}}>
                    {/*<p onClick={(ev) => { me.openFile(e.file_url); }}>{e.name} {e.file_url}</p>*/}
                    <div style={{fontSize: "18px", display: "flex", flexDirection: "row", alignItems: "center", alignContent: "center"}}>
                      {/*<p>{e.file_url}</p>*/}
                      <a style={{marginLeft: "14px"}} href={e} target={"_blank"}><i class="fal fa-cloud-download fa-3x"></i></a>
                    </div>
                  </div>
                )}
              </div>
            }
          </div>
          <br/>
      </div>
    );
  }
}
