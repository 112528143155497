import React from 'react';
import UserEmployee from "./UserEmployee";
import Button from '@material-ui/core/Button';


export default class UserEmployeesIndex extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      user_employees: [],
      loading: true
    };

    this.updateEmployees = this.updateEmployees.bind(this);
    this.addEmployee = this.addEmployee.bind(this);
  } 



  componentDidMount() {
    this.updateEmployees();
  }


  componentWillUnmount() {
  }


  updateEmployees() {
    // alert("Update employees");
    let me = this;
    fetch("/users/user_employees.json").then((e) => {
      return e.json()
    }).then((e) => {
      let prevState = {... me.state };
      prevState.user_employees = e;
      me.setState(prevState);
    });
  }


  addEmployee() {
    let me = this;
    fetch("/users/create_employee").then((e) => {
      // alert("Add employee");  
      me.updateEmployees();
    });
  }


  render() {
    let xxx = <div className="white-container">
      <div style={{maxHeight: "80px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", alignItems: "center", width: "100%"}}>
        <h1>Сотрудники</h1>
        <Button variant="contained" color="primary" onClick={this.addEmployee} >Добавить сотрудника</Button>
      </div>
      {
        this.state.user_employees.map(
          (e) => 
          <UserEmployee updateIndex={this.updateEmployees} key={`user-employee-${e.id}`} content={e} />
        )
      }
      {
        this.state.user_employees.length == 0 &&
        <p>Пока не создано ни одного сотрудника</p>
      }
    </div>;
    return (xxx);
  }
}