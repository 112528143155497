import React from 'react';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import "./packaging-list.scss";


export default class PackagingList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      successOpen: false,
      packagingListItems: [],
      packagingList: {}
    };

    this.updatePackageList = this.updatePackageList.bind(this);
    this.changeField = this.changeField.bind(this);
    this.changeFieldAbsolute = this.changeFieldAbsolute.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }


  handleClose() {
    let prevState = {... this.state};
    prevState.successOpen = false
    this.setState(prevState);
  }

  componentDidMount() {
    let me = this;
    let prevState = {... this.state };
    let dataGetUrl = `/orders/${me.props.order.id}.json`;
    // alert(me.props.order);
    if (this.props.current_user) {
      dataGetUrl += `?user_id=${this.props.current_user.id}`;
    }

    if (dataGetUrl.indexOf("https://") == -1) {
      dataGetUrl = document.SERVER_URL + dataGetUrl;
    }

    fetch(dataGetUrl).then((e) => {
        return e.json()
    }).then((e) => {
      if (!e) {
        return
      }
      if (!e.packaging_list) {
        let url = "/orders/packaging_list_fields"; 
        if (url.indexOf("https://") == -1) {
          url = document.SERVER_URL + url;
        }
        fetch(url).then(
          (e) => {
            return e.json()
          } 
        ).then( (e) => {
            prevState.packagingListItems = e;
            let packagingList = {};
            for(let i = 0; i < prevState.packagingListItems.length; i++) {
              packagingList[prevState.packagingListItems[i].name] = 0;
            }
            prevState.packagingList = packagingList;
            this.setState(prevState);
          }
        )
      } else {
      // alert(this.props.order.packaging_list);
        prevState.packagingList = JSON.parse(e.packaging_list);
        me.setState(prevState);
      }
      // prevState.packagingList = JSON.parse(this.props.order.packaging_list);
    });
  }


  changeFieldAbsolute(fieldName, ev) {
    // alert(fieldName + " " + ev.target.value);
    // alert(fieldName + " "+delta);
    let prevState = {... this.state };
    let needRefresh = true;
    for (let i in prevState.packagingList) {
      if (prevState.packagingList[i] > 0) {
        needRefresh = false;
      }
    }

    prevState.packagingList[fieldName] = ev.target.value;
    if (prevState.packagingList[fieldName] >= 0) {
      this.setState(prevState);  
      this.updatePackageList(JSON.stringify(prevState.packagingList), needRefresh);
    }
  }


  changeField(fieldName, delta) {
    // alert(fieldName + " "+delta);
    let prevState = {... this.state };
    let needRefresh = true;
    for (let i in prevState.packagingList) {
      if (prevState.packagingList[i] > 0) {
        needRefresh = false;
      }
    }

    prevState.packagingList[fieldName] += delta;
    if (prevState.packagingList[fieldName] >= 0) {
      this.setState(prevState);  
      this.updatePackageList(JSON.stringify(prevState.packagingList), needRefresh);
    }
  }


  updatePackageList(data, needRefresh) {
    let me = this;

    let updateFieldUrl = `/orders/${this.props.order.id}/update_field`;

    // alert(this.props.current_user);

    if (this.props.current_user) {
      updateFieldUrl += `?user_id=${this.props.current_user.id}`;
    }

    if (updateFieldUrl.indexOf("https://") == -1) {
      updateFieldUrl = document.SERVER_URL + updateFieldUrl;
    }

    // alert(updateFieldUrl);

    fetch(updateFieldUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify({
        new_value: data,
        changed_field: "packaging_list"
      })
    }).then((response) => {
      let prevState = {... me.state};
      prevState.successOpen = true;
      prevState.packaging_list = response.packaging_list;
      me.setState(prevState);
      if (!!needRefresh) {
        setTimeout(function() {
          me.props.updateCallback();  
        }, 1500);
      }
      return response.json();
    });
  }



  componentWillUnmount() {
  }


  render() {
    let me = this;
    
    let xxx = <div className="packaging-list">
      <h3>Упаковочный лист</h3>
      {Object.keys(this.state.packagingList).map((e) => 
        <div key={`package-field-${e}`} className="packaging-list-field">
          <p>{e}</p>
          <i onClick={(x) => this.changeField(e, -1)} style={{marginLeft: "auto"}} className="fal fa-angle-left fa-2x hide-print" />
          <TextField type="number" onChange={ (x) => this.changeFieldAbsolute(e, x) } value={this.state.packagingList[e]} inputProps={{min: 0, style: { textAlign: 'center' }}} style={{maxWidth: "49px", margin: "7px"}}/>
          <i onClick={(x) => this.changeField(e, 1)} className="fal fa-angle-right fa-2x hide-print" />
        </div>
      )}
      <Snackbar open={me.state.successOpen} autoHideDuration={6000} onClose={me.handleClose}>
        <Alert onClose={me.handleClose} severity="success">
          Данные обновлены
        </Alert>
      </Snackbar>
    </div>
    ;
    return (xxx);
  }
}