import React from 'react';
import WorkAction from "./WorkAction";

export default class WorkList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentWillMount() {
  }

  render() {
    let me = this;
    // console.log(this.state.calendar);
    return (
      <div className="work-list">
        <br />
        { !me.props.list &&
          <p className="loading">Загрузка</p>
        }

        { (!!me.props.list) && (me.props.list.length == 0) &&
          <p className="loading">{!me.props.empty_text ? 'Данных пока нет' : me.props.empty_text}</p>
        }

        { !!me.props.list && 
          <>
           {me.props.list.map((e) =>
              <WorkAction key={`work-action-${e.id}`} text={e.name} color={e.color} />
            )}
          </>
        }
        <br />
      </div>
    );
  }
}