import React from "react";
import Menu from "./Menu";

export default class EditableMenu extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
		this.checkIfSomethingElseNeeded = this.checkIfSomethingElseNeeded.bind(this);
	}

	componentDidMount() {
		this.checkIfSomethingElseNeeded();
	}

	checkIfSomethingElseNeeded() {
		let me = this;
		let taskIndex = Object.keys(me.props.task).length - 1;
		let tasks = [];
		for (let taskIndex in Object.keys(me.props.task)) {
			let taskName = Object.keys(me.props.task)[taskIndex];
			if (taskName == "color") continue;
			if (taskName == "toothNumberTouched") continue;
			tasks.push(taskName)
		}
		
		// alert(taskIndex);
		let lastField = tasks[tasks.length - 1];
		// alert(JSON.stringify(me.props.task));
		// alert(lastField);
		let menuId = null;
		try {
			menuId = me.props.task[lastField].menu;	
		} catch(e) {}
		
		if (!menuId) return;
		let menuUrl = `/menus/${menuId}.json`;
		// alert(menuUrl);
		if (menuUrl.indexOf("https://") == -1) {
	      menuUrl = document.SERVER_URL + menuUrl;
	    }
		fetch(menuUrl).then( (e) => {return e.json()}).then((e) => {
			let myMenuItem;
			// alert(JSON.stringify(e.menu_variants))
			for (let menuItemIndex in e.menu_variants) {
				let menu_item = e.menu_variants[menuItemIndex];

				// alert(menu_item.name + " == " + me.props.task[lastField].value);
				if (me.props.task[lastField])
					if (menu_item.name == me.props.task[lastField].value) {
						let dedicatedMenuUrl = `/menus/${menu_item.opens_menu}.json`;
						if (dedicatedMenuUrl.indexOf("https://") == -1) {
					      dedicatedMenuUrl = document.SERVER_URL + dedicatedMenuUrl;
					    }
						// alert(dedicatedMenuUrl);
						fetch(dedicatedMenuUrl).then((f) => {return f.json();}).then(
							(m) => {
								let prevState = {... me.state};
								prevState.needsClarification = {id: m.id, name: m.name};
								// alert(JSON.stringify(m));
								me.setState(prevState);
							}
						);
						// alert(JSON.stringify(menu_item));
					}
			}
		});
	}

	render() {
		let me = this;
		// alert(JSON.stringify(me.props.task));
		return <div>
			<table>
				<thead></thead>
				<tbody>
					{Object.keys(me.props.task).filter((e) => {return (e != "color" && e != "toothNumberTouched")}).map( (e) =>
						<tr key={`row-${e}`}>
							<td style={{paddingLeft: "7px", borderLeft: `2px solid ${me.props.task.color}`}}><b style={{marginRight: '14px'}}>{e.replace("скрыть", "")}</b></td>
							<td>{me.props.task[e].value}</td>
						</tr>
					)}
					{ me.state.needsClarification && 
						<tr style={{cursor: "pointer"}} onClick={(e) => {me.props.disableJaw(); me.props.goToMenuRaw(me.state.needsClarification.id, me.props.task);}} >
							<td style={{paddingLeft: "7px", borderLeft: `2px solid #f00`}}><b style={{lineHeight: "1.01em", marginRight: '14px'}}>{me.state.needsClarification.name}</b></td>
							<td style={{lineHeight: "1.01em", color: "#f00"}}>Требуется уточнение</td>
						</tr>
					}
				</tbody>
			</table>
			{/*<p>{JSON.stringify(me.props.task)}</p>*/}
		</div>
	}
}