import React from 'react';
import NewsItemWidget from "./NewsItemWidget";
import "./news_items.scss";

export default class NewsItems extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      newsItems: []
    }
  } 

  componentDidMount() {
    let me = this;
    fetch('/news_items', {
      method: "get",
    })
    .then(response => response.json())
    .then(newsItems => {
      // alert(newsItems.length);
      me.setState({ newsItems: newsItems });
    });
  }

  render() {
    let xxx = <div>
        <p style={{fontSize: "18px", marginBottom: "14px"}}>Новости</p>
        { this.state.newsItems.map( (newsItem, index) => 
          <NewsItemWidget key={`news-${index}`} content={newsItem} />
        ) }
      </div>
    return (xxx);
  }
}