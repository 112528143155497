import React from "react";
import TechStageTreeView from "./TechStageTreeView";
import "./tech-process-tree.scss";
import Lined from "../Shared/Lined";
import $ from "jquery";
import Xarrow from "react-xarrows";

export default class TechProcessTreeView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tech_processes: []
    };

    this.getTechProcesses = this.getTechProcesses.bind(this);
    this.prepareTechProcesses = this.prepareTechProcesses.bind(this);
  }

  prepareTechProcesses(e) {
    let me = this;
    let prevState = {... me.state};
    let toAdd = [];
    for (let i in e) {
      for (let ii in e[i]) {
        e[i][ii].ref = React.createRef();
        for(let sideProcessId in e[i][ii].side_processes) {
          for(let sideProcessTechStageId in e[i][ii].side_processes[sideProcessId].tech_stages) {
            e[i][ii].side_processes[sideProcessId].tech_stages[sideProcessTechStageId].ref = React.createRef();
          }
        }
      }
    }
    prevState.tech_processes = e;
    me.setState(prevState);
  }

  componentDidMount() {
    if (this.props.tech_process) {
      this.prepareTechProcesses([this.props.tech_process]);
    } else {
      this.getTechProcesses();
    }
  }

  getTechProcesses() {
    let me = this;
    let url = document.SERVER_URL + "/flow/tech_processes";
    $.ajax({
      url: url,
      dataType: "json",
      type: 'GET',
      crossDomain: true,
      success: (e) => {
        me.prepareTechProcesses(e);
      }
    });
  }

  render() {
    let me = this;
    let arrows = [];
    return <>
      {me.state.tech_processes.map((ee, i) => {
        let techProcessId = -1;
        techProcessId = ee[0].id;
        return <div className="tech-process-tree" key={`tech-proc-${i}`}>
          { ee.map( (e, ii) => {
            let techStageId = `tech-process-${techProcessId}-tech-stage-${e.id}`;
              return <div style={{display: "flex", flexDirection: "column"}} key={`tech-stage-of-${i}-${ii}`}>
                {/*<div style={{position: "relative", top: "49px", right: "28px"}} id={techStageId}></div>*/}
                <TechStageTreeView  key={`t-s-${i}-${ii}`} tech_stage={e} markStartId={techStageId}/>
                { e.side_processes &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    {e.side_processes.map( (s_p, s_p_index) => {
                      console.log(techProcessId);
                      let sideProcessEndId = `tech-process-${techProcessId}-side-process-${s_p.id}-tech-stage-${e.id}-end`;
                      let sideProcessEndTargetId = `tech-process-${techProcessId}-tech-stage-${s_p.end_tech_stage_id}`;
                      arrows.push([sideProcessEndId, sideProcessEndTargetId])
                      return (
                          <div className="tech-process-tree" style={{position: "relative"}}>
                            <div style={{width: "3px", height: "49px", background: "#303030", display: "flex", position: "absolute", left: "28px", top: "-49px"}} />
                            <TechStageTreeView key={`t-s-${i}-${ii}-${s_p_index}`} tech_stage={s_p} />
                            <div className="tech-process-tree" style={{position: "absolute", left: "100%"}}>
                              {
                                s_p.tech_stages.map((s_p_t_s, spts_i) => {
                                  return <TechStageTreeView key={`t-s-${i}-${ii}-${s_p_index}-${spts_i}`} tech_stage={s_p_t_s} markEndId={(spts_i == s_p.tech_stages.length - 1) ? sideProcessEndId : "asdasd"}/>
                                })
                              }
                              {/*<div style={{position: "relative", top: "49px"}} id={sideProcessEndId}></div>*/}
                            </div>

                          </div>
                      )
                      return <p key={`sp_index_${s_p_index}`}>{s_p.name}</p>
                    })}
                  </div>
                }
              </div>}
          )}
        </div>
      })}
      { arrows.map( (e) => {
        return <>
          <Xarrow end={e[1]} start={e[0]} />
        </>
      })}
    </>
  }
}
