import React from 'react';
import TextField from '@material-ui/core/TextField';
import ChatAudio from "./ChatAudio";
import ChatFiles from "./ChatFiles";


async function postData(url = '', data = {}) {
  // Default options are marked with *
  // const response = await fetch(url, {
  //   method: 'POST', // *GET, POST, PUT, DELETE, etc.
  //   mode: 'cors', // no-cors, *cors, same-origin
  //   cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
  //   credentials: 'same-origin', // include, *same-origin, omit
  //   headers: {
  //     'Content-Type': 'application/json'
  //     // 'Content-Type': 'application/x-www-form-urlencoded',
  //   },
  //   redirect: 'follow', // manual, *follow, error
  //   referrerPolicy: 'no-referrer', // no-referrer, *client
  //   body: JSON.stringify(data) // body data type must match "Content-Type" header
  // });

  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *client
    body: data // body data type must match "Content-Type" header
  });

  // body: formData
  return await response.json(); // parses JSON response into native JavaScript objects
}


export default class ChatSend extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      message_text: "",
      loading : false,
      sendingAudio: false,
      message_attachments_attributes: []
    };
    this.updateMessageText = this.updateMessageText.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.triggerSendAudio = this.triggerSendAudio.bind(this);
    this.add_attachment = this.add_attachment.bind(this);
  }

  add_attachment(files) {
    let prevState = {... this.state};
    for (let i in files) {
      prevState.message_attachments_attributes.push(files[i]);
    }
    this.setState(prevState);
    // alert(JSON.stringify(this.state));
  }

  updateMessageText(e) {
    let prevState = {...this.state};
    prevState.message_text = e.target.value;
    this.setState(prevState);
  }

  triggerSendAudio() {
    let prevState = {... this.state};
    prevState.sendingAudio = !prevState.sendingAudio;
    this.setState(prevState);
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      this.sendMessage();
    }
  }

  sendMessage() {
    let prevState = {...this.state};

    let formData = new FormData();
    formData.append("message[content]", this.state.message_text);
    formData.append("message[dialog_id]", this.props.activeDialog.id);

    for(let i = 0; i < this.state.message_attachments_attributes.length; i++) {
      // alert(Object.keys(this.state.message_attachments_attributes[i]));
      formData.append(`message[files][${i}]`, this.state.message_attachments_attributes[i].blob);
    }

    // let message = {
      // content: this.state.message_text,
      // dialog_id: this.props.activeDialog.id,
      // files: this.state.message_attachments_attributes
    // };

    // alert(JSON.stringify(message));

    prevState.loading = true;
    this.setState(prevState);
    postData("/dialogs/send_message", formData)
    .then((data) => {
      prevState.message_text = "";
      prevState.loading = false;
      prevState.message_attachments_attributes = [];
      this.setState(prevState);
    });
  }

  render() {
    let xxx = <div className={`${(!!this.props.activeDialog && !this.state.loading) ? 'chat-send' : 'chat-send inactive'}`}>
      <ChatFiles added_attachements={this.state.message_attachments_attributes} add_attachment={this.add_attachment} dialog={this.props.activeDialog} />
      { !this.state.sendingAudio && 
        <TextField onKeyPress={this.handleKeyPress} value={this.state.message_text} onChange={this.updateMessageText} size="small" style={{padding: 0, width: "100%", margin: "7px"}} id="outlined-basic" placeholder="Сообщение" />
      }
      {/*<i style={{margin: "7px"}} className="fal fa-comment-alt fa-2x"></i>*/}
      { ( (this.state.message_text.length == 0) && !this.state.sendingAudio) &&
        <i onClick={this.triggerSendAudio} style={{margin: "7px", cursor: "pointer"}} className="fal fa-microphone fa-2x"></i>
      }
      { (this.state.message_text.length > 0) && 
        <i onClick={this.sendMessage} style={{margin: "7px"}} className="fal fa-paper-plane fa-2x"></i>
      }

      { !!this.state.sendingAudio &&
        <ChatAudio triggerSendAudio={this.triggerSendAudio} dialog={this.props.activeDialog} />
      }
    </div>;
    return (xxx);
  }
}