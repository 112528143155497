import React from 'react';
import Timer from "../Timer/Timer";


export default class OrderTimersContainer extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      intervalId: null,
      order_timers: [],
      isActive: false
    };

    this.updateTimers = this.updateTimers.bind(this);
  }


  componentDidMount() {
    this.updateTimers();
    var intervalId = setInterval(this.updateTimers, 10000);
    let prevState = {... this.state};
    prevState.intervalId = intervalId;
    prevState.isActive = true;
    this.setState(prevState);
  }


  updateTimers() {
    let me = this;

    let url = `${document.SERVER_URL}/orders/${this.props.order.id}/order_timers`;
    if (url.indexOf("https://") == -1) {
      url = document.SERVER_URL + url;
    }
    // alert(url);
    fetch(url).then( (e) => { return e.json()}).then( (e) => {
      let prevState = {... me.state};
      if (!prevState.isActive) return;
      prevState.order_timers = e;
      me.setState(prevState);
    });
  }


  render() {
    let me = this;
    return <div>
      {me.state.order_timers.map( (e) => 
        <Timer order_timer={e} />
      )}
    </div>
  }

}