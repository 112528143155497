import React from 'react'
import ToothTaskShow from "./ToothTaskShow";

let teethNumbers = ["18","17","16","15","14","13","12","11","21","22","23","24","25","26","27","28","38","37","36","35","34","33","32","31","41","42","43","44","45","46","47","48"];

export default class TextTz extends React.Component {
  
  constructor(props) {
    super(props);
    let teeth = [];
    
    this.state = {
        teeth: teeth,
        menus: [],
        task: {},
        prevToothTouched: null,
        orderLoaded: false,
        about: null
    };

    this.showAbout = this.showAbout.bind(this);
    this.makeCommas = this.makeCommas.bind(this);
    this.makeCommasFromArray = this.makeCommasFromArray.bind(this);
    this.generateWorkName = this.generateWorkName.bind(this);
  }

  componentDidMount() {
    let me = this;
    // alert(JSON.stringify(this.props.order.tz));
    if (!!this.props.order) {
        let data = JSON.parse(this.props.order.tz);
        if (!data) return;
        // alert(data);
        let prevState = {... data};

        prevState.about = null;
        this.setState(data); 
        setTimeout(function() {
            me.showAbout();
        }, 100);  
    }
    
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;
    if (!!this.props.order && !!this.props.order.tz && !this.state.orderLoaded) {
        let prevState = {... this.state};

        prevState = JSON.parse(this.props.order.tz);
        // alert("set:" + JSON.stringify(prevState));
        if (!prevState) return;
        prevState.orderLoaded = true;
        prevState.about = null;
        // alert(JSON.parse(this.props.order.tz).teeth);
        // alert(322);
        this.setState(prevState);
        setTimeout(function() {
            me.showAbout();
        }, 1000);  
    }
  }


  showAbout() {
    let me = this;
    let uberTask = {};
    for (let ii = 0; ii < teethNumbers.length; ii++ ) {
        let toothNumber = teethNumbers[ii];
        // console.log(toothNumber);
        let title = toothNumber;
        let toothIndex = teethNumbers.indexOf(toothNumber);
        let tooth = this.state.teeth[toothIndex];
        
        let prevTooth = this.state.teeth[toothIndex - 1];
        
        if (!tooth) {
            // alert(`Skipping ${ii} ${toothIndex} ${teethNumbers[ii]}`)
            return;
        }

        
        let hasBridge = tooth.has_bridge;
        let prevHasBridge = !!prevTooth && prevTooth.has_bridge;
        if (!!hasBridge || prevHasBridge) {
            let bridgeStartIndex = -1;
            hasBridge = true;
            while (hasBridge) {
                let prevTooth = this.state.teeth[toothIndex + bridgeStartIndex];
                if (!prevTooth) {
                    bridgeStartIndex += 1;
                    break;
                }
                hasBridge = prevTooth.has_bridge;
                if (!hasBridge) {
                    bridgeStartIndex += 1;
                    break
                }
                bridgeStartIndex -= 1;
            }

            hasBridge = tooth.has_bridge;
            let bridgeEndIndex = 1;
            while (hasBridge) {
                let nextTooth = this.state.teeth[toothIndex + bridgeEndIndex];
                hasBridge = nextTooth.has_bridge;
                bridgeEndIndex += 1;
            }
            // console.log(bridgeStartIndex + " "+ bridgeEndIndex);
            title = '';
            let start = true;
            let task = [];
            for(let i=bridgeStartIndex; i < bridgeEndIndex; i++) {
                let t = this.state.teeth[toothIndex + i];
                if (!start) {
                    title += "-";
                }
                
                if (Object.keys(t.task).length > 0) {
                    title += t.toothNumber;
                    start = false;
                    task.push([t.toothNumber,t.task]);
                }
            }
            if (title.indexOf("-") >= 0) {
                title = "Мост " + title;
            } 
            uberTask[title] = me.makeCommasFromArray(task);
        } else {
            if (tooth.task) {
                if (Object.keys(tooth.task).length > 0) {
                    uberTask[title] = tooth.task;    
                }
            } else {
                // alert(`Skipping ${ii} ${toothIndex} ${teethNumbers[ii]}`)
            }
        }
    }

    // alert(`shoAbout ${Object.keys(uberTask).length}`);

    uberTask = me.makeCommas(uberTask);
    let prevState = {... me.state};
    prevState.about = uberTask;
    this.setState(prevState);
  }


  makeCommas(uberTask) {
    let res = {};

    for (let i in Object.keys(uberTask)) {
        // console.log(i);
        let workName = Object.keys(uberTask)[i];
        let work = uberTask[workName];
        let workFound = false;
        // console.log(workName);
        // console.log(JSON.stringify(work));

        for(let j in Object.keys(res) ) {
            let addedWorkName = Object.keys(res)[j];
            // console.log("Compared: "+addedWorkName);
            let comparedWork = res[addedWorkName];
            if (JSON.stringify(work) == JSON.stringify(comparedWork)) {
               workFound = true;
               // console.log("FOUND!!!")
               delete res[addedWorkName];
               res[(addedWorkName+", "+workName)] = work;
               break;
            }
        }
        if (!workFound) {
            res[workName] = work;
        }
    }

    return res;
  }

  makeCommasFromArray(uberTaskArray) {
    let res = [];
    for(let i in uberTaskArray) {
        let taskName = uberTaskArray[i][0];
        let taskWork = uberTaskArray[i][1];
        // console.log(taskName);
        let workFound = false;

        for (let j in res) {
            let comparedTaskName = res[j][0];
            // console.log("compared " + comparedTaskName);
            let comparedTaskWork = res[j][1];
            if (JSON.stringify(comparedTaskWork) == JSON.stringify(taskWork)) {
                res[j][0] += ", "+taskName;
                workFound = true; 
                break;
            }
        }

        if (!workFound) res.push(uberTaskArray[i]);
    }
    return res;
  }

  generateWorkName(task) {
    return "";
    return this.describe(task);

    let text = "";
    for (let i in task) {
      if ("color" == i) continue;
      text += i + ": " + task[i].value + "\n"
    }
    // alert(text);
    return text;
  }


  describe(jsonData) {
    let preProcess = {};
    
    for (let i in Object.keys(jsonData)) {
      let key = Object.keys(jsonData)[i];
      preProcess[key.toLowerCase().trim()] = jsonData[key];
    }
    
    // const template = "#{Реставрация} #{МАТЕРИАЛ} \nЦвет #{Прешеечная область} {Экватор}";
    const template = "{Реставрация} {Форма понтика} {МАТЕРИАЛ}, {ВИД ОБЛИЦОВКИ}, П-{Прешеечная область} Э-{Экватор} РК-{Режущий край}, К-{Цвет культи}, {Контакт}, {Форма зуба} форма, {Цвет десны}, {Плечо}, {Гирлянда}";
    const regexp = /\{.*?\}/g;
    let processed = "" + template;
    let foundFields = [...processed.matchAll(regexp)];
    for(let i in foundFields) {
      let fieldName = foundFields[i];
      // alert(foundFields[i][0].length);
      let loweredFieldName = foundFields[i][0].toLowerCase().replaceAll("{", "").replaceAll("}", "").trim();
      // alert(loweredFieldName);
      processed = processed.replaceAll(fieldName, loweredFieldName);
      // alert(processed);
      // alert(JSON.stringify(preProcess) + " "+ loweredFieldName);
      processed = processed.replaceAll(loweredFieldName, preProcess[loweredFieldName] ? preProcess[loweredFieldName].value : "");
    }
    // processed = processed.replace()
    
    // alert(processed);
    return processed;
  }



  render() {
    let me = this;
    // alert("Rerender");
    // alert(JSON.stringify(me.state));
    if (!me.state.about) {
        return <p>ТЗ определяется</p>
    }
    let about = "ТЗ не определено";
    let parts = [];
    try {
        about = me.state.about;
        for (let i in Object.keys(about)) {
            let key = Object.keys(about)[i];
            let aboutPart = "";
            if (!Array.isArray(about[key])) {
                aboutPart = me.generateWorkName(about[key]);
                // console.log(key + " " + aboutPart);
                parts.push([key, aboutPart]);    
            } else {
                aboutPart = [];
                for (let j in about[key]) {
                    aboutPart.push(about[key][j][0]);
                    aboutPart.push(me.generateWorkName(about[key][j][1]));
                }
                parts.push([key, aboutPart]);
            }
            
        }
    } catch(e) {
        console.error(e);
        // console.error(me.state.about);
    }

    let xxx = <div>
        <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
            { parts.map( (e) => 
                    <ToothTaskShow key={`task-${e[0]}`} title={e[0]} content={e[1]} /> 
                )
            }
        </div>
    </div>;
    return (xxx);
  }
}
