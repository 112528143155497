import React from "react";
import Calendar from "rc-year-calendar";
import 'rc-year-calendar/locales/rc-year-calendar.ru';
import "./order_calendar.scss";
// import "./calendar_style.scss";
export default class UserOrderCalendar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dateSource: []
        }
        this.onRangeSelected = this.onRangeSelected.bind(this);
        this.updateCalendar = this.updateCalendar.bind(this);
    }

    componentDidMount() {
        let me = this;
        me.updateCalendar();
    }

    updateCalendar() {
        let me = this;
        if (!me.props.getUrl) {
            let prevState = {... this.state};
            prevState.dateSource = me.processDates(me.props.dateSource);
            me.setState(prevState);
        } else {
            let url = document.SERVER_URL + me.props.getUrl;
            fetch(url).then((e) => {
                return e.json();
            }).then((e) => {
                let prevState = {... me.state};
                prevState.dateSource = me.processDates(e);
                me.setState(prevState);
            });
        }
    }

    processDates(dates) {
        let res = [];
        for(let i = 0; i < dates.length; i++) {
            let el = {... dates[i]};
            el.startDate = new Date(dates[i].start_date);
            el.endDate = new Date(dates[i].end_date);
            res.push(el);
        }
        return res;
    }

    // e.startDate / e.endDate
    onRangeSelected(e) {
        // alert(JSON.stringify(e));
        // console.log(e.startDate);
        // console.log(e.endDate);
    }

    onDayClick(dayContent) {
        // alert("123123");
        let res = `${dayContent.date.toLocaleDateString()}  (cобытий: ${dayContent.events.length} )`;
        for(let i = 0; i < dayContent.events.length; i++) {
            let ev = dayContent.events[i];
            // alert(JSON.stringify(ev));
            res += ev.name +`(${ev.start_date.toLocaleString()})///`;

        }
        let url = document.SERVER_URL + `/admin/user_orders/shipping_on_day?date=${dayContent.date}&hide_menus=true`;
        window.open(url, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
        // alert(url);
        // alert(res);

        // document.location.href = url;
    }

    render() {
        let me = this;
        return <div>
            <Calendar
                enableRangeSelection={true}
                enableContextMenu={true}
                language={'ru'}
                style={'background'}
                onRangeSelected={(e) => {me.onRangeSelected(e); } }
                dataSource={me.state.dateSource}
                onDayClick={e => {me.onDayClick(e)}}
            />
        </div>

    }

}