import React from 'react'
import MessageInList from '../Message/MessageInList';
import "./announcements.scss";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class Announcements extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      messages: [],
      loading: true
    };

    this.tick = this.tick.bind(this);
  } 


  tick() {
    let url = "/users/get_my_messages"
    fetch(url)
    .then(response => response.json())
    .then(messages => {
      let hasUrgent = false;
      let urgentMessages = [];
      for (let i in messages) {
        if ("Срочно" == messages[i].message_type) {
          urgentMessages.push(messages[i]);
        }
      }

      this.setState({
        messages: urgentMessages,
        loading: false
      });

    });
  }



  componentDidMount() {
    this.tick();
    let me = this;
    setTimeout(function() {
      me.timerID = setInterval(
        () => me.tick(), 10000
      );
    }, 15000);
  }


  componentWillUnmount() {
    clearInterval(this.timerID);
  }


  render() {
    let xxx = <>
      { this.state.loading &&
        <div className={"messages-container"}>
          <CircularProgress />
          <p>Загрузка сообщений</p>
        </div>
      }
      {!this.state.loading &&
        <div className={"messages-container"}>
          {this.state.messages.map((message) => <MessageInList key={message.id} itself={message}/>)}
        </div>
      }
      </>;
    return (xxx);
  }
}