import React from 'react';

function wait(delayInMS) {
    return new Promise(resolve => setTimeout(resolve, delayInMS));
}

export default class VideoRecorder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stream: null,
            recording: false,
            starting: false,
            recorder: null,
            uploading: false
        }
        this.chunks = [];
        this.startRecording = this.startRecording.bind(this);
        this.startStream = this.startStream.bind(this);
        this.onRecordStop = this.onRecordStop.bind(this);
        this.preview  = React.createRef();
        this.recording  = React.createRef();
        this.startButton = React.createRef();
        this.stopButton = React.createRef();
        this.downloadButton = React.createRef();
        this.logElement = React.createRef();
        this.recordingTimeMS = 5000;
    }


    startStream(stream) {
        let me = this;
        let prevState = {... me.state};
        prevState.stream = stream;
        prevState.recorder = new MediaRecorder(stream);
        prevState.recorder.onstop = me.onRecordStop;

        prevState.recorder.ondataavailable = function(e) {
            me.chunks.push(e.data);
        }
        me.setState(prevState, function() {
            me.preview.current.srcObject = stream;
            me.downloadButton.current.href = stream;
            me.preview.current.captureStream = me.preview.current.captureStream || me.preview.current.mozCaptureStream;
        });
    }



    componentDidMount() {
        let me = this;
        navigator.mediaDevices.getUserMedia({
            video: true,
            audio: true
        }).then(me.startStream);
    }

    startRecording() {
        let me = this;
        let prevState = {... me.state};
        if (me.state.recorder.state != "recording") {
            prevState.recording = true;
            me.setState(prevState, () => {
                me.state.recorder.start();
            });
        } else {
            prevState.recording = true;
            me.setState(prevState, () => {
                me.chunks = [];
                me.state.recorder.stop();
            });
        }
    }

    onRecordStop(e) {
        let me = this;
        let recorded = this.recording.current;
        me.blob = new Blob(me.chunks, { type: "video/webm" });
        let recordedURL = window.URL.createObjectURL(this.blob);
        recorded.src = recordedURL;
        let formData = new FormData();
        formData.append("video_message", me.blob);
        if (me.props.context) {
            formData.append("context", me.props.context);
        }
        let prevState = {... this.state};
        prevState.uploading = true;
        prevState.recording = false;
        me.setState(prevState);
        let url = "";
        if (me.props.order) {
            url = `/admin/user_orders/${me.props.order.id}/upload_video`;

        }
        if (me.props.pre_scan) {
            url = `/admin/pre_scans/${me.props.pre_scan.id}/upload_video`;
        }
        fetch(url, {
            method: 'POST',
            body: formData
        }).then(
            response => response.json() // if the response is a JSON object
        ).then(success => {
            let prevState = {... me.state};
            prevState.uploading = false;
            me.setState(prevState);
            // alert(JSON.stringify(success));
            // me.props.triggerSendAudio();
        }).catch(
            error => console.log(error) // Handle the error response object
        );
    }

    render() {
        let me = this;
        return <div>
            <div key={`${me.state.uploading}-${me.state.recording}`}>
                <h1>Отсканируйте QR, чтобы {me.state.recording ? `завершить запись` : `начать запись` }</h1>
                {me.state.recorder &&
                    <p>{me.state.recording ? 'Идет запись' : ''}</p>
                }
                {
                    me.state.uploading &&
                        <h3 style={{color: "#f00"}}>Идет выгрузка</h3>
                }
            </div>

            <div className="left">
                <div id="startButton" ref={me.startButton} className="button">
                    Запись
                </div>
                <h2>Предпросмотр</h2>
                <video ref={me.preview } id="preview" width="640" height="480" controls={true} autoPlay muted></video>
            </div>

            <div className="right">
                <h2>Записано</h2>
                <video ref={me.recording } id="recording" width="160" height="120" controls></video>
                <a style={{display: 'none'}} ref={me.downloadButton} id="downloadButton" className="button">
                    Download
                </a>

            </div>

            <div className="bottom">
                <pre id="log"></pre>
            </div>
        </div>
    }
}