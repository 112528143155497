import React from "react";
import Table from "../Shared/statistics/UserOrdersStatisticalTable";

export default class AccountantZone extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openTable: false
        };
        this.iRef = React.createRef();
        this.triggerTable = this.triggerTable.bind(this);
        this.closeMe = this.closeMe.bind(this);
    }

    triggerTable() {
        let me = this;
        let prevState = {... me.state};
        prevState.openTable = !prevState.openTable;
        me.setState(prevState, function() {
            me.props.callback(me.props.myId);
        });
    }

    closeMe() {
        let me = this;
        let prevState = {... me.state};
        prevState.openTable = false;
        me.setState(prevState);
    }

    render() {
        let me = this;
        return (<div style={{display: "flex", flexDirection: "column"}}>
            <div onClick={me.triggerTable} style={{cursor: "pointer" , display: "flex", flexDirection: "row", alignItems: "center", alignContent: "center"}}>
                <i ref={me.iRef} style={{marginRight: "7px", transform: me.state.openTable ? 'rotate(90deg)' : "rotate(0deg)"}} className={"fal fa-angle-right fa-2x status-i"}></i>
                <h3>{me.props.data.name}</h3>
            </div>
            <div style={ me.state.openTable ? {display: "block"} : {display: "none"}}>
                <Table date_title={me.props.data.name}
                       show_dates={true}
                       hide_toolbar={true}
                       hide_search={true}
                       disable_paging={true}
                       title={false}
                       actions_disabled={true}
                       getUrl={me.props.data.get_url}
                       dateStart={me.props.date_start}
                       dateEnd={me.props.date_end} />
            </div>

        </div>);
    }
}