import React from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
// import locale from "date-fns/locale/ru";
import StatZone from "./StatZone";


Date.prototype.yyyymmdd = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
        (dd > 9 ? "" : "0") + dd,
        ".",
        (mm > 9 ? "" : "0") + mm,
        ".",
        this.getFullYear()
    ].join("");
};

var date = new Date();
date.yyyymmdd();



export default class Statistics extends React.Component {
    constructor(props) {
        super(props);
        let start = new Date();
        start.setUTCHours(0, 0, 0, 0);

        let end = new Date();
        end.setUTCHours(23, 59, 59, 999);
        this.state = {
            startDate: start,
            endDate: end,
            showCalendar: false,
            refs: []
        };
        this.handleSelect = this.handleSelect.bind(this);
        this.triggerCalendar = this.triggerCalendar.bind(this);
        this.closeAllExcept = this.closeAllExcept.bind(this);
    }

    componentDidMount() {
        let me = this;
        let refs = [];
        for (let i = 0; i < me.props.data.sections.length; i++) {
            refs.push(React.createRef());
        }
        let prevState = {...me.state};
        prevState.refs = refs;
        me.setState(prevState);
    }

    componentWillUnmount() {}

    componentDidUpdate() {}

    handleSelect(ranges) {
        // alert(JSON.stringify(ranges));
        let prevState = { ...this.state };
        prevState.startDate = ranges.selection.startDate;
        prevState.endDate = ranges.selection.endDate ? ranges.selection.endDate : ranges.selection.startDate;
        this.setState(prevState);
        // alert(ranges);
    }

    triggerCalendar() {
        let me = this;
        let prevState = { ...me.state };
        prevState.showCalendar = !prevState.showCalendar;
        me.setState(prevState);
    }

    closeAllExcept(index) {
        let me = this;
        for(let i = 0; i < me.props.data.sections.length; i++) {
            if (i == index) continue;
            me.state.refs[i].current.closeMe();
        }
    }

    render() {
        let me = this;
        return (
            <div>
                <p
                    style={{ color: "#858585", cursor: "pointer" }}
                    onClick={me.triggerCalendar}
                >
                    {me.state.showCalendar ? "Скрыть каледнарь" : "Показать календарь"}
                </p>
                <p>
                    <b>Период:</b> {me.state.startDate.yyyymmdd()} -{" "}
                    {me.state.endDate.yyyymmdd()}
                </p>
                <div
                    style={
                        me.state.showCalendar ? { display: "block" } : { display: "none" }
                    }
                >
                    <DateRange
                        dateDisplayFormat={"dd.MM.Y"}
                        ranges={[
                            {
                                startDate: me.state.startDate,
                                endDate: me.state.endDate,
                                key: "selection"
                            }
                        ]}
                        date={new Date()}
                        onChange={me.handleSelect}
                    />
                </div>

                {me.props.data.sections.map((e, eId) => {
                    return <StatZone ref={me.state.refs[eId]} callback={me.closeAllExcept} myId={eId} key={`${eId}-${me.state.startDate}-${me.state.endDate}`} data={e} date_start={me.state.startDate} date_end={me.state.endDate} />;
                })}
            </div>
        );
    }
}
