import React from 'react';

export default class ChatParticipants extends React.Component {
  
  constructor(props) {
    super(props);
    // alert(212312);
    // this.state = {
    //     name: "",
    //     menu_variants: []  
    // };
  } 

  componentDidMount() {
    // alert(JSON.stringify(this.props.participants));
    // this.setState(this.props.content);
  }

  render() {
    // alert(JSON.stringify(this.props.participants));
    let xxx = <div className="chat-participants">
      { this.props.dialogs.map( (dialog, index) => 
        <div onClick={(e) => this.props.activateChat(dialog)} className={!!dialog.isActive ? 'chat-participant-active' : 'chat-participant'} key={`chat-participant-${dialog.id}`}>
          <div className="chat-left-part">
            {/*<p className="chat-role">{`${dialog.role}`}</p>*/}
            <p className="chat-name">{`${dialog.name}`}</p>
          </div>
          <i className="fal fa-phone fa-2x"></i>
        </div>
      )}
    </div>;
    return xxx;
  }
}