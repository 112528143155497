import React from "react";
import "./audio-helper.scss";

export default class AudioHelper extends React.Component {
    constructor(props) {
        super(props);
        let me = this;
        this.state = {
            listening: false,
            recognized: "",
            listeningTimeout: null,
            listeningEnabled: false,
            dict: {}
        };
        me.executeCommand = me.executeCommand.bind(this);
        me.activateCommandListening = me.activateCommandListening.bind(this);
        me.restartRecognition = me.restartRecognition.bind(this);
    }

    executeCommand(_cmd) {
        let me = this;
        let prevState = {... me.state};
        prevState.recognized = _cmd;
        me.setState(prevState, () => {
            if (!me.state.listening) {
                let articonPossibles = ["василий","привет archicom", "привет артикон", "привет артикул", "привет артем"];
                let detected = false;
                for(let artipossible of articonPossibles) {
                    if (_cmd.indexOf(artipossible) > -1) {
                        detected = true;
                        break;
                    }
                }
                if (detected)
                    me.activateCommandListening().then(() => {
                    });
                return;
            }

            for(let o of Object.keys(me.state.dict)) {
                if (_cmd.toLowerCase().indexOf(o) > -1) {
                    document.location.href = me.state.dict[o];
                    return;
                }
            }
        });
    }

    restartRecognition() {
        let me = this;

        // me.speechRecognition = new webkitSpeechRecognition();
        me.speechRecognition.continuous = true;
        me.speechRecognition.interimResults = false;
        me.speechRecognition.lang = 'ru-RU';
        me.speechRecognition.onstart = function() {
            let prevState = {... me.state};
            prevState.listeningEnabled = true;
            me.setState(prevState);
        }
        me.speechRecognition.onend = function() { me.restartRecognition();}

        me.speechRecognition.onresult = function(event) {
            for (let i = event.resultIndex; i < event.results.length; i++) {
                let finalTranscript = event.results[i][0].transcript.toLowerCase();
                me.executeCommand(finalTranscript);
                // if (event.results[i].isFinal) {
                //     me.setState({
                //         recognized: finalTranscript
                //     }, () => {
                //         me.executeCommand(finalTranscript);
                //     });
                // } else {
                //     if (!me.state.listening) me.executeCommand(finalTranscript);
                // }
            }
        }
        me.speechRecognition.start();
    }

    activateCommandListening() {
        let me = this;
        if (me.state.listeningTimeout) clearTimeout(me.state.listeningTimeout);
        let prevState = {... me.state};
        prevState.listeningTimeout = setTimeout(() => {
            let prevState = {... me.state};
            prevState.listening = false;
            me.setState(prevState);
        }, 15000);
        prevState.listening = true;
        return new Promise((resolve) => {
            me.setState(prevState, () => {resolve(true);});
        });

    }

    componentDidMount() {
        let me = this;
        if (!("webkitSpeechRecognition" in window)) return;
        me.speechRecognition = new webkitSpeechRecognition();
        fetch("/audio_commands.json").then((e) => e.json()).then((e) => {
            let prevState = {... me.state};
            prevState.dict = e;
            me.setState(prevState, () => {
                me.restartRecognition();
            });
        });

    }

    render() {
        let me = this;
        let size = Object.keys(me.state.dict).length;
        let numOfLines = 4;
        let marqueeSize = parseInt(size / numOfLines);
        let marquees = [];
        for(let i = 0; i < numOfLines; i++) {
            let commands = Object.keys(me.state.dict).slice(marqueeSize * i, marqueeSize * (i + 1)).map((x) => {
               return `<a href='${me.state.dict[x]}'>${x}</a>`;
            }).join("&nbsp;&nbsp;");
            marquees.push(commands);
        }

        return <div>
            <div style={{ opacity: 0.4 }} className={"flex flex-row content-center items-center"}>
                <p style={{fontSize: "10px", margin: 0, marginRight: "10px"}}>Скажите: 'Василий!'</p>
                <i style={me.state.listeningEnabled ? {} : {opacity: 0.5} } className="far fa-microphone fa-2x" onClick={me.activateCommandListening}></i>
            </div>
            <div className={`listening-state ${me.state.listening ? 'listening-state-active' : ''}`}>
                {marquees.map((m, i) => {
                    return <marquee style={{marginBottom: "10px", opacity: 0.7}} scrollAmount={2} direction={i % 2 === 0 ? 'left' : 'right'}><div dangerouslySetInnerHTML={{__html: m}}/></marquee>
                    })
                }
                <input type="text" value={me.state.recognized} />
            </div>
        </div>;
    }
}