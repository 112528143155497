import React from 'react';
import OrderStage from "./OrderStage";
import QRCode from "react-qr-code";
import OrderTimersContainer from "./OrderTimersContainer";
import "./order.scss";
import $ from "jquery";

var refreshingZakazStages = false;

async function postData(url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST', 
    mode: 'cors', 
    cache: 'no-cache', 
    credentials: 'same-origin', 
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', 
    referrerPolicy: 'no-referrer', 
    body: JSON.stringify(data) 
  });
  return await response.json(); 
}


export default class OrderCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uId: new Date(),
      zakaz_stages: [],
      situation: {},
      reworked: null,
      order: props.order
    }

    // alert(this.props);
    // alert(JSON.stringify(this.props.order));

    this.handleCallback = this.handleCallback.bind(this);
    this.ventillateSituation = this.ventillateSituation.bind(this);
    this.updateZakazStages = this.updateZakazStages.bind(this);
    this.refreshZakazStages = this.refreshZakazStages.bind(this);
    this.updateCallbackWithOrderReload = this.updateCallbackWithOrderReload.bind(this);
    this.updateOrder = this.updateOrder.bind(this);
  }


  async updateZakazStages() {
    let me = this;
    return new Promise( (resolve, reject) => {
      let url = `/zakaz_stages?user_order_id=${me.props.order.id}`;
      if (url.indexOf("https://") == -1) {
        url = document.SERVER_URL + url;
      }
      fetch(url, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
      }).then((res) => {
        return res.json();
      }).then((data) => {
        let prevState = {... me.state};
        prevState.uId = new Date();
        prevState.zakaz_stages = data;
        for(let i in prevState.zakaz_stages) {
          prevState.zakaz_stages[i].ref = React.createRef();
        }
        me.setState(prevState, function (xxx) {
          resolve(true);
        });
        // let situation = this.ventillateSituation(data);
      });
    });
    
  }

  componentDidMount() {
    $("form").submit(false);
    this.updateZakazStages();
  }

  async updateOrder() {
    let me = this;

    return new Promise((resolve, reject) => {
      let url = `/orders/${me.props.order.id}}/json`;
      if (url.indexOf("https://") == -1) {
        url = document.SERVER_URL + url;
      }
      fetch(url, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
      }).then((res) => {
        return res.json();
      }).then((data) => {
        let prevState = {...me.state};
        prevState.uId = new Date();
        prevState.order = data;
        me.setState(prevState, function (xxx) {
          me.updateZakazStages().then((xxx) => {
            resolve(true);
          });
        });
      });
    });

  }

  ventillateSituation(data) {
    let situation = {};

    for (let i = 0; i< data.length; i++) {
      situation[data[i].symbol_code] = "";
    }
  }

  async refreshZakazStages() {
    // if (refreshingZakazStages) return;
    // refreshingZakazStages = true;
    let me = this;

    for(let i in me.state.zakaz_stages) {
      if (!!me.state.zakaz_stages[me.state.zakaz_stages.length - 1 - i].ref.current) {
        await me.state.zakaz_stages[me.state.zakaz_stages.length - 1 - i].ref.current.updateAvailability();
      }
    }
    // refreshingZakazStages = false;
  }

  async handleCallback(parsel) {
    let me = this;
    return new Promise( (resolve, reject) => {
      me.refreshZakazStages();
      resolve(true);
    });
  }

  async updateCallbackWithOrderReload(parsel) {
    let me = this;
    return new Promise( (resolve, reject) => {
      me.updateOrder().then( (e) => {
        me.refreshZakazStages();
        resolve(true);
      });
    });
  }

  componentWillUnmount() {
  }

  render() {
    let me = this;
    let qrContent = `{"user_order": { "id": ${this.props.order.id}}}`;
    let xxx = <div className="order" key={this.state.uId}>
      {me.props.order.get_reworked &&
        <p style={{color: "#F00"}}>{me.props.order.correction_type} <a href={`/admin/user_orders/${me.state.order.get_reworked.id}`}>АРТ-{me.state.order.get_reworked.id}</a></p>
      }
        <div style={{display: "flex", flexDirection: "row", alignContent: "center", alignItems: "center"}}>
          {/*<h1 className={"hide-print"} style={{marginRight: "21px"}}>Заказ-наряд</h1>*/}
          <OrderTimersContainer order={this.state.order} />
        </div>
        <div className="qr-container" style={{position: "absolute", top: "7px", right: "7px"}}>
          <QRCode size={126} value={qrContent} />
        </div>
        {this.state.zakaz_stages.map(
          (stage, stageIndex) =>
            <OrderStage current_user={me.props.current_user} ref={stage.ref} blocked={me.state.situation[stage.symbol_code]} updateCallback={me.handleCallback} updateCallbackWithOrderReload={me.updateCallbackWithOrderReload} className="order-stage" order={me.state.order} key={`order-stage-${stage.id}-${stageIndex}`} content={stage} />
        )}
    </div>;
    return (xxx);
  }


}