import { XMLParser } from "fast-xml-parser";
import React from "react";

export default class XmlTableView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: this.props.content,
            columns: [],
            rows: [],
            value: null
        };
        this.setMode = this.setMode.bind(this);
    }

    componentDidMount() {
        let me = this;
        // let xmlDoc = parser.parseFromString(me.props.content,"text/xml");
        // let implantLibratyEntry = xmlDoc.getElementByTagName("ImplantLibraryEntry");
        const parser = new XMLParser();
        // console.log(me.props.content);
        try {
            let x = parser.parse(me.props.content);
            let typeConfigs = x.ImplantLibraryEntry.TypeConfig.ImplantTypeConfig;
            // alert(JSON.stringify(typeConfigs));
            // alert(typeof typeConfigs);
            if (!Array.isArray(typeConfigs)) {
                typeConfigs = [typeConfigs];
            }
            // alert(JSON.stringify(typeConfigs));
            // alert(typeConfigs.length);
            let rows = [];
            for (let o of typeConfigs) {
                // alert("!: "+JSON.stringify(o));
                let row = [o.DisplayInformation];
                // alert("!!: "+JSON.stringify(o.SubtypeConfig));
                // alert("!!!: "+JSON.stringify(o.SubtypeConfig.ImplantSubtypeConfig));

                for (let j of o.SubtypeConfig.ImplantSubtypeConfig) {
                    // alert(JSON.stringify(j));
                    row.push(j.DisplayInformation);
                }

                rows.push(row);
            }

            let prevState = { ...me.state };
            prevState.rows = rows;
            me.setState(prevState);
        } catch (e) {
            console.error(e);
        }
    }

    setMode(mode, toSend) {
        let me = this;
        let prevState = { ...me.state };
        prevState.value = mode;
        // alert(mode);
        me.setState(prevState, () => {
            me.props.callback("параметры импланта", toSend);
        });
    }

    render() {
        let me = this;
        return (
            <div>
                {me.state.rows.map((e) => {
                    return (
                        <div
                            style={{
                                background: "#f1f1f1",
                                marginBottom: "7px",
                                display: "flex",
                                flexDirection: "row",
                                alignContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <h3
                                style={{
                                    marginRight: "14px",
                                    whiteSpace: "nowrap",
                                    fontWeight: "bold",
                                    width: "120px",
                                    fontSize: "17px"
                                }}
                            >
                                {e[0]}
                            </h3>
                            {e.map((xx, i) => {
                                if (i == 0) return <></>;
                                let style = {
                                    width: "80px",
                                    cursor: "pointer",
                                    textAlign: "center",
                                    fontWeight: "300",
                                    whiteSpace: "nowrap"
                                };
                                style.padding = "4px";
                                style.paddingLeft = "1px";
                                style.paddingRight = "1px";
                                style.borderRadius = "4px";
                                let value = `${e}-${xx}`;
                                if (value == me.state.value) {
                                    style.background = "#acacac";
                                }
                                return (
                                    <h4 onClick={(eeee) => { me.setMode(value, `${e[0]}-${xx}`) }} style={style}>
                                        {xx}
                                    </h4>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        );
    }
}
