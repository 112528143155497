import React from 'react';
import PackagingList from '../../PackagingLists/PackagingList';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

export default class PackagingListStage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      successOpen: false,
      packagingListItems: [],
      packagingList: {}
    };

    this.updatePackageList = this.updatePackageList.bind(this);
    this.changeField = this.changeField.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    let prevState = {... this.state};
    prevState.successOpen = false
    this.setState(prevState);
  }

  componentDidMount() {
    let prevState = {... this.state };
    if (!this.props.order.packaging_list) {
      fetch("/orders/packaging_list_fields").then(
        (e) => {
          return e.json()
        } 
      ).then( (e) => {
          prevState.packagingListItems = e;
          let packagingList = {};
          for(let i = 0; i < prevState.packagingListItems.length; i++) {
            packagingList[prevState.packagingListItems[i].name] = 0;
          }
          prevState.packagingList = packagingList;
          this.setState(prevState);
        }
      )
    } else {
      // alert(this.props.order.packaging_list);
      prevState.packagingList = JSON.parse(this.props.order.packaging_list);
      this.setState(prevState);
    }

  }


  changeField(fieldName, delta) {
    // alert(fieldName + " "+delta);
    let prevState = {... this.state };
    prevState.packagingList[fieldName] += delta;
    if (prevState.packagingList[fieldName] >= 0) {
      this.setState(prevState);  
      this.updatePackageList(JSON.stringify(prevState.packagingList));
    }
  }


  updatePackageList(data) {
    let me = this;
    let updateUrl = `/orders/${this.props.order.id}/update_field`;

    if (this.props.current_user) {
      updateUrl += `?user_id=${this.props.current_user}`;
    }

    if (updateUrl.indexOf("https://") == -1) {
      updateUrl = document.SERVER_URL + updateUrl;
    }

    fetch(updateUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify({
        new_value: data,
        changed_field: "packaging_list"
      })
    }).then((response) => {
      let prevState = {... me.state};
      prevState.successOpen = true;
      me.setState(prevState);
      return response.json();
    });
  }

  componentWillUnmount() {
  }

  render() {
    let me = this;
    let xxx = <>
      <PackagingList current_user={this.props.current_user} updateCallback={me.props.updateCallback} order={this.props.order} />
      <Snackbar open={me.state.successOpen} autoHideDuration={6000} onClose={me.handleClose}>
        <Alert onClose={me.handleClose} severity="success">
          Данные обновлены
        </Alert>
      </Snackbar>
    </>
      ;

    return (xxx);
  }
}