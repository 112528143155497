import React from 'react';
import Button from '@material-ui/core/TextField';
import SelfUpdatedSwitchField from "../../Shared/SelfUpdatedSwitchField";
import CircularProgress from '@material-ui/core/CircularProgress';
import $ from "jquery";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ThreeDViewer from "../../Shared/ThreeDViewer";

async function postOrderData(url = '', data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *client
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  });
  return await response.json(); // parses JSON response into native JavaScript objects
}


export default class FilesUploadStage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      stlLink: "https://bohdanbirdie.github.io/stl-obj-demo/bottle.stl",
      protocol_type: this.props.order.protocol_type,
      model_should_be_printed: this.props.model_should_be_printed,
      files: [],
      showFileUrl: null
    };

    this.inputRef = React.createRef();
    this.addFiles = this.addFiles.bind(this);
    this.addAttachment = this.addAttachment.bind(this);
    this.updateCallback = this.updateCallback.bind(this);
    this.updateNeedFor3dModelCallback = this.updateNeedFor3dModelCallback.bind(this);
    this.upload = this.upload.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.openFile = this.openFile.bind(this);
  }


  openFile(showFileUrl) {
    let prevState = {... this.state};
    prevState.showFileUrl = showFileUrl;
    this.setState(prevState);
  }


  addAttachment(x) {
    let me = this;
    let prevState = this.state;
    prevState.files.push(x);
    this.setState(prevState, (e) => {
      me.upload(x);
    });
  }


  upload(x) {
    let me = this;
    let formData = new FormData();
    formData.append("order_id", this.props.order.id);
    formData.append("file", x.blob, x.name);
    // alert(formData);
    // alert(JSON.stringify(x));
    let url = `${document.SERVER_URL}/orders/${this.props.order.id}/upload_file`;
    $.ajax({
        url: url,
        data: formData,
        dataType: "json",
        type: 'POST',
        contentType: false, // NEEDED, DON'T OMIT THIS (requires jQuery 1.6+)
        processData: false, // NEEDED, DON'T OMIT THIS
        success: function(res) {
          let prevState = {... me.state};
          let indexOf = prevState.files.indexOf(x);
          prevState.files[indexOf].loading = false;
          prevState.files[indexOf].file_url = res.file_url;
          me.setState(prevState);
        },
        error: function(x) {
        }
    });
  }

  addFiles () {
    this.inputRef.current.click();
  }




  componentDidMount() {
    let me = this;
    if (!this.inputRef.current) return;
    
    this.inputRef.current.onchange = function(e) {
      // alert(2);
      if (0 == me.inputRef.current.files) return;
      let files = me.inputRef.current.files;
      // let fileReaders = [];

      for (let i = 0; i < files.length; i++) {
        let f = files[i];
        // me.add_attachment([{name: f.name, blob: f}]);
        me.addAttachment({name: f.name, blob: f, loading: true});
        // let fileReader = new FileReader();
        // fileReader.onloadend = function (e) {
        //   var blob = e.target.result;
        //   // me.props.add_attachment([{name: f.name, blob: blob}]);
        //   me.addAttachment({name: f.name, blob: blob, loading: true});
        // };
        // fileReader.readAsArrayBuffer(f);
      }
    }
  }

  componentDidUpdate() {
    let me = this;
    if (!this.inputRef.current) return;
    
    this.inputRef.current.onchange = function(e) {
      // alert(2);
      if (0 == me.inputRef.current.files) return;
      let files = me.inputRef.current.files;
      // let fileReaders = [];

      for (let i = 0; i < files.length; i++) {
        let f = files[i];
        // me.add_attachment([{name: f.name, blob: f}]);
        me.addAttachment({name: f.name, blob: f, loading: true});
        // let fileReader = new FileReader();
        // fileReader.onloadend = function (e) {
        //   var blob = e.target.result;
        //   // me.props.add_attachment([{name: f.name, blob: blob}]);
        //   me.addAttachment({name: f.name, blob: blob, loading: true});
        // };
        // fileReader.readAsArrayBuffer(f);
      }
    }
  }


  componentWillUnmount() {
    if (!this.inputRef.current) return;
    this.inputRef.current.removeAttribute("onchange");
  }

  updateCallback(val) {
    let me = this;
    this.props.updateCallback();
    let prevState = {... this.state};
    prevState.protocol_type = val;
    this.setState(prevState);
    // me.updateSpecialtiesWithId(val);  
  }

  updateNeedFor3dModelCallback(val) {
    let me = this;
    this.props.updateCallback();
    let prevState = {... this.state};
    prevState.model_should_be_printed = val;
    this.setState(prevState);
    // me.updateSpecialtiesWithId(val);
  }


  handleClose() {
    let prevState = {... this.state};
    prevState.showFileUrl = null;
    this.setState(prevState);
  }

  render() {
    let variants = [
      ["с уровня слепка", "с уровня слепка"],
      ["с уровня файла", "с уровня файла"],
      ["с уровня модели", "с уровня модели"]
    ];

    let orderStlFiles = [];

    let me = this;

    try {
      if (me.props.order.stl_files) 
        orderStlFiles = me.props.order.stl_files;
    } catch(e) {}


    // alert(JSON.stringify(me.props.order));
    let orderSpecialityUpdateUrl = `/orders/${this.props.order.id}/update_field`;
    let orderSpecialityGetUrl = `/orders/${this.props.order.id}.json`;

    if (this.props.current_user) {
      orderSpecialityUpdateUrl += `?user_id=${this.props.current_user.id}`
      orderSpecialityGetUrl += `?user_id=${this.props.current_user.id}`
    }

    let xxx = <div>
      <h3 className="hide-print">Выберите протокол</h3>
      <SelfUpdatedSwitchField current_user={me.props.current_user} updateCallback={me.updateCallback} label={""} variants={variants} updateUrl={orderSpecialityUpdateUrl} getUrl={orderSpecialityGetUrl} fieldName="protocol_type" />

      { me.state.protocol_type == "с уровня файла" &&
        <div className={"hide-print"}>
          <SelfUpdatedSwitchField current_user={me.props.current_user} updateCallback={me.updateNeedFor3dModelCallback} label={""}
                                  variants={[["Требуется печать модели", "Требуется печать модели"], ["Не требуется печать модели", "Не требуется печать модели"]]} updateUrl={orderSpecialityUpdateUrl}
                                  getUrl={orderSpecialityGetUrl} fieldName="model_should_be_printed"/>
          <div style={{display: "flex", flexDirection: "row", alignContent: "center", alignItems: "center"}}>
            <p><i onClick={this.addFiles} style={{margin: "7px"}}  className="fal fa-paperclip fa-2x"></i>&nbsp;Добавить файл</p>
            <input multiple={true} ref={this.inputRef} type="file" style={{display: "none"}} />
          </div>
          { orderStlFiles.map( (e) => 
            <div key={`file-${e.id}`} style={{display: "flex"}}>
              <div style={{fontSize: "18px", display: "flex", flexDirection: "row", alignItems: "center", alignContent: "center"}}>
                <p>{e.file_url}</p>
                <i className="fal fa-cube fa-3x" onClick={(ev) => { me.openFile(e.file_url); }}></i>
                <a style={{marginLeft: "14px"}} href={e.file_url} target={"_blank"}><i class="fal fa-cloud-download fa-3x"></i></a>
              </div>
              
            </div>
          )}
          { me.state.files.map( (e, eId) => 
            <div key={`uploadedfile-${eId}`} style={{display: "flex"}}>
              {/*<p onClick={(ev) => { me.openFile(e.file_url); }}>{e.name} {e.file_url}</p>*/}
              <div style={{fontSize: "18px", display: "flex", flexDirection: "row", alignItems: "center", alignContent: "center"}}>
                <p>{e.file_url}</p>
                <i className="fal fa-cube fa-3x" onClick={(ev) => { me.openFile(e.file_url); }}></i>
                <a style={{marginLeft: "14px"}} href={e.file_url} target={"_blank"}><i class="fal fa-cloud-download fa-3x"></i></a>
                {e.loading && 
                  <CircularProgress size={40}/>
                }
              </div>
            </div>
          )}
        </div> 
      }
      <Dialog onClose={me.handleClose} open={me.state.showFileUrl}>
        <ThreeDViewer file_url={me.state.showFileUrl} />
      </Dialog>
    </div>;

    return (xxx);
  }
}