import React from 'react';
import "./tooth-color.scss";

export default class ToothColorPicker extends React.Component {
   vitaColors = {
    "A": ["1", "2", "3", "3.5", "4"],
    "B": ["1", "2", "3", "4"],
    "C": ["1", "2", "3", "4"],
    "D": ["2", "3", "4"],
    "BL": ["BL1", "BL2","BL3", "BL4", "OM3", "OM2", "OM1"]
  }
  
  masterColors = {
    "A": ["1M1:1M2 (1:1)", "2M2", "3M2", "3R2,5", "4L2,5"],
    "B": ["1М1", "2L1,5 : 2М2 (1:1)", "2L1,5 : 3L1,5 (2:1)", "3L2,5","3M3"],
    "C": ["3M1 : 2L1,5 (1:1)", "3L1,5", "4L1,5 : EC11 (2:1)", "5M1 : EC10 (2:1)", "4M1 : EC10 (2:1)"],
    "D": ["2M1 : 3M1 (1:1)", "3M2 : 4R1,5 (1:1)", "3L1,5 : EC11 (2:1)"]

  }
  

  constructor(props) {
    super(props);
    this.state = {
      chosenColor: "-1"
    };
    this.setColor = this.setColor.bind(this);
  }


  setColor(e) {
    let prevState = {... this.state};
    prevState.chosenColor = e;
    this.props.setColor(e);
    this.setState(prevState);

    if (this.props.callback) {
      this.props.callback(e);
    }
  }

  render() {
    let me = this;
    // console.log(this.state.calendar);
    return (
      <div className="tooth-color-picker-v1">
        <div className="tooth-row-v1">
          <h4>Цвет VITA</h4>
          <button variant="contained">Опеределить цвет по фото</button>
          <h4>3d Master</h4>
        </div>
        <div className="color-systems-row-v1">
          <div className="color-column-v1">
            {Object.keys(this.vitaColors).map( (x) => 
            <div className="color-line-v1" key={`row-${x}`}>
              <p className="big-color-letter-v1">{x}</p>
              <div style={{width: "21px"}}></div>
              {this.vitaColors[x].map( (e) => 
                <p style={{cursor: "pointer", background: (me.state.chosenColor == `${x}${e}` ? '#ededed' : "#fff" )}} onClick={(y) => {me.setColor(`${x}${e}`);}} key={`row-${x}${e}`} className="color-button">{x}{e}</p>
              )}
            </div>
            )}
          </div>
          
          <div className="vertical-line-v1"></div>
          
          <div className="color-column-v1">
            {Object.keys(this.masterColors).map( (x) => 
            <div className="color-line-v1"  key={`row-${x}`}>
              {this.masterColors[x].map( (e) => 
                <p style={{cursor: "pointer", background: (me.state.chosenColor == `${e}` ? '#ededed' : "#fff" )}} onClick={(y) => {me.setColor(e);}} key={`row-${x}${e}`} className="color-button">{x}{e}</p>
              )}
            </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}


// const el = document.querySelector("#root");
// ReactDOM.render(<ToothColorPicker />, el);