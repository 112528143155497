import React from 'react';

export default class WorkAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentWillMount() {
  }

  render() {
    let me = this;
    // console.log(this.state.calendar);
    return (
      <div className="work-action" style={{marginBottom: "7px"}}>
        <div style={{display: "block", width: "10px", height: "10px", borderRadius: "50%", background: me.props.color }}></div>
        <p style={{fontSize: "10px"}}>{me.props.text}</p>
        <i className="fal fa-info-circle"></i>
      </div>
    );
  }
}