import React from "react";
import $ from "jquery";
import "./menus.scss";
import "./color-system.scss";
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Lined from "../../Shared/Lined";
import SelfUpdatedAsyncSelectField from "../../Shared/SelfUpdatedAsyncSelectField";
import SelfUpdatedTextField from "../../Shared/SelfUpdatedTextField";
import SelfUpdatedCheckField from "../../Shared/SelfUpdatedCheckField";


class MenuDeepView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      references: [],
      open: false,
      activeMenuVariant: null,
      activeMenuEdit: null,
      nestedMenus: React.createRef(),
      iAmCut: false,
      currentCut: null
    };
    this.uploadChangesTimeout = null;

    this.refToMe = React.createRef();
    this.addMenuVariant = this.addMenuVariant.bind(this);
    this.updateMe = this.updateMe.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleMenuDialogClose = this.handleMenuDialogClose.bind(this);
    this.openMenuVariantMenu = this.openMenuVariantMenu.bind(this);
    this.openMenuEdit = this.openMenuEdit.bind(this);
    this.hideShow = this.hideShow.bind(this);
    this.createMenu = this.createMenu.bind(this);
    this.cutMe = this.cutMe.bind(this);
    this.notifyCopied = this.notifyCopied.bind(this);
    this.pasteMenu = this.pasteMenu.bind(this);
  }

  pasteMenu(menuVariant) {
    let me = this;
    let url = `/admin/menus/move_menu_to_menu_variant?menu_id=${me.state.currentCut}&menu_variant_id=${menuVariant}`;
    // alert(url);
    fetch(url).then((e) => {
      return e.json();
    }).then((e) => {
      me.updateMe();
    });
  }

  notifyCopied(variantId) {
    let me = this;
    let prevState = {... me.state};
    prevState.currentCut = variantId;
    me.setState(prevState, () => {
      // alert(me.state.references.length);
      for (let xx of me.state.references) {
        if (!xx.menuRef) continue;
        console.log(variantId);
        xx.menuRef.current.notifyCopied(variantId);
      }
      me.forceUpdate();
    });
  }

  cutMe() {
    let me = this;
    if (me.props.top) return;
    let prevState = {... this.state};
    prevState.iAmCut = !prevState.iAmCut;
    me.setState(prevState, () => {
      if (prevState.iAmCut)
        me.props.setCutVariant(me.props.menu.id);
      else{
        me.props.setCutVariant(null);
      }
    });
  }

  openDialog() {
    let prevState = {... this.state};
    prevState.open = true;
    this.setState(prevState);
  }

  handleMenuDialogClose() {
    let prevState = {... this.state};
    prevState.activeMenuEdit = null;
    this.setState(prevState);
  }

  handleClose(e) {
    let prevState = {... this.state};
    prevState.activeMenuVariant = null;
    this.setState(prevState);
  }

  updateViaNetwork() {
  }

  componentDidMount() {
    let me = this;
    let prevState = { ...this.state };
    let newMenus = [];
    if (!me.props.menus) return;
    // console.log();
    for (let i = 0; i < me.props.menus.length; i++) {
      let menuWithReference = {
        ref: React.createRef(),
        menuRef: React.createRef(),
        itself: me.props.menus[i]
      };
      console.log(me.props.menus[i]);
      newMenus.push(menuWithReference);
    }

    prevState.references = newMenus;
    this.setState(prevState);
  }

  updateMe(_fast) {
    let me = this;
    if (_fast) {
      if (me.props.top) {
        // alert(1);
        me.props.updateAll();
      } else {
        // alert(2);
        me.props.update();
      }
      return;
    }

    if (me.uploadChangesTimeout) {
      console.log(me.uploadChangesTimeout);
      clearTimeout(me.uploadChangesTimeout);
    }
    me.uploadChangesTimeout = setTimeout(() => {
      if (me.props.top) {
        // alert(1);
        me.props.updateAll();
      } else {
        // alert(2);
        me.props.update(true);
      }
    }, 400);
  }

  removeMenuVariant(menuVariantId) {
    let name = prompt(`Введите число ${menuVariantId} для подтверждения удаления перехода`, "");
    if (name != `${menuVariantId}`) {
      alert("Удаление не подтверждено");
      return;
    }

    let me = this;

    let url = `/admin/menu_variants/${menuVariantId}/remove`;

    fetch(url).then((e) => {
      return e.json();
    }).then( (e) => {
      // alert(JSON.stringify(e));
      me.updateMe();
    });
  }

  addMenuVariant(menuId) {
    let name = prompt('Укажите название кнопки', "");
    if (name.length < 2) return; 
    let me = this;
    
    let url = `/admin/menus/${menuId}/add_menu_variant?name=${name}`;
    
    fetch(url).then((e) => {
      return e.json();
    }).then( (e) => {
      // alert(JSON.stringify(e));
      me.updateMe();
    });
  }

  openMenuEdit(menuId) {
    let me = this;
    let prevState = {... me.state};
    fetch(`/admin/menus/${menuId}/about`).then((e) => {
      return e.json()
    }).then((x) => {
      prevState.activeMenuEdit = x;
      me.setState(prevState);
    });
  }


  openMenuVariantMenu(menuVariant) {
    let me = this;
    let prevState = {... me.state};
    fetch(`/admin/menu_variants/${menuVariant}/about`).then((e) => {
      return e.json()
    }).then((x) => {
      prevState.activeMenuVariant = x;
      me.setState(prevState);
    });
  }

  hideShow() {
    let me = this;
    if (me.state.nestedMenus.current) {
      let isShown = me.state.nestedMenus.current.style.display != "none";
      me.state.nestedMenus.current.style.display = isShown ? "none" : "block";
    }
  }

  createMenu(menuVariantId) {
    // alert(menuVariantId);
    let me = this;
    fetch(`/admin/menu_variants/${menuVariantId}/add_menu`).then((e) => {
      return e.json()
    }).then((x) => {
      // alert(JSON.stringify(x));
      me.updateMe();
    })
  }

  render() {
    let me = this;
    return (
      <div className={`menu-content`} >
        <div className="menu-itself" ref={this.refToMe} style={{position: "relative"}}>
          <p style={{borderLeft: "none", position: "absolute", top: "-40px", right: "0px", fontSize: "22px"}}>{!!this.props.menu.finish_and_start_tz ? "Завершение ✅" : ""}</p>
          <b>
            <a href={`${document.SERVER_URL}/admin/menus/${me.props.menu.id}/edit`} target={"_blank"}>
              <span style={{fontSize: "42px"}}>{this.props.menu.takes_color ? "🎨" : ""}</span>{this.props.menu.meta_name}({this.props.menu.id})
            </a>
            <i className={"fal fa-edit"} onClick={(eee) => {me.openMenuEdit(me.props.menu.id)}} style={{cursor: "pointer"}}></i>
            <i onClick={me.hideShow} style={{color: "#ffffff", position: "absolute", top: "4px", right: "10px", cursor: "pointer"}} className={"fal fa-times fa-2x"} />
            <i onClick={me.cutMe} style={{ cursor: "pointer", color: me.state.iAmCut ? "#f00" : "#ffffff", position: "absolute", top: "4px", right: "30px"}} className={"fal fa-copy fa-2x"} />
          </b>
          {this.state.references.map((e, i) => (
            <p key={`m_i_l-${i}`} ref={e.ref}>
              <ul
                style={{
                  padding: 0,
                  borderLeft: "1px solid #ededed",
                  paddingLeft: "14px",
                  listStyle: "none"
                }}
              >
                {e.itself.link_name.split("||").map((xy) => {
                  let name = xy.split(";")[0];
                  let id = xy.split(";")[1];
                  let color = xy.split(";")[3];
                  let finishAndStartTz = xy.split(";")[2] == "true";
                  // console.log(JSON.stringify(e.itself));
                  return (
                    <li
                      style={{
                        borderBottom: "1px solid #ededed",
                        marginBottom: "14px",
                        display: "flex",
                        flexDirection: "row",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "space-between"
                      }}
                    >
                      { (color.length > 0) && (color != "#000000") &&
                        <span style={{display: "flex", width: "30px", height: "30px", borderRadius: "50%", background: color, marginRight: "7px", color: "#ababab"}}></span>
                      }
                      <a href={`/admin/menu_variants/${id}`} target="_blank">{name}{finishAndStartTz ? "✅" : ""}</a>
                      <p style={{
                          color: "#ededed",
                          marginLeft: "auto",
                          fontSize: "12px",
                          cursor: "pointer"
                        }}
                        onClick={(eee) => {
                          me.openMenuVariantMenu(id);
                        }}
                      >
                        Ред
                      </p>
                      <p style={{
                          marginLeft: "7px",
                          color: "#ededed",
                          fontSize: "12px"
                        }}
                        onClick={(xxx) => {
                          me.removeMenuVariant(id)
                        }}
                      >
                        Удалить
                      </p>

                      <p style={{
                        marginLeft: "7px",
                        color: "#ededFF",
                        fontSize: "12px"
                      }}
                         onClick={(xxx) => {
                           me.createMenu(id)
                         }}
                      >
                        Создать новое меню
                      </p>
                      {me.state.currentCut &&
                        <>
                          <i onClick={(ee) => {
                            me.pasteMenu(id);
                          }} className={"fal fa-paste"} style={{marginLeft: "7px", color: "#f00"}}></i>
                        </>
                      }
                    </li>
                  );
                })}
              </ul>
            </p>
          ))}
          <button style={{cursor: "pointer"}} onClick={(eee) => {me.addMenuVariant(me.props.menu.id);}}>Добавить кнопку</button>
          <Dialog onClose={me.handleMenuDialogClose} aria-labelledby="customized-dialog-title" open={!!me.state.activeMenuEdit}>
            {me.state.activeMenuEdit &&
            <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
              {me.state.activeMenuEdit.name}
            </DialogTitle>
            }
            {me.state.activeMenuEdit &&
            <DialogContent dividers>
              {/*<p>{JSON.stringify(me.state.activeMenuVariant)}</p>*/}
              <SelfUpdatedTextField updateCallback={me.updateMe} variant="outlined"  updateUrl={`/admin/menus/${me.state.activeMenuEdit.id}/update_field`} getUrl={`/admin/menus/${me.state.activeMenuEdit.id}/about`} fieldName="meta_name" label="meta_name"/>
              <SelfUpdatedTextField updateCallback={me.updateMe} variant="outlined"  updateUrl={`/admin/menus/${me.state.activeMenuEdit.id}/update_field`} getUrl={`/admin/menus/${me.state.activeMenuEdit.id}/about`} fieldName="name" label="name"/>
              <SelfUpdatedTextField updateCallback={me.updateMe} variant="outlined"  updateUrl={`/admin/menus/${me.state.activeMenuEdit.id}/update_field`} getUrl={`/admin/menus/${me.state.activeMenuEdit.id}/about`} fieldName="sets_variable" label="sets_variable"/>
              <SelfUpdatedCheckField updateCallback={me.updateMe} variant="outlined" updateUrl={`/admin/menus/${me.state.activeMenuEdit.id}/update_field`} getUrl={`/admin/menus/${me.state.activeMenuEdit.id}/about`} fieldName="finish_and_start_tz" label="finish_and_start_tz" />
              <SelfUpdatedCheckField updateCallback={me.updateMe} variant="outlined" updateUrl={`/admin/menus/${me.state.activeMenuEdit.id}/update_field`} getUrl={`/admin/menus/${me.state.activeMenuEdit.id}/about`} fieldName="takes_color" label="takes_color" />
              {/*<SelfUpdatedAsyncSelectField getUrl={`/admin/menu_variants/${me.state.activeMenuVariant.id}/json`} updateCallback={me.handleChange} placeholder="Меню" label="Выберите меню, на которое идет ссылка" fieldName="opens_menu" options_url="/admin/menus/search" updateUrl="/admin/menu_variants/update_batch" />*/}
            </DialogContent>
            }
            <DialogActions>
              <Button autoFocus onClick={this.handleClose} color="primary">
                Закрыть
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog onClose={me.handleClose} aria-labelledby="customized-dialog-title" open={!!me.state.activeMenuVariant}>
            {me.state.activeMenuVariant &&
              <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                {me.state.activeMenuVariant.name}
              </DialogTitle>
            }
            {me.state.activeMenuVariant &&
              <DialogContent dividers>
                {/*<p>{JSON.stringify(me.state.activeMenuVariant)}</p>*/}
                <SelfUpdatedTextField updateCallback={me.updateMe} variant="outlined"  updateUrl={`/admin/menu_variants/${me.state.activeMenuVariant.id}/update_field`} getUrl={`/admin/menu_variants/${me.state.activeMenuVariant.id}/json`} fieldName="name" label="name"/>
                <SelfUpdatedTextField updateCallback={me.updateMe} variant="outlined"  updateUrl={`/admin/menu_variants/${me.state.activeMenuVariant.id}/update_field`} getUrl={`/admin/menu_variants/${me.state.activeMenuVariant.id}/json`} fieldName="alert_message" label="alert_message"/>
                <SelfUpdatedTextField updateCallback={me.updateMe} variant="outlined"  updateUrl={`/admin/menu_variants/${me.state.activeMenuVariant.id}/update_field`} getUrl={`/admin/menu_variants/${me.state.activeMenuVariant.id}/json`} fieldName="tooltip" label="tooltip"/>
                <SelfUpdatedTextField updateCallback={me.updateMe} variant="outlined"  updateUrl={`/admin/menu_variants/${me.state.activeMenuVariant.id}/update_field`} getUrl={`/admin/menu_variants/${me.state.activeMenuVariant.id}/json`} fieldName="opens_menu" label="sets_menu"/>
                <SelfUpdatedTextField updateCallback={me.updateMe} variant="outlined"  updateUrl={`/admin/menu_variants/${me.state.activeMenuVariant.id}/update_field`} getUrl={`/admin/menu_variants/${me.state.activeMenuVariant.id}/json`} fieldName="sets_color" label="sets_color"/>
                <SelfUpdatedTextField updateCallback={me.updateMe} variant="outlined"  updateUrl={`/admin/menu_variants/${me.state.activeMenuVariant.id}/update_field`} getUrl={`/admin/menu_variants/${me.state.activeMenuVariant.id}/json`} fieldName="order_index" label="order_index"/>
                <SelfUpdatedCheckField updateCallback={me.updateMe} variant="outlined" updateUrl={`/admin/menu_variants/${me.state.activeMenuVariant.id}/update_field`} getUrl={`/admin/menu_variants/${me.state.activeMenuVariant.id}/json`} fieldName="finish_and_start_tz" label="finish_and_start_tz" />
                <SelfUpdatedCheckField updateCallback={me.updateMe} variant="outlined" updateUrl={`/admin/menu_variants/${me.state.activeMenuVariant.id}/update_field`} getUrl={`/admin/menu_variants/${me.state.activeMenuVariant.id}/json`} fieldName="takes_text" label="takes_text" />
                <SelfUpdatedCheckField updateCallback={me.updateMe} variant="outlined" updateUrl={`/admin/menu_variants/${me.state.activeMenuVariant.id}/update_field`} getUrl={`/admin/menu_variants/${me.state.activeMenuVariant.id}/json`} fieldName="sets_date" label="sets_date" />
                <SelfUpdatedCheckField updateCallback={me.updateMe} variant="outlined" updateUrl={`/admin/menu_variants/${me.state.activeMenuVariant.id}/update_field`} getUrl={`/admin/menu_variants/${me.state.activeMenuVariant.id}/json`} fieldName="is_separated" label="is_separated" />
                <SelfUpdatedCheckField updateCallback={me.updateMe} variant="outlined" updateUrl={`/admin/menu_variants/${me.state.activeMenuVariant.id}/update_field`} getUrl={`/admin/menu_variants/${me.state.activeMenuVariant.id}/json`} fieldName="sets_tooth_color" label="sets_tooth_color" />
                <SelfUpdatedCheckField updateCallback={me.updateMe} variant="outlined" updateUrl={`/admin/menu_variants/${me.state.activeMenuVariant.id}/update_field`} getUrl={`/admin/menu_variants/${me.state.activeMenuVariant.id}/json`} fieldName="ask_sections" label="ask_sections" />
                <SelfUpdatedCheckField updateCallback={me.updateMe} variant="outlined" updateUrl={`/admin/menu_variants/${me.state.activeMenuVariant.id}/update_field`} getUrl={`/admin/menu_variants/${me.state.activeMenuVariant.id}/json`} fieldName="ask_upper_sections" label="ask_upper_sections" />
                <SelfUpdatedCheckField updateCallback={me.updateMe} variant="outlined" updateUrl={`/admin/menu_variants/${me.state.activeMenuVariant.id}/update_field`} getUrl={`/admin/menu_variants/${me.state.activeMenuVariant.id}/json`} fieldName="show_removed_teeth" label="show_removed_teeth" />
                <SelfUpdatedCheckField updateCallback={me.updateMe} variant="outlined" updateUrl={`/admin/menu_variants/${me.state.activeMenuVariant.id}/update_field`} getUrl={`/admin/menu_variants/${me.state.activeMenuVariant.id}/json`} fieldName="resets_tooth" label="resets_tooth" />
                {/*<SelfUpdatedAsyncSelectField getUrl={`/admin/menu_variants/${me.state.activeMenuVariant.id}/json`} updateCallback={me.handleChange} placeholder="Меню" label="Выберите меню, на которое идет ссылка" fieldName="opens_menu" options_url="/admin/menus/search" updateUrl="/admin/menu_variants/update_batch" />*/}
              </DialogContent>
            }

            <DialogActions>
              <Button autoFocus onClick={this.handleClose} color="primary">
                Закрыть
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <div className={`menu-nested ${me.props.level > 0 ? "hide-me" : ''}`}
             ref={me.state.nestedMenus}>
          {this.state.references.map((e, i) => (
            <>
              <MenuDeepView
                style={me.props.level > 1 ? {display: "none"} : {}}
                level={me.props.level + 1}
                key={`mdv-${i}`}
                prevRef={false ? me.refToMe : e.ref}
                name={e.itself.name}
                menus={e.itself.menus}
                menu={e.itself}
                top={false}
                setCutVariant={me.props.setCutVariant}
                update={me.updateMe}
                ref={e.menuRef}
              />
            </>
          ))}
        </div>
        <Lined ref1={me.props.prevRef} ref2={me.refToMe} />
      </div>
    );
  }
}

export default class MenuCheck extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menus: [],
      kkey: 0,
      copiedVariant: null
    };

    this.allMenus = {};

    this.getMenus = this.getMenus.bind(this);
    this.setCutVariant = this.setCutVariant.bind(this);
  }

  setCutVariant(variantId) {
    // alert(variantId);
    let me = this;
    let prevState = {... me.state};
    if (me.state.copiedVariant == variantId) {
      prevState.copiedVariant = null;
      me.setState(prevState);
      return;
    } else {
      prevState.copiedVariant = variantId;
      me.setState(prevState, () => {
        for(let menu of me.state.menus) {
          if (!menu.ref) continue;
          menu.ref.current.notifyCopied(variantId);
        }
      });
    }
  }

  handleChange(event) {}

  componentDidMount() {
    this.getMenus(this.props.menus);
  }

  componentWillUnmount() {}

  componentDidUpdate() {}

  getMenus(menus) {
    let me = this;
    if (!!menus) {
      me.setState({
        menus: menus
      });
    } else {
      let menuId = me.props.menuId;
      let url = "/flow/menus";
      if (!!menuId) {
        url += "?menu_id="+menuId;
      }
      fetch(url).then((e) => {
        return e.json()
      }).then((e) => {
        let newK = me.state.kkey + 1;
        let newMenus = e.map((cc) => {
          let newX = {... cc};
          newX.ref = React.createRef();
          return newX;
        })
        me.setState({
          menus: newMenus,
          kkey: newK,
          ref: React.createRef(),
          menuRef: React.createRef()
        }, (xxx) => {
          me.forceUpdate();
        });
      });
    }
  }

  render() {
    let me = this;
    return (
      <div>
        <h1 style={{borderRadius: "7px", position: "fixed", top: "-10px", left: "10x", background: "#fff", padding: "14px", zIndex: 100}}>Меню системы с высоты птичьего полета <span onClick={(ee) => {me.getMenus(null)}}><i className={"fal fa-sync-alt"} /></span></h1>
        <br/><br/><br/><br/>
        {me.state.menus.map((e) => (
          <>
            {!!e && (
              <MenuDeepView
                level={0}
                key={`menu-${e.id}-${me.state.kkey}`}
                prevRef={me.refToMe}
                name={e.name}
                menus={e.menus}
                menu={e}
                top={true}
                updateAll={(ee) => {me.getMenus(null)}}
                setCutVariant={me.setCutVariant}
                ref={e.ref}
              />
            )}
          </>
        ))}
      </div>
    );
  }
}
