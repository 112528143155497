import React from 'react';
import ChatParticipants from "./ChatParticipants"
import ChatMessages from "./ChatMessages"
import ChatSend from "./ChatSend";
import "./chat.scss";
import "./chat_messages.scss";

export default class ChatWidget extends React.Component {
  
  constructor(props) {
    super(props);
    // alert(212312);
    this.state = {
        dialogs: [],
        activeDialog: null
    };
    this.activateChat = this.activateChat.bind(this);
    this.tick = this.tick.bind(this);
  } 


  tick() {
    let me = this;
    let url = this.props.dialogsUrl;
    fetch(url)
    .then(response => response.json())
    .then(dialogs => {
      let prevState = {... this.state};

      if (!!prevState.activeDialog) {
        for (let i in dialogs) {
          if (dialogs[i].id == prevState.activeDialog.id) {
            dialogs[i].isActive = true;
            break;
          }
        }
      }


      this.setState({
        dialogs: dialogs,
      }, () => {
        if (!me.state.activeDialog && dialogs.length > 0) {
          me.activateChat(dialogs[0])
        }
      });

    });
  }



  componentDidMount() {
    this.tick();
    let me = this;
    setTimeout(function() {
      me.timerID = setInterval(
        () => me.tick(), 10000
      );
    }, 500);
  }


  componentWillunmount() {
    clearInterval(this.timerID);
    // this.setState(this.props.content);
  }

  activateChat(dialog) {
    let prevState = {... this.state};
    let dialogIndex = prevState.dialogs.indexOf(dialog);
    
    for (let i in prevState.dialogs) {
      prevState.dialogs[i].isActive = false;
    }

    prevState.dialogs[dialogIndex].isActive = true;
    prevState.activeDialog = dialog;
    this.setState(prevState);
    
  }

  render() {
    let xxx = <div className="chat-widget">
      <div className="active-participant">
        { !!this.state.activeDialog > 0 && 
          <p style={{marginTop: "20px"}}>{`${this.state.activeDialog.name}`}</p>
        }
        { !this.state.activeDialog && 
          <p>Диалог не выбран</p>
        }
      </div>
      <ChatParticipants activateChat={this.activateChat} dialogs={this.state.dialogs} />
      <ChatMessages dialog={this.state.activeDialog} />
      <ChatSend activeDialog={this.state.activeDialog}/>
    </div>;
    return (xxx);
  }
}