import React from 'react';
import CourierOperationZone from "./CourierOperationZone";
import WorkList from "../Workers/Shared/WorkList";

const actionState = {
    title: "Выберите действие",
    actions: [
        {
            id: 1,
            title: "Скан QR",
            command: "{\"command\": \"scan_qr\"}"
        },
        {
            id: 2,
            title: "Связаться с руководителем отдела",
            command: "{\"command\": \"scan_qr\"}"
        }, {
            id: 3,
            title: "Карта",
            command: "{\"command\": \"show_map\"}"
        },
    ]
};

export default class CourierShow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            current_courier: null,
            current_courier_session: null,
            pending_jobs: null,
            previous_jobs: null,
            updateIntervalId: null
        };
        this.updateWorks = this.updateWorks.bind(this);
        this.updateStatus = this.updateStatus.bind(this);
        this.setCourierSession = this.setCourierSession.bind(this);
    }


    componentWillUnmount() {
       clearInterval(this.state.updateRouteListIntervalId);
    }


    componentDidMount() {
        let me = this;
        let prevState = {...this.state};
        prevState.current_courier = this.props.current_courier;
        prevState.updateRouteListIntervalId = setInterval(me.updateWorks, 20_000);
        // alert(prevState.current_courier);
        this.setState(prevState, function () {
            me.updateWorks();
        });

    }


    updateStatus() {
        let me = this;
        let url = "/couriers/current_situation";
        fetch(url).then((e) => {
            return e.json();
        }).then(
            (e) => {
                let prevState = {... this.state};
                me.setState(prevState);
            }
        );
    }

    setCourierSession(courierSession) {
        let prevState = {... this.state};
        prevState.current_courier_session = courier;
        // this.setState(prevState);
        prevState.updateIntervalId = setInterval(this.updateStatus, 4000);
        this.setState(prevState);
    }

    updateWorks() {
        let me = this;
        let url = document.SERVER_URL + "/couriers/current_job";
        fetch(url).then((e) => {
            return e.json();
        }).then((e) => {
            let prevState = {... me.state};
            prevState.pending_jobs = e;
            me.setState(prevState);
        });
    }

    render() {
        let me = this;
        // console.log(this.state.calendar);
        return (
            <div className="courier-show">
                {!me.state.current_courier &&
                    <p>Войдите в свою учетную запись, чтобы начать работу</p>
                }
                <WorkList empty_text="Ожидается маршрутный лист" list={me.state.pending_jobs}/>
                {!!me.state.current_courier &&
                    <div className="worker-show-container" >
                        {/*<WorkList empty_text="Ожидаются работы" list={me.state.pending_jobs} />*/}
                        <CourierOperationZone actionState={actionState} current_courier={me.state.current_courier} />
                        <hr />
                        <br/>
                    </div>
                }
            </div>
        );
    }
}