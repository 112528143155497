import React from 'react';
import Button from '@material-ui/core/Button';
import SelfUpdatedTextField from "../../Shared/SelfUpdatedTextField";
import SelfUpdatedSelectField from "../../Shared/SelfUpdatedSelectField";
import SelfUpdatedSwitchField from "../../Shared/SelfUpdatedSwitchField";
import SelfUpdatedDateTimeField from "../../Shared/SelfUpdatedDateTimeField";


export default class DeliveryFormUser extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      user_employees: [],
      pick_points: [],
      delivery: null,
      first_update: true
    };

    this.updateDelivery = this.updateDelivery.bind(this);
  } 


  updateDelivery() {
    let me = this;
    if (!this.props.delivery) return;
    // alert(JSON.stringify(this.props.delivery));
    let url = `/deliveries/${this.props.delivery.id}.json`;

    if (this.props.current_user) {
      url = `/deliveries/${this.props.delivery.id}.json?user_id=${this.props.current_user.id}`;
    }
    fetch(url).then(
      (e) => {return e.json()}).then( (delivery) => {
        // alert(JSON.stringify(delivery));
        let prevState = {... me.state};
        prevState.delivery = delivery;
        me.setState(prevState);
        url = `/users/user_employees.json?user_id=${me.props.current_user.id}`;
        fetch(url).then(
          (e) => {
            return e.json()
          } 
        ).then(
          (e) => {
            prevState.user_employees = e;
            url = `/pick_points.json?user_id=${me.props.current_user.id}`;
            // me.setState(prevState);
            fetch(url).then(
              (e) => {
                return e.json()
              } 
            ).then(
              (e) => {
                prevState.pick_points = e;
                let firstBoot = prevState.first_update;
                prevState.first_update = false;
                me.setState(prevState);
                if (firstBoot) {
                  return;
                }
                if (!!me.props.updateCallback) {
                  me.props.updateCallback();
                }
              }
            )
          }
        )
    });
  }


  componentDidMount() {
    this.updateDelivery();
  }

  componentWillUnmount() {
  }

  render() {
    let deliveryUpdateUrl = null;
    let deliveryGetUrl = null;
    let me = this;
    if (!!this.state.delivery) {
      deliveryUpdateUrl = `/deliveries/${me.state.delivery.id}/update_field`;
      deliveryGetUrl = `/deliveries/${me.state.delivery.id}.json`;

      if (this.props.current_user) {
        deliveryUpdateUrl += `?user_id=${me.props.current_user.id}`;
        deliveryGetUrl += `?user_id=${me.props.current_user.id}`;
      }  
    }


    let xxx = <div className="delivery-container" style={{background: "#fff", padding: "14px"}}>
      <h3>Доставка</h3>
      { !!this.state.delivery &&
        <SelfUpdatedSwitchField updateCallback={this.updateDelivery} label={"Тип доставки"} variants={[[false,"Курьер"], [true, "Самостоятельно"]]} updateUrl={deliveryUpdateUrl} getUrl={deliveryGetUrl} fieldName="by_myself" />
      }

      { (!!this.state.delivery && !this.state.delivery.by_myself ) && 
        <SelfUpdatedSwitchField updateCallback={this.updateDelivery} label={"Срочность"} variants={[[false,"Не срочно"], [true, "Срочно"]]} updateUrl={deliveryUpdateUrl} getUrl={deliveryGetUrl} fieldName="is_urgent" />
      }

      { (!!this.state.delivery && !this.state.delivery.by_myself ) && 
        <div className="mode-tabs">
          <p className="cool-label">Ответственный сотрудник</p>
          <SelfUpdatedSelectField updateCallback={this.updateDelivery} variant="outlined" className={this.props.className} variants={this.state.user_employees.map((e) => [e.id, `${e.last_name} ${e.first_name} ${e.middle_name}`])} updateUrl={deliveryUpdateUrl} getUrl={deliveryGetUrl} fieldName="user_employee_id" label="Ответственный сотрудник"/>
        </div>
      }

      { (!!this.state.delivery && !this.state.delivery.by_myself && !this.state.delivery.user_employee_id ) && <div className="mode-tabs">
          <p className="cool-label">Имя передающего лица, если это не сотрудник</p>
          <SelfUpdatedTextField updateCallback={this.updateDelivery} variant="outlined" className={this.props.className} updateUrl={deliveryUpdateUrl} getUrl={deliveryGetUrl} fieldName="contact" label="Передающее лицо"/>
        </div>
      }

      { (!!this.state.delivery && !this.state.delivery.by_myself ) && 
        <SelfUpdatedDateTimeField updateCallback={this.updateDelivery} label={"Уточните время забора"} updateUrl={deliveryUpdateUrl} getUrl={deliveryGetUrl} fieldName="pick_date"/>
        }

      { (!!this.state.delivery && !this.state.delivery.by_myself ) && 
        <div className="mode-tabs">
          <p className="cool-label">Точка забора заказа</p>
          <SelfUpdatedSelectField updateCallback={this.updateDelivery} variant="outlined" className={this.props.className} variants={this.state.pick_points.map((e) => [e.id, `${e.name}`])} updateUrl={deliveryUpdateUrl} getUrl={deliveryGetUrl} fieldName="pick_point_id" label="Точка забора"/>
        </div>
      }
      { (!!this.state.delivery && !this.state.delivery.by_myself && !this.state.delivery.pick_point_id) && <div className="mode-tabs">
          <p className="cool-label">Адрес доставки, если не указана точка забора</p>
          <SelfUpdatedTextField updateCallback={this.updateDelivery} variant="outlined" className={this.props.className} updateUrl={deliveryUpdateUrl} getUrl={deliveryGetUrl} fieldName="address" label=""/>
        </div>
      }
    </div>;
    return (xxx);
  }
}