import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';

const fieldName = "cell";
const label = "Номер телефона";

export default class Cell extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      user: null
    }

    this.handleChange = this.handleChange.bind(this);
  } 

  componentDidMount() {
    let me = this;
    fetch('/users/me.json')
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      me.setState({user: data})
    });
  }

  handleChange(e) {
    let me = this;
    
    fetch('/users/update_field', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify({
        new_value: e.target.value,
        changed_field: fieldName
      })
    }).then((response) => {
      return response.json();
    })
    .then((data) => {
      me.setState({user: data})
    });
  }

  goToHref(href) {
    document.location.href = href;
  }

  render() {
    let xxx = <div style={{marginRight: "7px"}}>
      {!this.state.user && 
        <Skeleton variant="rect" width={140} height={54} />
      }

      {!!this.state.user && 
        <TextField  onChange={this.handleChange} value={this.state.user[fieldName]} id="outlined-basic" label={label} variant="outlined" />
      }
    </div>;
    return (xxx);
  }
}



