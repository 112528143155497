import React from 'react';
import Button from '@material-ui/core/Button';
import PickPoint from './PickPoint';
// import SelfUpdatedTextField from "../../Shared/SelfUpdatedTextField";
// import SelfUpdatedSelectField from "../../Shared/SelfUpdatedSelectField";

export default class PickPointsIndex extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      items: []
    }
    // alert(this.props.variants.length);
    this.handleChange = this.handleChange.bind(this);
    this.addPickPoint = this.addPickPoint.bind(this);
    this.updatePickPoints = this.updatePickPoints.bind(this);
  }


  componentDidMount() {
    let me = this;
    this.updatePickPoints();
  }


  updatePickPoints() {
    let me = this;
    let url = "/pick_points";

    if (me.props.current_user) {
      url = `/pick_points?user_id=${me.props.current_user.id}`;
    }

    fetch(url)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      // alert(data);
      let prevState = {... this.state};
      prevState.items = data;
      me.setState(prevState);
    });
  }


  handleChange(newValue) {
  }


  componentWillUnmount() {
  }


  addPickPoint() {
    let me = this;
    fetch("/users/add_pick_point").then((e) => {
      // alert("Add pick_point");  
      me.updatePickPoints();
    });
  }


  render() {
    let me = this;
    let xxx = <div className="white-container">
      <div style={{maxHeight: "80px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", alignItems: "center", width: "100%"}}>
        <h1>Точки забора</h1>
        <Button variant="contained" color="primary" onClick={this.addPickPoint} >Добавить точку забора</Button>
      </div>
      {this.state.items.map( (e, i) => 
        <PickPoint updatePickPoints={this.updatePickPoints} key={`pp-${i}`} content={e} />)}
      <br />
    </div>;
    return (xxx);
  }
}