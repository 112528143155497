import React from 'react';
import $ from 'jquery';
import SelfUpdatedTextField from "../Shared/SelfUpdatedTextField";

export default class PickPoint extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			resource: null
		}
    	this.mapRef = React.createRef();
		this.addPlacemark = this.addPlacemark.bind(this);
		this.initMap = this.initMap.bind(this);
		this.removeMe = this.removeMe.bind(this);
	}


	initMap() {
		let coords = [this.props.content.lat, this.props.content.lon];
	    if (!coords[0] || !coords[1]) {
	      coords = [55.76, 37.64];
	    }
		let myMap = new this.ymaps.Map(`map-for-${this.props.content.id}`, {
		    center: coords,
		    zoom: 7
		});
		// alert(coords);
		this.map = myMap;
		let myPlacemark = new ymaps.Placemark(coords, {
	        hintContent: 'Собственный значок метки',
	        balloonContent: 'Это красивая метка'
	    });
	    this.map.geoObjects.add(myPlacemark)
		// this.addPlacemark(coords);
    }


	componentDidMount() {
	    let me = this;
	    
    	$.getScript( "https://api-maps.yandex.ru/2.1/?apikey=00fb9595-c0ad-45a3-96e6-f9f6633eb015&lang=ru_RU", function( data, textStatus, jqxhr ) {
    		me.ymaps = ymaps;
    		ymaps.ready(me.initMap);
		});	
	    
		
	}
  
	addPlacemark(coords) {
	// alert(coords);

	}

	removeMe() {
		fetch(`/pick_points/${this.props.content.id}/delete`);
		this.props.updatePickPoints();
	}
  
	render() {
		let xxx = <div className="pick-point">
	      <div className="address-column">
	      	<SelfUpdatedTextField label={"Название"} updateUrl={`/pick_points/${this.props.content.id}/update_field`} getUrl={`/pick_points/${this.props.content.id}.json`} fieldName="name"/>
	      	<br/>
	      	<SelfUpdatedTextField label={"Адрес"} updateUrl={`/pick_points/${this.props.content.id}/update_field`} getUrl={`/pick_points/${this.props.content.id}.json`} fieldName="address"/>
	        {/*<p className="pick-point-name">{this.props.content.name}</p>*/}
	        {/*<p>{this.props.content.address}</p>*/}
	      </div>
	      <div id={`map-for-${this.props.content.id}`} ref={this.mapRef} style={{width: "300px", height: "200px"}}></div>
	      <i style={{marginTop: "auto", marginBottom: "auto"}} onClick={this.removeMe} className="fas fa-minus-circle fa-2x" />
		</div>;
		return xxx;
	}
}