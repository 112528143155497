import React from "react";
import * as THREE from '../vendor/three.js';

var OrbitControls = require('three-orbit-controls')(THREE);
var STLLoader = require('three-stl-loader')(THREE);


var loader = new STLLoader();
// https://artlab.skobeltsyn.com//uploads/vb8rr4n8728hc2r3.3.stl
// variable for the ReactDOM to grab

function addShadowedLight( x, y, z, color, intensity, scene ) {
	var directionalLight = new THREE.DirectionalLight( color, intensity );
	directionalLight.position.set( x, y, z );
	scene.add( directionalLight );
	directionalLight.castShadow = true;
	var d = 1;
	directionalLight.shadow.camera.left = -d;
	directionalLight.shadow.camera.right = d;
	directionalLight.shadow.camera.top = d;
	directionalLight.shadow.camera.bottom = -d;
	directionalLight.shadow.camera.near = 1;
	directionalLight.shadow.camera.far = 4;
	directionalLight.shadow.mapSize.width = 1024;
	directionalLight.shadow.mapSize.height = 1024;
	directionalLight.shadow.bias = -0.002;
}

function square(ref, file_url) {
	var scene = new THREE.Scene();
	// scene.background = new THREE.Color( 0xFFFFFF00 );
	var camera = new THREE.PerspectiveCamera( 75, window.innerWidth/window.innerHeight, 0.1, 10 );

	var renderer = new THREE.WebGLRenderer({ alpha: true });
	renderer.setSize( window.innerWidth, window.innerHeight );
	renderer.setClearColor( 0x000000, 0 ); 
	renderer.domElement.style.width = "100%";
	renderer.domElement.style.height = "100%";
	renderer.domElement.style.minWidth = "40vmin";
	renderer.domElement.style.minHeight = "40vmin";

	ref.appendChild( renderer.domElement );
	var controls = new OrbitControls(camera, renderer.domElement);

	// var cubeGeometry = new THREE.BoxGeometry( 1, 1, 1 );
	// var cubeMaterial = new THREE.MeshBasicMaterial( { color: 0xaaffff } );
	// var cylinder = new THREE.Shape();
	// cylinder.vertices.push();
	
	loader.load(`${document.SERVER_URL}${file_url}`, function (geometry) {
	  var material = new THREE.MeshNormalMaterial()
	  var mesh = new THREE.Mesh(geometry, material)
	  scene.add(mesh)
	});

	addShadowedLight( 1, 1, 1, 0xffffff, 1.35 , scene);
	addShadowedLight( -1, -1, -1, 0xffffff, 1.35, scene );

	camera.position.z = 5;

	var render = function () {
		controls.update();
		requestAnimationFrame( render );
		// cube.rotation.x += 0.000001;
		// cube.rotation.y += 0.01;
		renderer.render(scene, camera);
	};

	render();
}

export default class ThreeDViewer extends React.Component {
	constructor(props) {
		super(props);
		this.ref3d = React.createRef();
	}

	componentDidMount() {
		square(this.ref3d.current, this.props.file_url);
	}

	render() {
		return(
			<div style={{background: "transparent", position: "relative", minWidth: "40vmin", minHeight: "40vmin" }}>
				<div ref={this.ref3d}></div>
			</div>
		)
	};
}
