import React from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


export default class ChatAudio extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      message_text: "",
      available : false,
      recording: true,
      playing: false
    };
    this.playZoneRef = React.createRef();;
    this.chunks = [];
    this.startStream = this.startStream.bind(this);
    this.sayError = this.sayError.bind(this);
    this.startStream = this.startStream.bind(this);
    this.onRecordStop = this.onRecordStop.bind(this);
    this.stopRecording = this.stopRecording.bind(this);
    this.playMessage = this.playMessage.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    // this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  onRecordStop(e) {
    let me = this;
    const audio = this.playZoneRef.current;
    this.blob = new Blob(this.chunks, { 'type' : 'audio/ogg; codecs=opus' });
    const audioURL = window.URL.createObjectURL(this.blob);
    audio.src = audioURL;
  }

  startStream(stream) {
    let me = this;
    this.mediaStream = stream;
    this.mediaRecorder = null;
    try {
      me.mediaRecorder = new MediaRecorder(stream);  
      me.mediaRecorder.start();

      me.mediaRecorder.ondataavailable = function(e) {
        // alert("Chunk");
        me.chunks.push(e.data);
      }

      me.mediaRecorder.onstop = me.onRecordStop;
    } catch(e) {this.mediaRecorder = null;}
    
    if (!this.mediaRecorder) {
      this.sayError();
      return;
    }
    let prevState = {... this.state};
    prevState.available = true;
    this.setState(prevState);
    // alert(this.mediaRecorder);
    // alert("Start");
  }


  sayError() {
    alert("Ваш браузер не поддерживает запись аудио");
  }


  componentDidMount() {
    if (navigator.mediaDevices.getUserMedia) {
      const constraints = { audio: true };
      navigator.mediaDevices.getUserMedia(constraints).then(this.startStream, this.sayError);
    } else {
       this.sayError();
    }
  }


  componentWillUnmount() {
    this.mediaStream.getTracks().forEach(function(track) {
      track.stop();
    });
    try {
      this.mediaRecorder.stop();
    } catch(e) {}
  }


  stopRecording() {
    this.mediaRecorder.stop();
    let prevState = {... this.state};
    prevState.recording = false;
    this.setState(prevState);
  }


  playMessage() {
    let prevState = {... this.state};
    let me = this;
    prevState.playing = true;
    this.setState(prevState);
    this.playZoneRef.current.play();
    this.playZoneRef.current.onended = function() {
        let prevState = {... me.state};
        prevState.playing = false;
        me.setState(prevState);
    };
  }


  sendMessage() {
    let me = this;

    let formData = new FormData();
    formData.append("audio_message", me.blob);

    fetch("/users/send_audio_message?dialog_id=" + me.props.dialog.id, {
      method: 'POST',
      body: formData
    }).then(
    response => response.json() // if the response is a JSON object
    ).then(success => {
      // alert(JSON.stringify(success));
      me.props.triggerSendAudio();
    }).catch(
    error => console.log(error) // Handle the error response object
    );
  }

  render() {
    let xxx = <div className="audio-recorder">
      {(!!this.state.available && this.state.recording) && 
        <div className="chunk-btn">
          <i className="far fa-circle pulsing-color" />
          <p>Идет запись</p>
        </div>
      }
     
      {(!!this.state.available && this.state.recording) && 
        <div className="chunk-btn" onClick={this.stopRecording} >
          <i className="far fa-stop"/>
          <p> остановить запись</p>
        </div>
        
      }

      {(!!this.state.available && !this.state.recording) && 
        <div className="chunk-btn" onClick={this.playMessage} >
          <i className={this.state.playing ? "fas fa-play" : "far fa-play"} />
          <p>Воспроизвести</p>
        </div>
      }

      {(!!this.state.available && !this.state.recording) && 
        <div className="chunk-btn" onClick={this.sendMessage} >
          <i className={"fas fa-cloud-upload"}/>
          <p>Отправить</p>
        </div>
      }

      {!this.state.available && 
        <p>Загрузка</p>
      }

      <audio style={{display: "none"}} ref={this.playZoneRef} />
      <p onClick={this.props.triggerSendAudio} className="cancel-audio">Отмена</p>
    </div>;
    return (xxx);
  }
}