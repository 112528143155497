import React from 'react';
import Button from '@material-ui/core/TextField';
import SelfUpdatedSwitchField from "../../Shared/SelfUpdatedSwitchField";

export default class ChooseSpecialtyStage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      specialties: [],
      subMenu: []
    };

    this.updateCallback = this.updateCallback.bind(this);
    this.updateSpecialties = this.updateSpecialties.bind(this);
    this.updateSpecialtiesWithId = this.updateSpecialtiesWithId.bind(this);

  }


  updateSpecialtiesWithId(specId) {
    let url = `/orders/specialties?user_id=${this.props.order.id}`;
    let me = this;
    if (!!specId) {
      url = `/orders/specialties?specialty_id=${specId}&user_id=${this.props.order.id}`;
    }
    if (url.indexOf("https://") == -1) {
      url = document.SERVER_URL + url;
    }
    // alert(url);

    fetch(url).then(
      (e) => {
        return e.json()
      } 
    ).then(
      (e) => {
        // alert(JSON.stringify(e));
        let prevState = {... me.state };
        let subMenu = [];
        for(let specialtyIndex in e) {
          // alert(e[specialtyIndex].id + " == " + me.props.order.specialty_id)
          if (e[specialtyIndex].id == specId || (e[specialtyIndex].is_chosen && e[specialtyIndex].is_main)) {
            subMenu = e[specialtyIndex].subspecialty_items;
          }
        }
        prevState.subMenu = subMenu;
        prevState.specialties = e;
        me.setState(prevState);
      }
    )
  }

  updateSpecialties() {
    let me = this;
    me.updateSpecialtiesWithId(this.props.order.specialty_id);
  }

  componentDidMount() {
    this.updateSpecialties();
  }


  updateCallback(val) {
    let me = this;
    this.props.updateCallback();
    me.updateSpecialtiesWithId(val);  
  }


  render() {
    let me = this;
    let orderSpecialityUpdateUrl = `/orders/${this.props.order.id}/update_field`;
    let orderSpecialityGetUrl = `/orders/${this.props.order.id}.json`;

    if (this.props.current_user) {
      orderSpecialityUpdateUrl += `?user_id=${this.props.current_user.id}`
      orderSpecialityGetUrl += `?user_id=${this.props.current_user.id}`
    }
    // if (orderSpecialityUpdateUrl.indexOf("https://") == -1) {
    //   orderSpecialityUpdateUrl = document.SERVER_URL + orderSpecialityUpdateUrl;
    // }

    // if (orderSpecialityGetUrl.indexOf("https://") == -1) {
    //   orderSpecialityGetUrl = document.SERVER_URL + orderSpecialityGetUrl;
    // }

    let xxx = 
      <>
        <h3 className="hide-print">Специализация</h3>
        <SelfUpdatedSwitchField current_user={me.props.current_user} updateCallback={me.updateCallback} label={""} variants={this.state.specialties.map((e) => [e.id, e.name])} updateUrl={orderSpecialityUpdateUrl} getUrl={orderSpecialityGetUrl} fieldName="specialty_id" />
        { me.state.subMenu.length > 0 && 
          <SelfUpdatedSwitchField key={me.state.subMenu.map((e) => `${e.id}-` ).join("--")} current_user={me.props.current_user} updateCallback={me.updateCallback} label={""} variants={me.state.subMenu.map((e) => [e.pointed_order_specialty ? e.pointed_order_specialty.id : null, e.pointed_order_specialty ? e.pointed_order_specialty.name : 'Специальность удалена'])} updateUrl={orderSpecialityUpdateUrl} getUrl={orderSpecialityGetUrl} fieldName="specialty_id" />
        }
      {/* <SelfUpdatedSwitchField current_user={me.props.current_user} updateCallback={this.props.updateCallback} label={""} variants={this.state.specialties.map((e) => [e.id, e.name])} updateUrl={orderSpecialityUpdateUrl} getUrl={orderSpecialityGetUrl} fieldName="specialty_id" /> */}
      </>
    ;
    return (xxx);
  }
}