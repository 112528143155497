import React from 'react'
import SideMenu from "./SideMenu";


export default class AdminUserLeftMenu extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      // menu: [
      //   {
      //     title: "Скан QR",
      //     action: {
      //       href: "/users/qr_code"
      //     }
      //   },
      //   {
      //     title: "Оформить заказ",
      //     action: {
      //       href: "/orders/new"
      //     }
      //   },
      //   {
      //     title: "Вызвать курьера",
      //     action: {
      //       href: "/users/new_delivery"
      //     }
      //   },
      //   {
      //     title: "Сообщения",
      //     action: {
      //       href: "/users/messages"
      //     }
      //   }
      // ]  
      menu: [

      ]
    };

    
  }

  componentDidMount() {
    let me = this;
    let url = "/flow/get_left_menu";
    fetch(url).then((e) => { return e.json();}).then( (e) => {
      let prevState = {... me.state};
      prevState.menu = e;
      me.setState(prevState);
    });
  }


  render() {
    let me = this;
    let xxx = <SideMenu key={`${me.state.menu.length}`} menu={me.state.menu}/>;
    return (xxx);
  }
}

