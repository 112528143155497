import React from 'react';
import "./date-time-picker.scss";
import TextField from '@material-ui/core/TextField';

function zeroPad(num, places) {
  var zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join("0") + num;
}

export default class SelfUpdateDateTimeField extends React.Component {
	
	constructor(props) {
	    super(props);
	    const now = new Date();
	    const currentMonth = now.getMonth();
	    const currentYear = now.getFullYear();
	    this.state = {
			resource: null,
			currentDate: new Date(),
			days: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
			months: [
				'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
				'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь',
			],
			weekDays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
			lastMonth: 11,
			month: 0,
			nextMonth: 1,
			year: 0,
			currentMonth: currentMonth,
			day: -1,
			currentYear: currentYear,
			time: [0,0],
			calendar: [
				{ id: 'week-1', data: [0, 0, 0, 0, 0, 0, 0] },
				{ id: 'week-2', data: [0, 0, 0, 0, 0, 0, 0] },
				{ id: 'week-3', data: [0, 0, 0, 0, 0, 0, 0] },
				{ id: 'week-4', data: [0, 0, 0, 0, 0, 0, 0] },
				{ id: 'week-5', data: [0, 0, 0, 0, 0, 0, 0] },
				{ id: 'week-6', data: [0, 0, 0, 0, 0, 0, 0] },
			],
			holidays: [],
			holiday: '',
	    };

	    this.previousCalendar = this.previousCalendar.bind(this);
	    this.nextCalendar = this.nextCalendar.bind(this);
	    this.changeTime = this.changeTime.bind(this);
	    this.setDay = this.setDay.bind(this);
	    this.setDate = this.setDate.bind(this);
	    this.handleChange = this.handleChange.bind(this);
	    this.handleClose = this.handleClose.bind(this);
	    this.changeHours = this.changeHours.bind(this);
		this.changeMinutes = this.changeMinutes.bind(this);
	    // this.setState({
	    //   currentMonth,
	    //   currentYear,
	    //   month: currentMonth,
	    //   year: currentYear,
	    // });

	    // this.setCalendar(new Date(currentYear, currentMonth, 1));
	}


	handleClose() {
	  let prevState = {... this.state};
	  prevState.successOpen = false
	  this.setState(prevState);
	}


	setDate(date) {
	  	let prevState = {... this.state};
	  	prevState.currentDate = date;
	  	prevState.time = [date.getHours(), date.getMinutes()];
	  	this.setState(prevState);
	}


	componentWillMount() {
	    const now = new Date();
	    const currentMonth = now.getMonth();
	    const currentYear = now.getFullYear();

	    // this.setState({
	    //   currentMonth,
	    //   currentYear,
	    //   month: currentMonth,
	    //   year: currentYear,
	    // });

	    this.setCalendar(new Date(currentYear, currentMonth, 1));
	}


	componentDidMount() {
	    let me = this;
	}


	handleChange(newValue) {
	    let me = this;

	    if (me.props.activeCallback) {
	    	me.props.activeCallback(newValue);
	    	return;
	    }
	}


	setDay(day) {
		let me = this;
	    // alert(JSON.stringify(day));
	    let newDateValue = new Date(this.state.currentYear, day.month, day.value, this.state.time[0], this.state.time[1]);
	    let prevState = {... this.state};
	    prevState.currentDate = newDateValue;
	    this.setState(prevState);
	    // this.handleChange(newDateValue);
	    // alert(newDateValue);
	    if (me.props.activeCallback) {
	    	me.props.activeCallback(newDateValue);
	    	return;
	    }
	}

	changeTime(x) {
		let me = this;

	    let prevState = {... this.state};
	    let time = prevState.time;
	    let newH = (time[0]+x[0]) % 24;
	    let newM = (time[1]+x[1]) % 60;
	    if (newH < 0) {
	      newH = 24 + newH;
	    }
	    if (newM < 0) {
	      newM = 60 + newM;
	    }
	    let newTime = [newH, newM];
	    prevState.time = newTime;
	    let newDateValue = new Date(this.state.currentYear, this.state.currentDate.getMonth(), this.state.currentDate.getDate(), newTime[0], newTime[1]);
	    prevState.currentDate = newDateValue;
	    this.setState(prevState);

	    if (me.props.activeCallback) {
	    	me.props.activeCallback(newDateValue);
	    	return;
	    }
	    // this.handleChange(newDateValue);
	}

	setMonth(date) {
	    const month = date.getMonth();
	    const lastMonth = month === 0 ? 11 : month - 1;
	    const nextMonth = month === 11 ? 0 : month + 1;

	    this.setState({
	      lastMonth,
	      month,
	      nextMonth,
	    });

	    return { lastMonth, month, nextMonth };
	}

	setCalendar(date) {
	    const { lastMonth, month, nextMonth } = this.setMonth(date);
	    const year = date.getFullYear();
	    const weekday = date.getDay();
	    const days = this.checkLeapYear(year);
	    let nextMonthDay = 0;

	    const firstWeek = this.state.calendar[0].data.map((day, index) => {
	      let holiday = '';
	      if (index < weekday) {
	        const value = (days[lastMonth] - (weekday - index)) + 1;
	        return {
	          value,
	          class: 'day--soft',
	          month: lastMonth,
	        };
	      }
	      const value = (index - weekday) + 1;
	      return {
	        value: (index - weekday) + 1,
	        class: '',
	        month,
	      };
	    });
	    const secondWeek = this.state.calendar[0].data.map((day, index) => {
	      const value = firstWeek[6].value + index + 1;
	      return {
	        value,
	        class: '',
	        month,
	      };
	    });
	    const thirdWeek = this.state.calendar[0].data.map((day, index) => {
	      const value = secondWeek[6].value + index + 1;
	      return {
	        value,
	        class: '',
	        month,
	      };
	    });
	    const forthWeek = this.state.calendar[0].data.map((day, index) => {
	      const value = thirdWeek[6].value + index + 1;
	      return {
	        value,
	        class: '',
	        month,
	      };
	    });
	    const fifthWeek = this.state.calendar[0].data.map((day, index) => {
	      if (forthWeek[6].value + index + 1 > days[month]) {
	        nextMonthDay += 1;
	        return {
	          value: nextMonthDay,
	          class: 'day--soft',
	          month: nextMonth,
	        };
	      }
	      const value = forthWeek[6].value + index + 1;
	      return {
	        value,
	        class: '',
	        month,
	      };
	    });
	    const sixthWeek = this.state.calendar[0].data.map((day, index) => {
	      if (fifthWeek[6].value + index + 1 > days[month] || fifthWeek[6].value < 10) {
	        nextMonthDay += 1;
	        return {
	          value: nextMonthDay,
	          class: 'day--soft',
	          month: nextMonth,
	        };
	      }

	      const value = fifthWeek[6].value + index + 1;
	      return {
	        value,
	        class: '',
	        month,
	      };
	    });

	    this.setState({
	      month,
	      year,
	      calendar: [
	        { id: 'week-1', data: firstWeek },
	        { id: 'week-2', data: secondWeek },
	        { id: 'week-3', data: thirdWeek },
	        { id: 'week-4', data: forthWeek },
	        { id: 'week-5', data: fifthWeek },
	        { id: 'week-6', data: sixthWeek },
	      ],
	    });
	}

	checkLeapYear(year) {
	    let days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
	    if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
	      days = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
	    }
	    this.setState({
	      days,
	    });
	    return days;
	}

	previousCalendar() {
	    const month = this.state.month !== 0 ? this.state.month - 1 : 11;
	    const year = this.state.month !== 0 ? this.state.year : this.state.year - 1;
	    this.setCalendar(new Date(year, month, 1));
	}

	nextCalendar() {
	    const month = this.state.month !== 11 ? this.state.month + 1 : 0;
	    const year = this.state.month !== 11 ? this.state.year : this.state.year + 1;
	    this.setCalendar(new Date(year, month, 1));
	}

	changeHours(h) {
		let me = this;
		let prevState = {... this.state};
		let time = prevState.time;
		let newH = (h) % 24;
		let newM = (time[1]) % 60;
		if (newH < 0) {
			newH = 24 + newH;
		}
		if (newM < 0) {
			newM = 60 + newM;
		}
		let newTime = [newH, newM];
		prevState.time = newTime;
		let newDateValue = new Date(this.state.currentYear, this.state.currentDate.getMonth(), this.state.currentDate.getDate(), newTime[0], newTime[1]);
		prevState.currentDate = newDateValue;
		this.setState(prevState);

		if (me.props.activeCallback) {
			me.props.activeCallback(newDateValue);
			return;
		}
	}

	changeMinutes(m) {
		let me = this;
		let prevState = {... this.state};
		let time = prevState.time;
		let newH = (time[0]) % 24;
		let newM = (m) % 60;
		if (newH < 0) {
			newH = 24 + newH;
		}
		if (newM < 0) {
			newM = 60 + newM;
		}
		let newTime = [newH, newM];
		prevState.time = newTime;
		let newDateValue = new Date(this.state.currentYear, this.state.currentDate.getMonth(), this.state.currentDate.getDate(), newTime[0], newTime[1]);
		prevState.currentDate = newDateValue;
		this.setState(prevState);

		if (me.props.activeCallback) {
			me.props.activeCallback(newDateValue);
			return;
		}
	}


	render() {
		let me = this;
	    // console.log(this.state.calendar);
	    return (
	    	<div className="date-time-field">
	    		<div style={{display: "flex", flexDirection: "row", alignContent: "center", alignItems: "center"}}>
					<div className="calendar">
						<div className="calendar-header">
						  <span className="button-container button-container--left">
						    <button onClick={this.previousCalendar} className="button-content button-content--left" />
						  </span>
						  <span className="calendar-header-date">{`${this.state.year} ${this.state.months[this.state.month]}`}</span>
						  <span className="button-container button-container--right">
						    <button onClick={this.nextCalendar} className="button-content button-content--right" />
						  </span>
						</div>
						<div className="week">
						  {this.state.weekDays.map(weekDay => <div key={weekDay} className="weekday">{weekDay}</div>)}
						</div>
						{this.state.calendar.map(week =>
						  <div key={week.id} className="week">
						    {week.data.map(day =>
						      <div
						        key={`${day.month}${day.value}`}
						        className={`day ${day.class} ${(this.state.currentDate.getDate() == day.value && this.state.currentDate.getMonth() == day.month) ? "active-day" : ""}`} onClick={(e) => {this.setDay(day)}}>
						        {day.value < 10 && day.value !== ' ' ? `0${day.value}` : day.value}
						      </div>,
						    )}
						  </div>,
						)}
					</div>
					<div className="time-input">
				      <div className="time-place">
				        <i className="fal fa-angle-up fa-2x" onClick={(e) => this.changeTime([1,0])} />
				        {/*<p>{zeroPad(this.state.time[0],2)}</p>*/}
					    <TextField type="number" onChange={ (x) => this.changeHours(x) } value={this.state.time[0]} inputProps={{min: 0, style: { textAlign: 'center' }}} style={{maxWidth: "49px", margin: "7px"}}/>
				        <i className="fal fa-angle-down fa-2x" onClick={(e) => this.changeTime([-1,0])} />
				      </div>
				      <p>:</p>
				      <div className="time-place">
				        <i className="fal fa-angle-up fa-2x" onClick={(e) => this.changeTime([0,1])} />
				        {/*<p>{zeroPad(this.state.time[1],2)}</p>*/}
					  	<TextField type="number" onChange={ (x) => this.changeMinutes(x) } value={this.state.time[1]} inputProps={{min: 0, style: { textAlign: 'center' }}} style={{maxWidth: "49px", margin: "7px"}}/>
				        <i className="fal fa-angle-down fa-2x" onClick={(e) => this.changeTime([0,-1])} />
				      </div>
				    </div>
			    </div>
			</div>
	    );
	}
}
