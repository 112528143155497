import React from 'react'
import SideMenu from "./SideMenu";


export default class UserLeftMenu extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      menu: [
        {
          title: "Скан QR",
          action: {
            href: "/users/qr_code"
          }
        },
        {
          title: "Оформить заказ",
          action: {
            href: "/orders/new"
          }
        },
        {
          title: "Вызвать курьера",
          action: {
            href: "/users/new_delivery"
          }
        },
        {
          title: "Сообщения",
          action: {
            href: "/users/messages"
          }
        }
      ]    
    };

    
  } 


  render() {
    let xxx = <SideMenu menu={this.state.menu}/>;
    return (xxx);
  }
}

