import React from "react";
import ReactJson from 'react-json-view';


export default class JSONViewer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {}
	}

	componentDidMount() {
	}

	render() {
		let xxx = <ReactJson src={this.props.data} collapsed={true}/>
		return xxx;
	}
}