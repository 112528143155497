import React from 'react';
import SelfUpdatedTextField from "../../Shared/SelfUpdatedTextField";
import SelfUpdatedSelectField from "../../Shared/SelfUpdatedSelectField";
import SelfUpdatedCheckField from "../../Shared/SelfUpdatedCheckField";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';



export default class UserEmployee extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      removeDialogOpen: false
    };

    this.removeMe = this.removeMe.bind(this);
    this.destroyOk = this.destroyOk.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }


  removeMe() {
    let prevState = {... this.state};
    prevState.removeDialogOpen = true;

    this.setState(prevState);
  }

  destroyOk() {
    fetch(`/user_employees/${this.props.content.id}/delete`).then( (e) => {
      this.closeDialog();
      this.props.updateIndex();
    });
  }

  closeDialog() {
    let prevState = {... this.state};
    prevState.removeDialogOpen = false;

    this.setState(prevState);
  }



  componentDidMount() {
  }


  componentWillUnmount() {
  }


  render() {
    let xxx = <div className="user-employee">
      <div style={{width: "100%", display: "flex", flexDirection: "row", alignContent: "center", alignItems: "center", justifyContent: "space-between"}}>
        <SelfUpdatedSelectField variants={["Ортопед","Хирург","Ортодонт","Комбинированный протез"]} updateUrl={`/user_employees/${this.props.content.id}/update_field`} getUrl={`/user_employees/${this.props.content.id}.json`} fieldName="profession" multiple={true} label="Специализация"/>
        <div style={{width: "100%", display: "flex"}}></div>
        <SelfUpdatedCheckField updateUrl={`/user_employees/${this.props.content.id}/update_field`} getUrl={`/user_employees/${this.props.content.id}.json`} fieldName="is_default" label="Назначать по-умолчанию" />
      </div>
      <br />
      <div className="employee-fields">
        <SelfUpdatedTextField updateUrl={`/user_employees/${this.props.content.id}/update_field`} getUrl={`/user_employees/${this.props.content.id}.json`} fieldName="last_name" label="Фамилия" />
        <SelfUpdatedTextField updateUrl={`/user_employees/${this.props.content.id}/update_field`} getUrl={`/user_employees/${this.props.content.id}.json`} fieldName="first_name" label="Имя" />
        <SelfUpdatedTextField updateUrl={`/user_employees/${this.props.content.id}/update_field`} getUrl={`/user_employees/${this.props.content.id}.json`} fieldName="middle_name" label="Отчество" />

        <i style={{marginBottom: "7px"}} onClick={this.removeMe} className="fas fa-minus-circle fa-2x" />
      </div>

      <Dialog
        open={this.state.removeDialogOpen}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Удалить сотрудника?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Вы собираетесь удалить сотрудника {this.props.content.last_name} {this.props.content.first_name} {this.props.content.middle_name}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.closeDialog} color="primary">
            Отмена
          </Button>
          <Button onClick={this.destroyOk} color="primary">
            Удалить
          </Button>
          
        </DialogActions>
      </Dialog>
    </div>;
    return (xxx);
  }
}