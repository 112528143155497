import React from 'react';
import Button from '@material-ui/core/Button';

export default class AwaitingStage extends React.Component {

	constructor(props) {
	    super(props);
	    this.state = {
	      specialties: [],
	    };

	    this.updateCallback = this.updateCallback.bind(this);
	    this.startScaner = this.startScaner.bind(this);
	    this.moveToFolder = this.moveToFolder.bind(this);
	}


	render() {
		let xxx = (
			<div style={{alignItems: "center", alignContent: "center", display: "flex", flexDirection: "row"}}>
				<p style={{margin: 0}}>Примерка</p>
				<Button style={{marginLeft: "77px"}}>Подтвердить</Button>
			</div>
		);
		return 
	}

}