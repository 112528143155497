import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import $ from "jquery";

async function postOrderData(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return await response.json(); // parses JSON response into native JavaScript objects
}


export default class SelfUpdatedFileField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            files: [],
            showFileUrl: null,
            counter: 0
        };

        this.inputRef = React.createRef();
        this.addFiles = this.addFiles.bind(this);
        this.addAttachment = this.addAttachment.bind(this);
        this.updateCallback = this.updateCallback.bind(this);
        this.upload = this.upload.bind(this);
    }

    addAttachment(x) {
        let me = this;
        let prevState = this.state;
        prevState.files.push(x);
        this.setState(prevState, (e) => {
            me.upload(x);
        });
    }


    upload(x) {
        let me = this;
        let formData = new FormData();
        formData.append(me.props.parentModelFieldName, me.props.parentId);
        formData.append(me.props.fileUrlFieldName, x.blob, x.name);
        if (me.props.current_user) {
            formData.append("user_id", me.props.current_user.id);
        }
        // alert(formData);
        // alert(JSON.stringify(x));
        let url = `${document.SERVER_URL}/${me.props.updateUrl}`;
        $.ajax({
            url: url,
            data: formData,
            dataType: "json",
            type: 'POST',
            contentType: false, // NEEDED, DON'T OMIT THIS (requires jQuery 1.6+)
            processData: false, // NEEDED, DON'T OMIT THIS
            success: function(res) {
                let prevState = {... me.state};
                prevState.counter += 1;
                let indexOf = prevState.files.indexOf(x);
                // alert(indexOf);
                prevState.files[indexOf].loading = false;
                prevState.files[indexOf][me.props.fileUrlFieldName] = res[me.props.fileUrlFieldName];
                me.setState(prevState);
            },
            error: function(x) {
            }
        });
    }

    addFiles () {
        this.inputRef.current.click();
    }

    componentDidMount() {
        let me = this;
        if (!this.inputRef.current) return;
        fetch(me.props.getUrl).then((e) => {return e.json()}).then(
            (e) => {
                let prevState = {... me.state};
                prevState.files = e;
                prevState.counter += 1;
                me.setState(prevState, function() {
                    this.inputRef.current.onchange = function(e) {
                        // alert(2);
                        if (0 == me.inputRef.current.files) return;
                        let files = me.inputRef.current.files;
                        // let fileReaders = [];

                        for (let i = 0; i < files.length; i++) {
                            let f = files[i];
                            // me.add_attachment([{name: f.name, blob: f}]);
                            me.addAttachment({name: f.name, blob: f, loading: true});
                            // let fileReader = new FileReader();
                            // fileReader.onloadend = function (e) {
                            //   var blob = e.target.result;
                            //   // me.props.add_attachment([{name: f.name, blob: blob}]);
                            //   me.addAttachment({name: f.name, blob: blob, loading: true});
                            // };
                            // fileReader.readAsArrayBuffer(f);
                        }
                    }
                });
            }
        )
    }

    componentDidUpdate() {
        let me = this;
        if (!this.inputRef.current) return;

        this.inputRef.current.onchange = function(e) {
            // alert(2);
            if (0 == me.inputRef.current.files) return;
            let files = me.inputRef.current.files;
            // let fileReaders = [];

            for (let i = 0; i < files.length; i++) {
                let f = files[i];
                // me.add_attachment([{name: f.name, blob: f}]);
                me.addAttachment({name: f.name, blob: f, loading: true});
                // let fileReader = new FileReader();
                // fileReader.onloadend = function (e) {
                //   var blob = e.target.result;
                //   // me.props.add_attachment([{name: f.name, blob: blob}]);
                //   me.addAttachment({name: f.name, blob: blob, loading: true});
                // };
                // fileReader.readAsArrayBuffer(f);
            }
        }
    }


    componentWillUnmount() {
        if (!this.inputRef.current) return;
        this.inputRef.current.removeAttribute("onchange");
    }

    updateCallback(val) {
        let me = this;
        this.props.updateCallback();
        let prevState = {... this.state};
        this.setState(prevState);
        // me.updateSpecialtiesWithId(val);
    }


    handleClose() {
        let prevState = {... this.state};
        prevState.showFileUrl = null;
        this.setState(prevState);
    }

    render() {
        let me = this;
        let xxx = <div>
            <h3 className="hide-print">{me.props.label}</h3>
            <div style={{display: "flex", flexDirection: "row", alignContent: "center", alignItems: "center"}}>
                <p><i onClick={this.addFiles} style={{margin: "7px"}}  className="fal fa-paperclip fa-2x"></i>&nbsp;Добавить файл</p>
                <input multiple={true} ref={this.inputRef} type="file" style={{display: "none"}} />
            </div>
            <div className={"hide-print"} key={`files-state-${me.state.counter}`}>
                { me.state.files.map( (e, eId) =>
                    <div key={`uploadedfile-${eId}`} style={{display: "flex"}}>
                        {/*<p onClick={(ev) => { me.openFile(e.file_url); }}>{e.name} {e.file_url}</p>*/}
                        <div style={{fontSize: "18px", display: "flex", flexDirection: "row", alignItems: "center", alignContent: "center"}}>
                            <p>{e[me.props.fileUrlFieldName]}</p>
                            <a style={{marginLeft: "14px"}} href={e[me.props.fileUrlFieldName]} target={"_blank"}><i class="fal fa-cloud-download fa-3x"></i></a>
                            {e.loading &&
                            <CircularProgress size={40}/>
                            }
                        </div>
                    </div>
                )}
            </div>
        </div>;

        return (xxx);
    }
}