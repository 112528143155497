import React from 'react';
import SelfUpdatedAsyncSelectField from "../Shared/SelfUpdatedAsyncSelectField";

export default class NewDeliveryForAdmin extends React.Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        let me = this;

        let url = "/admin/deliveries/new?current_user_id="+e;
        if (me.props.is_rework_of_user_order_id)
            url += "&is_rework_of_user_order_id="+me.props.is_rework_of_user_order_id;
        document.location.href = url;
    }

    render() {
        let me = this;
        let xxx = (
            <SelfUpdatedAsyncSelectField updateCallback={me.handleChange} placeholder="Укажите имя клиента" label="Укажите имя клиента" fieldName="some_123" options_url="/users/search" updateUrl="/users/search" />
        );
        return xxx;
    }
}