import React from "react";
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";

class UserOrderLink extends  React.Component {
    render() {
        let me = this;
        return <a href={document.SERVER_URL + "/admin/user_orders/" + me.props.id}>Окрыть</a>
    }
}

export default class UserOrdersTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            columns: [],
            updateId: null,
            actions: [
                {
                    icon: "UserOrderLink",
                    tooltip: 'Перейти',
                    onClick: (event, rowData) => {
                        let url =`/admin/user_orders/${rowData.id}`;
                        window.open(url, '_blank').focus();

                    }
                }
            ]
        }
        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData();
        let me = this;
        setInterval(me.getData, 5000);
    }

    restoreColumns(data) {
        let keys = [];
        for(let i in data) {
            let obj = data[i];
            let objKeys = Object.keys(obj);
            for (let j in objKeys) {
                if (keys.indexOf(objKeys[j]) == -1) {
                    keys.push([objKeys[j], obj[objKeys[j]]]);
                }
            }
        }
        return keys.map((e) => {
            return {
                title: e[1],
                field: e[0],
                cellStyle: (e, rowData) => {
                    if (!rowData.first_login) {
                        return { color: "red" };
                    }
                },
            };
        })
    }

    getData() {
        let me = this;
        fetch(document.SERVER_URL + "/orders/admin_search").then((e) => {
            return e.json();
        }).then((e) => {
            let prevState = {... me.state};
            prevState.data = e.data;
            prevState.columns = me.restoreColumns(e.columns);
            me.setState(prevState);
        });
    }

    onPageChange() {
        console.log("ok");
    }

    render() {
        let me = this;
        return (
            <div style={{ maxWidth: "100%" }}>
                {me.state.data.length == 0 &&
                    <p>Загрузка</p>
                }
                {me.state.data.length > 0 &&
                    <MaterialTable
                        options={{
                            paging:true,
                            pageSize:126,       // make initial page size
                            emptyRowsWhenPaging: true,   //to make page size fix in case of less data rows
                            pageSizeOptions:[6,12,20,50,100],    // rows selection options
                        }}
                        localization={{
                            toolbar: {
                                searchTooltip: 'Поиск',
                                searchPlaceholder: "Поиск"
                            },
                            header: {
                                actions: ''
                            }
                        }}
                        columns={me.state.columns}
                        data={me.state.data}
                        actions={me.state.actions}
                        onPageChange={me.onPageChange}
                        title="Заказы"
                        components={{
                            Action: props => (
                                <Button
                                    onClick={(event) => props.action.onClick(event, props.data)}
                                    color="primary"
                                    variant="contained"
                                    style={{textTransform: 'none'}}
                                    size="small"
                                >
                                    {props.data.button_name ? props.data.button_name : props.action.tooltip}
                                </Button>
                            ),
                            Cell: props => (
                                <p
                                    style={{textTransform: 'none'}}
                                >
                                    {props.data.button_name ? props.data.button_name : props.action.tooltip}
                                </p>
                            )
                        }}
                    />
                }
            </div>
        );
    }
}