import React from 'react';
import SelfUpdatedAsyncSelectField from "../Shared/SelfUpdatedAsyncSelectField";
import OrderCreate from "../Order/OrderCreate";
import $ from "jquery";

export default class NewOrderForAdminInApp extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    	current_user: null,
	    	new_order: null
	    }
	    this.handleChange = this.handleChange.bind(this);
	}

	handleChange(e) {
		let me = this;
		let prevState = {... this.state};
		prevState.current_user = {id: e};
		let data = {
			user_id: e,
			admin_id: me.props.admin_id
		}

		let url = document.SERVER_URL + "/orders/find_or_create_from_app";
		$.ajax({
			url: url,
			method: "post",
			data: data,
			success: function(res) {
				prevState.new_order = res;
				// alert(JSON.stringify(res));
				me.setState(prevState);
			}
		});

		
		// document.location.href = "/admin/user_orders/new?current_user_id="+e;
	}


	render() {
		let me = this;
		let xxx = (
			<>
				{ !this.state.current_user &&
					<SelfUpdatedAsyncSelectField updateCallback={me.handleChange} placeholder="Укажите имя клиента" label="Укажите имя клиента" fieldName="some_123" options_url="/users/search" updateUrl="/users/search" />
				}
				{ !!this.state.current_user && !!this.state.new_order && 
					<OrderCreate current_user={this.state.current_user} order={this.state.new_order} />
				}
			</>

		);
		return xxx;
	}
}