import React from 'react'
import SideMenu from "./SideMenu";

export default class AdminUserRightMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      menu: [

      ]  
    };

  }


  componentDidMount() {
    let me = this;
    let url = "/flow/get_right_menu";
    fetch(url).then((e) => { return e.json();}).then( (e) => {
      let prevState = {... me.state};
      prevState.menu = e;
      me.setState(prevState);
    });
  }


  render() {
    let me = this;
    let xxx = <SideMenu key={`${me.state.menu.length}`} menu={me.state.menu}/>;
    return (xxx);
  }
}