import React from 'react';
import TextField from '@material-ui/core/TextField';
import SelfUpdatedTextField from "../../Shared/SelfUpdatedTextField";
import SelfUpdatedSelectField from "../../Shared/SelfUpdatedSelectField";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import "./user-employee.scss";

export default class ChooseEmployeeStage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user_employees: [],
      newEmployeeDialogOpen: false,
      newEmployeeCreds: {}
    };

    this.updateCallback = this.updateCallback.bind(this);
    this.updateEmployees = this.updateEmployees.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.createEmployee = this.createEmployee.bind(this);
    this.openNewEmployeeDialog = this.openNewEmployeeDialog.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  updateEmployees(callback) {
    let me = this;
    let url = "/users/user_employees.json";

    if (me.props.current_user) {
      url = `/users/user_employees.json?user_id=${me.props.current_user.id}`;
    }
    
    // alert(url);

    if (url.indexOf("https://") == -1) {
      url = document.SERVER_URL + url;
    }

    fetch(url).then(
      (e) => {
        return e.json()
      } 
    ).then(
      (e) => {
        // alert(JSON.stringify(e));
        let prevState = {... me.state };
        prevState.user_employees = e;
        if (callback)
          me.setState(prevState, callback);
        else
          me.setState(prevState);
      }
    )
  }

  componentDidMount() {
    this.updateEmployees(null);
  }

  updateCallback(val) {
    if (!!this.props.updateCallback) {
      this.props.updateCallback({ChooseEmployeeStage: val});
    }
  }

  handleClose() {
    let prevState = {... this.state};
    let me = this;
    prevState.newEmployeeDialogOpen = false;
    this.setState(prevState, function(xxx) {
      me.updateEmployees(null);
    });

  }

  createEmployee() {
    let me = this;

    let data = {
      employee: this.state.newEmployeeCreds
    };

    let url = `/user_employees/create.json?order_id=${me.props.order.id}`;
    if (me.props.current_user) {
      url += `&user_id=${me.props.current_user.id}`;
    }

    if (url.indexOf("https://") == -1) {
      url = document.SERVER_URL + url;
    }
    // alert(JSON.stringify(data));
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(
      (e) => {
        me.handleClose();
        me.updateEmployees(me.props.updateCallback);
        return e.json()
      } 
    );
    // alert(JSON.stringify(data));
  }


  openNewEmployeeDialog() {
    let prevState = {... this.state};
    prevState.newEmployeeDialogOpen = true;
    this.setState(prevState);
  }


  handleChange(field, e) {
    // alert(e.target.value);
    // alert(field, e.target.value);
    let prevState = {... this.state };
    prevState.newEmployeeCreds[field] = e.target.value;
    this.setState(prevState);
    if (!!this.props.updateCallback) {
      this.props.updateCallback();
    }
  }


  render() {
    let me = this;
    let updateEmployeeUrl = `/orders/${this.props.order.id}/update_field`;
    let getEmployeeUrl = `/orders/${this.props.order.id}.json`;

    if (this.props.current_user) {
      updateEmployeeUrl += `?user_id=${this.props.current_user.id}`;
      getEmployeeUrl += `?user_id=${this.props.current_user.id}`;
    }

    let xxx = 
      <div>
        <div style={{display: "flex", flexDirection: "row", alignContent: "center", alignItems: "center"}}>
          <SelfUpdatedSelectField current_user={me.props.current_user} updateCallback={me.updateCallback} variant="outlined" className={me.props.className} variants={me.state.user_employees.map((e) => [e.id, `${e.last_name} ${e.first_name} ${!!e.middle_name ? e.middle_name : ''}`])} updateUrl={updateEmployeeUrl} getUrl={getEmployeeUrl} fieldName="user_employee_id" label="Врач"/>
          <Button variant="outlined" onClick={me.openNewEmployeeDialog}>Создать&nbsp;нового&nbsp;сотрудника</Button>
        </div>
        <Dialog onClose={me.handleClose} open={me.state.newEmployeeDialogOpen}>
          <DialogContent style={{padding: "21px"}}>
            <h3>Новый сотрудник</h3>
            <br/>
            <TextField onChange={(e) => { me.handleChange("last_name", e); }} label="Фамилия" /><br/>
            <TextField onChange={(e) => { me.handleChange("first_name", e); }} label="Имя" /><br/>
            <TextField onChange={(e) => { me.handleChange("middle_name", e); }} label="Отчество" /><br/>
            <TextField onChange={(e) => { me.handleChange("cell", e); }} label="Номер телефона" /><br/>
            <TextField onChange={(e) => { me.handleChange("email", e); }} label="Email" /><br/>
            <div style={{marginTop: "21px", display: "flex", flexDirection: "column" , borderBottom: "1px solid #858585", paddingBottom: "2px"}}>
              <label>Дата Рождения</label>
              <input style={{border: "none"}} type={"date"} onChange={(e)  => { me.handleChange("birthdate", e); }} />
            </div>
            <br/>
            <br/>
            <br/>
          </DialogContent>
          <DialogActions>
            <Button onClick={me.handleClose}>Отмена</Button>
            <Button onClick={me.createEmployee} color="primary">Создать</Button>
          </DialogActions>
        </Dialog>
      </div>
    ;
    return (xxx);
  }
}