import React from 'react';
import "./toothtask.scss";

export default class ToothTaskShow extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      processedContent: []
    };
  }

  htmlizeText(inputText) {
    if (!inputText) return;
    let htmlOutput = "<ul>";
    
    let data = inputText.split("\n");
    for (let i in data) {
      let row = data[i];
      // alert(row);
      let color = "#222222";  
      if (row.indexOf("уточн") >= 0 ) {
        color = '#f00';
      } else {
        if (row.indexOf("скрыть") >= 0 ) {
          continue;
        }
        if (row.indexOf("toothNumberTouched") >= 0 ) {
          continue;
        }
        if (row.indexOf("Продолжить") >= 0 ) {
          continue;
        }
      }
      let htmledRow = `<li style='color: ${color}'>${row}</li>`;  
      htmlOutput += htmledRow;
    }
    htmlOutput += "</ul>";
    
    return htmlOutput;
  }

  componentDidMount() {
    let me = this;
    let processedContent = [];
    if (!!Array.isArray(me.props.content)) {
      for(let i in me.props.content) {
        processedContent.push(me.htmlizeText(me.props.content[i]));
      }
    } else {
      // alert(me.props.content);
      processedContent.push(me.htmlizeText(me.props.content));
    }
    let prevState = {... me.state};
    prevState.processedContent = processedContent;
    me.setState(prevState);
  }


  render() {
    let color = "#222222";
    try {
      if (this.props.content.indexOf("уточнение")  >= 0) {
        color = "#d00";
      }
    } catch(e) {}
    let xxx = <div className="toothtask-show" style={{color: `${color}`, marginLeft: "60px", border: `1px solid ${this.props.color}`}}>
      <h2>{this.props.title}</h2>
      {this.state.processedContent.map(
        (e) => 
        <div key={`kkey-${e}`} dangerouslySetInnerHTML={{__html: e}} />
      )}
    </div>
    return (xxx);
  }
}

