import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import $ from "jquery";


async function postData(url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST', 
    mode: 'cors', 
    cache: 'no-cache', 
    credentials: 'same-origin', 
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', 
    referrerPolicy: 'no-referrer', 
    body: JSON.stringify(data) 
  });
  return await response.json(); 
}


export default class OrderClarification extends React.Component {
  
  constructor(props) {
    super(props);
    // alert(212312);
    this.state = {
        open: false,
        clarification:  {
          comment: ""
        },
        intervalId: null
    };
    this.openDialog = this.openDialog.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.getClarification = this.getClarification.bind(this);
  }

  openDialog() {
    let prevState = {... this.state};
    prevState.open = true;
    this.setState(prevState);
    // me.getClarification();
  }

  componentDidMount() {
    let me = this;
    let prevState = {...this.state};
    prevState.intervalId = setInterval(me.getClarification, 47000 + Math.random()*100000);
    me.setState(prevState);
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  getClarification() {
    let me = this;
    // console.log(JSON.stringify(this.props));
    // alert(me.props.order);
    if (!me.props.order) return;
    let url = `/orders/${me.props.order.id}/get_clarification?tooth_number=${me.props.toothNumber}`;
    if (url.indexOf("https://") == -1) {
      url = document.SERVER_URL + url;
    }
    fetch(url).then( (e) => {return e.json()}).then(
        (e) => {
          let prevState = {... this.state};
          prevState.clarification = e;
          me.setState(prevState);
        }
    );
  }

  handleClose(e) {
    let prevState = {... this.state};
    prevState.open = false;
    this.setState(prevState);
  }

  handleCommentChange(e) {
    let prevState = {... this.state};
    prevState.clarification.comment = e.target.value;
    this.setState(prevState);
    let url = `/orders/${this.props.order.id}/update_clarification?tooth_number=${this.props.toothNumber}`;
    if (url.indexOf("https://") == -1) {
      url = document.SERVER_URL + url;
    }
    postData(url, {new_comment: prevState.clarification.comment});
  }

  render() {
    let me = this;
    let xxx = <div className="news-item-widget">
        { !!me.state.clarification.comment &&
          <div style={{display: "flex", width: "7px", height: "7px", borderRadius: "50%", background: "#ed0"}}></div>
        }
        <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={this.state.open}>
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            Уточнение данных по зубу {this.props.toothNumber}
            {/*{this.props.content.title}*/}
          </DialogTitle>
          {me.props.current_user &&
            <DialogContent dividers>
              <p className="when-is-it">Каким образом связаться с заказчиком?</p>
              <Typography style={{display: "flex", flexDirection: "column"}} gutterBottom>
                <div style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  alignContent: "center",
                  marginRight: "7px"
                }}>
                  <i className="fab fa-whatsapp"/>
                  <a href={`https://api.whatsapp.com/send?phone=${me.props.current_user.cell}`} target="_blank"
                     style={{margin: 0, marginLeft: "14px"}}>Написать в Whatsapp</a>
                </div>
                <div style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  alignContent: "center",
                  marginRight: "7px"
                }}>
                  <i className="far fa-phone"/>
                  <a href={`tel: ${me.props.current_user.cell}`} style={{margin: 0, marginLeft: "14px"}}>Уточнить по
                    телефону</a>
                </div>
                <div style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  alignContent: "center",
                  marginRight: "7px"
                }}>
                  <i className="far fa-envelope"/>
                  <a href={`mailto: ${me.props.current_user.email}`} target="_blank"
                     style={{margin: 0, marginLeft: "14px"}}>Уточнить по email</a>
                </div>
                <br/>
                <textarea onChange={me.handleCommentChange} value={me.state.clarification.comment} style={{
                  boxSizing: "border-box",
                  padding: "7px",
                  borderRadius: "10px",
                  width: "100%",
                  minHeight: "90px"
                }} placeholder="Комментарий"/>
              </Typography>
            </DialogContent>
          }
          <DialogActions>
            <Button onClick={(t) => {this.handleClose(); me.props.setColorReal(false, true) }} style={{color: "#fff"}} color="primary">
              Перейти к редактированию
            </Button>
            <Button style={{color: "#fff"}} onClick={this.handleClose} color="primary">
              Закрыть
            </Button>
          </DialogActions>
        </Dialog>
      </div>;
    return (xxx);
  }
}