import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ToothTask from "../Tz/ToothTask"

export default class UserNewOrder extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  } 


  componentDidMount() {
  }


  render() {
    let xxx = <ToothTask />;
    return (xxx);
  }
}