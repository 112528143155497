import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

export default class SelfUpdatedSelectField extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      resource: null,
      successOpen: false,
      variants: []
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.getOptionLabel = this.getOptionLabel.bind(this);
    this.filter = createFilterOptions();
  } 


  getOptionLabel(e) {
    // alert(e);
    if (!e.title) {
      for (let i in this.props.variants) {
        // console.log(this.props.variants[i][0]+" " + e.value);
        if (this.props.variants[i][0] == e.value) {
          return this.props.variants[i][1];
        }
      }
      return "";
    }
    try {
      return e.title;
    } catch(e) {
      return "";
    }
  }

  componentDidMount() {
    let me = this;
    let url = this.props.getUrl;
    if (url.indexOf("https://") == -1) {
      url = document.SERVER_URL + url;
    }
    // alert(url);
    fetch(url)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      // alert(variants.length);
      me.setState({resource: data})
    });
  }

  handleChange(e, newValue) {
    let me = this;
    // alert()
    // alert(JSON.str  ingify(newValue));

    if (!!me.props.createAction) {
      if (newValue.title.indexOf("Создать") >= 0) {
        me.props.createAction(newValue.inputValue);
        // alert("Create");
        // inputValue
        return;
      }
    }

    let value = newValue.value;
    if (!value) {
      value = e.target.value;
    }
    
    // alert(value);
    // alert(JSON.stringify(e));
    // alert(e.target.value);
    // alert(me.props.fieldName);
    let url = this.props.updateUrl;
    if (url.indexOf("https://") == -1) {
      url = document.SERVER_URL + url;
    }

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify({
        new_value: value,
        changed_field: me.props.fieldName
      })
    }).then((response) => {
      return response.json();
    })
    .then((data) => {
      me.setState({resource: data, successOpen: true}, function(xxx) {
        me.setState({resource: data, successOpen: false}, function (xxx) {
          if (!!me.props.updateCallback) {
            me.props.updateCallback(e.target.value);
          }
        })
      });
    });
  }


  goToHref(href) {
    document.location.href = href;
  }

  handleClose() {
    let prevState = {... this.state};
    prevState.successOpen = false
    this.setState(prevState);
  }

  

  render() {
    let me = this;
    let variants = [];
    let material = me.props.variants;
    // alert(material.length);
    if (!me.props.variants) {
      material = me.state.resource[me.props.fieldName];
    }
    
    for (let i = 0; i < material.length; i++) {
      let variant = material[i];
      variants.push({title: !Array.isArray(variant) ? variant : variant[1], value: variant[0]});
    }

    let xxx = <div  className={this.props.className} style={{marginRight: "7px", minWidth: "200px"}}>
      {!this.state.resource && 
        <Skeleton variant="rect" width={140} height={54} />
      }

      {!!this.state.resource && 
        <div style={{display: "flex", flexDirection: "column", alignContent: "flex-start", alignItems: "flex-start"}} >
          {/*<p style={{margin: 0, fontSize: "12px", marginRight: "12px", color: "#6C6D6E"}}>{this.props.label}</p>*/}
          

          <Autocomplete
            key={`seed-${me.state.variants.legnth}`}
            style={{minWidth: "320px", minHeight: "40px"}}
            label={me.props.label}
            filterOptions={(options, params) => {
              const filtered = me.filter(options, params);

              if (!!me.props.createAction) {
                if (params.inputValue !== '' && filtered.length == 0) {
                  filtered.push({
                    inputValue: params.inputValue,
                    title: `Создать "${params.inputValue}"`,
                  });
                }
              }

              return filtered;
            }}
            placeholder={me.props.label}
            options={variants}
            value={!me.state.resource[me.props.fieldName] ? {} : {value: me.state.resource[me.props.fieldName]}}
            getOptionLabel={me.getOptionLabel}
            onChange={me.handleChange} 
            freeSolo
            renderInput={(params) => <TextField {...params} label={me.props.label} style={{width: "100%"}}  variant="outlined" />}
          />
        </div>
      }
      <Snackbar open={me.state.successOpen} autoHideDuration={6000} onClose={me.handleClose}>
        <Alert onClose={me.handleClose} severity="success">
          Данные обновлены
        </Alert>
      </Snackbar>
    </div>;
    return (xxx);
  }
}



