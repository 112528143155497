import React from 'react'

class JsonTableView extends React.Component {
  
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }


  shiftTouch(toothName) {
    alert(toothName);
  }


  makeHtml() {
    let res = teeth[this.props.toothNumber].code;
    if (this.state.hovered) {
        res = res.replace("#B0B7C1", "#7B61FF")
    }
    res = res.replace("#FFFFFE", this.props.color)

    return res;
  }
  


  render() {
    let xxx = <div style={{width: "306px", height: "520", position: "relative"}}>
  		{this.state.teeth.map( (item, index) =>  <JawTooth key={`tooth_${index}`} toothNumber={item.toothNumber} color={item.color} setColor={this.setColor} />)}
    </div>;
    return (xxx);
  }



}


export default JsonTableView;