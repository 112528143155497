import React from 'react';
import $ from 'jquery';

export default class PickPointForm extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
			resource: null
		}
	}

	componentDidMount() {
		// $.getScript( "https://api-maps.yandex.ru/2.1/?apikey=00fb9595-c0ad-45a3-96e6-f9f6633eb015&lang=ru_RU", function( data, textStatus, jqxhr ) {
		//   // console.log( data ); // Data returned
		//   // console.log( textStatus ); // Success
		//   // console.log( jqxhr.status ); // 200
		//   // alert( "Load was performed." );
		//   ymaps.ready(init);
	 //        function init(){
	 //            var myMap = new ymaps.Map("map", {
	 //                center: [55.76, 37.64],
	 //                zoom: 7
	 //            });
	 //        }
		// });
	}
	// 

	render() {
		let xxx = <div>
			<h1>New pick point</h1>
			<div id="map" style={{width: "600px", height: "400px"}}></div>
		</div>;

		return xxx;
	}
}
