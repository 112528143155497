import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SelfUpdatedTextField from "../../Shared/SelfUpdatedTextField";
import SelfUpdatedSelectField from "../../Shared/SelfUpdatedSelectField";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import "./user_patient.scss";


export default class ChoosePatientStage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user_patients: [],
      newPatientDialogOpen: false,
      newUserCreds: {},
      importing: false
    };

    this.openNewPatientDialog = this.openNewPatientDialog.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.createPatient = this.createPatient.bind(this);
    this.updatePatients = this.updatePatients.bind(this);
    this.createPatientFromSelectField = this.createPatientFromSelectField.bind(this);
    this.importPatient = this.importPatient.bind(this);
  }


  updatePatients() {
    let url = "/users/user_patients.json";

    if (this.props.current_user) {
      url = `/users/user_patients.json?user_id=${this.props.current_user.id}`;
    }

    if (url.indexOf("https://") == -1) {
      url = document.SERVER_URL + url;
    }


    fetch(url).then(
      (e) => {
        return e.json()
      } 
    ).then(
      (e) => {
        // alert(JSON.stringify(e));
        let prevState = {... this.state };
        prevState.user_patients = e;
        this.setState(prevState);
      }
    )
  }


  importPatient() {
    let prevState = {... this.state};
    prevState.importing = !prevState.importing;
    this.setState(prevState);
  }


  componentDidMount() {
    this.updatePatients();
  }


  openNewPatientDialog() {
    let prevState = {... this.state};
    prevState.newPatientDialogOpen = true;
    
    this.setState(prevState);
  }


  handleClose() {
    let prevState = {... this.state};
    prevState.newPatientDialogOpen = false;
    this.setState(prevState);
  }


  componentWillUnmount() {
  }


  handleChange(field, e) {
    let me = this;
    // alert(e.target.value);
    // alert(field, e.target.value);
    let prevState = {... this.state };
    prevState.newUserCreds[field] = e.target.value;
    this.setState(prevState, function(xxx) {
      if (!!me.props.updateCallback) {
        me.props.updateCallback();
      }
    });

  }


  createPatientFromSelectField(value) {
    let me = this;
    try {
      let data = value.split(" ");
      let x = {};
      x["last_name"] = data[0];
      x["first_name"] = data[1];
      x["middle_name"] = data[2];
      let prevState = {... this.state};
      prevState.newUserCreds = x;
      this.setState(prevState, function(xxx) {
        me.createPatient()
      });
    } catch(e) {}
  }


  createPatient() {
    let data = {
      patient: this.state.newUserCreds
    };

    // alert(JSON.stringify(data));

    let me = this;
    let url = `/user_patients/create.json?order_id=${this.props.order.id}`;
    if (this.props.current_user) {
      url += `&user_id=${this.props.current_user.id}`;
    }

    if (url.indexOf("https://") == -1) {
      url = document.SERVER_URL + url;
    }
    // alert(url);
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(
      (e) => {
        me.handleClose();
        me.updatePatients();
        if (!!me.props.updateCallback) {
          me.props.updateCallback();
        }
        return e.json()
      } 
    );
    // alert(JSON.stringify(data));
  }


  render() {

    let me = this;
    let updateUserPatientUrl = `/orders/${this.props.order.id}/update_field`;
    let getUserPatientUrl = `/orders/${this.props.order.id}.json`;

    if (this.props.current_user) {
      updateUserPatientUrl += `?user_id=${this.props.current_user.id}`;
      getUserPatientUrl += `?user_id=${this.props.current_user.id}`;
    }

    if (updateUserPatientUrl.indexOf("https://") == -1) {
      updateUserPatientUrl = document.SERVER_URL + updateUserPatientUrl;
    }

    if (getUserPatientUrl.indexOf("https://") == -1) {
      getUserPatientUrl = document.SERVER_URL + getUserPatientUrl;
    }

    let xxx = <div className="patient-stage">
        <div className="patient-stage-main-container">
          {me.state.importing && 
            <SelfUpdatedSelectField key={`patients-${me.state.user_patients.length}`} createAction={me.createPatientFromSelectField} updateCallback={me.props.updateCallback} variant="outlined" className={me.props.className} variants={me.state.user_patients.map((e) => [e.id, `${e.last_name} ${e.first_name}`])} updateUrl={updateUserPatientUrl} getUrl={getUserPatientUrl} fieldName="user_patient_id" label="Пациент"/>
          }
          {me.state.importing && 
            <Button variant="outlined" onClick={me.openNewPatientDialog}>Создать&nbsp;нового&nbsp;пациента</Button>
          }
          {!me.state.importing && 
            <SelfUpdatedTextField updateCallback={me.props.updateCallback} type="text" updateUrl={updateUserPatientUrl} getUrl={getUserPatientUrl} fieldName="patient_name" label="Пациент" />
          }
          {!me.state.importing &&
          <SelfUpdatedTextField updateCallback={me.props.updateCallback} type="text" updateUrl={updateUserPatientUrl} getUrl={getUserPatientUrl} fieldName="patient_age" label="Возраст пациента" />
          }
          {!me.state.importing &&
            <SelfUpdatedSelectField key={`patients-${me.state.user_patients.length}`} updateUrl={updateUserPatientUrl} variant="outlined" className={me.props.className} variants={[["Мужской", "Мужской"], ["Женский", "Женский"]]} updateUrl={updateUserPatientUrl} getUrl={getUserPatientUrl} fieldName="patient_gender" label="Пол"/>
          }

          <div className="spacer" style={{display: "flex", width: "10px"}}></div>
          {!me.state.importing && 
            <Button onClick={me.importPatient}>Импортировать</Button>
          }
          {!!me.state.importing && 
            <Button onClick={me.importPatient}>Отмена</Button>
          }
        </div>
        <Dialog onBackdropclick={me.handleClose} onClose={me.handleClose} open={me.state.newPatientDialogOpen}>
          <DialogContent style={{padding: "21px"}}>
            <h3>Новый пациент</h3>
            <br/>
            <TextField onChange={(e) => { me.handleChange("last_name", e); }} label="Фамилия" /><br/>
            <TextField onChange={(e) => { me.handleChange("first_name", e); }} label="Имя" /><br/>
            <TextField onChange={(e) => { me.handleChange("middle_name", e); }} label="Отчество" /><br/>
            <br/>
            <br/>
            <br/>
          </DialogContent>
          <DialogActions>
            <Button onClick={me.handleClose}>Отмена</Button>
            <Button onClick={me.createPatient} color="primary">Создать</Button>
          </DialogActions>
        </Dialog>
      </div>
    ;
    return (xxx);
  }
}