import React from "react";

export default class RouteList extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
    }

    componentWillMount() {
        // could do something like pull state from API
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    componentDidUpdate() {
    }

    render() {
        let me = this;
        return <div className="route-list">
            { me.props.route_list.route_list_items.map(
                (e) => {
                    return <p>✅&nbsp;{e.delivery.address} - {e.user_order ? e.user_order.short_name : ""}</p>
                }
            )
            }
        </div>
    }

}