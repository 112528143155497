import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

// const fieldName = "cell";
// const label = "Номер телефона";
// const getUrl = `/users/me.json`;
// const updateUrl = '/users/update_field';

export default class SelfUpdatedTextField extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      resource: null,
      successOpen: false
    }

    this.uploadTimeout = null;

    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    let me = this;
    let url = this.props.getUrl;
    if (url.indexOf("https://") == -1) {
      url = document.SERVER_URL + url;
    }
    fetch(url)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      me.setState({resource: data})
    });
  }

  handleChange(e) {
    let me = this;
    if (me.uploadTimeout) {
      clearTimeout(me.uploadTimeout);
    }
    me.uploadTimeout = setTimeout(() => {
      let url = this.props.updateUrl;
      if (url.indexOf("https://") == -1) {
        url = document.SERVER_URL + url;
      }
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({
          new_value: e.target.value,
          changed_field: this.props.fieldName
        })
      }).then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!!me.props.updateCallback) {
          me.props.updateCallback();
        }
        let prevState = {... me.state};
        prevState.successOpen = true;
        me.setState(prevState);

        setTimeout(function() {
          let prevState = {... me.state};
          prevState.successOpen = false;
          me.setState(prevState);
        }, 5000);
      });
    }, 200);
  }

  goToHref(href) {
    document.location.href = href;
  }

  handleClose() {
    let prevState = {... this.state};
    prevState.successOpen = false
    this.setState(prevState);
  }

  render() {
    let me = this;
    let xxx = <div  className={this.props.className} style={{marginRight: "7px", width: "100%", minWidth: "200px"}}>
      {!this.state.resource && 
        <Skeleton variant="rect" width={140} height={54} />
      }

      {!!this.state.resource && 
        <TextField type={this.props.type} style={{width: "100%", minHeight: "40px"}} onChange={this.handleChange} defaultValue={this.state.resource[this.props.fieldName]} id="outlined-basic" label={this.props.label} variant="outlined" />
      }

      <Snackbar open={me.state.successOpen} autoHideDuration={6000} onClose={me.handleClose}>
        <Alert onClose={me.handleClose} severity="success">
          Данные обновлены
        </Alert>
      </Snackbar>
    </div>;
    return (xxx);
  }
}


