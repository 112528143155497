import React from "react";
import ReactQR from "react-qr-reader";
import QRCode from "react-qr-code";

export default class ProcessedMaterialTerminal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active_worker_catch_session: null,
            cycle_id: null,
            scannedCodes: [],
            currentCode: "",
            codeLoading: false
        }
        this.checkIfAnyGetSession = this.checkIfAnyGetSession.bind(this);
        this.catchMaterial = this.catchMaterial.bind(this);
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        let me = this;
        me.checkIfAnyGetSession();
        let iid = setInterval(me.checkIfAnyGetSession, 10000);
        let prevState = {... me.state};
        prevState.cycle_id = iid;
        me.setState(prevState, function () {
            document.addEventListener("keydown",(event) => {
                if (event.key == "Shift") {
                    return;
                }
                if (event.key != "Enter") {
                    let prevState = {... me.state};
                    prevState.currentCode += me.punto(event.key);
                    me.setState(prevState);
                    //alert(event.key);
                } else{
                    let prevState = {... me.state};
                    me.catchMaterial(prevState.currentCode);

                }
            })
        });
    }

    catchMaterial(word) {
        let me = this;
        if (me.state.codeLoading) {
            return;
        }
        let prevState = {... me.state};
        prevState.scannedCodes.push(prevState.currentCode);
        prevState.currentCode = "";
        prevState.codeLoading = true;
        me.setState(prevState, function () {
            let url = document.SERVER_URL + `/admin/processed_material_catches/catch_material?processed_session_id=${me.state.active_worker_catch_session.id}&sku=${word}`;
            fetch(url).then( (e) => {return e.json()}).then((e) => {
                let prevState = {... me.state};
                prevState.codeLoading = false;
                me.setState(prevState, function() {
                    me.checkIfAnyGetSession();
                });
            });
        });
    }

    componentWillUnmount() {
        let me = this;
        resetInterval(me.state.cycle_id);
    }

    checkIfAnyGetSession() {
        let url = document.SERVER_URL + "/admin/processed_material_catches/check";
        let me = this;
        fetch(url).then((e) => {return e.json();}).then(
            (e) => {
                let prevState = {... me.state};
                prevState.active_worker_catch_session = e;
                me.setState(prevState);
            }
        )
    }

    punto(bukva) {
        let lat = "`qwertyuiop[]asdfghjkl;'zxcvbnm,.~/QWERTYUIOP{}ASDFGHJKL:\"ZXCVBNM<>?";
        let rus = "ёйцукенгшщзхъфывапролджэячсмитьбю.ЁЙЦУКЕНГШЩЗХЪФЫВАПРОЛДЖЭЯЧСМИТЬБЮ,";
        let pos = -1;
        pos = rus.indexOf(bukva);
        if (pos >= 0) {
            // alert(lat.substr(pos, 1);
            return lat.substr(pos, 1);
        }
        return bukva;
    }


    render() {
        let me = this;
        let qrContent = `{"processed_material": { "action": "start_session"}}`;
        let style = me.state.codeLoading ? {opacity: 0.4} : {opacity: 1};
        return <div style={style} key={`${JSON.stringify(style)}`}>
            <h1>Склад</h1>
            { !me.state.active_worker_catch_session &&
                <div>
                   <h2>Отсканируйте QR код для того, чтобы начать забирать материалы</h2>
                </div>
            }
            { !!me.state.active_worker_catch_session &&
            <div>
                <h2>Сбор материалов для сотрудника:&nbsp;
                    {me.state.active_worker_catch_session.worker.last_name}&nbsp;
                    {me.state.active_worker_catch_session.worker.first_name}&nbsp;
                    {me.state.active_worker_catch_session.worker.middle_name}&nbsp;
                </h2>
                <h2>Отсканируйте QR код для того, чтобы войти в меню, чтобы завершить текущий забор материалов</h2>
            </div>
            }
            <div className="qr-container" >
                <QRCode size={126} value={qrContent} />
            </div>
            { !!me.state.active_worker_catch_session &&
            <div>
                <br/>
                <br/>
                <div key={`kk-${me.state.active_worker_catch_session.processed_material_catches.length}`}>
                    {/*<p>{me.state.active_worker_catch_session.processed_material_catches.length}</p>*/}
                    {me.state.active_worker_catch_session.processed_material_catches.map( (e, ind) => {
                      return <p style={{margin: 0, marginBottom: "14px", fontSize: "19px"}} key={`212-${e.id}-${ind}`}>{ind}: {e.processed_material.name}</p>
                    })
                    }
                </div>
                <div style={{ background: "#f00"}}>
                    {me.state.scannedCodes.map((e) => {
                        // return <p>{e}</p>

                    })}
                </div>
                {/*<div className="qr-container" >*/}
                {/*    <QRCode size={126} value={qrContent} />*/}
                {/*</div>*/}
            </div>
            }

        </div>
    }
}
