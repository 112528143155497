import React from 'react';
import OrderStage from "../OrderStage";
import QRCode from "react-qr-code";
import CircularProgress from '@material-ui/core/CircularProgress';
import Timeline from "../../Shared/Timeline/Timeline";

export default class OrderShow extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			order: null,
			stages: [],
			situation: {}
	    }

	    this.getTimeLine = this.getTimeLine.bind(this);
	}


	getTimeLine() {
		let url = `/orders/${this.props.order.id}/roadmap`;

		let me = this;
		fetch(url).then((e) => {return e.json()}).then(
			(e) => {
				let prevState = {... me.state};
				prevState.stages = e;
				me.setState(prevState);
			}
		);
	}

	componentDidMount() {
		this.getTimeLine();
	}

	render() {
		let me = this;
		let qrContent = `{"user_order": { "id": ${this.props.order.id}}}`;
		return (<div style={{position: "relative", background: "#fff", padding: "7px"}}>
			<div style={{width: "100%", position: "relative", display: "flex", flexDirection: "row",justifyContent: "space-between", marginBottom: "35px" }}>
	          <QRCode size={126} value={`${qrContent}`} />
			  <h1 style={{fontSize: '14px'}}>{me.props.order.name}</h1>
	        </div>
			<Timeline elements={me.state.stages}/>
		</div>);
	}
}