import React from 'react';
import Timer from 'react-compound-timer';
// import JawTooth from "../components/jaw/JawTooth";

export default class OrderTimer extends React.Component {
  
	constructor(props) {
		super(props);
		this.state = {
			order_timer: null
			// date_time_left: 100,
			// total_time: 0
		}
	}

	componentDidMount() {
		let me = this;
		let url = `/timers/${this.props.order_timer.id}`;
		// alert(url);
		fetch(url).then((e) => {return e.json()}).then( (e) => {
			let prevState = {... me.state};
			prevState.order_timer = e;
			this.setState(prevState);
		});
	}

	render() {
		let me = this;
		let color = me.props.color ? me.props.color : "#e44";
		let xxx = (
			<div className="timer">
				{!!me.state.order_timer && 
					<Timer
					    initialTime={me.state.order_timer.time_left * 1000}
					    direction="backward"
					>
					    {() => (
					        <div style={{marginTop: "7px", fontSize: (!!me.props.fontSize1 ? me.props.fontSize1 : "18px"), color: color, display: "flex", flexDirection: "column", fontFamily: "sans-serif"}}>
								{ !me.props.hide_caption &&
									<p style={{margin: 0, marginRight: "14px"}}>{me.state.order_timer.name} {me.state.order_timer.target_date}</p>
								}
					        	<div style={{marginTop: "7px", fontSize: (me.props.fontSize2 ? me.props.fontSize2 : "22px"), color: color, display: "flex", flexDirection: "row", fontFamily: "sans-serif"}}>
						            <p className="hide-print" style={{margin: 0, marginRight: "7px", fontSize: "14px"}}>Ост.&nbsp;<Timer.Days />дн</p>
						            <p className="hide-print" style={{margin: 0, marginRight: "7px", fontSize: "14px"}}><Timer.Hours />ч</p>
						            <p className="hide-print" style={{margin: 0, marginRight: "7px", fontSize: "14px"}}><Timer.Minutes />м</p>
						            <p className="hide-print" style={{margin: 0, marginRight: "7px", fontSize: "14px"}}><Timer.Seconds />с</p>
					            </div>
								{ !me.props.no_rescheldue &&
					            	<a className="hide-print" href={`/orders/${me.state.order_timer.user_order_id}/deadline_correction?tech_process_id=${me.state.order_timer.tech_stage_id}`}>Коррекция сроков</a>
								}
					        </div>
					    )}
					</Timer>

				}
			</div>
		);
		return xxx;
	}
}