import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import "./header.scss";
import logo from "./logo.png";
import Status from "../Gauge/status";
import DialogContent from "@material-ui/core/DialogContent";
import ChatWidget from "../Chat/ChatWidget";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AudioHelper from "./AudioHelper";

export default class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      leftMenuOpen: false,
      admin_user: null,
      user: null,
      worker: null,
      courier: null,
      dialogUrl: null
    }

    this.toggleLeftMenu = this.toggleLeftMenu.bind(this);
    this.endSession = this.endSession.bind(this);
    this.showDialogs = this.showDialogs.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }


  toggleLeftMenu() {
    let prevState = {... this.state};
    prevState.leftMenuOpen = !prevState.leftMenuOpen;
    this.setState(prevState);
  }


  componentDidMount() {
    let prevState = {... this.state};
    prevState.admin_user = this.props.admin_user;
    prevState.user = this.props.user;
    prevState.worker = this.props.worker;
    prevState.courier = this.props.courier;
    
    if (!prevState.admin_user) {
      prevState.admin_user = document.admin_user
    }

    if (!prevState.user) {
      prevState.user = document.user
    }

    if (!prevState.worker) {
      prevState.worker = document.worker
    }

    if (!prevState.courier) {
      prevState.courier = document.courier
    }

    this.setState(prevState);
    this.signOut = this.signOut.bind(this);
    // this.setState(this.props.content);
  }

  endSession() {
    fetch(document.SERVER_URL + "/workers/end_session").then((e) => {
      return e.json();
    }).then((e) => {
      document.location.reload();
    });
  }

  signOut() {
    localStorage.removeItem("admin");
    localStorage.removeItem("user");
    localStorage.removeItem("worker");
    localStorage.removeItem("courier");
    this.props.resetRoles();
  }

  handleClose() {
    let prevState = {... this.state};
    prevState.dialogUrl = null;
    this.setState(prevState);
  }

  showDialogs() {
    let prevState = {... this.state};
    prevState.dialogUrl = document.SERVER_URL + "/workers/my_dialogs";
    this.setState(prevState);
  }

  render() {
    let me = this;

    let showSignInButton = !(this.state.user || this.state.worker || this.state.admin_user);
    let sign_out_url = "/users/sign_out";
    if (!!this.state.worker) {
      sign_out_url = "/workers/sign_out";
    }
    if (!!this.state.couriers) {
      sign_out_url = "/couriers/sign_out";
    }
    if (!!this.state.admin_user) {
      sign_out_url = "/admin/logout";
    }
    if (!!this.state.admin) {
      sign_out_url = "/admin/logout";
    }

    let xxx = <>
        {(this.state.worker) &&
          <i id={'toggle-left-menu'} className="fal fa-bars" onClick={me.toggleLeftMenu}></i>
        }
        {!this.state.admin_user &&
          <a id={'go-to-root-via-logo'} href="/"><img src={logo} id="articon-logo"/></a>
        }

        {!!this.state.admin_user &&
          <a style={{marginRight: "14px"}} id={'go-to-admins-cabinet-via-logo'} href="/admin/"><img src={logo} id="articon-logo"/></a>
        }
        {!!this.state.admin_user &&
          <Status getUrl="/flow/status" />
        }
        {(this.state.user) &&
          <a id={'go-to-users-cabinet'} href={`/users/${this.state.user.id}`}><i className="fal fa-user fa-2x"></i></a>
        }
        {(this.state.courier) &&
          <a id={'go-to-couriers-cabinet'} href={`/couriers/${this.state.courier.id}`}><i className="fal fa-user fa-2x"></i></a>
        }
        {(this.state.admin_user) && (window.innerWidth > 800) &&
          <AudioHelper />
        }
        {(this.state.admin_user) &&
          <a style={{marginLeft: "auto", marginRight: "21px"}} id={'go-to-admins-cabinet'} href={`/admin`}>{this.state.admin_user.email}</a>
        }
        {(this.state.worker) &&
          <a id={'go-to-workers-cabinet'} href={`/workers/${this.state.worker.id}`}><i className="fal fa-user fa-2x"></i></a>
        }
        {/*{showSignInButton &&*/}
        {/*  <a href="/users/sign_in"><i className="fal fa-sign-in fa-2x"></i></a>*/}
        {/*}*/}
        {!showSignInButton && !!me.props.iAmFromRails &&
          <a id={'sign-out-buttton'} href={sign_out_url}><i className="fal fa-sign-out fa-2x"></i></a>
        }

        {!showSignInButton && !me.props.iAmFromRails &&
          <a id={'sign-out-buttton-with-fa'} onClick={me.signOut}><i className="fal fa-sign-out fa-2x"></i></a>
        }

        <React.Fragment>
          <Drawer anchor={"left"} open={me.state.leftMenuOpen} onClose={me.toggleLeftMenu}>
            <br />
            <br />
            <button style={{border: "none", background: "#EDEDED", marginBottom: "7px"}} onClick={me.endSession}>Завершить смену</button>
            <button style={{border: "none", background: "#EDEDED", marginBottom: "7px"}} onClick={me.showDialogs}>Мои диалоги</button>
          </Drawer>
        </React.Fragment>

        <Dialog onClose={me.handleClose} open={!!me.state.dialogUrl}>
          <DialogContent style={{padding: "21px"}}>
            <ChatWidget dialogsUrl={me.state.dialogUrl} />
          </DialogContent>
          <DialogActions>
            <Button onClick={me.handleClose}>Закрыть</Button>
          </DialogActions>
        </Dialog>
      </>;
    return (xxx);
  }
}