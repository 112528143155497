import AsyncSelect from 'react-select/async';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';


const customStyles = {
	menu: (provided, state) => ({
	    ...provided,
	    minWidth: "320px",
	}),
  placeholder: (provided, state) => ({
    ...provided,
    textAlign: "left",
  }),
  option: (provided, state) => ({
    ...provided,
    textAlign: "left",
    color: 'black'
  }),
  groupHeading: (provided, state) => ({
    ...provided,
    textAlign: "left",
    color: "gray",
    opacity: 1
  }),
  container: (provided, state) => ({
      ...provided,
	  minWidth: "300px"
	}),
};


export default class SelfUpdatedAsyncSelectField extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      resource: null,
      successOpen: false
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.loadOptions = this.loadOptions.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);

    // this.inputRef = React.createRef();
    // alert(1);
  } 

  componentDidMount() {
    let me = this;
    let url = "";

    if (!this.props.getUrl) {
    	me.setState({resource: {} })
    } else {
	    fetch(this.props.getUrl)
	    .then((response) => {
	      return response.json();
	    })
	    .then((data) => {
	      me.setState({resource: data})
	    });
	}
  }

  handleChange(e) {
    let me = this;
    let newVal = me.inputRef.select.select.state.focusedOption.value;
    if (newVal.length == 0) {
      return;
    }
    // alert(me.inputRef.current.value);
    // alert(e.target.value);
    // alert(me.props.fieldName)

    if (!me.props.updateUrl) {
        me.props.updateCallback(newVal);
        return;
    }
    let url = this.props.updateUrl;
    if (url.indexOf("https://") == -1) {
      url = document.SERVER_URL + url;
    }
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify({
        new_value: newVal,
        changed_field: me.props.fieldName
      })
    }).then((response) => {
      return response.json();
    })
    .then((data) => {
      me.setState({resource: data, successOpen: true}, function() {
          if (!!me.props.updateCallback) {
              me.props.updateCallback(newVal);
          }
          setTimeout(function() {
              me.setState({resource: data, successOpen: false});
          }, 5000);
      });
    });
  }


  goToHref(href) {
    document.location.href = href;
  }
  

	handleClose() {
	    let prevState = {... this.state};
	    prevState.successOpen = false
	    this.setState(prevState);
	}


    // options = []
	loadOptions(inputVal, callBack) {
		let url = this.props.options_url;

		if (url.indexOf("?") >= 0)  url += "&query="+encodeURI(inputVal);
		if (url.indexOf("?") == -1) url += "?query="+encodeURI(inputVal);


        if (url.indexOf("https://") == -1) {
          url = document.SERVER_URL + url;
        }
		// alert(url);

		fetch(url).then(
			(e) => { return e.json(); }
		).then( (e) => {
			callBack(e);
		});
	}


	onInputChange(newValue) {
		// alert(newValue);
	}

  onKeyDown(e) {
    if (e.key == "Enter") {
      // alert(12);
      e.preventDefault();
      e.stopPropagation();
      // alert(e.key);
    }
  }

  render() {
    let me = this;

    let xxx = <div  className={this.props.className} style={{marginRight: "7px"}}>
      {!this.state.resource && 
        <Skeleton variant="rect" width={140} height={54} />
      }

      {!!this.state.resource && 
        <div style={{minWidth: "300px", display: "flex", flexDirection: "column", alignContent: "flex-start", alignItems: "flex-start"}} >
          <p style={{margin: 0, fontSize: "12px", marginRight: "12px", color: "#6C6D6E"}}>{this.props.label}</p>
          
          <AsyncSelect 
	          ref={ref => {me.inputRef = ref;}}
              instanceId={me.props.id ? me.props.id : null}
	          onChange={me.handleChange}
	          styles={customStyles} 
	          defaultOptions 
	          loadOptions={me.loadOptions} 
	          onInputChange={me.onInputChange}
	          placeholder={me.props.placeholder} 
	          label={me.props.placeholder}
              onKeyDown={me.onKeyDown}
	          noOptionsMessage={() => 'Введите несколько символов'}
	          defaultValue={!me.state.resource[me.props.fieldName] ? "" : me.state.resource[me.props.fieldName] }/>
        </div>
      }
      <Snackbar open={me.state.successOpen} autoHideDuration={6000} onClose={me.handleClose}>
        <Alert onClose={me.handleClose} severity="success">
          Данные обновлены
        </Alert>
      </Snackbar>
    </div>;
    return (xxx);
  }
}
