import React from 'react'
import MenuItem from "./MenuItem"
import "./menus.scss";
import Button from '@material-ui/core/Button';
import XmlTableView from "./XmlTableView";

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        name: "",
        menu_variants: []  
    };

    this.triggerMenuHoveredState = this.triggerMenuHoveredState.bind(this);
    this.unTriggerHoveredPic = this.unTriggerHoveredPic.bind(this);
    this.setColor = this.setColor.bind(this);
  } 

  componentDidMount() {
    this.setState(this.props.content);
  }

  triggerMenuHoveredState(item) {
    this.props.triggerHoveredPic(item);
  }

  unTriggerHoveredPic(item) {
    this.props.unTriggerHoveredPic(item);
  }


  async setColor(e, menuItem) {
    let me = this;
    return new Promise( (resolve, reject) => {
      me.props.setColor(e, menuItem).then((e) => {
        resolve(true);
      })
    });
  }


  render() {
    let me = this;
    let hasSomethingToClarify = false;
    let continueEnabled = false;
    try {
      continueEnabled = me.props.jawRef.current.continueEnabled();
    } catch(e) {
    }

    if (!!me.props.task) {
      if (JSON.stringify(me.props.task).indexOf("уточн") >= 0) {
        hasSomethingToClarify = true;
      }
    }

    let menu_variants = this.state.menu_variants;



    let xxx = <div className={this.props.hidden ? "menu hidden" : "menu"}>
      <h2 style={{fontSize: "19px", marginBottom: "10px", height: "25px"}}>{this.state.name}</h2>
      {/*<p style={{position: "relative", border: "1px solid #858585", color: "#858585", padding: "7px", borderRadius: "7px"}}>Завершить выбор конструкции</p>*/}
      
      { this.props.menu_level > 0 &&
        <p className="back" onClick={this.props.levelUp}>Назад</p>
      }


      <ul>
        {menu_variants.map( (item) => <MenuItem setVariable={me.props.setVariable} enableToothReset={me.props.enableToothReset} blocked={(("Продолжить" == item.name) && !continueEnabled) || me.props.blocked} chosenColor={me.props.task[item.variable_name]} setColor={me.setColor} triggerMenuHoveredState={me.triggerMenuHoveredState} unTriggerHoveredPic={me.unTriggerHoveredPic} key={item.name} sets_color={item.sets_color} content={item} openMenu={this.props.openMenu} ></MenuItem>)}
      </ul>
      <br /><br /><br />
      <Button style={me.props.blocked ? {border: "1px solid #858585", pointerEvents: "none", opacity: 0.2} : {border: "1px solid #858585"}} onClick={me.props.handleContextMenu} >Завершить выбор конструкции</Button>
      
    </div>;
    return (xxx);
  }
}


export default Menu;
