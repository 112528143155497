import React from 'react';
import ChatMessage from "./ChatMessage";

export default class ChatMessages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: []
    };
    this.messagesScrollerRef = React.createRef();
  } 

  tick() {
    if (!this.props.dialog) return;
    let url = "/chat_messages/?dialog_id="+this.props.dialog.id;
    // alert(url);
    fetch(url)
    .then(response => response.json())
    .then(messages => {
      let prevState = {... this.state};
      prevState.messages = messages;
      let messagesChanged = messages.length != this.state.messages.length;
      this.setState(prevState);
      if (messagesChanged) {
        this.scrollToBottom();
      }
    });
  }

  componentDidMount() {
    this.tick();
    let me = this;
    setTimeout(function() {
      me.timerID = setInterval(
        () => me.tick(), 5000
      );
    }, 100);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  scrollToBottom = () => {
    let el = this.messagesScrollerRef.current;//document.getElementById("chat-messages-container");
    el.scrollTo(0,el.scrollHeight);
  }

  render() {
    let xxx = <div className="chat-messages" id="chat-messages-container" ref={this.messagesScrollerRef}>
        {
          this.state.messages.map((message,index) => 
            <ChatMessage key={message.id} content={message} />
          )
        }
      </div>;
    return (xxx);
  }
}