import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import "./switcher.scss";

// import SelfUpdatedTextField from "../../Shared/SelfUpdatedTextField";
// import SelfUpdatedSelectField from "../../Shared/SelfUpdatedSelectField";

export default class SelfUpdatedSwitchField extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      resource: null,
      successOpen: false
    }
    
    // alert(this.props.variants.length);
    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }


  componentDidMount() {
    let me = this;
    let url = this.props.getUrl;
    if (url.indexOf("https://") == -1) {
      url = document.SERVER_URL + url;
    }
    fetch(url)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      me.setState({resource: data})
    });
  }


  handleChange(newValue) {
    let me = this;
    let url = this.props.updateUrl;
    if (url.indexOf("https://") == -1) {
      url = document.SERVER_URL + url;
    }
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify({
        new_value: newValue,
        changed_field: me.props.fieldName
      })
    }).then((response) => {
      return response.json();
    })
    .then((data) => {
      let prevState = {... me.state};
      prevState.successOpen = true;
      prevState.resource = data;
      me.setState(prevState, function (xxx) {
        if (!!me.props.updateCallback) {
          me.props.updateCallback(newValue);
        }

        setTimeout(function() {
          let prevState = {... me.state};
          prevState.successOpen = false;
          me.setState(prevState);
        }, 5000);
      });
    });
  }


  handleClose() {
    let prevState = {... this.state};
    prevState.successOpen = false
    this.setState(prevState);
  }


  setIsUrgent(val) {
    let prevState = {... this.state};
    prevState.delivery.is_urgent = val;
    this.setState(prevState);
  }


  componentWillUnmount() {
  }

  render() {
    let me = this;
    let xxx = <div className="switcher-container">
      { !!this.state.resource && 
        <div className={me.props.vertical ? "mode-tabs-vertical" : "mode-tabs"}>
          {!!this.props.label && 
            <p className="cool-label">{this.props.label}</p>
          }
          <div className='buttons-container' style={{display: "flex", flexDirection: "row", alignItems: "center", alignContent: "center"}}>
            { this.props.variants.map( (variant) => 
              <Button key={`vv-${variant}`} variant="contained" style={me.state.resource[me.props.fieldName] == variant[0] ? {background: "#AFB7C0", color: "#fff"} : {}} disableElevation color={( me.state.resource[me.props.fieldName] == variant[0] ) ? "primary" : "default"} onClick={(e) => me.handleChange(variant[0])}>
                {variant[1]}
              </Button>
            )}
          </div>
        </div>
      }
      <br />
      <Snackbar open={me.state.successOpen} autoHideDuration={6000} onClose={me.handleClose}>
        <Alert onClose={me.handleClose} severity="success">
          Данные обновлены
        </Alert>
      </Snackbar>
    </div>;
    return (xxx);
  }
}
