import React from 'react';
import IsBlocked from "./Fields/IsBlocked";
import Cell from "./Fields/Cell";
import Email from "./Fields/Email";
import ImagePicker from "./Fields/ImagePicker";
import SelfUpdatedTextField from "../Shared/SelfUpdatedTextField";
import SelfUpdatedSelectField from "../Shared/SelfUpdatedSelectField";

const formParsel = {
  model: "Users",
  fields: [
    [
      <ImagePicker key="0" getUrl="/users/me.json" updateUrl="/users/upload_image" field="pic_url" />, 
      <IsBlocked key="1" />,
      <SelfUpdatedSelectField variants={["Сарафанное радио","Instagram","Facebook","Email рассылка","Вконтакте"]} key="2" updateUrl="/users/update_field" getUrl="/users/me.json" fieldName="how_did_you_find_us" label="Как вы нашли нас?"/>
    ],
    [ 
      <SelfUpdatedTextField key="4" updateUrl="/users/update_field" getUrl="/users/me.json" fieldName="last_name" label="Фамилия" />, 
      <SelfUpdatedTextField key="5" updateUrl="/users/update_field" getUrl="/users/me.json" fieldName="first_name" label="Имя" />, 
      <SelfUpdatedTextField key="6" updateUrl="/users/update_field" getUrl="/users/me.json" fieldName="middle_name" label="Отчество" />, 
    ],
    [
      <SelfUpdatedTextField type="email" key="7" updateUrl="/users/update_field" getUrl="/users/me.json" fieldName="email" label="Email" />,
      <SelfUpdatedTextField type="tel" key="8" updateUrl="/users/update_field" getUrl="/users/me.json" fieldName="cell" label="Номер телефона" />
    ],
    [<SelfUpdatedTextField key="9" updateUrl="/users/update_field" getUrl="/users/me.json" fieldName="inn" label="ИНН" />, <SelfUpdatedTextField key="5" updateUrl="/users/update_field" getUrl="/users/me.json" fieldName="ogrn" label="ОГРН" />],
    [<SelfUpdatedTextField key="10" updateUrl="/users/update_field" getUrl="/users/me.json" fieldName="address" label="Юридический адрес" />],
    [<SelfUpdatedTextField key="11" updateUrl="/users/update_field" getUrl="/users/me.json" fieldName="bank_name" label="Название банка" />, <SelfUpdatedTextField key="8" updateUrl="/users/update_field" getUrl="/users/me.json" fieldName="bik" label="БИК" />],
    [<SelfUpdatedTextField key="12" updateUrl="/users/update_field" getUrl="/users/me.json" fieldName="bank_account_number" label="Номер счета" />, <SelfUpdatedTextField key="10" updateUrl="/users/update_field" getUrl="/users/me.json" fieldName="bank_korr_account" label="Корр. счет" />]
  ]
};

export default class UserSettings extends React.Component {
  constructor(props) {
    super(props);
  } 


  componentDidMount() {
  }



  goToHref(href) {
    document.location.href = href;
  }


  render() {
    let xxx = <div className="white-container"><table>
      <tbody>
          { formParsel.fields.map( (row, index) => 
            <tr style={{verticalAlign: "center", marginBottom: "7px"}} key={`row-${index}`} >
              { row.map( (block, index) => 
                <td style={{verticalAlign: "center", paddingBottom: "17px", width: "33%", paddingRight: "7px"}}>
                  {block}
                </td>
              )}
            </tr>
          )}
        </tbody>
      </table></div>;
    return (xxx);
  }
}