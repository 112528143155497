import React from 'react';
import ToothTask from '../../Tz/ToothTask';

export default class ProjectIdStage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
    this.returnToFirstMenu = this.returnToFirstMenu.bind(this);
    this.toothTaskRef = React.createRef();
  }


  returnToFirstMenu() {
    this.toothTaskRef.current.returnToFirstMenu();
  }



  componentDidMount() {
  }


  componentWillUnmount() {
  }


  render() {
    let me = this;
    let xxx = <div className="jaw-tz" style={{marginTop: "50px"}}>
        <ToothTask current_user={me.props.current_user} ref={me.toothTaskRef} updateCallback={me.props.updateCallback} order={this.props.order}/>
      </div>
    ;
    return (xxx);
  }
}