import React from 'react'
import Button from '@material-ui/core/Button';

export default class MessageInList extends React.Component {

  constructor(props) {
    super(props);
    this.goToHref = this.goToHref.bind(this);
  } 

  goToHref(href) {
    document.location.href = href;
  }

  componentDidMount() {
  }

  generateStyle(messageType) {
    if ("Срочно" == messageType) {
      return {
        background: "#ECECB2",
        border: "1px solid #9FCB94"
      }
    }
    return {};
  }


  generateClassName(messageType) {
    if ("Срочно" == messageType) {
      return "message urgent"
    }
    return "message";
  }


  render() {
    let xxx = <div className={this.generateClassName(this.props.itself.message_type)}>
        <p className="message-title" >{this.props.itself.title}</p>      
        <p className="message-content" dangerouslySetInnerHTML={{__html: this.props.itself.content}}></p>      
        {!!this.props.itself.action_url && 
            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
              <Button onClick={(e) => {this.goToHref(this.props.itself.action_url)}} style={{border: "1px solid #646464", maxWidth: "320px", marginTop: "20px"}}>{this.props.itself.action_text}</Button>
            </div>
        }
      </div>;
    return (xxx);
  }
}