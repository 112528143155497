import React from 'react';
import "./gauge.scss";

export default class Status extends React.Component {
  
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			intervalId: null
		};
		this.getData = this.getData.bind(this);
	} 


	componentDidMount() {
		let me = this;

		me.getData().then((_res) => {
			let intervalId = setInterval(me.getData, 15000);
			let prevState = {... me.state};
			prevState.intervalId = intervalId;
			me.setState(prevState);
		});

	}


	componentWillUnmount() {
	   clearInterval(this.state.intervalId);
	}


	async getData() {
	  	let me = this;
		return new Promise((_resolve) => {
			let url = document.SERVER_URL + me.props.getUrl;

			fetch(url).then((e) => {return e.json()}).then(
				(e) => {
					let prevState = {... this.state};
					prevState.data = e;
					me.setState(prevState, () => {
						_resolve(true);
					});
				}
			);
		});
	}


	render() {
	  	let me = this;
	    let xxx = <div className="gauges">
	    	{ me.state.data.map( (e) => 
		    		<a href={e.href} key={`gauge-${e.id}`} style={{color: "#222222", margin: 0, marginRight: "14px"}}>{e.name} ({e.number})</a>
		    	)
	    	}
	    </div>;
	    return (xxx);
	}
}