import React from 'react';
import WorkList from "./Shared/WorkList";
import OperationZone from "./OperationZone";
import StartSessionZone from "./StartSessionZone";
import OrderTimer from "../Timer/Timer";

var actionState = {
  title: "Выберите действие",
  actions: [
    {
      id: 1,
      title: "Скан QR",
      command: "{\"command\": \"scan_qr\"}"
    },
    {
      id: 2,
      title: "Связаться с руководителем отдела",
      command: "{\"command\": \"scan_qr\"}"
    }
  ]
};


export default class WorkerShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current_worker: null,
      current_worker_session: null,
      pending_jobs: null,
      previous_jobs: null,
      updateIntervalId: null,
      order_timers: []
    };

    this.updateStatus = this.updateStatus.bind(this);
    this.setWorkerSession = this.setWorkerSession.bind(this);
    this.updateTimers = this.updateTimers.bind(this);
  }


  componentWillUnmount() {
    clearInterval(this.state.updateIntervalId);
  }


  componentDidMount() {
    this.updateTimers();
    let prevState = {...this.state};
    prevState.current_worker = this.props.current_worker;
    let intervalId = setInterval(this.updateTimers, 10000);
    prevState.intervalId = intervalId;
    // alert(prevState.current_worker);
    this.setState(prevState);
  }


  updateTimers() {
    let me = this;

    let url = `${document.SERVER_URL}/workers/order_timers`;
    if (url.indexOf("https://") == -1) {
      url = document.SERVER_URL + url;
    }
    // alert(url);
    fetch(url).then( (e) => { return e.json()}).then( (e) => {
      let prevState = {... me.state};
      prevState.order_timers = e;
      me.setState(prevState);
    });
  }


  updateStatus() {
    let me = this;
    let url = "/workers/get_status";
    fetch(url).then((e) => {
      return e.json();
    }).then(
      (e) => {
        if (e.errors) {
          alert(e.errors);
          setTimeout(() => {
            document.location.reload();
          }, 10000);
          return;
        }
        let prevState = {... this.state};
        prevState.pending_jobs = e.pending_jobs;    
        prevState.previous_jobs = e.previous_jobs;
        me.setState(prevState);
      }
    );
  }

  setWorkerSession(workerSession) {
    let prevState = {... this.state};
    prevState.current_worker_session = workerSession;
    // this.setState(prevState);
    prevState.updateIntervalId = setInterval(this.updateStatus, 4000);
    this.setState(prevState);
  }


  render() {
    let me = this;
    // console.log(this.state.calendar);
    return (
    	<div className="worker-show">
        {!me.state.current_worker && 
          <p>Войдите в свою учетную запись, чтобы начать работу</p>
        }
        {!!me.state.current_worker && !!me.state.current_worker_session &&
          <div className="worker-show-container" >
            {me.state.order_timers.length == 0 &&
              <WorkList empty_text="Ожидаются работы" list={me.state.pending_jobs}/>
            }
            {/*{ me.state.order_timers.map ( (e) =>*/}
            {/*  <OrderTimer order_timer={e} no_rescheldue={true} />*/}
            {/*)}*/}
            <OperationZone actionState={actionState} current_worker={me.state.current_worker} />
            <hr />
            <br/>
            <h4>Выполнено</h4>
            <WorkList key={`done-jobs-${me.state.previous_jobs ? me.state.previous_jobs.length : -11}`} empty_text="Нет выполненных работ" list={me.state.previous_jobs} />
          </div>
        }

        {!!me.state.current_worker && !me.state.current_worker_session &&
            <StartSessionZone current_worker={me.state.current_worker} setWorkerSession={me.setWorkerSession}/>
        }
      </div>
    );
  }
}