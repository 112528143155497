import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

export default class UserSignupForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      login: "",
      password: "",
      passwordConfirmation: ""     
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.setLogin = this.setLogin.bind(this);
    this.setPassword = this.setPassword.bind(this);
    this.setPasswordConfirmation = this.setPasswordConfirmation.bind(this);
    this.goToHref = this.goToHref.bind(this);
  } 


  componentDidMount() {
  }


  handleSubmit(e) {
    e.preventDefault();
    let data = {
      user: {
        email: this.state.login,
        password: this.state.password,
        password_confirmation: this.state.passwordConfirmation
      }
    };

    if (this.state.login.length <= 3 || this.state.password.length <= 3) {
      return;
    }

    fetch('/users/custom_reg', {
      method: "POST",
      headers: {
        'Content-Type': "application/json;charset=utf-8"
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(user => {
      if (!!user) {
        document.location.href = `/users/${user.id}`;
      } else {
        // alert("Nope");
      }
    });
    // alert("Send " + this.state.login + " " + this.state.password);
    // alert('A name was submitted: ' + this.login.current.value);
  }


  setLogin(e) {
    let prevState = {... this.state};
    prevState.login = e.target.value;
    this.setState(prevState);
    // alert(e.target.value);
  }

  setPassword(e) {
    let prevState = {... this.state};
    prevState.password = e.target.value;
    this.setState(prevState);
  }

  setPasswordConfirmation(e) {
    let prevState = {... this.state};
    prevState.passwordConfirmation = e.target.value;
    this.setState(prevState);
  }


  goToHref(href) {
    document.location.href = href;
  }


  render() {
    let xxx = <>
      {/*<form className={this.useStyles()} noValidate autoComplete="off">*/}
      <form className="devise-form" noValidate autoComplete="off" onSubmit={this.handleSubmit}>
        <TextField
          id="filled-secondary"
          label="Телефон/email"
          variant="outlined"
          color="secondary"
          onChange={this.setLogin}
        />
        <TextField
          id="outlined-primary"
          label="Пароль"
          type="password"
          variant="outlined"
          color="secondary"
          defaultValue=""
          onChange={this.setPassword}
        />
        <TextField
          id="outlined-primary"
          label="Пароль еще раз"
          type="password"
          variant="outlined"
          color="secondary"
          defaultValue=""
          onChange={this.setPasswordConfirmation}
        />
        <Button type="submit" variant="contained" color="primary" onSubmit={this.handleSubmit}>
          {this.props.submitText}
        </Button>
        <p onClick={(e) => this.goToHref('/users/sign_in')}>Вход</p>
      </form>
      </>;
    return (xxx);
  }
}