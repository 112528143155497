import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import "./devise_form.scss";
import $ from "jquery";

export default class UserSignInForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      login: "",
      password: ""        
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.setLogin = this.setLogin.bind(this);
    this.setPassword = this.setPassword.bind(this);
    this.goToHref = this.goToHref.bind(this);
  }


  componentDidMount() {
  }


  handleSubmit(e) {
    let me = this;
    e.preventDefault();
    let data = {
      user: {
        email: this.state.login,
        password: this.state.password
      }
    };

    if (this.state.login.length <= 3 || this.state.password.length <= 3) {
      return;
    }

    let serverPart = !document.SERVER_URL ? "" : document.SERVER_URL;
    let url = serverPart + '/users/universal_sign_in'; 
    // alert(url);
    
    $.ajax({
      url: url,
      method: "post",
      dataType: "json",
      data: data,
      success: function(user) {
        if (!!user) {
          if (!!user.resource && !!me.props.iAmFromRails) {
            document.location.href = user.redirect_url;
          } else {
            localStorage.setItem(user.resource_type, user.resource);
            me.props.resetRoles();
            // alert("Nope");
          }
        } else {
          let prevState = {... me.state};
          prevState.error = "Логин или пароль введены неправильно";
          me.setState(prevState);
        }
      }
    });

    // fetch(url, {
    //   method: "POST",
    //   headers: {
    //     'Content-Type': "application/json;charset=utf-8"
    //   },
    //   body: JSON.stringify(data)
    // })
    // .then(response => response.json())
    // .then(user => {
    //   if (!!user.resource && !!me.props.iAmFromRails) {
    //     document.location.href = user.redirect_url;
    //   } else {
    //     localStorage.setItem(res.resource_type, user.resource);
    //     // alert("Nope");
    //   }
    // });
    // alert("Send " + this.state.login + " " + this.state.password);
    // alert('A name was submitted: ' + this.login.current.value);
  }

  setLogin(e) {
    let prevState = {... this.state};
    prevState.login = e.target.value;
    this.setState(prevState);
    // alert(e.target.value);
  }

  setPassword(e) {
    let prevState = {... this.state};
    prevState.password = e.target.value;
    this.setState(prevState);
  }


  goToHref(href) {
    document.location.href = href;
  }

  render() {
    let me = this;
    let xxx = <>
      {/*<form className={this.useStyles()} noValidate autoComplete="off">*/}
      <form className="devise-form" noValidate autoComplete="off" onSubmit={this.handleSubmit}>
        <TextField
          id="filled-secondary"
          label="Телефон/email"
          variant="outlined"
          color="secondary"
          onChange={this.setLogin}
        />
        <TextField
          id="outlined-primary"
          label="Пароль"
          type="password"
          variant="outlined"
          color="secondary"
          defaultValue=""
          onChange={this.setPassword}
        />
        {
          me.state.error &&
              <span style={{color: "#f00", marginBottom: "14px"}}>{me.state.error}</span>
        }
        <Button type="submit" variant="contained" color="primary" onSubmit={this.handleSubmit}>
          {this.props.submitText}
        </Button>
        <p onClick={(e) => this.goToHref('/users/sign_up')}>Регистрация</p>
      </form>
      </>;
    return (xxx);
  }
}