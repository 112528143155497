import React from 'react';
import UserPatient from "./UserPatient";
import Button from '@material-ui/core/Button';


export default class UserPatientsIndex extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      user_patients: [],
      loading: true
    };

    this.updatePatients = this.updatePatients.bind(this);
    this.addPatient = this.addPatient.bind(this);
  } 



  componentDidMount() {
    this.updatePatients();
  }


  componentWillUnmount() {
  }


  updatePatients() {
    // alert("Update Patients");
    let me = this;
    fetch("/users/user_patients.json").then((e) => {
      return e.json()
    }).then((e) => {
      let prevState = {... me.state };
      prevState.user_patients = e;
      me.setState(prevState);
    });
  }


  addPatient() {
    let me = this;
    fetch("/users/create_patient").then((e) => {
      // alert("Add Patient");  
      me.updatePatients();
    });
  }


  render() {
    let xxx = <div className="white-container">
      <div style={{maxHeight: "80px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", alignItems: "center", width: "100%"}}>
        <h1>Пациенты</h1>
        <Button variant="contained" color="primary" onClick={this.addPatient} >Добавить пациента</Button>
      </div>
      {
        this.state.user_patients.map(
          (e) => 
          <UserPatient updateIndex={this.updatePatients} key={`user-patient-${e.id}`} content={e} />
        )
      }
      {
        this.state.user_patients.length == 0 &&
        <p>Пока не создано ни одного пациента</p>
      }
    </div>;
    return (xxx);
  }
}