import React from 'react';

async function postData(url = '', data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *client
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  });
  return await response.json(); // parses JSON response into native JavaScript objects
}


export default class ChatFiles extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      files: []
    }
    this.inputRef = React.createRef();
    this.addFiles = this.addFiles.bind(this);
    this.addAttachment = this.addAttachment.bind(this);
  }


  addAttachment(x) {
    let me = this;
    let prevState = this.state;
    prevState.files.push(x);
    this.setState(prevState);

  }


  componentDidMount() {
    let me = this;
    this.inputRef.current.onchange = function(e) {
      if (0 == me.inputRef.current.files) return;
      let files = me.inputRef.current.files;
      // let fileReaders = [];

      for (let i = 0; i < files.length; i++) {
        let f = files[i];
        me.props.add_attachment([{name: f.name, blob: f}]);
        me.addAttachment({name: f.name, blob: f});
        // let fileReader = new FileReader();
        // fileReader.onloadend = function (e) {
        //   var blob = e.target.result;
        //   me.props.add_attachment([{name: f.name, blob: blob}]);
        //   me.addAttachment({name: f.name, blob: blob});
        // };
        // fileReader.readAsBinaryString(f);
      }
    }
  }

  componentWillUnmount() {
    this.inputRef.current.removeAttribute("onchange");
  }


  addFiles () {
    this.inputRef.current.click();
  }


  render() {
    let me = this;
    let xxx = <div className="chat-files" > 
        <i onClick={this.addFiles} style={{margin: "7px"}}  className="fal fa-paperclip fa-2x"></i>
        { this.props.added_attachements.length > 0 && 
          <p>{this.props.added_attachements.length}</p>
        }
        <input multiple={true} ref={this.inputRef} type="file" style={{display: "none"}} />
      </div>;
    return (xxx);
  }
}