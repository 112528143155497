import React from 'react'
import SideMenuButton from "./SideMenuButton";
import "./menu.scss";

export default class SideMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
       qrScanning: false
    };
    this.goToHref = this.goToHref.bind(this);
  } 

  componentDidMount() {
  }

  goToHref(href) {
    document.location.href = href;
  }


  render() {
    let me = this;
    let xxx = <div>
        <div className={"menu-content"} style={{flexDirection: "column"}}>
          {this.props.menu.map( (item) =>
            <SideMenuButton id={item.id} href={item.action.href} title={item.title} style={{background: "#DCE2E9", fontSize: "14px"}} key={item.title} />
          )}
        </div>
      </div>;
    return (xxx);
  }
}