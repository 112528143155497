import React from 'react';
import Button from '@material-ui/core/Button';
import QrCodeScaner from "../../Users/QrCodeScaner";

export default class UserActivationStage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            specialties: [],
            qrScanned: false
        };

        this.updateCallback = this.updateCallback.bind(this);
        this.startScaner = this.startScaner.bind(this);
        this.moveToFolder = this.moveToFolder.bind(this);
        this.printPage = this.printPage.bind(this);
    }

    componentDidMount() {
        // fetch("/orders/specialties").then(
        //   (e) => {
        //     return e.json()
        //   }
        // ).then(
        //   (e) => {
        //     // alert(JSON.stringify(e));
        //     let prevState = {... this.state };
        //     prevState.specialties = e;
        //     this.setState(prevState);
        //   }
        // )
    }

    componentWillUnmount() {
    }

    startScaner() {
        let prevState = {... this.state};
        prevState.qrScanned = true;
        this.setState(prevState);
    }

    updateCallback(val) {
        // if (!!this.props.updateCallback) {
        //   this.props.updateCallback({ChooseEmployeeStage: val});
        // }
    }

    printPage() {
        this.moveToFolder();
        window.print();
    }

    justPrint() {
        window.print();
    }

    moveToFolder() {
        let me = this;
        let url = `/orders/${me.props.order.id}/activate_by_user`;
        if (url.indexOf("https://") == -1) {
            url = document.SERVER_URL + url;
        }
        // alert(url);
        fetch(url).then( (e) => {
            return e.json();
        }).then((e) => {
            if (e.errors) {
                alert(e.errors);
            } else {
                document.location.href = "/orders/"+me.props.order.id;
            }
        });
    }

    render() {
        let me = this;
        let xxx =
            <>
                {/*<h3>Подтверждение заказа</h3>*/}
                {/*<p>Распечатайте и отсканируйте QR код вашего заказа, чтобы подтвердить его и вызвать курьера</p>*/}
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                    <Button color="primary" style={{background: "#DCE2E9", fontSize: "14px", color: "#404040"}} onClick={me.moveToFolder}>Отправить заявку</Button>
                    {/*<Button color="primary" style={{background: "#DCE2E9", fontSize: "14px", color: "#404040"}} onClick={(e) => {me.moveToFolder("На согласовании")}}>На согласование</Button>*/}
                    {/*<Button color="primary" style={{background: "#DCE2E9", fontSize: "14px", color: "#404040"}}>Распечатать QR код</Button>*/}
                    {/*<Button onClick={me.startScaner} color="primary" style={{background: "#DCE2E9", fontSize: "14px", color: "#404040"}}>Отсканировать QR код</Button>*/}
                </div>
                {/*<QrCodeScaner qrScanning={me.state.qrScanned} />*/}

            </>
        ;
        return (xxx);
    }
}
