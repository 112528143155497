import React from "react";
import StatZone from "./StatZone";
import GCalendar from "../Timeline/GCalendar";


Date.prototype.yyyymmdd = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
        (dd > 9 ? "" : "0") + dd,
        ".",
        (mm > 9 ? "" : "0") + mm,
        ".",
        this.getFullYear()
    ].join("");
};

export default class Statistics extends React.Component {
    constructor(props) {
        super(props);
        let start = new Date();
        start.setUTCHours(0, 0, 0, 0);
        let end = new Date();
        end.setUTCHours(23, 59, 59, 999);
        this.state = {
            startDate: start,
            endDate: end,
            refs: []
        };
        this.setStartDateEndDate = this.setStartDateEndDate.bind(this);
        this.closeAllExcept = this.closeAllExcept.bind(this);
        // this.handleSelect = this.handleSelect.bind(this);
    }

    componentDidMount() {
        let me = this;
        let refs = [];
        for (let i = 0; i < me.props.data.sections.length; i++) {
            refs.push(React.createRef());
        }
        let prevState = {...me.state};
        prevState.refs = refs;
        me.setState(prevState);
    }

    componentWillUnmount() {}

    componentDidUpdate() {}

    setStartDateEndDate(_startDate, _endDate) {
        let me = this;
        let prevState = {... me.state};
        prevState.startDate = _startDate;
        prevState.endDate = _endDate;
        me.setState(prevState);
    }
    // handleSelect(ranges) {
    //     // alert(JSON.stringify(ranges));
    //     let prevState = { ...this.state };
    //     prevState.startDate = ranges.selection.startDate;
    //     prevState.endDate = ranges.selection.endDate;
    //     this.setState(prevState);
    //     // alert(ranges);
    // }

    // triggerCalendar() {
    //     let me = this;
    //     let prevState = { ...me.state };
    //     prevState.showCalendar = !prevState.showCalendar;
    //     me.setState(prevState);
    // }
    //
    closeAllExcept(index) {
        let me = this;
        for(let i = 0; i < me.props.data.sections.length; i++) {
            if (i == index) continue;
            me.state.refs[i].current.closeMe();
        }
    }

    render() {
        let me = this;
        return (
            <div>
                <GCalendar dataUrl={"/admin/user_orders/amounts.json"} callback={me.setStartDateEndDate}/>
                <p>{`${me.state.startDate} - ${me.state.endDate}`}</p>
                {me.props.data.sections.map((e, eId) => {
                    return <StatZone ref={me.state.refs[eId]}
                                     callback={me.closeAllExcept}
                                     myId={eId}
                                     key={`${eId}-${me.state.startDate}-${me.state.endDate}`}
                                     data={e}
                                     date_start={me.state.startDate}
                                     date_end={me.state.endDate}
                                     show_dates={false} />;
                })}
            </div>
        );
    }
}
