import React from 'react';
import Button from '@material-ui/core/Button';
import QrCodeScaner from "../../Users/QrCodeScaner";

export default class ActivateOrderStage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      specialties: [],
      qrScanned: false
    };

    this.updateCallback = this.updateCallback.bind(this);
    this.startScaner = this.startScaner.bind(this);
    this.moveToFolder = this.moveToFolder.bind(this);
    this.printPage = this.printPage.bind(this);
    this.justPrint = this.justPrint.bind(this);
  }

  componentDidMount() {
    // fetch("/orders/specialties").then(
    //   (e) => {
    //     return e.json()
    //   } 
    // ).then(
    //   (e) => {
    //     // alert(JSON.stringify(e));
    //     let prevState = {... this.state };
    //     prevState.specialties = e;
    //     this.setState(prevState);
    //   }
    // )
  }

  componentWillUnmount() {
  }

  startScaner() {
    let prevState = {... this.state};
    prevState.qrScanned = true;
    this.setState(prevState);
  }

  updateCallback(val) {
    // if (!!this.props.updateCallback) {
    //   this.props.updateCallback({ChooseEmployeeStage: val});
    // }
  }

  printPage(_ev) {
    // alert(_ev);
    _ev.target.style.opacity = 0.5;
    _ev.target.style.pointerEvents = "none";

    this.moveToFolder().then((e) => {
      _ev.target.style.opacity = 1;
      _ev.target.style.pointerEvents = "all";
    });
    // window.print();
  }

  justPrint() {
    let me = this;
    document.location.href = `/admin/user_orders/${me.props.order.id}/print`
  }

  moveToFolder() {
    let me = this;
    let url = `/admin/user_orders/${me.props.order.id}/put_to_folder?folder_name=${encodeURI('в работе')}`;
    if (url.indexOf("https://") == -1) {
      url = document.SERVER_URL + url;
    }

    return new Promise((resolve, reject) => {
      fetch(url).then( (e) => {
        //document.location.reload()
        document.location.href = "/admin/user_orders/"+me.props.order.id+"?with_print=true";
        //document.location.href = "/admin/user_orders?q%5Bstatus_contains%5D=на+согласовании&commit=Filter&order=id_desc";
        resolve(true);
      });
    });
    // alert(url);
  }

  render() {
    let me = this;
    let xxx = 
      <>
        {/*<h3>Подтверждение заказа</h3>*/}
        {/*<p>Распечатайте и отсканируйте QR код вашего заказа, чтобы подтвердить его и вызвать курьера</p>*/}
        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
          { "в работе" != me.props.order.status &&
            <Button color="primary" style={{background: "#DCE2E9", fontSize: "14px", color: "#404040"}} onClick={me.printPage}>В работу</Button>
          }
          { "в работе" == me.props.order.status &&
            <Button color="primary" style={{background: "#DCE2E9", fontSize: "14px", color: "#404040"}} onClick={me.justPrint}>Распечатать заказ-наряд</Button>
          }
          {/*<Button color="primary" style={{background: "#DCE2E9", fontSize: "14px", color: "#404040"}} onClick={(e) => {me.moveToFolder("На согласовании")}}>На согласование</Button>*/}
          {/*<Button color="primary" style={{background: "#DCE2E9", fontSize: "14px", color: "#404040"}}>Распечатать QR код</Button>*/}
          {/*<Button onClick={me.startScaner} color="primary" style={{background: "#DCE2E9", fontSize: "14px", color: "#404040"}}>Отсканировать QR код</Button>*/}
        </div>
        {/*<QrCodeScaner qrScanning={me.state.qrScanned} />*/}

      </>
    ;
    return (xxx);
  }
}
