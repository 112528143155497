import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import "./date-time-picker.scss";
import TextField from "@material-ui/core/TextField";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import "./calendar.scss";

function zeroPad(num, places) {
  var zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join("0") + num;
}

export default class SelfUpdateDateTimeField extends React.Component {
	constructor(props) {
	    super(props);
	    const now = new Date();
	    const currentMonth = now.getMonth();
	    const currentYear = now.getFullYear();
		let currentDate = new Date();
		let minDate = null; //new Date(currentDate.getTime()  + 24 * 60 * 60 * 1000 );
		let maxDate = null; //new Date(currentDate.getTime()  + 35 * 24 * 60 * 60 * 1000 );

		this.state = {
			resource: null,
			currentDate: new Date(),
			days: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
			months: [
				'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
				'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
			],
			weekDays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
			lastMonth: 11,
			month: 0,
			nextMonth: 1,
			year: 0,
			currentMonth: currentMonth,
			day: -1,
			currentYear: currentYear,
			time: [0,0],
			calendar: [
				{ id: 'week-1', data: [0, 0, 0, 0, 0, 0, 0] },
				{ id: 'week-2', data: [0, 0, 0, 0, 0, 0, 0] },
				{ id: 'week-3', data: [0, 0, 0, 0, 0, 0, 0] },
				{ id: 'week-4', data: [0, 0, 0, 0, 0, 0, 0] },
				{ id: 'week-5', data: [0, 0, 0, 0, 0, 0, 0] },
				{ id: 'week-6', data: [0, 0, 0, 0, 0, 0, 0] },
			],
			holidays: [],
			holiday: '',
			minDate: minDate,
			maxDate: maxDate
	    };

	    this.previousCalendar = this.previousCalendar.bind(this);
	    this.nextCalendar = this.nextCalendar.bind(this);
	    this.changeTime = this.changeTime.bind(this);
	    this.setDay = this.setDay.bind(this);
	    this.setDate = this.setDate.bind(this);
	    this.handleChange = this.handleChange.bind(this);
	    this.handleClose = this.handleClose.bind(this);
		this.changeHours = this.changeHours.bind(this);
		this.changeMinutes = this.changeMinutes.bind(this);
		this.onCalendarChange = this.onCalendarChange.bind(this);
		this.getData = this.getData.bind(this);
		this.getLimits = this.getLimits.bind(this);
	}


	handleClose() {
	  let prevState = {... this.state};
	  prevState.successOpen = false
	  this.setState(prevState);
	}


	async setDate(date) {
		let me = this;
		return new Promise((resolve => {
	  		let prevState = {... me.state};
			prevState.currentDate = date;
			prevState.time = [date.getHours(), date.getMinutes()];
			me.setState(prevState, () => {
				resolve(true);
			});
		}));
	}

	componentWillMount() {
	    const now = new Date();
	    const currentMonth = now.getMonth();
	    const currentYear = now.getFullYear();
	    this.setCalendar(new Date(currentYear, currentMonth, 1));
	}


	componentDidMount() {
		let me = this;
		me.getData().then(me.getLimits);
	}

	async getLimits() {
		let me = this;
		const now = new Date();
		return new Promise((resolve) => {
			if (me.props.limits_get_url) {
				fetch(me.props.limits_get_url).then((e) => {
					return e.json();
				}).then((e) => {
					let daySizeInMilliseconds = 24 * 60 * 60 * 1000;
					let min = new Date(now.getTime() + e.min_time * daySizeInMilliseconds);
					let max = new Date(now.getTime() + e.max_time * daySizeInMilliseconds);

					let prevState = {... me.state};
					prevState.minDate = min;
					prevState.maxDate = max;
					// alert(me.props.limits_get_url);
					me.setState(prevState, () => {
						// alert("Min and max set" + `${min} -  ${max}`);
						setTimeout(me.getLimits, 5000);
						resolve(true);
					});
				}).catch((e) => {
					setTimeout(me.getLimits, 5000);
				});
			} else {
				resolve(true);
			}
		});

	}

	async getData() {
		let me = this;
		return new Promise((resolve) => {
			fetch(this.props.getUrl)
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				// alert(data[me.props.fieldName]);
				if (!!data[me.props.fieldName]) {
					me.setDate(new Date(data[me.props.fieldName])).then((e) => {
						resolve(true);
					});
				} else {
					resolve(true);
				}
			});
		});
	}


	handleChange(newValue) {
	    let me = this;

	    if (me.props.activeCallback) {
	    	me.props.activeCallback(newValue);
	    	return;
	    }
	    // let url = 
	    fetch(this.props.updateUrl, {
	      method: 'POST',
	      headers: {
	        'Content-Type': 'application/json;charset=utf-8'
	      },
	      body: JSON.stringify({
	        new_value: newValue,
	        changed_field: me.props.fieldName
	      })
	    }).then((response) => {
	      return response.json();
	    }).then((data) => {
	    	let prevState = {... me.state};
	    	prevState.resource = data;
	    	prevState.successOpen = true;

			me.setState(prevState);

			if (!!me.props.updateCallback) {
				me.props.updateCallback(true);
			}

			setTimeout(function() {
		        let prevState = {... me.state};
		        prevState.successOpen = false;
		        me.setState(prevState);
			}, 5000);

	    });
		return true;
	}


	setDay(day) {
	    // alert(JSON.stringify(day));
	    let newDateValue = new Date(this.state.currentYear, day.month, day.value, this.state.time[0], this.state.time[1]);
	    let prevState = {... this.state};
	    prevState.currentDate = newDateValue;
	    this.setState(prevState);
	    this.handleChange(newDateValue);
	    // alert(newDayValue);
	}

	changeTime(x) {
	    let prevState = {... this.state};
	    let me = this;
	    let time = prevState.time;
	    let newH = (time[0]+x[0]) % 24;
	    let newM = (time[1]+x[1]) % 24;
	    if (newH < 0) {
	      newH = 24 + newH;
	    }
	    if (newM < 0) {
	      newM = 60 + newM;
	    }
	    let newTime = [newH, newM];
	    prevState.time = newTime;
	    let newDateValue = new Date(this.state.currentYear, this.state.currentDate.getMonth(), this.state.currentDate.getDate(), newTime[0], newTime[1]);
	    prevState.currentDate = newDateValue;
	    this.setState(prevState, function() {
			me.handleChange(newDateValue);
		});
	}

	setMonth(date) {
	    const month = date.getMonth();
	    const lastMonth = month === 0 ? 11 : month - 1;
	    const nextMonth = month === 11 ? 0 : month + 1;

	    this.setState({
	      lastMonth,
	      month,
	      nextMonth,
	    });

	    return { lastMonth, month, nextMonth };
	}

	setCalendar(date) {
	    const { lastMonth, month, nextMonth } = this.setMonth(date);
	    const year = date.getFullYear();
	    const weekday = date.getDay();
	    const days = this.checkLeapYear(year);
	    let nextMonthDay = 0;

	    const firstWeek = this.state.calendar[0].data.map((day, index) => {
	      let holiday = '';
	      if (index < weekday) {
	        const value = (days[lastMonth] - (weekday - index)) + 1;
	        return {
	          value,
	          class: 'day--soft',
	          month: lastMonth,
	        };
	      }
	      const value = (index - weekday) + 1;
	      return {
	        value: (index - weekday) + 1,
	        class: '',
	        month,
	      };
	    });
	    const secondWeek = this.state.calendar[0].data.map((day, index) => {
	      const value = firstWeek[6].value + index + 1;
	      return {
	        value,
	        class: '',
	        month,
	      };
	    });
	    const thirdWeek = this.state.calendar[0].data.map((day, index) => {
	      const value = secondWeek[6].value + index + 1;
	      return {
	        value,
	        class: '',
	        month,
	      };
	    });
	    const forthWeek = this.state.calendar[0].data.map((day, index) => {
	      const value = thirdWeek[6].value + index + 1;
	      return {
	        value,
	        class: '',
	        month,
	      };
	    });
	    const fifthWeek = this.state.calendar[0].data.map((day, index) => {
	      if (forthWeek[6].value + index + 1 > days[month]) {
	        nextMonthDay += 1;
	        return {
	          value: nextMonthDay,
	          class: 'day--soft',
	          month: nextMonth,
	        };
	      }
	      const value = forthWeek[6].value + index + 1;
	      return {
	        value,
	        class: '',
	        month,
	      };
	    });
	    const sixthWeek = this.state.calendar[0].data.map((day, index) => {
	      if (fifthWeek[6].value + index + 1 > days[month] || fifthWeek[6].value < 10) {
	        nextMonthDay += 1;
	        return {
	          value: nextMonthDay,
	          class: 'day--soft',
	          month: nextMonth,
	        };
	      }

	      const value = fifthWeek[6].value + index + 1;
	      return {
	        value,
	        class: '',
	        month,
	      };
	    });

	    this.setState({
	      month,
	      year,
	      calendar: [
	        { id: 'week-1', data: firstWeek },
	        { id: 'week-2', data: secondWeek },
	        { id: 'week-3', data: thirdWeek },
	        { id: 'week-4', data: forthWeek },
	        { id: 'week-5', data: fifthWeek },
	        { id: 'week-6', data: sixthWeek },
	      ],
	    });
	}

	checkLeapYear(year) {
	    let days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
	    if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
	      days = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
	    }
	    this.setState({
	      days,
	    });
	    return days;
	}

	previousCalendar() {
	    const month = this.state.month !== 0 ? this.state.month - 1 : 11;
	    const year = this.state.month !== 0 ? this.state.year : this.state.year - 1;
	    this.setCalendar(new Date(year, month, 1));
	}

	nextCalendar() {
	    const month = this.state.month !== 11 ? this.state.month + 1 : 0;
	    const year = this.state.month !== 11 ? this.state.year : this.state.year + 1;
	    this.setCalendar(new Date(year, month, 1));
	}


	changeHours(_h) {
		if (!_h) return;
		if (_h.length == 0) return;
		let h = parseInt(_h.target.value);
		// alert(h);

		let me = this;
		let prevState = {... this.state};
		let time = prevState.time;
		let newH = h % 24;
		let newM = (time[1]) % 60;
		if (newH < 0) {
			newH = 24 + newH;
		}
		if (newM < 0) {
			newM = 60 + newM;
		}
		let newTime = [newH, newM];
		prevState.time = newTime;
		// alert(newTime);
		try {
			let newDateValue = new Date(this.state.currentYear, this.state.currentDate.getMonth(), this.state.currentDate.getDate(), newTime[0], newTime[1]);
			if (!(newDateValue instanceof Date && !isNaN(newDateValue)))
				return;
			prevState.currentDate = newDateValue;
			this.setState(prevState, function () {
				me.handleChange(newDateValue);
			});
		} catch(e) {}

		return true;
	}

	changeMinutes(_m) {
		if (!_m) return;
		if (_m.length == 0) return;
		let me = this;
		let m = parseInt(_m.target.value);
		let prevState = {... this.state};
		let time = prevState.time;
		let newH = (time[0]) % 24;
		let newM = (m) % 60;
		if (newH < 0) {
			newH = 24 + newH;
		}
		if (newM < 0) {
			newM = 60 + newM;
		}
		let newTime = [newH, newM];
		prevState.time = newTime;
		try {
			let newDateValue = new Date(this.state.currentYear, this.state.currentDate.getMonth(), this.state.currentDate.getDate(), newTime[0], newTime[1]);
			prevState.currentDate = newDateValue;
			if (!(newDateValue instanceof Date && !isNaN(newDateValue)))
				return;
			this.setState(prevState, function () {
				me.handleChange(newDateValue);
			});
		} catch (e) {}
	}

	onCalendarChange(_m) {
		let prevState = {... this.state};
		let me = this;
		let newDateValue = new Date(_m.getFullYear(), _m.getMonth(), _m.getDate(), prevState.time[0], prevState.time[1]);

		prevState.currentDate = newDateValue;
		this.setState(prevState, function() {
			me.handleChange(newDateValue);
		});
	}

	render() {
		let me = this;
		let style = {};
		if (me.props.disabled) {
			style = {
				pointerEvents: "none",
				opacity: 0.5
			}
		}
		// console.log(this.state.calendar);
	    return (
	    	<div className="date-time-field" style={style}>
	    		<p className="cool-label">{this.props.label}</p>
	    		<div key={`calendar-${me.state.minDate} - ${me.state.maxDate}`} style={{display: "flex", flexDirection: "row", alignContent: "center", alignItems: "center"}}>
					{/*<p>{`${me.state.minDate} - ${me.state.maxDate}`}</p>*/}
					<Calendar
						onChange={me.onCalendarChange}
						minDate={me.state.minDate}
						maxDate={me.state.maxDate}
						value={me.state.currentDate}
					/>
					<div className="time-input">
				      <div className="time-place">
				        <i className="fal fa-angle-up fa-2x" onClick={(e) => this.changeTime([1,0])} />
				        {/*<p>{zeroPad(this.state.time[0],2)}</p>*/}
						  <TextField type="number" onChange={ (x) => me.changeHours(x) } value={me.state.time[0]} inputProps={{min: 0, style: { textAlign: 'center' }}} style={{maxWidth: "49px", margin: "7px"}}/>
				        <i className="fal fa-angle-down fa-2x" onClick={(e) => this.changeTime([-1,0])} />
				      </div>
				      <p>:</p>
				      <div className="time-place">
				        <i className="fal fa-angle-up fa-2x" onClick={(e) => this.changeTime([0,1])} />
				        {/*<p>{zeroPad(this.state.time[1],2)}</p>*/}
						  <TextField type="number" onChange={ (x) => me.changeMinutes(x) } value={me.state.time[1]} inputProps={{min: 0, style: { textAlign: 'center' }}} style={{maxWidth: "49px", margin: "7px"}}/>
				        <i className="fal fa-angle-down fa-2x" onClick={(e) => this.changeTime([0,-1])} />
				      </div>
				    </div>
			    </div>
			    <Snackbar open={me.state.successOpen} autoHideDuration={6000} onClose={me.handleClose}>
				  <Alert onClose={me.handleClose} severity="success">
				    Данные обновлены
				  </Alert>
				</Snackbar>
			</div>
	    );
	}
}
