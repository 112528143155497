import React from 'react';
import KanBanView from "../KanbanView/KanbanView";
import CircularProgress from '@material-ui/core/CircularProgress';

export default class UserOrders extends React.Component {
  
  constructor(props) {
    super(props);

    this.getData = this.getData.bind(this);
    // alert(212312);
    this.state = {
      current_user: null
    };
  } 

  componentDidMount() {
    this.getData();
    // this.setState(this.props.content);
  }


  getData() {
    let prevState = {... this.state};
    prevState.current_user = JSON.parse(this.props.current_user);
    this.setState(prevState);
    // alert(JSON.stringify(this.props.current_user));
  }




  render() {
    let me = this;
    let xxx = <div>
      <h1>Мои заказы</h1>
      {!me.state.current_user && 
        <CircularProgress />
      }

      {!!me.state.current_user && 
        <KanBanView user={me.state.current_user} />
      }
    </div>;
    return (xxx);
  }
}