import React from "react";
import "./Timeline.scss";
import OrderTimer from "../../Timer/Timer";

class TimelineElement extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
    }

    componentWillMount() {
        // could do something like pull state from API
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    componentDidUpdate() {
    }

    render() {
        let me = this;

        return <div className={"timeline-element"} >
            <div className={'timeline-element-line'}>
                <div className={'circle'}></div>
                <div className={'vertical-line'}></div>
            </div>
            <div className={`timeline-content`}>
                <h2>{me.props.content.name}</h2>
                <p>{me.props.content.needed_action}</p>
                { me.props.content.actions &&
                <div className={`timeline-item-actions`}>
                    {me.props.content.actions.map( (eAction, eActionIndex) => {
                        return <button key={`e-a-${eActionIndex}`}>{eAction.title}</button>
                    })}
                </div>
                }
            </div>
            { !!me.props.content.order_timer &&
                <OrderTimer order_timer={me.props.content.order_timer}/>
            }
        </div>;
    }

}

export default class Timeline extends React.Component {
    constructor(params) {
        super(params);
    }

    render() {
        let me = this;
        return <div className={'timeline'}>
            {me.props.elements.map( (e, eIndex) => {
                return <TimelineElement content={e} key={`element-${eIndex}`} />
            })}
        </div>;
    }
}
