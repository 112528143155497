import React from 'react';
import JawTooth from "./JawTooth";
import "./jaw.scss";
import ToothColorPickerV2 from "../tooth_menu/ToothColorPickerV2";
import Button from '@material-ui/core/Button';
import DrawZone from "./DrawZone";

let teethNumbers = [
    "18","17","16","15","14","13","12","11","21","22","23","24","25","26","27","28","38","37","36","35","34","33","32","31","41","42","43","44","45","46","47","48"
];

function arrayRotate(arr) {
  arr.unshift(arr.pop());
  return arr;
}

var prevToothTouched = null;
class Jaw extends React.Component {
  
  constructor(props) {
    super(props);
    let teeth = teethNumbers.map((x) => {return {toothNumber: x, has_bridge: false, menus: [], task: {}, hovered: false, color: "#FFFFFE"} });
    this.state = {
        teeth: teeth,
        menus: [],
        task: {},
        orderLoaded: false,
    };
    
    
    this.setColor = this.setColor.bind(this);
    this.addBridge = this.addBridge.bind(this);
    this.setColorDirect = this.setColorDirect.bind(this);
    this.setTzReady = this.setTzReady.bind(this);
    this.continueEnabled = this.continueEnabled.bind(this);
    this.addBridgeToTooth = this.addBridgeToTooth.bind(this);
    this.updateData = this.updateData.bind(this);
    this.undoAction = this.undoAction.bind(this);

    this.jawTzRef = React.createRef();

    // alert(JSON.stringify(this.props.order));
  }

  componentDidMount() {
      let me = this;
    // alert(JSON.stringify(this.props.order));
    if (!!this.props.order) {
        let data = {};
        try {
            data = JSON.parse(this.props.order["tz"+me.props.activeJaw]);
        } catch (e) {
        }
        // alert(JSON.stringify(data));
        this.setState(data);   
    }
    
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   if (!!this.props.order && !!this.props.order.tz && !this.state.orderLoaded) {
  //       let prevState = {... this.state};

  //       prevState.teeth = JSON.parse(this.props.order.tz).teeth;
  //       prevState.orderLoaded = true;
  //       // alert(JSON.parse(this.props.order.tz).teeth);
  //       // alert(322);
  //       this.setState(prevState);   
  //   }
  // }


  addBridgeToTooth(tooth) {
    let res = {... tooth};
    let toothIndex = teethNumbers.indexOf(tooth.toothNumber)
    let nextTooth = this.state.teeth[toothIndex + 1];
    if (tooth.has_bridge || ((Object.keys(tooth.task).length > 0) && (Object.keys(nextTooth.task).length > 0))) {
        res.has_bridge = !tooth.has_bridge;
    }
    // alert(JSON.stringify(res));
    return res;
  }


  addBridge(_toothNumber, event) {
    let toothIndex = teethNumbers.indexOf(_toothNumber);
    if (toothIndex == -1) return;
    let tooth = this.state.teeth[toothIndex];
    tooth = this.addBridgeToTooth(tooth);
    
    let tempState = {... this.state};
    tempState.teeth[toothIndex] = tooth;
    this.props.updateData(JSON.stringify(tempState));
    // // alert(Object.keys(tooth.task).length);
    // // alert(Object.keys(nextTooth.task).length);
    // // alert(12);
    // if (tooth.has_bridge || ((Object.keys(tooth.task).length > 0) && (Object.keys(nextTooth.task).length > 0))) {
    //     tooth.has_bridge = !tooth.has_bridge;
    //     // alert(tooth.has_bridge);
    //     tempState.teeth[toothIndex] = tooth;
    //     // alert(JSON.stringify(tooth));
    //     this.setState(tempState);
    //     this.props.updateData(JSON.stringify(tempState));
    // }

  }

  async setColorDirect(_toothNumberTouched, task) {
    let me = this;
    let tempState = {... me.state};

    return new Promise( (resolve, reject) => {
    
        let toothIndex = teethNumbers.indexOf(_toothNumberTouched);
        let tooth = me.state.teeth[toothIndex];
        
        tooth.task = task;
        
        if (JSON.stringify(task).indexOf("Понтик") >= 0 ) {
            let bridgedToothIndex = toothIndex - 1;
            if (!(bridgedToothIndex < 0 || bridgedToothIndex > 31)) {
                let bridgedTooth = me.state.teeth[bridgedToothIndex];    
                if (!bridgedTooth.has_bridge) bridgedTooth = me.addBridgeToTooth(bridgedTooth);
                tempState.teeth[bridgedToothIndex] = bridgedTooth;
            }
            if (!tooth.has_bridge) tooth = me.addBridgeToTooth(tooth);

            let leftTooth = toothIndex - 1;
            if (JSON.stringify(task).indexOf("Скопировать параметры соседа") >= 0) {
                if (leftTooth >=0) {
                    let copiedTooth = me.state.teeth[bridgedToothIndex];
                    // tooth.task = copiedTooth.task;
                    for(let i in Object.keys(copiedTooth.task)) {
                        let key = Object.keys(copiedTooth.task)[i];
                        if (!tooth.task[key]) {
                            tooth.task[key] = copiedTooth.task[key];
                        }
                    }
                    // tooth.task["Тип реставрации"] = task["Тип реставрации"];
                    // tooth.task["Реставрация"] = task["Реставрация"];
                }
            }
        }

        tempState.teeth[toothIndex] = tooth;

        me.setState(tempState, function() {
            resolve(true);
            // me.props.updateData(JSON.stringify(tempState));    
        });
        // alert(JSON.stringify(tempState));
        
    });
  }


  async updateData() {
    let me = this;
    return new Promise( (resolve, reject) => {
        me.props.updateData(JSON.stringify(me.state));
        resolve(true);
    });
  }


  async setColor(_toothNumberTouched, task, holdsShift) {
    let me = this;
    let tsk = Object.assign({}, task);
    let teethNamesToWorkOn = [_toothNumberTouched];
    let tempState = {... this.state };
    if (holdsShift && !!prevToothTouched) { //CW or CCW fill by least distance
        // alert(1);
        teethNamesToWorkOn = [];
        let index1 = teethNumbers.indexOf(prevToothTouched);
        let index2 = teethNumbers.indexOf(_toothNumberTouched);
        let iAmCW = true;
        if (index1 > index2) {
            [index1, index2] = [index2, index1];
            iAmCW = false;
        }

        for(let i=index1 + iAmCW; i < index2 + !!iAmCW; i++) {
            teethNamesToWorkOn.push(teethNumbers[i]);
        }
    }
    
    prevToothTouched = _toothNumberTouched;
    if (this.props.disabled) {
        // console.log(2.1);
        let index = teethNumbers.indexOf(_toothNumberTouched);
        let toothTask = this.state.teeth[index].task;

        if (Object.keys(toothTask).length > 0) {
            // alert(JSON.stringify(toothTask));
            if (me.props.resettingTooth) {
                // alert("Reset");
                let pState = {... me.state};
                // alert(JSON.stringify(pState));
                pState.teeth[index].task = {};
                pState.teeth[index].color = null;
                me.props.updateData(JSON.stringify(pState));
                return;
            }

            this.props.setWorkFromTooth(toothTask, _toothNumberTouched);
        } else {
            // alert(teethNamesToWorkOn);
            if (teethNamesToWorkOn.length > 0) {
                for(let tId in teethNamesToWorkOn) {
                    await this.props.addSelected(teethNamesToWorkOn[tId]);
                }
            }
        }
        me.setState(tempState);
        return;
    }
    // alert(444);

    
    console.log(3);
    // alert(JSON.stringify(teethNamesToWorkOn));
    // alert("0000");
    if (!this.props.setColor) {
        // alert(111);
        console.log(3.1);
        this.props.addSelected(_toothNumberTouched);
        if (teethNamesToWorkOn.length > 0) {
            for(let tId in teethNamesToWorkOn) {
                this.props.addSelected(teethNamesToWorkOn[tId]);
            }
        }
        // alert(!!tsk.adds_value);
        if (!!tsk.adds_value) {
            console.log("3.1.1");
            // alert(12121222);
            // alert(teethNamesToWorkOn);
            for(let _toothId in teethNamesToWorkOn) {
                let _toothNumber = teethNamesToWorkOn[_toothId];
                // alert(_toothNumber);
                
                let toothIndex = teethNumbers.indexOf(_toothNumber);
                let tooth = this.state.teeth[toothIndex];
                let taskKeyFound = false;
                for (let i in Object.keys(tsk)) {
                    let taskKey = Object.keys(tsk)[i];
                    if (tooth.task[taskKey]) taskKeyFound = true;
                    if (taskKey != "adds_value")
                        tooth.task[taskKey] =  tsk[taskKey];
                }

                let rotatedKeys = Object.keys(tooth.task);
                let newToothTask = {};
                if (!taskKeyFound) {
                    rotatedKeys = arrayRotate(Object.keys(tooth.task));
                }
                // alert(rotatedKeys);
                for(let keyIndex in rotatedKeys) {
                    let key = rotatedKeys[keyIndex];
                    newToothTask[key] = tooth.task[key];
                }

                tempState.teeth[toothIndex].task = newToothTask;
            }
            console.log("3.1.2")
            me.setState(tempState, function() {
                me.props.updateData(JSON.stringify(tempState));    
            });
        }
        // alert("0000111");
         // tempState = {... this.state };
        // tempState.prevToothTouched = _toothNumberTouched;
        // // alert(tempState.prevToothTouched);
        // this.setState(tempState);
        // alert(2.2);
        return;
    }

    console.log(4);
    // let tempState = Object.assign({}, this.state);
    
    // alert(JSON.stringify(teethNamesToWorkOn));
    for(let _toothId in teethNamesToWorkOn) {
        let _toothNumber = teethNamesToWorkOn[_toothId];
        let toothIndex = teethNumbers.indexOf(_toothNumber);
        let tooth = this.state.teeth[toothIndex];
        // alert(222);
        console.log(tooth.color + " " + this.props.setColor);
        if (tooth.color != this.props.setColor) {
            // alert(313131);
            // if (me.props.resettingTooth) {
            //     tooth.task = {};
            //     tempState.teeth[toothIndex] = tooth;
            //     continue;
            // }
            //
            // console.log("4.1");
            // tooth.color = this.props.setColor;
            // let tsk = Object.assign({}, this.state.task);
            
            // tsk.color = tooth.color;
            // alert(3);

            if (!tsk.adds_value) {
                console.log("4.1.1");
                if (Object.keys(tooth.task).length == 0)
                    tooth.task = tsk;
            }
            else {
                console.log("4.1.2");
                // alert(12121222);
                if (Object.keys(tooth.task).length == 0)
                    for (let i in Object.keys(tsk)) {
                        let taskKey = Object.keys(tsk)[i];
                        if (taskKey != "adds_value")
                            tooth.task[taskKey] =  tsk[taskKey];
                    }
            }
            // alert(JSON.stringify(tooth.task));
        } else {
            console.log(4.2);
            tooth.color = "#FFFFFE";    
            tooth.task = {};
        }

        // tooth.task = task;
        if (JSON.stringify(tsk).indexOf("Понтик") >= 0 ) {
            let bridgedToothIndex = toothIndex - 1;
            if (!(bridgedToothIndex < 0 || bridgedToothIndex > 31)) {
                let bridgedTooth = me.state.teeth[bridgedToothIndex];    
                if (!bridgedTooth.has_bridge) bridgedTooth = me.addBridgeToTooth(bridgedTooth);
                tempState.teeth[bridgedToothIndex] = bridgedTooth;
            }
            if (!tooth.has_bridge) tooth = me.addBridgeToTooth(tooth);
        }

        // tooth.task.color = this.props.setColor;
        tempState.teeth[toothIndex] = tooth;
    }
    console.log(5);
    // alert(22);
    me.props.updateData(JSON.stringify(tempState));
    // me.setState(tempState, function() {
        // me.props.updateData(JSON.stringify(tempState));    
    // });
  }


  setTzReady() {
    let me = this;
    let url = `/orders/${this.props.order.id}/update_field`;
    if (url.indexOf("https://") == -1) {
      url = document.SERVER_URL + url;
    }
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify({
        new_value: true,
        changed_field: "tz_ready"
      })
    }).then((response) => {
      return response.json();
    }).then((e) => {
        me.props.updateCallback();
        // this.props.updateData(JSON.stringify(tempState));
    })
  }

  continueEnabled() {
    let me = this;
    try {
        return me.jawTzRef.current.continueEnabled();
    } catch(e) {
        return false;
    }
  }

  undoAction() {
      let me = this;
      let url = `/orders/${this.props.order.id}/undo`;
      if (url.indexOf("https://") == -1) {
          url = document.SERVER_URL + url;
      }

      fetch(url).then((response) => {
          return response.json();
      }).then((e) => {
          if (e.tz) {
              let data = JSON.parse(e.tz);
              me.setState(data, function (xx) {
                  me.props.getOrderData();
              });
          }

          // this.props.updateData(JSON.stringify(tempState));
      })
  }


  render() {
    // alert(1111);
    let me = this;


    let teeth = this.state.teeth;
    let numOfFilledTeeth = 0;
    for(let j in teeth) {
        if (Object.keys(teeth[j].task).length > 0) {
            numOfFilledTeeth += 1;
        }
    }

    if (!this.state.teeth) {
        return <p>Данные загружаются</p>
    }

    // } catch(e) {}

    // alert(teeth.length);
    let jawDisabled = !!me.props.narrative_image_src || !!me.props.blocked_by_menu;
    let xxx = <div  style={{width: "357px", height: "820px", position: "relative"}}>
  		{(!me.props.narrative_image_src && !me.props.blocked_by_menu) &&
            <p className='jaw-hint'>
                {!me.props.resettingTooth &&
                    <span>{this.props.hint}</span>
                }
                {!!me.props.resettingTooth &&
                    <span style={{color: "#f00"}}>
                        Вы находитесь в режиме удаления ТЗ<br/>
                        <button onClick={me.props.stopResetting}>Остановить режим удаления</button>
                    </span>
                }
                { me.props.order.get_speciality && me.props.order.get_speciality.teeth_max_number &&
                    <b style={{marginLeft: "8px"}}>{numOfFilledTeeth}/{me.props.order.get_speciality.teeth_max_number}</b>
                }
            </p>
        }
        <div onClick={me.undoAction} className={"hide-print"} style={{display: "flex", flexDirection: "row", alignItems: "center", alignContent: "center", width: "100%", justifyContent: "center", position: "absolute", zIndex: 10, top: "-21px", right: "0"}}>
            <p className={"hide-print"} style={{margin: 0, marginRight: "14px"}}>Отменить действие </p>
            <i className="far fa-undo hide-print"></i>
        </div>

        {!me.props.takes_color && !!me.props.narrative_image_src && 
            <img className="jaw-image" src={`${document.SERVER_URL}${me.props.narrative_image_src}`} />
        }

        {!!me.props.takes_color && 
            <ToothColorPickerV2 ref={me.jawTzRef} takes_color_areas_schema={me.props.takes_color_areas_schema} order={me.props.order} key={`color-task-${JSON.stringify(me.props.setWork)}`} task={me.props.setWork} setVariable={me.props.setVariable}/>
        }

            <div style={{position: "absolute", top: "80px"}}>
                <DrawZone order={me.props.order} />
            </div>

            { teeth.map( (item, index) =>  {
                return <span key={`tooth-${index}`} style={{opacity: (!!jawDisabled ? '0.4' : 1), pointerEvents: (!!jawDisabled ? 'none' : "all")}}>
                    <JawTooth teeth_prices={me.props.teeth_prices}
                              chosenTeeth={me.props.chosenTeeth}
                              current_user={this.props.current_user}
                              updateCallback={me.props.updateCallback}
                              has_bridge={item.has_bridge}
                              addBridge={this.addBridge}
                              key={`tooth_${index}`}
                              toothNumber={item.toothNumber}
                              task={item.task}
                              color={item.color}
                              setColor={this.setColor}
                              settingColor={this.props.setColor}
                              setWork={this.props.setWork}
                              order={me.props.order} />
                </span>
            })}

        { me.props.order.get_speciality && me.props.order.get_speciality.teeth_max_number &&
            <Button onClick={numOfFilledTeeth <= me.props.order.get_speciality.teeth_max_number ? me.setTzReady : null}
                    style={{width: "calc(100% - 35px)", position: "absolute", top: "626px", left: "21px"}}>
                { (numOfFilledTeeth <= me.props.order.get_speciality.teeth_max_number) && (numOfFilledTeeth > 0) &&
                    <span>Завершить заполнение</span>
                }

                { (numOfFilledTeeth > me.props.order.get_speciality.teeth_max_number) || (numOfFilledTeeth == 0) &&
                    <span style={{color: "#f00"}}>Невозможно завершить заполнение, так как назначено ограничение количества единиц в одном заказе: мин. 1, макс. {me.props.order.get_speciality.teeth_max_number} шт.</span>
                }
            </Button>
        }

        { !(me.props.order.get_speciality && me.props.order.get_speciality.teeth_max_number) &&
            <Button onClick={numOfFilledTeeth > 0  ? me.setTzReady : null}
                    style={{width: "calc(100% - 35px)", position: "absolute", top: "626px", left: "21px"}}>
                {numOfFilledTeeth > 0 &&
                    <span>Завершить заполнение</span>
                }
                {numOfFilledTeeth === 0 &&
                    <span style={{color: "#f00"}}>Для продолжения заполните хотя бы одну единицу</span>
                }
            </Button>
        }
    </div>;
    return (xxx);
  }
}


export default Jaw;