import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import ToothColorPicker from './ToothColorPicker';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DateTimePicker from '../../Shared/DateTimePicker';
import "./menus.scss";
import {Checkbox, Tooltip} from "@material-ui/core";
import RemovedToothChooser from "./RemovedToothChooser";
import XmlTableView from "./XmlTableView";


export default class MenuItem extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      toothColorDialogOpen: false,
      deletedTeethDialogOpen: false,
      name: "",
      menuVariant: null,
      dateDialogOpen: false,
      textValue: ""
    }

    this.openMenu = this.openMenu.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.openToothColorDialog = this.openToothColorDialog.bind(this);
    this.openDateDialog = this.openDateDialog.bind(this);
    this.setColor = this.setColor.bind(this);
    this.handleDateClose = this.handleDateClose.bind(this);
    this.setTextValue = this.setTextValue.bind(this);
    this.setTextValueAndContinue = this.setTextValueAndContinue.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.handleDeletedTeethClose = this.handleDeletedTeethClose.bind(this);
  }

  componentDidMount() {
    // alert(JSON.stringify(this.props.content));
    let prevState = {... this.props.content};
    prevState.toothColorDialogOpen = false;
    if (!prevState.name) prevState.name = "";
    this.setState(prevState);
  }

  handleClose() {
    let me = this;
    let prevState = {... this.state};
    // alert(JSON.stringify(prevState));
    prevState.toothColorDialogOpen = false;
    // alert(JSON.stringify(prevState));
    this.setState(prevState, (e) => {
      // me.forceUpdate();
      // alert(2);
    });
  }

  handleDateClose() {
    let me = this;
    let prevState = {... this.state};
    // alert(JSON.stringify(prevState));
    prevState.dateDialogOpen = false;
    // alert(JSON.stringify(prevState));
    this.setState(prevState, (e) => {
      // me.forceUpdate();
      me.props.openMenu(me.state.opens_menu, me.state.name, false);
      // alert(2);
    });
  }

  handleDeletedTeethClose() {
    let me = this;
    let prevState = {... this.state};
    // alert(JSON.stringify(prevState));
    prevState.deletedTeethDialogOpen = false;
    me.setState(prevState, () => {
      me.props.openMenu(me.state.opens_menu);
    });
  }

  openToothColorDialog(e) {
    let prevState = {... this.state};
    prevState.toothColorDialogOpen = true;
    // alert(1);
    prevState.activeMenu = e;
    this.setState(prevState);
  }

  openDateDialog(e) {
    let prevState = {... this.state};
    prevState.dateDialogOpen = true;
    // alert(1);
    prevState.activeMenu = e;
    this.setState(prevState);
  }

  openDeletedToothDialog(e) {
    // alert(123);
    let prevState = {... this.state};
    prevState.deletedTeethDialogOpen = true;
    this.setState(prevState);
  }
  
  showAlert(message) {
    let me = this;
    let prevState = {... me.state};
    prevState.alertMessage = message;
    me.setState(prevState);
    alert(message);
  }

  openMenu(menuVariant) {
    if (!!this.props.content.alert_message) {
	if (this.props.content.alert_message.length > 0) {
		this.showAlert(this.props.content.alert_message);
		return;
        }
    }

    if (!!this.props.content.resets_tooth) {
      // alert(12312);
      this.props.enableToothReset();
    }


    if (!!this.props.content.sets_tooth_color) {
      this.openToothColorDialog(menuVariant);
      return;
    }

    if (!!this.props.content.sets_date) {
      this.openDateDialog(menuVariant);
      return;
    }

    if (!!this.props.content.show_removed_teeth) {
      this.openDeletedToothDialog(menuVariant);
      return;
    }

    this.props.openMenu(this.state.opens_menu, this.state.name, this.state.sets_color, this.props.content.finish_and_start_tz);
  }

  setColor(e) {
    this.props.setColor(e, this.props.content);
    this.handleClose();
    // alert(e);
  }

  setTextValue(e) {
    let me = this;
    let prevState = {... me.state};
    prevState.textValue = e.target.value;
    me.setState(prevState);
  }

  setTextValueAndContinue(_field_name, _value) {
    let me = this;
    return new Promise( (resolve, reject) => {
      let prevState = {... me.state};
      prevState.textValue = _value;
      me.props.setVariable(_field_name, _value);
      me.setState(prevState, () => {
        resolve(true);
      });
    });
  }

  render() {
    let me = this;

    return <>
      {  !me.props.content.ask_upper_sections && !me.props.content.ask_sections && !me.props.content.takes_text &&
        <li key={`m-i-${me.props.content.id}`}
            style={(!me.props.content.is_separated && me.props.blocked) ? {opacity: 0.4, pointerEvents: "none"} : {}}
            onMouseEnter={(e) => {
              me.props.triggerMenuHoveredState(me.props.content);
            }} onMouseLeave={(e) => {
          me.props.unTriggerHoveredPic(me.props.content);
        }} onClick={(e) => {
          this.openMenu(me.props.content);
        }} className={!!me.props.content.is_separated ? "separated" : ''}>
          {/*<div style={{position: absoldisplay: "flex", width: "3px", height: "3px", borderRadius: "50%", marginRight: "14px", backgroundColor: me.props.sets_color}}></div>*/}
          {me.props.content.tooltip &&
          <Tooltip title={me.props.content.tooltip}>
            <span>{me.state.name}</span>
          </Tooltip>
          }
          {!me.props.content.tooltip &&
          <span>{me.state.name}</span>
          }
          {me.props.content.icon_image_url &&
          <img style={{marginLeft: "auto", marginRight: "7px", width: "40px", height: "40px", objectFit: "contain"}}
               src={`${document.SERVER_URL}${me.props.content.icon_image_url}`}/>
          }
          {/*<span style={{marginLeft: "auto"}}>{me.props.chosenColor}</span>*/}
        </li>
      }

      { !!me.props.content.takes_text &&
        <li key={`m-i-${me.props.content.id}`}
            style={(!me.props.content.is_separated && me.props.blocked) ? {opacity: 0.4, pointerEvents: "none", display: "flex", flexDirection: "column"} : {display: "flex", flexDirection: "column"}}>
            <textarea onChange={me.setTextValue} rows={3} style={{width: "100%", minHeight: "100px", border: "1px solid #ededed", borderRadius: "7px"}} />
          <br/>
          <Button onClick={(e) => {
            me.props.openMenu(me.state.opens_menu, me.state.textValue, me.state.sets_color, me.props.content.finish_and_start_tz);
          }}>Сохранить и продолжить</Button>
        </li>
      }

      { !!me.props.content.ask_sections &&
        <li>
          <div>
            <div style={{borderBottom: "3px solid #858585", display: "flex", alignContent: "center", alignItems: "center"}}>
              <Checkbox />
              <div style={{minHeight: "43px", background: "#858585", width: "3px", height: "100%"}}></div>
              <Checkbox />
            </div>
            <div style={{display: "flex", alignContent: "center", alignItems: "center"}}>
              <Checkbox />
              <div style={{minHeight: "43px", background: "#858585", width: "3px", height: "100%"}}></div>
              <Checkbox />
            </div>
          </div>
          <br/>
          <Button style={{marginLeft: "14px"}} onClick={(e) => {
            me.props.openMenu(me.state.opens_menu, me.state.textValue, me.state.sets_color, me.props.content.finish_and_start_tz);
          }}>Продолжить</Button>
        </li>
      }

      { !!me.props.content.ask_upper_sections &&
      <li>
        <div>
          <div style={{borderBottom: "3px solid #858585", display: "flex", alignContent: "center", alignItems: "center"}}>
            <Checkbox />
            <div style={{minHeight: "43px", background: "#858585", width: "3px", height: "100%"}}></div>
            <Checkbox />
          </div>
          <div style={{display: "flex", alignContent: "center", alignItems: "center", opacity: 0.3, pointerEvents: "none"}}>
            <Checkbox />
            <div style={{minHeight: "43px", background: "#858585", width: "3px", height: "100%"}}></div>
            <Checkbox />
          </div>
        </div>
        <br/>
        <Button style={{marginLeft: "14px"}} onClick={(e) => {
          me.props.openMenu(me.state.opens_menu, me.state.textValue, me.state.sets_color, me.props.content.finish_and_start_tz);
        }}>Продолжить</Button>
      </li>
      }

      {
        (!!me.props.content.config_xml) && (me.props.content.config_xml.length > 0) &&
        <XmlTableView callback={me.setTextValueAndContinue} setColor={me.setColor} content={me.props.content.config_xml}/>
      }

      <Dialog onBackdropClick={me.handleClose} onClose={me.handleClose} open={me.state.toothColorDialogOpen}>
        <DialogContent>
          <ToothColorPicker callback={(e) => {me.props.openMenu(me.state.opens_menu, me.state.name, false);} } setColor={me.setColor} activeMenu={me.state.activeMenu}/>
        </DialogContent>
      </Dialog>

      <Dialog onBackdropClick={me.handleDateClose} onClose={me.handleDateClose} open={me.state.dateDialogOpen}>
        <DialogContent>
          <h1>Выберите дату</h1>
          <DateTimePicker activeCallback={(e) => {me.setColor(e);}}/>
          {/*<ToothColorPicker callback={(e) => {me.props.openMenu(me.state.opens_menu, me.state.name, false);} } setColor={me.setColor} activeMenu={me.state.activeMenu}/>*/}
        </DialogContent>
      </Dialog>

      <Dialog onBackdropClick={me.handleDeletedTeethClose} onClose={me.handleDeletedTeethClose} open={me.state.deletedTeethDialogOpen}>
        <DialogContent>
          <div style={{display: 'block', minHeight: "900px", overflow: "hidden"}}>
            <h1>Выберите зубы для удаления</h1>
            <RemovedToothChooser handleDeletedTeethClose={me.handleDeletedTeethClose} callback={me.setTextValueAndContinue} />
          </div>
          {/*<DateTimePicker activeCallback={(e) => {me.setColor(e);}}/>*/}
          {/*<ToothColorPicker callback={(e) => {me.props.openMenu(me.state.opens_menu, me.state.name, false);} } setColor={me.setColor} activeMenu={me.state.activeMenu}/>*/}
        </DialogContent>
      </Dialog>
      
    </>
    // return <li>{this.state.name}</li>
  }  
}

