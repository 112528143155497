import React from "react";
import VideoRecorder from "../Shared/VideoRecorder";

export default class PrescanUnboxer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active_worker_catch_session: null,
            scannedCodes: [],
            currentCode: "",
            codeLoading: false,
            current_order: null
        }
        this.catchOrder = this.catchOrder.bind(this);
        this.inputRef = React.createRef();
        this.videoRecorderRef = React.createRef();
    }

    punto(bukva) {
        let lat = "`qwertyuiop[]asdfghjkl;'zxcvbnm,.~/QWERTYUIOP{}ASDFGHJKL:\"ZXCVBNM<>?";
        let rus = "ёйцукенгшщзхъфывапролджэячсмитьбю.ЁЙЦУКЕНГШЩЗХЪФЫВАПРОЛДЖЭЯЧСМИТЬБЮ,";
        let pos = -1;
        pos = rus.indexOf(bukva);
        if (pos >= 0) {
            // alert(lat.substr(pos, 1);
            return lat.substr(pos, 1);
        }
        return bukva;
    }

    componentDidMount() {
        let me = this;
        let prevState = {... me.state};
        me.setState(prevState, function () {
            document.addEventListener("keydown",(event) => {
                if (event.key == "Shift") {
                    return;
                }
                if (event.key != "Enter") {
                    let prevState = {... me.state};
                    prevState.currentCode += me.punto(event.key);
                    me.setState(prevState);
                    //alert(event.key);
                } else{
                    let prevState = {... me.state};
                    me.catchOrder(prevState.currentCode);
                }
            })
        });
    }

    catchOrder(word) {
        // alert(word);
        let me = this;
        console.log(word);
        word = word.replaceAll("CapsLock", "");
        console.log(`filtered: ${word}`);
        if (me.state.codeLoading) {
            return;
        }
        let prevState = {... me.state};
        prevState.codeLoading = true;
        let parsed = null;
        try {
            parsed = JSON.parse(word);
        } catch (e) {
        }
        if (!parsed) {
            let me = {... me.state};
            prevState.codeLoading = false;
            prevState.currentCode = "";
            me.setState(prevState);
            return;
        }
        me.setState(prevState, function () {
            // return;
            let order = null;
            try {
                order = parsed;
                order = order.pre_scan;
                // alert(JSON.stringify(order));
            } catch (e) {
                prevState.codeLoading = false;
                prevState.currentCode = "";
                me.setState(prevState);
                return;
            }
            if (!order) {
                let prevState = {... me.state};
                prevState.codeLoading = false;
                prevState.currentCode = "";
                me.setState(prevState);
                return;
            }
            let url = document.SERVER_URL + `/admin/pre_scans/${order.id}/json`;
            fetch(url).then( (e) => {return e.json()}).then((e) => {
                let prevState = {... me.state};
                prevState.currentCode = "";
                prevState.scannedCodes.push(e);
                prevState.current_order = e;
                prevState.codeLoading = false;
                me.setState(prevState, function() {
                    me.videoRecorderRef.current.startRecording();
                });
            }).catch((e) => {
                let prevState = {... me.state};
                prevState.codeLoading = false;
                prevState.currentCode = "";
                me.setState(prevState);
            });
        });
    }

    record_stop(url) {
        alert(url);
    }

    render() {
        let me = this;
        return (
            <>
                { me.state.current_order &&
                <>
                    <h1>Сканирование для прескана-{me.state.current_order.id}</h1>
                </>
                }
                <VideoRecorder ref={me.videoRecorderRef} onRecordStop={me.record_stop} pre_scan={me.state.current_order} />
                {
                    me.state.scannedCodes.map((e, eId) => {
                        return <p style={{ margin: 0, marginBottom: "7px"}} key={`eee-${eId}`}>Действие для {e.name}</p>
                    })
                }

            </>
        );
    }
}