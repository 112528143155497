import React from 'react';
import teeth from "../jaw/html_elements/teeth.json";
import Button from "@material-ui/core/Button";
let teethNumbers = ["18","17","16","15","14","13","12","11","21","22","23","24","25","26","27","28","38","37","36","35","34","33","32","31","41","42","43","44","45","46","47","48"];

export default class RemovedTeeth extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            chosenTeeth: []
        }
        this.triggerTooth = this.triggerTooth.bind(this);
        this.finish = this.finish.bind(this);
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    triggerTooth(tooth) {
        let me = this;
        let prevState = {... me.state};
        let index = prevState.chosenTeeth.indexOf(tooth);
        if (index >= 0) {
            prevState.chosenTeeth.splice(index, 1);
        } else {
            prevState.chosenTeeth.push(tooth);
        }
        me.setState(prevState);
    }


    finish() {
        let me = this;
        me.props.callback("Удаление зубов", `${me.state.chosenTeeth}`).then((e) => {
            me.props.handleDeletedTeethClose();
        })
    }

    render() {
        let me = this;


        return <div style={{position: "relative", background: "#ededed", transform: "scale(0.4);"}}>
            <p>{JSON.stringify(me.state.chosenTeeth)}</p>
            <Button onClick={(e) => {me.finish();}}  >Сохранить и продолжить</Button>
            {teethNumbers.map((e) => {
                if (!teeth[e]) return <></>;
                return <div onClick={(ee)=> {me.triggerTooth(e);} } style={{position: "absolute", top: teeth[e].y + 75 + "px", left: (teeth[e].x - 10)+"px"}} dangerouslySetInnerHTML={{__html: me.state.chosenTeeth.indexOf(e) >= 0 ?  teeth[e].code.replace("FFFFFE", "ff0000") : teeth[e].code}}>
                </div>;
            })}
        </div>;
    }
}