import React from "react";
import "./tech-stage-tree.scss";
import ReactJson from "react-json-view";

export default class TechStageTreeView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tech_processes: []
    };
  }

  render() {
    let me = this;
    return (
      <div  className="tech-stage-tree">
          <div ref={me.props.ref}
               style={{
                   width: "28px",
                   height: "4px",
                   background: "#6F94E7",
                   display: "flex",
                   position: "absolute",
                   left: "-28px",
                   top: "50%"
               }}
               id={me.props.markStartId}
          ></div>
          <div style={{display: "flex", position: "absolute", top: "7px", right: "7px"}}>
              <i className={"fal fa-times"} style={{color: "#ffffff"}} style={{marginleft: "7px"}}></i>

          </div>
        <div style={{ display: "flex",flexDirection: "column", alignContent: "center" }}>
            <div>
              <p style={{ margin: 0, marginRight: "21px" }}>
                {me.props.tech_stage.id}
              </p>
              <p style={{ margin: 0, marginRight: "14px" }}>
                {me.props.tech_stage.name}
                  {me.props.tech_stage.order_pattern &&
                    <a style={{marginLeft: "14px"}} href={`/admin/tech_processes/${me.props.tech_stage.id}`} target={"_blank"}>Перейти</a>
                  }
              </p>
              {!!me.props.tech_stage.order_pattern && (
                <div style={{background: "#ededed", padding: "7px", borderRadius: "7px"}}>
                  <ReactJson
                  src={JSON.parse(me.props.tech_stage.order_pattern)}
                  collapsed={true}
                  />
                </div>
              )}
            </div>
            { me.props.tech_stage.shelving &&
                <div style={{display: "flex", marginTop: "28px", color: "#ababab"}}>
                    <span style={{marginRight: "7px"}}>Стеллаж</span>
                    <a style={{color: "#ababab"}} href={`/admin/shelvings/${me.props.tech_stage.shelving_id}`}>{me.props.tech_stage.shelving.name}</a>
                </div>
            }
        </div>
        <div ref={me.props.ref}
          style={{
            width: "28px",
            height: "4px",
            background: "#6F94E7",
            display: "flex",
            position: "absolute",
            right: "-28px",
            top: "50%"
          }} id={me.props.markEndId}
        >
            <i className={"fal fa-plus-circle"} style={{color: "#858585", position: "absolute", top: "7px", left: "7px"}}></i>
        </div>
      </div>
    );
  }
}
