import React from 'react';
import Button from '@material-ui/core/Button';
import NewOrderForAdminInApp from "./NewOrderForAdminInApp";

export default class AdminCabinet extends React.Component {
	constructor(props) {
	    super(props);

	    this.handleChange = this.handleChange.bind(this);
	    this.newOrder = this.newOrder.bind(this);
	}

	handleChange(e) {
		document.location.href = "/admin/user_orders/new?current_user_id="+e;
	}

	newOrder() {
		alert(123);
	}

	render() {
		let me = this;
		let xxx = (
			<>
				<h1>Уголок админа</h1>
				<Button onClick={me.newOrder}>Новый заказ</Button>
				<NewOrderForAdminInApp admin={this.props.admin} />
			</>
		);
		return xxx;
	}
}