import React from "react";
import ReactHtmlParser from 'html-react-parser';
import QrReader from 'react-qr-reader';
import $ from "jquery";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from "@material-ui/core/TextField";
import ChatWidget from "../Chat/ChatWidget";

let mapTicker = 0;

export default class CourierOperationZone extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            qrScanning: false,
            showMap: true,
            delay: 100,
            actionsStack: [],
            currentAction: null,
            hideResetButton: false,
            dialogUrl: null
        };

        this.parseAction = this.parseAction.bind(this);
        this.handleScan = this.handleScan.bind(this);
        this.triggerScanning = this.triggerScanning.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.initMap = this.initMap.bind(this);
        this.movePin = this.movePin.bind(this);
        this.mapRef = React.createRef();
    }


    componentDidMount() {
        let me = this;
        if ("geolocation" in navigator) {
            $.getScript( "https://api-maps.yandex.ru/2.1/?apikey=00fb9595-c0ad-45a3-96e6-f9f6633eb015&lang=ru_RU", function( data, textStatus, jqxhr ) {
                me.ymaps = ymaps;
                me.ymaps.ready(me.initMap);
            });
        } else {
            console.log("Not Available");
        }

        this.executeCommand({setUrl: "/couriers/current_work"});
        // alert(JSON.stringify(this.props.actionState));
    }


    movePin(position, placeMark, map) {
        placeMark.geometry.setCoordinates([position.coords.latitude, position.coords.longitude]);
        map.setCenter([position.coords.latitude, position.coords.longitude]);
        if (0 === mapTicker % 10) {
            let url = document.SERVER_URL + `/couriers/update_position?lat=${position.coords.latitude}&lon=${position.coords.longitude}`;
            fetch(url).then((e) => {return e.json()}).then((e) => {

            });
        }
        mapTicker += 1;
    }

    initMap() {
        let me = this;
        let coords = [55.76, 37.64];

        let myMap = new this.ymaps.Map(`map-for`, {
            center: coords,
            zoom: 7
        });
        // alert(coords);
        this.map = myMap;
        let myPlacemark = new ymaps.Placemark(coords, {
            hintContent: 'Собственный значок метки',
            balloonContent: 'Это красивая метка'
        });

        me.placeMark = myPlacemark;
        this.map.geoObjects.add(myPlacemark)

        navigator.geolocation.watchPosition(function(position) {
            me.movePin(position, myPlacemark, myMap);
        });
        // this.addPlacemark(coords);
    }


    parseAction(actionStr) {
        // alert(actionStr);
        let action = JSON.parse(actionStr);

        if ("scan_qr" == action.command) {
            this.triggerScanning();
        }
    }


    triggerScanning() {
        let prevState = {... this.state};
        prevState.qrScanning = !prevState.qrScanning;
        if (!prevState.qrScanning) {
            prevState.currentAction = null;
            // prevState.showMap = true;
            prevState.actionsStack = [];
        } else {
            // prevState.showMap = false;
        }

        this.setState(prevState);
        if (!prevState.qrScanning) {
            this.executeCommand({setUrl: "/couriers/current_work"});
        }
    }


    executeCommand(command) {
        let me = this;
        // try {
        //     if (command.shelving.id) {
        //         me.executeCommand({
        //             set_url: document.SERVER_URL+"/couriers/put_on_shelving?shelving_id="+command.shelving.id
        //         });
        //         return;
        //     }
        // } catch(e) {
        //
        // }

        let commandName = command;
        // alert(command);
        if (commandName.command) {
            commandName = command.command;
        }

        try {
            commandName = JSON.parse(command).command;
        } catch(e) {

        }

        // try {
        //     alert(JSON.stringify(commannd));
        // } catch(e) {
        //     alert(command);
        // }
        let url = "/couriers/parse_command";
        let parsel = {
            command: command
        }
       // alert(command);

        if ("stop_qr" == commandName) {
            me.triggerScanning();
            return;
        }

        if ("start_qr" == commandName) {
            let prevState = {... this.state};
            prevState.currentAction = null;
            prevState.actionsStack = [];
            prevState.qrScanning = true;
            this.setState(prevState);
            return;
        }

        if ("level_up" == commandName) {
            let prevState = {... me.state};
            prevState.currentAction = prevState.actionsStack.pop();
            me.setState(prevState);
            if (prevState.actionsStack.length == 0) {
                this.executeCommand({setUrl: "/couriers/current_work"});
            }
            return;
        }

        try {
            if (command.indexOf("dialog") >= 0) {
                let prevState = {... me.state};
                let dialogUrl = command.split("=")[1];
                prevState.dialogUrl = dialogUrl;
                me.setState(prevState);
                return;
            }
        } catch (e) {

        }

        if (command.json_command ) {
            parsel = {
                command: command.json_command
            }
        }

        if (command.setUrl ) {
            url = command.setUrl;
        }
        // alert(url);
        $.ajax({
            url: url,
            method: "post",
            data: parsel,
            success: function(res) {
                if (res.do_nothing) return null;

                let prevState = {... me.state};
                if (prevState.currentAction) {
                    prevState.actionsStack.push({... prevState.currentAction});
                }
                prevState.currentAction = res;
                me.setState(prevState);
            },
            error: function(res) {
                alert(JSON.stringify(res));
            }
        });
    }


    handleScan(data){
        let me = this;
        let prevState = {... this.state};
        // alert(data);
        if (!!data) {
            try {
                me.executeCommand(JSON.parse(data));
                // alert(data);
            } catch(e) {
                alert(e);
            }

            // prevState.result = data.text;
            // fetch(`/orders/apply_qr?id=${data.text}`).then(
            //   (e) => {
            //     return e.json()
            //   }
            // ).then((e) => {
            //   document.location.href = e.href;
            // })
            // this.setState(prevState);
        }
    }


    handleError(err){
        console.error(err)
    }

    handleClose() {
        let prevState = {... this.state};
        prevState.dialogUrl = null;
        this.setState(prevState);
    }


    render() {
        let me = this;

        const previewStyle = {
            height: "250px",
            width: "250px",
            marginRight: "auto",
            marginLeft: "auto"
        };

        return <div className="operation-zone">
            <div className="operation-content">
                { !this.state.qrScanning && !me.state.currentAction && !me.state.showMap &&
                    <i className='far fa-coffee fa-5x' style={{marginTop: "70px", color: "#F5EBE0"}}></i>
                }

                <div id={`map-for`} ref={me.mapRef} style={{width: "300px", height: "200px" }}></div>

                { this.state.qrScanning && !me.state.currentAction &&
                    <QrReader
                        delay={me.state.delay}
                        style={previewStyle}
                        onError={me.handleError}
                        onScan={me.handleScan}
                        facingMode="environment"
                    />
                }

                { me.state.currentAction &&
                <>
                    { me.state.currentAction.about &&
                    ReactHtmlParser (
                        me.state.currentAction.about
                    )
                    }
                    <br /><br /><br />
                </>
                }

            </div>
            {/*<h2>{me.props.actionState.title}</h2>*/}
            { me.state.currentAction && me.state.currentAction.title &&
            <h2>{me.state.currentAction.title}</h2>
            }

            { (!this.state.qrScanning && !this.state.currentAction ) &&
            <div className="action-list">
                {me.props.actionState.actions.map(
                    (e) =>
                        <button className="qr-button" onClick={(ev) => { me.parseAction(e.command); } } key={`action-${e.id}`}>{e.title}</button>
                )}
            </div>
            }


            { (!!this.state.qrScanning || !!this.state.currentAction) &&
                <div className="action-list" style={{flexWrap: "wrap"}}>
                    {!me.state.currentAction &&
                        <button className="qr-button" style={{opacity: 0.8}} onClick={(e) => {me.executeCommand("stop_qr")}}><i className="fal fa-home fa-2x"></i></button>
                    }

                    {!!me.state.currentAction && !me.state.currentAction.hideResetButton &&
                        <button className="qr-button" style={{opacity: 0.8}} onClick={(e) => {me.executeCommand("stop_qr")}}><i className="fal fa-home fa-2x"></i></button>
                    }

                    { !!me.state.currentAction && !!me.state.currentAction.actions &&
                        <>
                            {me.state.currentAction.actions.map(
                                (e) =>
                                    <button className="qr-button" style={{width: "90%"}} onClick={(ev) => { me.executeCommand(e.command); } } key={`action-${e.id}`}>{e.title}</button>
                            )}
                        </>
                    }
                </div>
            }

            <Dialog onClose={me.handleClose} open={me.state.dialogUrl}>
                <DialogContent style={{padding: "21px"}}>
                    <ChatWidget dialogsUrl={me.state.dialogUrl} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={me.handleClose}>Закрыть</Button>
                </DialogActions>
            </Dialog>
        </div>
    }
}