import React from "react";
import MaterialTable from "material-table";
import {Table} from "@material-ui/core";
import Figure from "./Figure";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
// import locale from "date-fns/locale/ru";
import ErrorBoundary from "./ErrorBoundary";

class UserOrderLink extends  React.Component {
    render() {
        let me = this;
        return <a href={document.SERVER_URL + "/admin/user_orders/" + me.props.id}>Окрыть</a>
    }
}

Date.prototype.yyyymmdd = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
        (dd > 9 ? "" : "0") + dd,
        ".",
        (mm > 9 ? "" : "0") + mm,
        ".",
        this.getFullYear()
    ].join("");
};

export default class UserOrdersStatisticalTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            data: [],
            columns: [],
            updateId: null,
            dates: [],
            customDate: null,
            startDate: this.props.dateStart,
            endDate: this.props.dateEnd,
            dateStart: this.props.dateStart,
            dateEnd: this.props.dateEnd,
            errorOnDownload: false,
            showCalendar: false,
            actions: [
                {
                    icon: "ViewColumn",
                    tooltip: 'Подробнее',
                    onClick: (event, rowData) => {
                        let url = rowData.open_url;
                        window.open(url, '_blank').focus();
                    }
                }
            ]
        }
        this.getData = this.getData.bind(this);
        this.setNewPeriod = this.setNewPeriod.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.triggerCalendar = this.triggerCalendar.bind(this);
    }

    componentDidMount() {
        // alert(12121);
        this.getData();
        // let me = this;
        // setInterval(me.getData, 5000);
    }

    componentDidCatch(error, errorInfo) {
        let prevState = {... this.state};
        prevState.hasError = true;
        this.setState(this);
    }

    restoreColumns(data, payload) {
        let keys = [];
        // alert(JSON.stringify(data));
        for(let i in data) {
            let obj = data[i];
            let objKeys = Object.keys(obj);
            for (let j in objKeys) {
                if (keys.indexOf(objKeys[j]) == -1) {
                    let isNumber = false;
                    for(let i = 0; i < payload.length; i++) {
                        let row = payload[i];
                        let val = row[objKeys[j]];
                        if (!val) continue;
                        isNumber = ((typeof val) == "number");
                        // alert(`${val}: ${isNumber}`);
                        if (isNumber) break;
                    }

                    keys.push([objKeys[j], obj[objKeys[j]], isNumber]);
                }
            }
        }
        return keys.map((e) => {
            // console.log(e);
            let column = {
                title: e[1],
                field: e[0],
                cellStyle: (e, rowData) => {
                    // console.log(JSON.stringify(rowData));
                    if (rowData.custom_style)  {
                        return rowData.custom_style;
                    }
                    return {};
                }
            };

            if (e[2]) {
                column.render = (rowData) => {
                    console.log(rowData);
                    let value = rowData;
                    let prevValue = null;
                    if (value) {
                        console.log(JSON.stringify(value));
                        if (value.value) value = rowData.value;
                        if (value.prev_value !== undefined) prevValue = value.prev_value;
                    }

                    return <p style={{margin: 0, whiteSpace: 'nowrap'}}><span style={{fontWeight: 600}}>{value[e[0]].toLocaleString()}</span> <span style={{marginTop: 0, marginLeft: "7px", fontSize: "0.7em", color: "#0C0", top: "-7px", position: "relative" }}>{prevValue}</span></p>;
                }
            }
            return column;
        }).filter( (e) => {
            for(let i in payload) {
                let seenObj = payload[i];
                if (Object.keys(seenObj).indexOf(e.field) >= 0) {
                    return true;
                }
            }
            return false;
        });
    }

    getData() {
        let me = this;
        let url = document.SERVER_URL + me.props.getUrl;
        // alert(JSON.stringify(me.props));
        if (url.indexOf("?") >= 0) {
            if (!me.state.customDate) {
                url += `&date_start=${me.props.dateStart}`;
                url += `&date_end=${me.props.dateEnd}`;
            } else {
                url += `&${me.state.customDate}`;
            }
        } else {
            if (!me.state.customDate) {
                url += `?date_start=${me.props.dateStart}`;
                url += `&date_end=${me.props.dateEnd}`;
            } else {
                url += `?${me.state.customDate}`;
            }
        }
        console.log(`${me.state.customDate}, ${me.props.dateStart}, ${me.props.dateEnd}`);
        // alert(url);
        fetch(url).then((e) => {
            return e.json();
        }).then((e) => {
            // console.log(e);
            let prevState = {... me.state};
            prevState.data = e.data;
            for(let i = 0; i < prevState.data.length; i++) {
                let objectKeys = Object.keys(prevState.data[i]);
                for(let j = 0; j < objectKeys.length; j++) {
                    let fieldName = objectKeys[j];
                    let _value = prevState.data[i][fieldName];
                    let value = prevState.data[i][fieldName];
                    let prevValue = prevState.data[i][fieldName];
                    if (_value) {
                        if (_value.value !== undefined) value = _value.value;
                        if (_value.prev_value !== undefined) prevValue = _value.prev_value;
                    }
                    // if (typeof value === 'number' ) {
                    //     try {
                    //         value = prevState.data[i][fieldName].toLocaleString()
                    //     } catch (e) {
                    //     }
                    // }
                    // prevState.data[i][fieldName] = value;
                    prevState.data[i][fieldName] = {value: value, prev_value: prevValue};
                }
            }

            // alert(JSON.stringify(e.data));
            prevState.columns = me.restoreColumns(e.columns, prevState.data);
            me.setState(prevState, function() {
                fetch(document.SERVER_URL + "/admin/user_orders/statistic_periods").
                then((e) => {return e.json();}).
                then((e) => {
                    let prevState = {... me.state};
                    prevState.dates = e;
                    me.setState(prevState);
                });
            });
        }).catch((error) => {
           let prevState = {... me.state};
           prevState.errorOnDownload = error;
           me.setState(prevState);
        });;
    }

    onPageChange() {
        console.log("ok");
    }

    setNewPeriod(newPeriod) {
        let me = this;
        // alert(newPeriod);
        let prevState = {... me.state};
        prevState.dateStart = new Date(newPeriod.split("&")[0].replace("date_start=",""));
        prevState.dateEnd = new Date(newPeriod.split("&")[1].replace("date_end=",""));
        prevState.customDate = newPeriod;
        prevState.data = [];
        me.setState(prevState, function() {
            me.getData();
        });
    }

    triggerCalendar() {
        let me = this;
        let prevState = { ...me.state };
        prevState.showCalendar = !prevState.showCalendar;
        me.setState(prevState);
    }

    handleSelect(ranges) {
        let me = this;
        // alert(JSON.stringify(ranges));
        let prevState = { ...this.state };
        prevState.dateStart = ranges.selection.startDate;
        prevState.dateEnd = ranges.selection.endDate;
        prevState.startDate = ranges.selection.startDate;
        prevState.endDate = ranges.selection.endDate;
        this.setState(prevState, () => {
            me.setNewPeriod(`date_start=${prevState.startDate.toISOString()}&date_end=${prevState.endDate.toISOString()}`);
        });

        alert(ranges);
    }

    render() {
        let me = this;
        if (me.state.hasError) {
            return <p>Произошла ошибка</p>;
        }
        let actions = me.state.actions;
        if (me.props.actions_disabled) {
            actions = false;
        }
        return (
            <ErrorBoundary>
                <div style={{ maxWidth: "100%", position: "relative", paddingLeft: "4px", borderLeft: "2px solid #00abff" }}>
                    {
                        me.props.show_dates &&
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", alignContent: "center", maxWidth: "100%", overflowX: "scroll", marginBottom: "21px" }}>
                                <p
                                    style={{ color: "#858585", cursor: "pointer", margin: 0, marginRight: "21px", marginTop: "auto", marginBottom: "auto" }}
                                    onClick={me.triggerCalendar}
                                >
                                    {me.state.showCalendar ? "Скрыть каледнарь" : "Показать календарь"}
                                </p>
                                <div
                                    style={{
                                        position: "absolute",
                                        top: "30px",
                                        left: "0",
                                        minWidth: "320px",
                                        minHeight: "200px",
                                        zIndex: 20,
                                        display: me.state.showCalendar ? "block" :  "none"
                                    }}
                                >
                                    <DateRange
                                        dateDisplayFormat={"dd.MM.Y"}
                                        ranges={[
                                            {
                                                startDate: me.state.startDate,
                                                endDate: me.state.endDate,
                                                key: "selection"
                                            }
                                        ]}
                                        date={new Date()}
                                        onChange={me.handleSelect}
                                    />
                                </div>
                                <p style={{margin: 0,marginTop: "auto", marginBottom: "auto", marginRight: "14px"}}>{me.state.dateStart.yyyymmdd()}&nbsp;-&nbsp;{me.state.dateEnd.yyyymmdd()}</p>
                                {me.state.dates.map((e) => {
                                    let bgColor = (e[0] == me.props.date_title) ? "#ededed" : "#ffffff";
                                    if (me.state.customDate) {
                                        bgColor = (e[1] == me.state.customDate) ? "#ededed" : "#ffffff";
                                    }
                                    return <button style={{border: "none", whiteSpace: "nowrap", padding: "7px", fontSize: "14px", background: bgColor, marginRight: "7px"}} onClick={(ev) => {me.setNewPeriod(e[1])}}>{e[0]}</button>;
                                })}
                            </div>
                    }
                    {me.state.data.length == 0 &&
                        <p>Загрузка ({me.props.getUrl})</p>
                    }
                    {me.state.errorOnDownload &&
                        <p style={{color: "#f00"}}>Ошибка загрузки [{`${me.state.errorOnDownload.stack}`}] <i onClick={me.getData} className={"fal fa-reload"}></i></p>
                    }
                    {me.state.data.length > 0 &&
                        <MaterialTable
                            options={{
                                paging: me.props.disable_paging ? false : true,
                                pageSize: me.state.data.length < 30 ? me.state.data.length : 30,       // make initial page size
                                // emptyRowsWhenPaging: true,   //to make page size fix in case of less data rows
                                pageSizeOptions:[6,12,20,50,100],    // rows selection options
                                search: !me.props.hide_search,
                                toolbar: !me.props.hide_toolbar,
                                filtering: true,
                                exportButton: true,
                                size: 'small'
                        }}
                        localization={{
                            toolbar: {
                                searchTooltip: 'Поиск',
                                searchPlaceholder: "Поиск"
                            }
                        }}
                        columns={me.state.columns}
                        data={me.state.data}
                        actions={actions}
                        onPageChange={me.onPageChange}
                        title={!me.props.title ? "Заказы" : me.props.title }
                        detailPanel={rowData => {
                            return (
                                <div style={{background: "#ededed"}}>
                                    {rowData.get_url &&
                                        <UserOrdersStatisticalTable date_title={me.props.date_title}
                                                     show_dates={true}
                                                     hide_toolbar={true}
                                                     hide_search={true}
                                                     disable_paging={true}
                                                     title={false}
                                                     actions_disabled={true}
                                                     getUrl={rowData.get_url}
                                                     dateStart={me.state.dateStart}
                                                     dateEnd={me.state.dateEnd} />
                                    }
                                    {rowData.figure_url &&
                                        <Figure title={`${rowData.figure_title}`} href={rowData.figure_url} />
                                    }
                                    {rowData.table &&
                                        <table>
                                            <tr>
                                                {/*<td>{(rowData.table)}</td>*/}
                                            </tr>
                                            {typeof rowData.table.map === "function" &&
                                                <tbody>
                                                    {rowData.table.map((subRow, subRowIndex) => {
                                                        return <tr key={`sub-row-${subRowIndex}`}>
                                                            {subRow.map((col, colIndex) => {
                                                                if (!col.open_url) {
                                                                    return <td key={`sub-row-${subRowIndex}-${colIndex}`}>{col.content.toString().toLocaleString()}</td>
                                                                } else {
                                                                    return <td key={`sub-row-${subRowIndex}-${colIndex}`}>
                                                                        <a href={col.open_url} target={"_blank"}>{col.content.toString().toLocaleString()}</a>
                                                                    </td>
                                                                }
                                                            })}
                                                        </tr>
                                                        }
                                                    )}
                                                </tbody>
                                            }
                                        </table>
                                    }
                                    {/*<p>{JSON.stringify(rowData)}</p>*/}
                                </div>
                            )
                        }}
                    />
                    }
                </div>
            </ErrorBoundary>
        );
    }
}
