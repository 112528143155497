import React from 'react';
import OrderTimer from "../Timer/Timer";
import "./table.scss";
import Timer from 'react-compound-timer';

export default class UserOrderKanbanView extends React.Component {
  
	constructor(props) {
		super(props);
		this.state = {
			order_stages: [],
			orders: [],
			intervalId: null,
			ordersIntervalId: null
		};
		// this.getOrderStages = this.getOrderStages.bind(this);
		this.getOrderData = this.getOrderData.bind(this);
	} 


	componentDidMount() {
		// let intervalId = setInterval(this.getOrderStages, 5000);
		this.getOrderData();
		let ordersIntervalId = setInterval(this.getOrderData, 55000);
		this.setState({ordersIntervalId: ordersIntervalId});
		// this.getOrderDatatages();
		// this.setState(this.props.content);
	}


	componentWillUnmount() {
	   // use intervalId from the state to clear the interval
	   // clearInterval(this.state.intervalId);
	   clearInterval(this.state.ordersIntervalId);
	}


	getOrderData() {
		let me = this;
		let url = `/orders/${me.props.order.id}/json`; 
		// alert(url);
		fetch(url).then((e) => {return e.json()}).then(
			(e) => {
				if (!e) return;
				let prevState = {... this.state};
				prevState.orders = [e];
				try {
					prevState.order_stages = e.get_tech_processes_wide.get_tech_stages;
					prevState.order_stages = prevState.order_stages.select((e) => {
						if (me.props.currentUser) {
							if (e.show_to_user) {
								return  true;
							}
						}
						return true;
					});
				} catch (e) {
				}
				me.setState(prevState);
			}
		);

	}

	startTime(order) {
	  	for (let toothIndex in order.processed_tooths) {
	  		let tooth = order.processed_tooths[toothIndex];
	  		let dateStart = Date.parse(tooth.updated_at);
	  		let dateNow = Date.now();
	  		// alert(dateStart);
	  		if (dateStart) {
	  			let d = dateNow - dateStart;
	  	// 		alert(d);
	  	// 		// alert(date);
	  			return d;
	  		}
	  	}
	  	return null;
	}


  anyToothOnStage(stage, order) {
  	for (let toothIndex in order.processed_tooths) {
  		if (order.processed_tooths[toothIndex].tech_stage_id == stage.id) {
  			return true;
  		}
  	}

  	try {
	  	if (stage.id == "in_review") {
	  		if (order.tz.indexOf("уточне") >= 0) {
	  			return true;
	  		}
	  	}
	} catch(e) {
	}

  	return false;
  }


  render() {
  	let me = this;
    let xxx = <div>
    	{/*<h1>feeffewew</h1>*/}
      {0 < me.state.orders.length &&
	      <table className="table table-header-rotated" style={{marginTop: "80px"}}>
		    <tbody>
	      		{/*{me.state.order_stages.map( (e) => 
	      			<th className="rotateth" style={{maxWidth: "40px", paddingRight: "7px"}} key={`header-stage-${e.id}`}>
	      				<div style={{width: "120px", transform: "tr" +
								"anslate(-5px, -3px) rotate(315deg)"}}><a href={`/admin/tech_stages/${e.id}`} target="_blank">{e.name}</a></div>
	      			</th>
	      		)}*/}
		      	{me.state.orders.map( (e, trIndex) =>
		      		<tr key={`tr-${trIndex}`}>
		      			<td style={{borderRight: "1px solid #858585", paddingRight: "7px", width: "189px", maxWidth: "189px", minWidth: "189px"}}>
		      				<div style={{display: "flex", flexDirection: "column", alignContent: "flex-start", alignItems: "flex-start", justifyContent: "flex-start"}}>
				      			<a style={{textAlign: "left", marginBottom: "14px"}} href={`/user_orders/${e.id}`}>{e.name}</a>
								{!me.props.current_user &&
									<a style={{color: "#858585", fontSize: "12px"}} href={`/orders/${e.id}/invoice`}
								   target={"_blank"}>Счет</a>
								}
								{!me.props.current_user &&
									<a style={{color: "#858585", fontSize: "12px"}}
								   href={`/orders/${e.id}/act_of_provided_services`} target={"_blank"}>Акт</a>
								}
								{!me.props.current_user &&
									<a style={{color: "#858585", fontSize: "12px"}}
								   href={`/admin/user_orders/${e.id}/show_hide_user_order`}
								   data-confirm={`Вы уверены, что желаете скрыть заказ АРТ-${e.id} из дашборда?`}>Скрыть
									из дашборда</a>
								}
			      			</div>
		      			</td>
		      			<td style={{borderRight: "1px solid #858585", paddingRight: "7px", width: "189px", maxWidth: "189px", minWidth: "189px"}}>
		      				<div>
		      					{e.responsible_worker && 
			      					<p>Отв: <a href={`/admin/workers/${e.responsible_worker_id}`} target="_blank">{e.responsible_worker.last_name}&nbsp;{e.responsible_worker.first_name[0]}</a></p>
			      				}

		  						{e.ticking_order_timers.map( (timer, timerIndex) =>
		  							<OrderTimer key={`timer-${timerIndex}`} fontSize1="10px" fontSize2="10px" key={`timer-${timer.id}`} order_timer={timer} />
		  						)}
		      				</div>
		      			</td>
						{
							me.state.order_stages.length == 0 &&
								<th className={"rotateth"}>Ожидает активации</th>
						}

		      			{me.state.order_stages.map( (s, stageIndex) =>
			      			<td className="rotateth" key={`order-${e.id}-stage-${s.id}-${stageIndex}`} style={me.anyToothOnStage(s, e) ? {maxWidth: "40px", background: "#8fadc8", borderTop: "1px solid #ededed"} : {background: "#f1fdfd", maxWidth: "40px", borderRight: "1px solid #858585", paddingRight: "7px"}}>
			      				<div style={{width: "120px", transform: "translate(-5px, -33px) rotate(315deg)", whiteSpace: "nowrap"}}><a href={`/admin/tech_stages/${s.id}`} style={{opacity: 0.7}} target="_blank">{s.name}</a></div>
			      				{me.anyToothOnStage(s, e) && 
			      					<>
			      						<a href={e.location_url} target={"_blank"}>{e.location}</a>
			      						{ e.parallel_processes.length > 0 && 
				      						<ul style={{listStyle: "none", padding: 0}}>
				      							{e.parallel_processes.map( (ee) => {
				      								return <li><span style={{fontSize: "14px"}}>{ee.side_process.name}</span> <span style={{fontSize: "10px"}}>{ee.progress*100}%</span></li>		
				      							})}
				      						</ul>
		      							}
		      							{me.startTime(e) && 
			      							<Timer
											    initialTime={me.startTime(e)}
											    direction="forward"
											>
											    {() => (
											        <div style={{marginTop: "7px", fontSize: "18px", color: "#00abff", display: "flex", flexDirection: "column", fontFamily: "sans-serif"}}>
											        	<div style={{marginTop: "7px", fontSize: "22px", color: "#00abff", display: "flex", flexDirection: "row", fontFamily: "sans-serif"}}>
												            <p className="hide-print" style={{margin: 0, marginRight: "7px", fontSize: "14px"}}><Timer.Days />дн</p>
												            <p className="hide-print" style={{margin: 0, marginRight: "7px", fontSize: "14px"}}><Timer.Hours />ч</p>
												            <p className="hide-print" style={{margin: 0, marginRight: "7px", fontSize: "14px"}}><Timer.Minutes />м</p>
												            <p className="hide-print" style={{margin: 0, marginRight: "7px", fontSize: "14px"}}><Timer.Seconds />с</p>
											            </div>
											        </div>
											    )}
											</Timer>
										}
			      					</>
			      				}
			      			</td>
			      		)}
			      	</tr>
		      	)}
	      	</tbody>
	      </table>
	  }
    </div>;
    return (xxx);
  }
}