import React from 'react'

export default class Index extends React.Component {
  
  constructor(props) {
    super(props);
    // alert(212312);
    // this.state = {
    //     name: "",
    //     menu_variants: []  
    // };
  } 

  componentDidMount() {

    // this.setState(this.props.content);
  }

  render() {
    let xxx = <div>
      <h1>ArtLab</h1>
    </div>;
    return (xxx);
  }
}