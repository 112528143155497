import React from 'react';

export default class ChatMessage extends React.Component {
  
  constructor(props) {
    super(props);
    // alert(212312);
    // this.state = {
    //     name: "",
    //     menu_variants: []  
    // };
  } 

  componentDidMount() {

    // this.setState(this.props.content);
  }

  render() {
    let xxx = <div className="chat-message">
      {this.props.content.admin_user &&
        <h3 style={{margin: 0, opacity: 0.6}}>Адм. {this.props.content.admin_user.email}</h3>
      }
      {this.props.content.worker &&
      <h3 style={{margin: 0, opacity: 0.6}}>Сотр. {this.props.content.worker.last_name} {this.props.content.worker.first_name}</h3>
      }
      <p>{this.props.content.content}</p>
      {!!this.props.content.sound_url && 
        <audio src={this.props.content.sound_url} controls={true} />
      }

      {!!this.props.content.message_attachements.length > 0 && 
        <div className="attached-files">
          {this.props.content.message_attachements.map( (e,index) => 
            <a href={e.file_url} target="blank">
              <i className="fal fa-paperclip"/>  {e.name}
            </a>
            )}
        </div>
      }
    </div>;
    return (xxx);
  }
}