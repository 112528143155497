import React from "react";
import $ from "jquery";


export default class Figure extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            graph: null,
            showRest: false,
            data: {data: {labels: ["Идет загрузка"]}}
        };
        // alert(this.props.href);
        this.triggerShowRest = this.triggerShowRest.bind(this);
    }

    triggerShowRest() {
        let prevState = {... this.state};
        prevState.showRest = !prevState.showRest;
        this.setState(prevState);
    }

    componentDidMount() {
        let me = this;
        $.getScript("https://cdn.jsdelivr.net/npm/chart.js", (e) => {
            if (!!this.props.graph) {
                let prevState = {... me.state};
                prevState.graph = graph;
                me.setState(prevState);
            } else {
                fetch(this.props.href).then((e) => {
                    return e.json();
                }).then((e) => {
                    // alert(JSON.stringify(e));
                    let prevState = {... me.state};
                    // prevState.graph = e[0];
                    me.ref = React.createRef();
                    prevState.graph = e;
                    prevState.data = e;

                    me.setState(prevState, function() {
                        let prevState = {... me.state};
                        prevState.graph = new Chart(me.ref.current, e);
                        me.setState(prevState);
                    });
                });
            }
        });
    }

    render() {
        let me = this;
        let sum = {};
        return <div>
            <h3>{me.props.title}</h3>
            {
                !me.state.graph &&
                    <p>Идет подсчет</p>
            }
            {me.state.graph &&
            <div>
                <div style={{maxWidth: "600px"}}>
                <canvas ref={me.ref}
                    width={420}
                    height={420}
                    style={{
                        display: "block",
                        boxSizing: "border-box",
                        height: "252px",
                        width: "324px",
                        background: "#ededed"
                    }}></canvas>
                </div>
                <br/>
                {me.props.show_table &&
                <table>
                    <tbody>
                    {me.state.data.table_titles &&
                    <tr style={{background: "#ededed"}}>
                        <td></td>
                        {me.state.data.table_titles.map((eee) => {
                            return <td>{eee}</td>
                        })}
                        <td onClick={me.triggerShowRest}>{me.state.showRest ? "Скрыть" : "Подробнее"}</td>
                    </tr>
                    }
                    {/*{JSON.stringify(me.state.data)}*/}
                    {me.state.data.ids.map((e, eIndex) => {
                        return <tr style={{display: me.state.showRest ? "table-row" : 'none'}}>
                            <td><a href={`/admin/${me.state.data.model_name}/${e[0]}`}>Перейти</a></td>
                            {e.map((ex, exindex) => {
                                let sumKey = "" + exindex;
                                if (sum[sumKey])
                                    sum[sumKey] += ex;
                                else
                                    sum[sumKey] = ex;
                                if (0 == exindex) {
                                    sum[sumKey] = "id";
                                    return <td style={{display: "none"}} key={`e-${eIndex}-${exindex}`}>{ex}</td>
                                } else {
                                    return <td key={`e-${eIndex}-${exindex}`}>{ex}</td>
                                }
                            })
                            }
                            <td></td>
                        </tr>
                    })}
                    <tr>
                        {Object.keys(sum).map((x) => {
                            if (typeof sum[x] != "string")
                                return <td>{sum[x]}</td>
                            else
                                return <td>-</td>
                        })
                        }
                        <td></td>
                    </tr>
                    </tbody>
                </table>
                }
            </div>
            }
        </div>;
    }
}