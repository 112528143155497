import React from 'react';
import SelfUpdatedAsyncSelectField from "../Shared/SelfUpdatedAsyncSelectField";

export default class NewOrderForAdmin extends React.Component {
	constructor(props) {
	    super(props);

	    this.handleChange = this.handleChange.bind(this);
	}

	handleChange(e) {
		let me = this;

		let url = "/admin/user_orders/new?current_user_id="+e;
		if (me.props.is_rework_of_user_order_id)
			url += "&is_rework_of_user_order_id="+me.props.is_rework_of_user_order_id;
		document.location.href = url;
	}

	render() {
		let me = this;
		let xxx = (
			<SelfUpdatedAsyncSelectField updateCallback={me.handleChange} id={'enter-client-name'} placeholder="Укажите имя клиента" label="Укажите имя клиента" fieldName="some_123" options_url="/users/search" updateUrl="/users/search" />
		);
		return xxx;
	}
}