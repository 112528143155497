import React from 'react';
import "./gcalendar.scss";
import $ from "jquery";

export default class GCalendar extends React.Component {
    constructor(props) {
        super(props);
        let currentYears = [];
        let yearNow = new Date().getFullYear();
        this.state = {
            currentYear: yearNow,
            currentYears: currentYears,
            year_columns: [],
            years: {},
            dayStart: new Date(),
            dayEnd: new Date(),
            days: [],
            maxOrders: 100
        };

        this.setYear = this.setYear.bind(this);
        this.workOnDayClick = this.workOnDayClick.bind(this);
        this.inWorkPeriod = this.inWorkPeriod.bind(this);
        this.getData = this.getData.bind(this);
        this.setDateGap = this.setDateGap.bind(this);
    }

    setYear(year) {
        let me = this;
        let prevState = { ...this.state };
        prevState.currentYear = year;
        // prevState.year_columns = prevState.years[toString(year)];
        // let numberOfDaysInYear = GCalendar.numberOfDaysInYear(year);
        let yearColumns = [];
        let currentYearColumn = null;
        // let startYearDate = new Date(year, 0, 1);
        // // alert(startYearDate);
        // alert(toString(year));
        let days = prevState.years[`${year}`];
        // alert(JSON.stringify(prevState.years[toString(year)]));
        // alert(days.length);
        let maxOrders = days[0].user_orders;
        for (let i = 0; i < days.length; i++) {
            if (days[i].user_orders > maxOrders) maxOrders = days[i].user_orders;
            // let date = new Date(startYearDate.getTime() + i * 24 * 60 * 60 * 1000);
            if (0 === i % 7) {
                if (i > 0) {
                    yearColumns.push(currentYearColumn);
                }
                currentYearColumn = [];
            }
            let day = days[i];
            currentYearColumn.push(day);
            // days.push(day);
        }
        prevState.maxOrders = maxOrders;
        prevState.days = days;
        prevState.year_columns = yearColumns;
        prevState.dayStart = null;
        prevState.dayEnd = null;
        me.setState(prevState);
    }

    workOnDayClick(dayNumber) {
        let me = this;
        let prevState = {... me.state };
        // alert(prevState.dayStart);
        if (prevState.dayEnd) {
            prevState.dayEnd = null;
            prevState.dayStart = null;
        }

        if (null == prevState.dayStart) {
            prevState.dayStart = dayNumber;
            me.setState(prevState, () => {
                // alert(`${prevState.dayStart} - ${prevState.dayEnd}`);
                me.props.callback(prevState.days[prevState.dayStart].when_is_it, prevState.days[prevState.dayStart].when_is_it);
            });
        } else {
            prevState.dayEnd = dayNumber;
            me.setState(prevState, () => {
                // alert(`${prevState.dayStart} - ${prevState.dayEnd}`);
                me.props.callback(prevState.days[prevState.dayStart].when_is_it, prevState.days[prevState.dayEnd].when_is_it);
            });
            return;
        }
        me.setState(prevState, () => {
        });
    }

    doy(date) {
        let now = date;
        let start = new Date(now.getFullYear(), 0, 0);
        let diff = now - start;
        let oneDay = 1000 * 60 * 60 * 24;
        let day = Math.floor(diff / oneDay);
        return day;
    }

    setDateGap(_dateStart, _dateEnd) {
        let me = this;
        let prevState = {... me.state};

        // alert(d1);
        prevState.dayStart = me.doy(_dateStart);
        prevState.dayEnd = me.doy(_dateEnd);
        // alert(`${_dateStart} - ${_dateEnd}-${prevState.dayStart}-${prevState.dayEnd}`);
        me.setState(prevState, () => {
            me.props.callback(_dateStart, _dateEnd);
        });
    }

    async getData() {
        let me = this;
        return new Promise((resolve, reject) => {
            $.ajax({
                url: me.props.dataUrl,
                method: "get",
                dataType: "json",
                success: (e) => {
                    // alert(e);
                    let prevState = {... me.state};
                    let data = {... e};
                    let processedData = {};
                    for(let year of Object.keys(e)) {
                        processedData[year] = {};
                        let firstDayDate = new Date(data[year][0].when_is_it);
                        let daysMissingAtStart = firstDayDate.getDay() - 1;
                        let days = [];
                        for(let j = daysMissingAtStart; j > 0; j--) {
                            let startWeekDate = new Date(firstDayDate - j * 24 * 60 * 60 * 1000);
                            let startWeekYear = startWeekDate.getFullYear();
                            days.push({
                                when_is_it: startWeekDate,
                                user_orders: 0,
                                wday: startWeekDate.getDay(),
                                year: startWeekYear,
                                day_num: j
                            });
                        }

                        for(let day of data[year]) {
                            day.when_is_it = new Date(day.when_is_it);
                            day.wday = day.when_is_it.getDay();
                            days.push(day);
                        }
                        processedData[year] = days;
                    }

                    prevState.years = processedData;
                    prevState.currentYears = Object.keys(e);
                    me.setState(prevState, () => {
                        resolve(true);
                    });
                }
            });
        });
    }

    componentDidMount() {
        let me = this;
        me.getData().then((e) => {
            me.setYear(me.state.currentYears[me.state.currentYears.length - 1]);
        });
    }

    inWorkPeriod(dayIndex) {
        let me = this;

        if (me.state.dayStart) {
            if (me.state.dayEnd) {
                let min = me.state.dayStart;
                let max = me.state.dayEnd;
                if (max < min) {
                    let t = max;
                    max = min;
                    min = t;
                }
                if (dayIndex >= min) {
                    if (dayIndex <= max) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    render() {
        let me = this;
        let currentMonth = -1; //me.state.yearColumns[0][0].when_is_it.getMonth();
        let monthStyle = "";
        let changedMonthStyle = "hue-rotate(45deg)";
        let monthChanged = true;
        return (
            <div key={`day-${me.state.dayStart}-${me.state.dayEnd}-${me.state.currentYear}`}>
                <div className={"g-years-container"}>
                    {me.state.currentYears.map((e) => {
                        return (
                            <p
                                onClick={(ev) => {
                                    me.setYear(e);
                                }}
                                className={e == me.state.currentYear ? "active" : ""}
                            >
                                {e}
                            </p>
                        );
                    })}
                </div>
                <div className={"g-calendar-container"} key={`${me.state.currentYear}`}>
                    <div className={"calendar-column"} >
                        <div
                            className={"calendar-day"}
                            style={{marginRight: "7px", background: "transparent", color: "#000", fontSize: "10px"}}
                        >

                        </div>
                        {["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"].map((d) => {
                            return <div
                                    className={"calendar-day"}
                                    style={{marginRight: "7px", background: "transparent", color: "#000", fontSize: "10px"}}
                                >
                                    {d}
                                </div>
                        }) }
                    </div>
                    {me.state.year_columns.map((yC, i) => {
                        let yCheaderStyle = {background: "transparent", color: "#000", fontSize: "10px"};
                        let showMonthName = false;
                        if ( monthChanged ) {
                            yCheaderStyle = {background: "#f00"};
                            showMonthName = true;
                        }
                        monthChanged = false;

                        if (0 == i) {
                            monthChanged = true;
                            showMonthName = false;
                        }
                        let currentYear = me.state.currentYear;
                        let monthNames = ["ЯНВ", "ФЕВ", "МАРТ", "АПР", "МАЙ", "ИЮН", "ИЮЛ", "АВГ", "СЕН", "ОКТ", "НОЯ", "ДЕК"];
                        let monthLength = ["ЯНВ", "ФЕВ", "МАРТ", "АПР", "МАЙ", "ИЮН", "ИЮЛ", "АВГ", "СЕН", "ОКТ", "НОЯ", "ДЕК"];
                        let months = [];
                        for(let i=0; i < monthNames.length; i++) {
                            let monthName = monthNames[i];
                            let x = {name: monthName, dateStart: new Date(currentYear, i, 1, 0, 0), dateEnd: new Date(currentYear, i + 1, 0, 0, 0)}
                            months.push(x);
                        }
                        return (
                            <div className={"calendar-column"} key={`column-${i}`}>
                                <div
                                    className={"calendar-day"}
                                    style={{color: "#222", backgroundColor: "rgba(255,255,255,0)", background: "rgba(255,255,255,0)", fontSize: "14px", transform: "translate3d(0,-10px,0)"}}
                                >
                                    <span style={{transparent: "translate3d(0, -15px, 0)"}} onClick={(eee) => {me.setDateGap(months[yC[0].when_is_it.getMonth()].dateStart,months[yC[0].when_is_it.getMonth()].dateEnd)}}>{showMonthName ? months[yC[0].when_is_it.getMonth()].name : ""}</span>
                                </div>
                                {yC.map((day, j) => {
                                    let style =  {};
                                    let background = [0, 171, 255, 1];
                                    if (me.inWorkPeriod(7 * i + j)) {
                                        background = [200,100,100, 1];
                                    }
                                    if (me.state.dayStart == (7 * i + j))
                                        style.border = "5px solid #0f0";
                                    background[3] = 0.3 + 0.7 * (day.user_orders / me.state.maxOrders);
                                    style.backgroundColor = `rgba(${background.join(',')})`;
                                    if (day.when_is_it.getMonth() != currentMonth) {
                                        monthChanged = true;
                                        if (monthStyle == changedMonthStyle) {
                                            monthStyle = "";
                                        } else {
                                            monthStyle = changedMonthStyle;
                                        }
                                        currentMonth = day.when_is_it.getMonth();
                                    }

                                    style.filter = monthStyle;

                                    // style.opacity = 0.3 + 0.7 * (day.user_orders / me.state.maxOrders);
                                    return (
                                        <div
                                            onClick={(ev) => {
                                                me.workOnDayClick(i * 7 + j);
                                            }}                                  
                                            className={"calendar-day"}
                                            style={style}
                                            key={`d-${day}`}
                                        >
                                            <div className={"tooltip"}>
                                                <p>{day.when_is_it.toLocaleDateString()}</p>
                                                <p>Заказы: {day.user_orders}</p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    static numberOfDaysInYear(year) {
        let now = new Date(year + 1, 0, 0);
        let start = new Date(now.getFullYear(), 0, 0);
        let diff = now - start;
        let oneDay = 1000 * 60 * 60 * 24;
        return Math.floor(diff / oneDay);
    }
}
