import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import "./news_items.scss";

export default class NewsItemWidget extends React.Component {
  
  constructor(props) {
    super(props);
    // alert(212312);
    this.state = {
        open: false
    };
    this.openDialog = this.openDialog.bind(this);
    this.handleClose = this.handleClose.bind(this);
  } 

  openDialog() {
    let prevState = {... this.state};
    prevState.open = true;
    this.setState(prevState);
  }

  componentDidMount() {
    // this.setState(this.props.content);
  }

  handleClose(e) {
    let prevState = {... this.state};
    prevState.open = false;
    this.setState(prevState);
  }

  render() {
    let xxx = <div className="news-item-widget">
        <p className="when-is-it">{this.props.content.when_is_it}</p>
        <p className="title">{this.props.content.title}</p>
        <p className="lead">{this.props.content.lead}</p>
        <Button onClick={this.openDialog} style={{maxWidth: "50%", marginTop: "7px"}} variant="outlined">Читать</Button>
        <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={this.state.open}>
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            {this.props.content.title}
          </DialogTitle>
          <DialogContent dividers>
            <p className="when-is-it">{this.props.content.when_is_it}</p>
            <Typography gutterBottom>
              {this.props.content.lead}
            </Typography>
            <Typography gutterBottom>
              {this.props.content.content}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={this.handleClose} color="primary">
              Закрыть
            </Button>
          </DialogActions>
        </Dialog>
      </div>;
    return (xxx);
  }
}