import React from 'react';
import Cropper from 'react-easy-crop';
import Slider from '@material-ui/core/Slider';
import Button from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

let imageId = `image-${parseInt(Math.random() * 1000)}-${parseInt(Math.random() * 1000)}-${parseInt(Math.random() * 1000)}-${parseInt(Math.random() * 1000)}`;

const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', error => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  const maxSize = Math.max(image.width, image.height)
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea
  canvas.height = safeArea

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2)
  ctx.rotate(getRadianAngle(rotation))
  ctx.translate(-safeArea / 2, -safeArea / 2)

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  )
  const data = ctx.getImageData(0, 0, safeArea, safeArea)

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  )

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise(resolve => {
    canvas.toBlob(file => {
      resolve(file);
      // resolve(URL.createObjectURL(file))
    });
  })
}

export default class ImagePicker extends React.Component {
  
	constructor(props) {
	    super(props);
	    this.state = {
	    	resource: null,
	    	file: null,
	    	imagePreviewUrl: null,
	    	croppedData: null,
	    	stateStyle: {},
		    crop: { x: 0, y: 0 },
		    zoom: 1,
		    aspect: 4 / 3,
		    cropping: false,
		    loading: true,
		    showDialog: false
	    }

	    this.cancel = this.cancel.bind(this);
	    this.saveAndSend = this.saveAndSend.bind(this);
	    this.handleClickOpen = this.handleClickOpen.bind(this);
	    this.handleClick = this.handleClick.bind(this);
	    this.edit = this.edit.bind(this);
	} 


	componentDidMount() {
		let me = this;
	    fetch(this.props.getUrl)
	    .then((response) => {
	      return response.json();
	    })
	    .then((data) => {
	    	let prevState = {... me.state};
	    	prevState.loading = false;
	    	prevState.imagePreviewUrl = data[me.props.field];
	    	prevState.stateStyle = {
	    		backgroundImage: `url(${prevState.imagePreviewUrl})`,
	    		backgroundSize: "cover"
	    	};
	      	me.setState(prevState);
	    });
	}


	componentWillUnmount() {
	}


	handleClick = function(e) {
		if (!this.state.imagePreviewUrl) {
			let input = document.getElementById(imageId);
			input.click();
		} else {
			this.handleClickOpen();
		}
		
	}


	handleImageChange(e) {
	    e.preventDefault();
	    let reader = new FileReader();
	    let file = e.target.files[0];
	    // alert("23232");
	    reader.onloadend = () => {
	    	let prevState = {...this.state};
	    	prevState.file = file;
	    	prevState.imagePreviewUrl = reader.result;
	    	// prevState.stateStyle = {
	    	// 	background: `url(${prevState.imagePreviewUrl})`,
	    	// 	backgroundSize: "cover"
	    	// };
	    	prevState.cropping = true;

			this.setState(prevState);
	    }

	    reader.readAsDataURL(file);
	}

	cancel = () => {
		this.setState({
	    	resource: null,
	    	file: null,
	    	// imagePreviewUrl: null,
	    	croppedData: null,
	    	stateStyle: {},
		    crop: { x: 0, y: 0 },
		    zoom: 2,
		    aspect: 4 / 3,
		    cropping: false
	    });
	}

	edit = () => {
		let prevState = {...this.state};
		prevState.showDialog = false;

		let input = document.getElementById(imageId);
		input.click();
	}

	onCropChange = (crop) => {
		let prevState = {...this.state};
		prevState.crop = crop;
		this.setState(prevState);
	}

	onCropComplete = (croppedArea, croppedAreaPixels) => {
		console.log(croppedArea, croppedAreaPixels)
		let prevState = {...this.state};
		prevState.croppedData = croppedAreaPixels;
		this.setState(prevState);
	}


	onZoomChange = (zoom) => {
		let prevState = {...this.state};
		prevState.zoom = zoom;
		this.setState(prevState);
	}


	handleClickOpen = () => {
		let prevState = {... this.state};
		prevState.showDialog = !prevState.showDialog;
		this.setState(prevState);
	};


	saveAndSend() {
		let me = this;
		let prevState = {...me.state};

		getCroppedImg(prevState.imagePreviewUrl, prevState.croppedData, 0).then(function(blob) {
			// alert(JSON.stringify(prevState.croppedData));
			let formData = new FormData();
			formData.append(me.props.field,blob);
			fetch(me.props.updateUrl, {
				method: 'POST',
				body: formData
			}).then(
			response => response.json() // if the response is a JSON object
			).then(success => {
				prevState.stateStyle = {
		    		backgroundImage: `url(${success[me.props.field]})`,
		    		backgroundSize: "cover"
		    	};
		    	prevState.cropping = false;
		    	me.setState(prevState);
			}).catch(
			error => console.log(error) // Handle the error response object
			);
		});
		
	    
	    
	    // formData.append(this.props.fieldName,this.state.imagePreviewUrl);

	    
	  }

    
  render() {
    return ( 
    	<div>
    		{!!this.state.loading && 
    			<Skeleton variant="circle" width={90} height={90} />
    		}
    		{!this.state.loading && 
    			<div>
			    	<div className="image-cirlce-avatar" onClick={this.handleClick} style={this.state.stateStyle} >
			    		{ !this.state.imagePreviewUrl &&
					    	<i className="fal fa-user fa-2x" style={{color: "var(--steel_gray_200)"}}></i>
					    }
				    	<input type="file" id={imageId} style={{display: "none"}} onChange={(e) => this.handleImageChange(e) } />
					</div>
					{ !!this.state.cropping && 
						<div className="cropper-controls">
							<p>Двигайте слайдер, чтобы масштабировать фото</p>
							<Slider
								value={this.state.zoom}
								min={1}
								max={3}
								step={0.1}
								onChange={(e, zoom) => this.onZoomChange(zoom)}
							/>
						</div>
					}
					{ !!this.state.cropping && 
						<div className="cropper">
							<div className="crop-container">
								<Cropper style={{zIndex: 100}}
						            image={this.state.imagePreviewUrl}
						            crop={this.state.crop}
						            zoom={this.state.zoom}
						            aspect={this.state.aspect}
						            onCropChange={this.onCropChange}
						            onCropComplete={this.onCropComplete}
						            onZoomChange={this.onZoomChange}
						        />
							</div>
						</div>
					}
					{ !!this.state.cropping && 
						<div className="cropper-actions">
							<Button onClick={this.cancel}>Отмена</Button>
							<Button onClick={this.saveAndSend} variant="contained" color="primary" >OK</Button>
						</div>
					}
				</div>
			}

			<Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={this.state.showDialog}>
		        <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
		          Фотография
		        </DialogTitle>
		        <DialogContent dividers>
		          <img src={this.state.imagePreviewUrl} style={{width: "100%",  height: "100%", backgroundSize: "cover"}} />
		        </DialogContent>
		        <DialogActions>
		        	<Button autoFocus onClick={this.handleClickOpen} color="default">
						Закрыть
					</Button>
					<Button autoFocus onClick={this.edit} color="primary">
						Редактировать
					</Button>
		        </DialogActions>
		      </Dialog>
		</div>
    )
  }
}