import React from 'react';
import OrderClarification from "../../Order/OrderClarification";

import teeth from "./html_elements/teeth.json";
import outerGirlanda from "./html_elements/outer_girlanda.json";
import innerGirlanda from "./html_elements/inner_girlanda.json";
import bridge from "./html_elements/bridge.json";

class JawTooth extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      menus: [],
      toothNumber: props.toothNumber,
      task: {},
      hovered: false,
      color: "#FFFFFE"
    };

    this.clarificationRef = React.createRef();
    this.makeHtml = this.makeHtml.bind(this);
    this.makeBridgeHtml = this.makeBridgeHtml.bind(this);
    this.makeInnerGirlandaHtml = this.makeInnerGirlandaHtml.bind(this);
    this.makeOuterGirlandaHtml = this.makeOuterGirlandaHtml.bind(this);
    this.setColor = this.setColor.bind(this);
    this.setColorReal = this.setColorReal.bind(this);
  }

  makeHtml() {
  	let me = this;

  	let res = teeth[this.props.toothNumber].code;
  	let borderColor = "#B0B7C1";
  	if (!!this.props.task) {
  		if (JSON.stringify(this.props.task).indexOf("уточнение") >= 0) borderColor = "#FF7B61";
  	}
  	
  	if (this.props.chosenTeeth) {
	  	if (this.props.chosenTeeth.indexOf(this.props.toothNumber) >= 0) {
	  		res = res.replace("#B0B7C1", "#7B61FF");	
	  	}
	}

  	res = res.replace("#B0B7C1", borderColor);
  	if (this.props.task.color) {
  		res = res.replace("#FFFFFE", this.props.task.color);
  	} else {
  		if (this.props.color)
	  		res = res.replace("#FFFFFE", this.props.color);
  	}
  	let pricePart = "";
  	if (me.props.teeth_prices[this.props.toothNumber]) {
  		pricePart = `<p style='color: #858585; opacity: 0.7; position: relative; z-index: 1000; pointer-events: none;'>${me.props.teeth_prices[this.props.toothNumber]}</p>`;
	}
  	return res; //+ pricePart;
  }

  makeInnerGirlandaHtml() {
  	let res = innerGirlanda[this.props.toothNumber].code;
  	res = res.replace("black", "transparent");
  	// console.log(this.props.task["Гирлянда"]);
  	if (this.props.task["Гирлянда"]) {
  		if (this.props.task["Гирлянда"].value == "360 градусов")
  			res = res.replace("transparent", "#3e2723");
  		if (this.props.task["Гирлянда"].value == "180 градусов")
  			res = res.replace("transparent", "#3e2723");
  	}

  	if (this.props.task["Плечо"]) {
	  	if (this.props.task["Плечо"].value == "Круговое")
  			res = res.replace("transparent", "#ff7043");
  	}

  	return res;
  }

  makeOuterGirlandaHtml() {
  	let res = outerGirlanda[this.props.toothNumber].code;
  	// console.log(this.props.task);
  	res = res.replace("black", "transparent");
  	// console.log(res);
  	if (this.props.task["Гирлянда"]) {

  		if (this.props.task["Гирлянда"].value == "360 градусов")
  			res = res.replace("transparent", "#3e2723");
  		
  		
  	}

  	if (this.props.task["Плечо"]) {
	  	if (this.props.task["Плечо"].value == "Вестибулярное")
  			res = res.replace("transparent", "#ff7043");
  		if (this.props.task["Плечо"].value == "Круговое")
  			res = res.replace("transparent", "#ff7043");
  	}
  	return res;
  }

  makeBridgeHtml() {
  	let res = bridge[this.props.toothNumber].code;

  	// if (this.props.color != "#FFFFFE") {
  		if (this.props.has_bridge) {
  			// console.log(this.props.has_bridge);
  			// res = res.replace("#FDFDFD", this.props.color);
  			res = res.replace("#FDFDFD", "#454545");
  		}
  	// } else {
  		// res += `<p>${this.props.has_bridge}</p>`;
  	// }

  	return res;
  }


  setColor(e) {
  	let me = this;
  	me.setColorReal(e, false);
  }

  setColorReal(e, skipComment) {
    let me = this;
	if (!!me.props.order) {
	 if (me.props.order.status == "на согласовании") {
		 if (!skipComment && !!me.props.task) {
			 if (JSON.stringify(me.props.task).indexOf("уточн") >= 0) {
				  me.clarificationRef.current.openDialog();
				  return
			 }
		 }

	 }
	}
	// alert(55);
	me.props.setColor(me.props.toothNumber, me.props.setWork, e.shiftKey );
  }

  addBridge(e) {
  	this.props.addBridge(this.props.toothNumber);	
  }


  componentDidMount() {
  }

  render() {
  	let me = this;
    let dy = 0;
    let xxx = 
    <>
	    <div onClick={(e) => {this.setColor(e)}} style={{position: "absolute", top: teeth[this.props.toothNumber].y + (80 + dy) + "px", left: teeth[this.props.toothNumber].x+"px", cursor: 'pointer'}} dangerouslySetInnerHTML={{__html: this.makeHtml()}}>
		</div>
		{ !!bridge[this.props.toothNumber] && !(me.props.order.get_speciality && me.props.order.get_speciality.disable_bridges) &&
			<div onClick={(e) => {this.addBridge(e)}} style={{position: "absolute", top: bridge[this.props.toothNumber].y + (70 + dy) + "px", left: bridge[this.props.toothNumber].x+"px", cursor: 'pointer'}} dangerouslySetInnerHTML={{__html: this.makeBridgeHtml()}}>
			</div>
		}
		{ !!outerGirlanda[this.props.toothNumber] && 
			<div style={{position: "absolute", top: outerGirlanda[this.props.toothNumber].y + (75 + dy)+ "px", left: (outerGirlanda[this.props.toothNumber].x - 10)+"px"}} dangerouslySetInnerHTML={{__html: this.makeOuterGirlandaHtml()}}>
			</div>
		}
		{ !!innerGirlanda[this.props.toothNumber] && 
			<div style={{position: "absolute", top: innerGirlanda[this.props.toothNumber].y + (75 + dy) + "px", left: (innerGirlanda[this.props.toothNumber].x - 5)+"px"}} dangerouslySetInnerHTML={{__html: this.makeInnerGirlandaHtml()}}>
			</div>
		}
		{  
			<OrderClarification setColorReal={me.setColorReal} current_user={this.props.current_user} ref={me.clarificationRef} order={me.props.order} toothNumber={me.props.toothNumber} />
		}

    </>;
    return (xxx);
  }
}


export default JawTooth;
