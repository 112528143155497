import React from 'react';
import Button from '@material-ui/core/Button';
import SelfUpdatedSelectField from "../../Shared/SelfUpdatedSelectField";
import SelfUpdatedSwitchField from "../../Shared/SelfUpdatedSwitchField";
import SelfUpdatedDateTimeField from "../../Shared/SelfUpdatedDateTimeField";

export default class DatesStage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      specialties: [],
      order_mode: null
    };

    this.updateCallback = this.updateCallback.bind(this);
  }

  componentDidMount() {
   
  }

  componentWillUnmount() {
  }

  updateCallback(val) {
    let me = this;
    let prevState = {... me.state};
    // if ("String" == val.className) {
      prevState.order_mode = val;
    // }
    me.setState(prevState, function() {
      if (!!me.props.updateCallback) {
        me.props.updateCallback();
      }
    });
  }

  printPage() {
    window.print();
  }


  render() {
    let me = this;
    let orderUpdateUrl = `/orders/${this.props.order.id}/update_field`;
    let orderGetUrl = `/orders/${this.props.order.id}.json`;
    // let orderLimitsUrl = `/admin/user_orders/${me.props.order.id}/limits.json`;
    let orderLimitsUrl = `/orders/${me.props.order.id}/limits.json`;

    if (this.props.current_user) {
      orderUpdateUrl += `?user_id=${this.props.current_user.id}`
      orderGetUrl += `?user_id=${this.props.current_user.id}`
    }
    let variants = [
      ["На готовую", "На готовую"],
      ["Примерка каркаса", "Примерка каркаса"],
      ["Прототип", "Прототип"]
    ];
    let calendarBlocked = true;
    if (me.props.order.order_mode || me.state.order_mode) {
      calendarBlocked = false;
    }

    let xxx = 
      <div className={"hide-print"}
           key={`${me.state.calendarBlocked}`}>
        <h3 className="hide-print">Исполнение заказа</h3>
        <SelfUpdatedSwitchField vertical={true}
                                updateCallback={this.updateCallback}
                                variant="outlined"
                                className={this.props.className}
                                variants={variants}
                                updateUrl={orderUpdateUrl}
                                getUrl={orderGetUrl}
                                fieldName="order_mode"
                                label="Тип выполнения работы"
        />

        <SelfUpdatedDateTimeField disabled={calendarBlocked}
                                  updateCallback={this.updateCallback}
                                  label={"Срок"}
                                  updateUrl={orderUpdateUrl}
                                  getUrl={orderGetUrl}
                                  fieldName="ready_date"
                                  limits_get_url={orderLimitsUrl}
        />
        {/*<QrCodeScaner qrScanning={me.state.qrScanned} />*/}
      </div>
    ;
    return (xxx);
  }
}