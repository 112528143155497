import React from 'react';
import TextField from '@material-ui/core/TextField';

export default class ProjectIdStage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
  }


  componentWillUnmount() {
  }


  render() {
    let me = this;
    let xxx = 
      <TextField className={this.props.className} disabled={true} defaultValue={`АРТ-${this.props.order.id}`} variant="outlined" label="Номер заказа" />
    ;
    return (xxx);
  }
}