import React from "react";
import Calendar from "./UserOrderCalendar";
import "./calendar_style.scss";
export default class UserOrderCalendarCustomStyle extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let me = this;
        return <Calendar {...me.props} />
    }
}

