// import React from 'react'
// import CircularProgress from '@material-ui/core/CircularProgress';

// const camelize = function (str) {
//   return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
//     if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
//     return index === 0 ? match.toLowerCase() : match.toUpperCase();
//   });
// }

// export default class FormRenderer extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       renderedComponents: [],
//       components: {}
//     }

//     this.renderData = this.renderData.bind(this);
//   } 


//   async componentDidMount() {
//     await this.renderData(this.props.parsel);
//   }



//   async renderData(parsel) {
//     const modelName = parsel.model;
//     let renderedComponents = [];
//     let components = {};
//     for (let rowId in parsel.fields) {
//       let componentsRow = [];
//       for(let colId in parsel.fields[rowId]) {
//         let field = parsel.fields[rowId][colId];
//         // let path = `../${modelName}/Fields/${field}`;
//         let patj = '@material-ui/core/CircularProgress';
//         // alert(patj);
//         let importedElement = await import(patj);
//         components[field] = importedElement;
//         // alert(importedElement);
//         componentsRow.push(importedElement);
//         // .then(math => {
//         //   console.log(math.add(16, 26));
//         // });
//       }
//       renderedComponents.push(componentsRow);
//     }

//     this.setState({components: components, renderedComponents: renderedComponents});
//   }


//   render() {
//     let me = this;
//     let xxx = <>
//         { (0 == this.state.renderedComponents.length) && 
//           <div style={{display: "flex", flexDirection: "row", alignContent: "center", alignItems: "center"}} className="messages-container">
//             <CircularProgress />
//             <p>Загрузка формы</p>
//           </div>
//         }
//         { (0 < me.state.renderedComponents.length) && 
//           <div style={{display: "flex", flexDirection: "column"}} >
//             { me.state.renderedComponents.map( (row, index) => 
//               <div key={`row-${index}`} style={{display: "flex", flexDirection: "row", alignContent: "center", alignItems: "center"}} >
//                 { row.map( (block, index) => 
//                   block
//                 )}
//               </div>
//             )}
//           </div>
//         }
//       </>;
//     return (xxx);
//   }
// }