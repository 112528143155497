import React, { Component } from 'react'
import QrReader from 'react-qr-scanner'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import $ from "jquery";
import ReactHtmlParser from "html-react-parser";

export default class QrCodeScaner extends Component {
  constructor(props){
    super(props)
    this.state = {
      delay: 100,
      result: 'Наведите сканер на QR код',
      showing: true,
      actionsStack: [],
      currentAction: null,
      hideResetButton: false,
      dialogUrl: null,
    }

    this.handleScan = this.handleScan.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  

  handleClose() {
    let prevState = {... this.state};
    prevState.showing = false;
    this.setState(prevState);
  }

  executeCommand(command) {
    let me = this;
    let commandName = command;
    // alert(123);
    let url = document.SERVER_URL + command.setUrl;
    try {
      let userOrder = JSON.parse(command.text).user_order;
      url = document.SERVER_URL + `/orders/${userOrder.id}/user_show`;
    } catch (e) {}

    if (command.openHref) {
      window.open(document.SERVER_URL + command.openHref,  '_blank').focus();
      return;
    }
    // alert(userOrder.user_order);
    // alert(JSON.stringify(command))
    $.ajax({
      url: url,
      method: "post",
      data: parsel,
      success: function(res) {
        if (res.do_nothing) return null;

        let prevState = {... me.state};
        if (prevState.currentAction) {
          prevState.actionsStack.push({... prevState.currentAction});
        }
        prevState.currentAction = res;
        me.setState(prevState);
      },
      error: function(res) {
        alert(JSON.stringify(res));
      }
    });

    return;





    let parsel = {
      command: command
    }


    // alert(command);
    if ("stop_qr" == commandName) {
      me.triggerScanning();
      return;

    }
    if ("start_qr" == commandName) {
      let prevState = {... this.state};
      prevState.currentAction = null;
      prevState.actionsStack = [];
      prevState.qrScanning = true;
      this.setState(prevState);
      return;

    }
    if ("level_up" == commandName) {
      let prevState = {... me.state};
      prevState.currentAction = prevState.actionsStack.pop();
      me.setState(prevState);
      if (prevState.actionsStack.length == 0) {
        this.executeCommand({setUrl: "/couriers/current_work"});
      }
      return;

    }

    try {
      if (command.indexOf("dialog") >= 0) {
        let prevState = {... me.state};
        let dialogUrl = command.split("=")[1];
        prevState.dialogUrl = dialogUrl;
        me.setState(prevState);
        return;
      }
    } catch (e) {
    }
    if (command.json_command ) {
      parsel = {
        command: command.json_command
      }

    }
    if (command.setUrl ) {
      url = command.setUrl;
    }
    // alert(url);
  }


  handleScan(data){
    let me = this;
    if (!!data) {
      try {
        // alert(data);
        me.executeCommand(JSON.parse(data));
      } catch(e) {
        me.executeCommand(data);
        // alert(e);
      }
    }
  }

  handleError(err){
    console.error(err)
  }


  render(){
    const previewStyle = {
      height: 240,
      width: 320,
    }
    let me = this;
    let xxx = <Dialog onClose={me.handleClose} open={me.state.showing && me.props.qrScanning}>
      <DialogContent style={{padding: "21px"}}>
        <h3>Сканирование QR</h3>
        <br/>
        <QrReader
        delay={me.state.delay}
        style={previewStyle}
        onError={me.handleError}
        onScan={me.handleScan}
        />
        { me.state.currentAction &&
        <>
          { me.state.currentAction.about &&
          ReactHtmlParser (
              me.state.currentAction.about
          )
          }
          <br /><br /><br />
        </>
        }
        { !!me.state.currentAction &&
        <>
          {me.state.currentAction.actions.map(
              (e) =>
                  <button className="qr-button" style={{width: "90%"}} onClick={(ev) => { me.executeCommand(e.command); } } key={`action-${e.id}`}>{e.title}</button>
          )}
        </>
        }
        <p>{this.state.result}</p>
        <br/>
        <br/>
      </DialogContent>
      <DialogActions>
        <Button onClick={me.handleClose}>Отмена</Button>
      </DialogActions>
    </Dialog>;
    return xxx;
  }
}