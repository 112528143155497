import React from "react";

export default class DrawZone extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            active: false,
            erasing: false
        };

        this.canvasRef = React.createRef();
        this.stopDrawing = this.stopDrawing.bind(this);
        this.startDrawing = this.startDrawing.bind(this);
        this.drawLine = this.drawLine.bind(this);
        this.triggerDrawing = this.triggerDrawing.bind(this);
        this.triggerErasing = this.triggerErasing.bind(this);
        this.saveToServer = this.saveToServer.bind(this);
    }

    async saveToServer() {
        let me = this;
        let imageBlob = await new Promise(resolve => me.canvasRef.current.toBlob(resolve, 'image/png'));
        let formData = new FormData();
        formData.append("image", imageBlob, "drawing.png");
        let response = await fetch(`/admin/user_orders/${me.props.order.id}/upload_comment_picture`, {
            method: 'POST',
            body: formData
        });

        let result = await response.json();
        me.triggerDrawing();
        // convert the response to json, modify it accordingly based on the returned response from your remote server
        // alert(result);
    }

    triggerErasing() {
        let me = this;
        let prevState = {... me.state};
        prevState.erasing = !prevState.erasing;
        me.setState(prevState);
    }

    componentDidMount() {
        let me = this;

        me.lineCap = 'round';
        me.x = 0;
        me.y = 0;
        me.isMouseDown = false;

        me.canvasRef.current.addEventListener( 'mousedown', me.startDrawing );
        me.canvasRef.current.addEventListener( 'mousemove', me.drawLine );
        me.canvasRef.current.addEventListener( 'mouseup', me.stopDrawing );
        if (me.props.order.jaw_drawing_url) {
            let context = me.canvasRef.current.getContext('2d');
            make_base();

            function make_base()
            {
                let base_image = new Image();
                base_image.src = me.props.order.jaw_drawing_url;
                base_image.onload = function(){
                    context.drawImage(base_image, 0, 0);
                }
            }

        }
        // this.canvasRef.current.addEventListener( 'mouseout', me.stopDrawing );
    }

    triggerDrawing() {
        let me = this;
        let prevState = {... me.state};
        prevState.active = !prevState.active;
        me.setState(prevState);

    }

    stopDrawing() {
        this.isMouseDown = false;
    }

    startDrawing(event) {
        let me = this;
        me.isMouseDown = true;
        [me.x, me.y] = [event.offsetX, event.offsetY];
    }

    drawLine(event) {
        let me = this;
        me.context =  me.canvasRef.current.getContext( '2d' );
        if ( me.isMouseDown ) {
            const newX = event.offsetX;
            const newY = event.offsetY;
            if (!me.state.erasing) {
                me.context.beginPath();
                me.context.moveTo( me.x, me.y );
                me.context.lineTo( newX, newY );
                me.context.stroke();
                console.log(newX, newY);
                //[x, y] = [newX, newY];
                me.x = newX;
                me.y = newY;
            } else {
                me.context.clearRect(newX - 10, newY - 10, 20, 20);
            }
        }
    }


    render() {
        let me = this;
        let width=357;
        let height=820;
        let style = {
            display: "flex",
            width: "357px",
            height: "820px",
            position: "relative",
            background: me.state.active ? "rgba(200,200,200,0.3)" : "transparent",
            zIndex: 200,
            pointerEvents: me.state.active ? "all" : "none"
        };

        return <div style={style}>
            <i onClick={me.triggerDrawing} className="fad fa-edit fa-2x" style={{pointerEvents: "all", position: "absolute", top: "10px", right: "0px", color: "#00abff", cursor: "pointer"}}></i>
            {
                me.state.active &&
                    <i onClick={me.triggerErasing} className={`${me.state.erasing ? 'fas' : 'fal'} fa-eraser fa-2x`} style={{pointerEvents: "all", position: "absolute", top: "40px", right: "5px", color: "#00abff", cursor: "pointer"}}></i>
            }
            {
                me.state.active &&
                    <i onClick={me.saveToServer} className={`fas fa-save fa-2x`} style={{pointerEvents: "all", position: "absolute", top: "10px", right: "35px", color: "#00abff", cursor: "pointer"}}></i>
            }
            <canvas width={width} height={height} ref={me.canvasRef} ></canvas>
        </div>;
    }

}
