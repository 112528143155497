import React from 'react';
import Button from '@material-ui/core/Button';


var possible_sessions = [
  {
    id: 1,
    name: "Гипсовщик"
  },
  {
    id: 2,
    name: "CAD моделировщик"
  }
]

export default class StartSessionZone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      possible_sessions: []
    };

    this.getPossibleSessions = this.getPossibleSessions.bind(this);
    this.setWorkerSession = this.setWorkerSession.bind(this);
  }


  setWorkerSession(workerSession) {
    let url = `/workers/start_session?connection_worker_department_id=${workerSession.id}`;
    fetch(url).then( (e) => {return e.json()}).then(
      (e) => {
        if (e.error) {
          alert(e.error);
          return;  
        }
        // alert(JSON.stringify(e));
        let prevState = {... this.state};

        let me = this;
        this.checkIfStartPossible(this.props.current_user, workerSession).then( (e) => {
          if (e.approved) {
            me.props.setWorkerSession(workerSession);
          }
        });     
      }
    );
  }


  checkIfStartPossible(worker, workerSession) {
    return new Promise( (resolve, reject) => {
      resolve({approved: true})
    });
  }


  componentDidMount() {
    this.getPossibleSessions();
  }

  getPossibleSessions() {
    let url = "/workers/get_possible_sessions";
    fetch(url).then((e) => {return e.json()}).then(
      (e) => {
        let prevState = {... this.state};
        prevState.possible_sessions = e;
        this.setState(prevState);
      }
    );
    
  }


  render() {
    // console.log(this.state.calendar);
    let me = this;
    return (
    	<div className="start-session-zone">
        <h1>Начало сессии</h1>
        { this.state.possible_sessions.map( (e) => 
            <Button onClick={(ev) => {me.setWorkerSession(e); }} style={{border: "1px solid #858585", width: "100%", marginBottom: "21px"}} key={e.id}>Начать смену как {e.role}</Button>
          )}
      </div>
    );
  }
}