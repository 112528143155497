import React from 'react';
// import QrReader from 'react-qr-scanner';
import ReactHtmlParser from 'html-react-parser';
import QrReader from 'react-qr-reader';
import $ from "jquery";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from "@material-ui/core/TextField";
import ChatWidget from "../Chat/ChatWidget";
import OrderTimer from "../Timer/Timer";


export default class OperationZone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      qrScanning: false,
      delay: 100,
      actionsStack: [],
      currentAction: null,
      hideResetButton: false,
      dialogUrl: null,
      filterValue: null,
      executing: false
    };

    this.parseAction = this.parseAction.bind(this);
    this.handleScan = this.handleScan.bind(this);
    this.triggerScanning = this.triggerScanning.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.submitRef = React.createRef();
  }
  

  componentDidMount() {
    this.executeCommand({setUrl: "/workers/current_job"});
    // alert(JSON.stringify(this.props.actionState));
  }

  parseAction(actionStr) {
    // alert(actionStr);
    let me = this;
    let action = JSON.parse(actionStr);

    if ("submit" == action.command) {
      let data = {
        question: me.submitRef.current.value
      };

      $.ajax({
        url: me.state.currentAction.href,
        method: "post",
        data: data,
        success: function (res) {
          let prevState = {... me.state};
          prevState.filterValue = null;
          prevState.currentAction = res;
          me.setState(prevState);
          // alert(JSON.stringify(res));
        }
      });
      return;
    }


    if ("scan_qr" == action.command) {
      this.triggerScanning();
    }
  }

  triggerScanning() {
    let prevState = {... this.state};
    prevState.qrScanning = !prevState.qrScanning;
    if (!prevState.qrScanning) {
      prevState.currentAction = null;
      prevState.actionsStack = [];
    }
    this.setState(prevState);
    if (!prevState.qrScanning) {
      this.executeCommand({setUrl: "/workers/current_job"});
    }
  }

  executeCommand(command) {
    let me = this;
    if (me.state.executing) return;
    let url = "/workers/parse_command";
    let parsel = {
      command: command
    }

    // alert(command);
    
    if ("stop_qr" == command) {
      me.triggerScanning();
      return;
    }

    if ("start_qr" == command) {
      let prevState = {... this.state};
      prevState.filterValue = null;
      prevState.currentAction = null;
      prevState.actionsStack = [];
      prevState.qrScanning = true;
      this.setState(prevState, () => {
      });
      return;
    }

    if ("level_up" == command) {
      let prevState = {... me.state};
      prevState.currentAction = prevState.actionsStack.pop();
      prevState.filterValue = null;
      me.setState(prevState);
      if (prevState.actionsStack.length == 0) {
        this.executeCommand({setUrl: "/workers/current_job"});
      }
      return;
    }
    
    try {
      if (command.indexOf("dialog") >= 0) {
          let prevState = {... me.state};
          let dialogUrl = command.split("=")[1];
          prevState.dialogUrl = dialogUrl;
          prevState.filterValue = null;
          me.setState(prevState);
          return;
      }
    } catch (e) {
    }

    if (command.json_command ) {
      parsel = {
        command: command.json_command
      }
    }

    if (command.setUrl ) {
      url = command.setUrl;
    }
    let prevState = {... me.state};
    prevState.executing = true;

    me.setState(prevState, () => {
      // alert(url);
      setTimeout(() => {
        let prevState1 = {... me.state};
        if (true == prevState1.executing) {
          prevState1.executing = false;
          me.setState(prevState1);
        }
      }, 15000);
      $.ajax({
        url: url,
        method: "post",
        data: parsel,
        success: function(res) {
          let prevState = {... me.state};
          prevState.filterValue = null;
          prevState.executing = false;
          if (res.do_nothing) {
            me.setState(prevState);
            return null;
          }

          if (prevState.currentAction) {
            prevState.actionsStack.push({... prevState.currentAction});
          }
          prevState.currentAction = res;
          me.setState(prevState);
        },
        error: function(res) {
          alert(JSON.stringify(res));
          let prevState = {... me.state};
          prevState.executing = false;
          me.setState(prevState);
        }
      });
    });
  }


  handleScan(data){
    let me = this;
    let prevState = {... this.state};
    // alert(data);

    if (!!data) {
      try {
        me.executeCommand(JSON.parse(data));  
        // alert(data);
      } catch(e) {
        alert(e);
      }      
      
      // prevState.result = data.text;
      // fetch(`/orders/apply_qr?id=${data.text}`).then(
      //   (e) => {
      //     return e.json()
      //   }
      // ).then((e) => {
      //   document.location.href = e.href;
      // })
      // this.setState(prevState);  
    }
  }


  handleError(err){
    console.error(err)
  }

  handleClose() {
    let prevState = {... this.state};
    prevState.dialogUrl = null;
    this.setState(prevState);
  }

  parseClick(ev) {
    if (!ev.target) return;

    // alert(ev.target.getAttribute("data_trigger_id"));
    if (ev.target.getAttribute("data_trigger_id")) {
      let target = document.getElementById(ev.target.getAttribute("data_trigger_id"));
      target.style.display = target.style.display == "none" ? "block" : "none";
    }
  }

  render() {
    let me = this;
    
    const previewStyle = {
      height: "250px",
      width: "250px",
      marginRight: "auto",
      marginLeft: "auto"
    };

    return <div className="operation-zone" style={me.state.executing ? {opacity: 0.6, pointerEvents: "none"} : {} }>
       
       <div className="operation-content">
        
        { !this.state.qrScanning && !me.state.currentAction &&
          <i className='far fa-coffee fa-5x' style={{marginTop: "70px", color: "#F5EBE0"}}></i>
        }

        { this.state.qrScanning && !me.state.currentAction &&
          <QrReader
            delay={me.state.delay}
            style={previewStyle}
            onError={me.handleError}
            onScan={me.handleScan}
            facingMode="environment"
            />
        }


        { me.state.currentAction &&
          <span onClick={(ev) => {me.parseClick(ev);}}>
            { me.state.currentAction.about &&
              ReactHtmlParser (
                me.state.currentAction.about
               ) 
            }
            <br /><br /><br />
            { !!me.state.currentAction.show_form &&
                <>
                  <textarea ref={me.submitRef} style={{padding: "7px",
                                    border: "1px solid #858585",
                                    width: "80%",
                                    fontSize: "18px",
                                    maxWidth: "320px",
                                    borderRadius: "7px",
                                    minHeight: "120px",
                                    marginBottom: "14px",
                                    marginLeft: "0px"
                  }}
                            placeholder={me.state.currentAction.form_caption}></textarea>
                  <button className="qr-button"
                        onClick={(ev) => {
                          me.parseAction("{\"command\": \"submit\"}");
                        }
                        }>{me.state.currentAction.buttonName}</button>
                </>
            }
          </span>
       }
        
       </div>
       {/*<h2>{me.props.actionState.title}</h2>*/}
       { me.state.currentAction && me.state.currentAction.title &&
        <h2>{me.state.currentAction.title}</h2> 
       }
       
       { (!this.state.qrScanning && !this.state.currentAction) &&
         <div className="action-list">
          {me.props.actionState.actions.map(
            (e) => 
             <button className="qr-button" onClick={(ev) => { me.parseAction(e.command); } } key={`action-${e.id}`}>{e.title}</button>
          )}
         </div>
       }
       

       { (!!this.state.qrScanning || !!this.state.currentAction) &&
         <div className="action-list" style={{flexWrap: "wrap"}}>
          {!me.state.currentAction &&
            <button className="qr-button" style={{opacity: 0.8}} onClick={(e) => {me.executeCommand("stop_qr")}}><i className="fal fa-home fa-2x"></i></button>
          }

          {!!me.state.currentAction && !me.state.currentAction.hideResetButton &&
            <button className="qr-button" style={{opacity: 0.8}} onClick={(e) => {me.executeCommand("stop_qr")}}><i className="fal fa-home fa-2x"></i></button>
          }

          { me.state.currentAction && 
            <>
              {me.state.currentAction.actions.length > 3 &&
                  <TextField variant={"outlined"} onChange={(e) => {
                    let prevState = {... me.state};
                    prevState.filterValue = e.target.value;
                    me.setState(prevState);
                  }} placeholder={"Фильтр"} style={{marginRight: "auto", marginLeft: "7px", width: "100%"}}/>
              }
             {me.state.currentAction.actions.filter((e) => {
                if (me.state.filterValue) {
                  return (e.title.indexOf(me.state.filterValue) >= 0);
                }
                return true;
             }).map(
              (e) =>
                <>
                  <button className="qr-button" style={{width: "90%"}} onClick={(ev) => { me.executeCommand(e.command); } } key={`action-${me.state.filterValue}-${e.id}`}>
                    {e.title}
                  {e.order_timer &&
                    <OrderTimer hide_caption={true} order_timer={e.order_timer} no_rescheldue={true} />
                  }
                  </button>
                </>
             )}
            </>
          }
         </div>
       }

      <Dialog onClose={me.handleClose} open={me.state.dialogUrl} fullScreen>
        <DialogContent style={{padding: "21px"}}>
          <ChatWidget dialogsUrl={me.state.dialogUrl} />
        </DialogContent>
        <DialogActions>
          <Button onClick={me.handleClose}>Закрыть</Button>
        </DialogActions>
      </Dialog>
     </div>
  }
}