import React from 'react';
import Button from '@material-ui/core/Button';

export default class ActivateOrderStage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      specialties: [],
      qrScanned: false
    };

    this.updateCallback = this.updateCallback.bind(this);
    this.startScaner = this.startScaner.bind(this);
    this.moveToFolder = this.moveToFolder.bind(this);
  }

  componentDidMount() {
   
  }

  componentWillUnmount() {
  }

  startScaner() {
    let prevState = {... this.state};
    prevState.qrScanned = true;
    this.setState(prevState);
  }

  updateCallback(val) {
    // if (!!this.props.updateCallback) {
    //   this.props.updateCallback({ChooseEmployeeStage: val});
    // }
  }

  printPage() {
    window.print();
  }


  moveToFolder(folderName) {
    let me = this;
    let url = `/admin/user_orders/${me.props.order.id}/put_to_folder?folder_name=${encodeURI('на согласовании')}`;
    // alert(url);
    if (url.indexOf("https://") == -1) {
      url = document.SERVER_URL + url;
    }
    fetch(url).then( (e) => {
      // alert(222);
      document.location.href = `/admin/user_orders/${me.props.order.id}`; //?q%5Bstatus_contains%5D=на+согласовании&commit=Filter&order=id_desc";
    });
  }


  render() {
    let me = this;
    let xxx = 
      <>
        {/*<h3>Подтверждение заказа</h3>*/}
        {/*<p>Распечатайте и отсканируйте QR код вашего заказа, чтобы подтвердить его и вызвать курьера</p>*/}
        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
          <Button color="primary" style={{background: "#DCE2E9", fontSize: "14px", color: "#404040"}} onClick={(e) => {me.moveToFolder("На согласовании")}}>На согласование</Button>
        </div>
        {/*<QrCodeScaner qrScanning={me.state.qrScanned} />*/}

      </>
    ;
    return (xxx);
  }
}